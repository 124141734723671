import { useQuery } from '@tanstack/react-query';
import { DocumentService } from 'services';
import { DocumentPayload } from 'services/document/document.model';

const useFetchComboBoxDocument = (enabled?: number | boolean, payload?: DocumentPayload) => {
  const { data: response } = useQuery({
    queryKey: ['COMBO_BOX_ITEM_DOCUMENT_KEY', payload],
    queryFn: () => DocumentService.getDocumentComboBoxItem(payload ? payload : {}),
    enabled: !!enabled,
    keepPreviousData: true,
  });

  const dataSource = response ? response.data.map((item) => ({ label: item.Title, value: item.Id })) : [];
  return { dataSource };
};

export default useFetchComboBoxDocument;

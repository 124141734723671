import { WithPermission } from 'components';
import { PATH_NAME } from 'core/constants/path-name';
import { PermissionEnum } from 'core/enums/PermissionEnum.enum';

import { lazy, Suspense } from 'react';

const VariableBinning = lazy(() => import('pages/VariableBinning/VariableBinning'));

// Biên mục hồ sơ, tài liệu
const DocumentIndexingList = lazy(() => import('pages/VariableBinning/Children/DocumentIndexing/DocumentIndexingList'));
const DocumentIndexingListPage = WithPermission(DocumentIndexingList);
const DocumentIndexingDetail = lazy(
  () => import('pages/VariableBinning/Children/DocumentIndexing/DocumentIndexingDetail'),
);
const DocumentIndexingDetailPage = WithPermission(DocumentIndexingDetail);
const DocumentIndexingLayout = lazy(
  () => import('pages/VariableBinning/Children/DocumentIndexing/DocumentIndexingLayout'),
);
const DocumentComponentDetail = lazy(() => import('pages/Share/components/DocumentComponentDetail'));
const DocumentComponentDetailPage = WithPermission(DocumentComponentDetail);

// Duyệt biên mục bổ sung
const ReviewSupplementalIndexingList = lazy(
  () => import('pages/VariableBinning/Children/ReviewSupplementalIndexing/ReviewSupplementalIndexingList'),
);
const ReviewSupplementalIndexingListPage = WithPermission(ReviewSupplementalIndexingList);
const ReviewSupplementalIndexingLayout = lazy(
  () => import('pages/VariableBinning/Children/ReviewSupplementalIndexing/ReviewSupplementalIndexingLayout'),
);
const ReviewSupplementalIndexingDetail = lazy(
  () => import('pages/VariableBinning/Children/ReviewSupplementalIndexing/ReviewSupplementalIndexingDetail'),
);
const ReviewSupplementalIndexingDetailPage = WithPermission(ReviewSupplementalIndexingDetail);

// Biên mục bổ sung hồ sơ tài liệu
const SupplementalDocumentIndexingList = lazy(
  () => import('pages/VariableBinning/Children/SupplementalDocumentIndexing/SupplementalDocumentIndexingList'),
);
const SupplementalDocumentIndexingListPage = WithPermission(SupplementalDocumentIndexingList);
const SupplementalDocumentIndexingDetail = lazy(
  () => import('pages/VariableBinning/Children/SupplementalDocumentIndexing/SupplementalDocumentIndexingDetail'),
);
const SupplementalDocumentIndexingDetailPage = WithPermission(SupplementalDocumentIndexingDetail);
const SupplementalDocumentIndexingLayout = lazy(
  () => import('pages/VariableBinning/Children/SupplementalDocumentIndexing/SupplementalDocumentIndexingLayout'),
);

// Quản lý biên mục bổ sung
const SupplementalIndexingManagementList = lazy(
  () => import('pages/VariableBinning/Children/SupplementalIndexingManagement/SupplementalIndexingManagementList'),
);
const SupplementalIndexingManagementListPage = WithPermission(SupplementalIndexingManagementList);
const SupplementalIndexingDetail = lazy(
  () => import('pages/VariableBinning/Children/SupplementalIndexingManagement/SupplementalIndexingDetail'),
);
const SupplementalIndexingDetailPage = WithPermission(SupplementalIndexingDetail);
const SupplementalIndexingLayout = lazy(
  () => import('pages/VariableBinning/Children/SupplementalIndexingManagement/SupplementalIndexingLayout'),
);

// Duyệt lưu kho
const VariableRepositoryBrowsingList = lazy(
  () => import('pages/VariableBinning/Children/VariableRepositoryBrowsing/VariableRepositoryBrowsingList'),
);
const VariableRepositoryBrowsingListPage = WithPermission(VariableRepositoryBrowsingList);
const VariableRepositoryBrowsingDetail = lazy(
  () => import('pages/VariableBinning/Children/VariableRepositoryBrowsing/VariableRepositoryBrowsingDetail'),
);
const VariableRepositoryBrowsingDetailPage = WithPermission(VariableRepositoryBrowsingDetail);
const VariableRepositoryBrowsingLayout = lazy(
  () => import('pages/VariableBinning/Children/VariableRepositoryBrowsing/VariableRepositoryBrowsingLayout'),
);

const VARIABLE_BINNING = {
  path: `${PATH_NAME.VARIABLE_BINNING.BASE}`,
  element: (
    <Suspense fallback={'Đang tải...'}>
      <VariableBinning />
    </Suspense>
  ),
  children: [
    {
      index: true,
      element: (
        <Suspense fallback={'Đang tải...'}>
          <DocumentIndexingListPage requiredPermissions={[PermissionEnum.DOC_INDEXING_VIEW]} />
        </Suspense>
      ),
    },
    {
      path: 'bien-muc-ho-so-tai-lieu',
      element: (
        <Suspense fallback={'Đang tải...'}>
          <DocumentIndexingLayout />
        </Suspense>
      ),
      children: [
        {
          index: true,
          element: (
            <Suspense fallback={'Đang tải...'}>
              <DocumentIndexingListPage requiredPermissions={[PermissionEnum.DOC_INDEXING_VIEW]} />
            </Suspense>
          ),
        },
        {
          path: '',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <DocumentIndexingListPage requiredPermissions={[PermissionEnum.DOC_INDEXING_VIEW]} />
            </Suspense>
          ),
        },
        {
          path: 'chi-tiet-ho-so/:id',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <DocumentIndexingDetailPage requiredPermissions={[PermissionEnum.DOC_INDEXING_DETAIL]} />
            </Suspense>
          ),
        },
        {
          path: 'bien-muc-tai-lieu/:id',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <DocumentComponentDetailPage requiredPermissions={[PermissionEnum.DOC_INDEXING_DETAIL]} />
            </Suspense>
          ),
        },
        {
          path: 'bien-muc-tai-lieu',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <DocumentComponentDetailPage requiredPermissions={[PermissionEnum.DOC_INDEXING_DETAIL]} />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: 'duyet-luu-kho',
      element: (
        <Suspense fallback={'Đang tải...'}>
          <VariableRepositoryBrowsingLayout />
        </Suspense>
      ),
      children: [
        {
          index: true,
          element: (
            <Suspense fallback={'Đang tải...'}>
              <VariableRepositoryBrowsingListPage
                requiredPermissions={[PermissionEnum.VARIABLE_REPOSITORY_BROWSING_VIEW]}
              />
            </Suspense>
          ),
        },
        {
          path: '',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <VariableRepositoryBrowsingListPage
                requiredPermissions={[PermissionEnum.VARIABLE_REPOSITORY_BROWSING_VIEW]}
              />
            </Suspense>
          ),
        },
        {
          path: 'chi-tiet-ho-so/:id',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <VariableRepositoryBrowsingDetailPage
                requiredPermissions={[PermissionEnum.VARIABLE_REPOSITORY_BROWSING_DETAIL]}
              />
            </Suspense>
          ),
        },
        {
          path: 'bien-muc-tai-lieu/:id',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <DocumentComponentDetailPage
                requiredPermissions={[PermissionEnum.DOC_COMP_VARIABLE_REPOSITORY_BROWSING_DETAIL]}
              />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: 'quan-ly-bien-muc-bo-sung',
      element: (
        <Suspense fallback={'Đang tải...'}>
          <SupplementalIndexingLayout />
        </Suspense>
      ),
      children: [
        {
          index: true,
          element: (
            <Suspense fallback={'Đang tải...'}>
              <SupplementalIndexingManagementListPage
                requiredPermissions={[PermissionEnum.SUPPLEMENTAL_INDEXING_MANAGEMENT_VIEW]}
              />
            </Suspense>
          ),
        },
        {
          path: '',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <SupplementalIndexingManagementListPage
                requiredPermissions={[PermissionEnum.SUPPLEMENTAL_INDEXING_MANAGEMENT_VIEW]}
              />
            </Suspense>
          ),
        },
        {
          path: 'chi-tiet-ho-so/:id',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <SupplementalIndexingDetailPage
                requiredPermissions={[PermissionEnum.SUPPLEMENTAL_INDEXING_MANAGEMENT_DETAIL]}
              />
            </Suspense>
          ),
        },
        {
          path: 'bien-muc-tai-lieu/:id',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <DocumentComponentDetailPage
                requiredPermissions={[PermissionEnum.SUPPLEMENTAL_INDEXING_MANAGEMENT_DETAIL]}
              />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: 'duyet-bien-muc-bo-sung',
      element: (
        <Suspense fallback={'Đang tải...'}>
          <ReviewSupplementalIndexingLayout />
        </Suspense>
      ),
      children: [
        {
          index: true,
          element: (
            <Suspense fallback={'Đang tải...'}>
              <ReviewSupplementalIndexingListPage
                requiredPermissions={[PermissionEnum.REVIEW_SUPPLEMENTAL_INDEXING_VIEW]}
              />
            </Suspense>
          ),
        },
        {
          path: '',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <ReviewSupplementalIndexingListPage
                requiredPermissions={[PermissionEnum.REVIEW_SUPPLEMENTAL_INDEXING_VIEW]}
              />
            </Suspense>
          ),
        },
        {
          path: 'chi-tiet-ho-so/:id',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <ReviewSupplementalIndexingDetailPage
                requiredPermissions={[PermissionEnum.REVIEW_SUPPLEMENTAL_INDEXING_DETAIL]}
              />
            </Suspense>
          ),
        },
        {
          path: 'bien-muc-tai-lieu/:id',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <DocumentComponentDetailPage requiredPermissions={[PermissionEnum.REVIEW_SUPPLEMENTAL_INDEXING_DETAIL]} />
            </Suspense>
          ),
        },
      ],
    },

    {
      path: 'bien-muc-bo-sung-ho-so-tai-lieu',
      element: (
        <Suspense fallback={'Đang tải...'}>
          <SupplementalDocumentIndexingLayout />
        </Suspense>
      ),
      children: [
        {
          index: true,
          element: (
            <Suspense fallback={'Đang tải...'}>
              <SupplementalDocumentIndexingListPage
                requiredPermissions={[PermissionEnum.SUPPLEMENTAL_DOCUMENT_INDEXING_VIEW]}
              />
            </Suspense>
          ),
        },
        {
          path: '',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <SupplementalDocumentIndexingListPage
                requiredPermissions={[PermissionEnum.SUPPLEMENTAL_DOCUMENT_INDEXING_VIEW]}
              />
            </Suspense>
          ),
        },
        {
          path: 'chi-tiet-ho-so/:id',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <SupplementalDocumentIndexingDetailPage
                requiredPermissions={[PermissionEnum.SUPPLEMENTAL_DOCUMENT_INDEXING_DETAIL]}
              />
            </Suspense>
          ),
        },
        {
          path: 'bien-muc-tai-lieu/:id',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <DocumentComponentDetailPage
                requiredPermissions={[PermissionEnum.SUPPLEMENTAL_DOCUMENT_INDEXING_DETAIL_DOC_COMP]}
              />
            </Suspense>
          ),
        },
        {
          path: 'bien-muc-tai-lieu',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <DocumentComponentDetailPage
                requiredPermissions={[PermissionEnum.SUPPLEMENTAL_DOCUMENT_INDEXING_DETAIL_DOC_COMP]}
              />
            </Suspense>
          ),
        },
      ],
    },
  ],
};
export default VARIABLE_BINNING;

import { useQuery } from '@tanstack/react-query';
import { UserService } from 'services/user';

const useFetchComboboxItemApprover = (enabled = true) => {
  const { data: response } = useQuery({
    queryKey: ['APPROVER_COMBO_BOX_KEY'],
    queryFn: () => UserService.getComboboxItemApprover({}),
    enabled,
  });

  const dataSource = response ? response.data.map((item) => ({ label: item.FullName, value: item.UserName })) : [];
  return { dataSource };
};

export default useFetchComboboxItemApprover;

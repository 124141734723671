import { ReactNode } from 'react';
import checkPermission from 'utils/check-permission';

interface CheckPermissionProps {
  allowedPermissions: any[];
  children: ReactNode;
}

const CheckPermission = ({ allowedPermissions, children }: CheckPermissionProps): any => {
  return checkPermission(allowedPermissions) ? children : null;
};

export default CheckPermission;

import { useQuery } from '@tanstack/react-query';
import { CategoryService } from 'services';

const useFetchTypeCategory = (queryKey: string, type: number, enabled = true) => {
  const { data: response } = useQuery({
    queryKey: [queryKey, type],
    queryFn: () => CategoryService.getComboBoxCategory({ Type: type }),
    enabled: enabled,
  });

  const dataSource = response ? response.data.map((item) => ({ label: item.Name, value: item.Id })) : [];
  return { dataSource };
};

export default useFetchTypeCategory;

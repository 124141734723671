import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

interface FilterDocument {
  dataFilter: any;
}
const initialState: FilterDocument = {
  dataFilter: null,
};

const filterDocument = createSlice({
  name: 'filterDocument',
  initialState,
  reducers: {
    setFilterDocument: (state: FilterDocument, action: PayloadAction<any>) => {
      state.dataFilter = action.payload;
    },
    removeFilterDocument: (state: FilterDocument) => {
      state.dataFilter = initialState.dataFilter;
    },
  },
});

export const { setFilterDocument, removeFilterDocument } = filterDocument.actions;

export default filterDocument.reducer;

export const getFilterDocumentSlice = (state: RootState) => state.filterDocument.dataFilter;

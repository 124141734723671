import { Layout } from 'antd';
import Header from 'layouts/Header';
import SideBar from 'layouts/SideBar';
import { useState } from 'react';
import { Link, Outlet } from 'react-router-dom';

const { Sider, Content } = Layout;

const AppLayout = () => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Layout className='AppLayout'>
      <Sider className='sider' theme='light' trigger={null} collapsible collapsed={collapsed} width={260}>
        <div className='logo'>
          {collapsed ? (
            <Link to={'/dashboard'}>
              <img srcSet='/logos/logo-small-dman.png' alt='logo' />
            </Link>
          ) : (
            <Link to={'/dashboard'}>
              <img srcSet='/logos/logo-full-dman.png 2x' alt='logo' />
            </Link>
          )}
        </div>

        <SideBar />
      </Sider>

      <Layout className='site-layout'>
        {/* header */}
        <Header collapsed={collapsed} setCollapsed={setCollapsed} />

        {/* content */}
        <Content className='content'>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default AppLayout;

import { useQuery } from '@tanstack/react-query';
import { ArchivesService } from 'services/own-categories';

const useFetchArchives = (enabled = true) => {
  const { data: response } = useQuery({
    queryKey: ['ARCHIVER_KEY'],
    queryFn: () => ArchivesService.getComboBoxArchives({}),
    enabled: enabled,
  });

  const dataSource = response ? response.data.map((item) => ({ label: item.ArchivesName, value: item.Id })) : [];
  return { dataSource };
};
export default useFetchArchives;

import { FileNameUrl } from 'core/interface/file-url';
import { ResponseWithPaging } from 'core/interface/response-has-paging';
import { FileStoragePayload, FileStorageSearch } from 'services/file-storage/file-storage.model';
import httpClient from 'services/http-client';

export const PREFIX_AUTH = '/api/FileStorage';

const getFileStorageDetail = (id: number) => {
  return httpClient.get<FileNameUrl>(`${PREFIX_AUTH}/id=${id}`);
};

const getFileStorage = (payload: FileStorageSearch) => {
  return httpClient.post<ResponseWithPaging<FileNameUrl[]>>(`${PREFIX_AUTH}/search`, payload);
};
const createFileStorage = (payload: FileStoragePayload) => {
  return httpClient.post(`${PREFIX_AUTH}`, payload);
};
const updateFileStorage = (payload: FileStoragePayload) => {
  return httpClient.put(`${PREFIX_AUTH}`, payload);
};
const deleteFileStorage = (id: number) => {
  return httpClient.delete(`${PREFIX_AUTH}`, { params: { id: id } });
};
const uploadFile = (payload: FormData) => {
  return httpClient.post<FileNameUrl>(`${PREFIX_AUTH}/uploadFile`, payload, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};
const uploadVideoAndImages = (payload: FormData) => {
  return httpClient.post<FileNameUrl>(`${PREFIX_AUTH}/uploadFileVideoAndImages`, payload, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};
const deleteFile = (url: string) => {
  return httpClient.post(`${PREFIX_AUTH}/deleteFile`, { url });
};

const FileStorageService = {
  getFileStorage,
  getFileStorageDetail,
  createFileStorage,
  updateFileStorage,
  deleteFileStorage,
  uploadFile,
  uploadVideoAndImages,
  deleteFile,
};

export default FileStorageService;

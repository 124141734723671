import { useQuery } from '@tanstack/react-query';
import { UserService } from 'services/user';
import { UserPayload } from 'services/user/user/user.model';

const useFetchComboboxItemUser = (enabled = true, payload?: UserPayload) => {
  const { data: response } = useQuery({
    queryKey: ['USER_COMBO_BOX_KEY'],
    queryFn: () => UserService.getUserComboboxItem(payload ? payload : {}),
    enabled,
  });

  const dataSource = response
    ? response.data.map((item) => ({ label: item.FullName, value: item.UserName, permission: item.Permissions }))
    : [];
  return { dataSource };
};

export default useFetchComboboxItemUser;

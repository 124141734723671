import { InboxOutlined } from '@ant-design/icons';
import { Col, Upload, Typography, Form, FormInstance } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { OCR, UploadFile } from 'assets/icons';
import ModalComponent from 'components/ModalComponent';
import SelectComponent from 'components/SelectComponent';
import { File } from 'core/constants/file';
import { BaseModal } from 'core/interface/base-modal';
import { useModal, useShowNotification } from 'hooks';
import { t } from 'i18next';
import { Dispatch, useState } from 'react';
import { UserService } from 'services/user';
import { getErrorMessage, showNotification } from 'utils';

interface UploadFileAndOCRProps {
  setFileList: Dispatch<any>;
  preview: any;
  disable?: boolean;
  allowed: RegExp;
  formDocument: FormInstance<any>;
}
enum DocuComponent {
  THONG_TU_30 = 2,
}

const { Dragger } = Upload;
interface ModalOCRProps extends BaseModal {
  handleOk: any;
  form: FormInstance<any>;
  setFileOcr: Dispatch<any>;
  disable?: boolean;
}
const ModalOCR = ({ form, handleOk, hide, isModalOpen, setFileOcr, disable }: ModalOCRProps) => {
  const typeDocuComponent = [{ label: 'Thông tư 30', value: DocuComponent.THONG_TU_30 }];

  const uploadFileProps = {
    multiple: false,
    maxCount: 1,
    beforeUpload(file: any) {
      if (file.size > File.LIMIT_SIZE) {
        showNotification('error', t('notification.error'), 'Kích thước tập tin không quá 100MB');
        return Upload.LIST_IGNORE;
      }
      if (!File.ALLOWED_OCR.exec(file.name)) {
        showNotification('error', t('notification.error'), 'Tập tin không đúng định dạng');
        return Upload.LIST_IGNORE;
      }
      return true;
    },
    onChange(info: any) {
      const { file } = info;
      setFileOcr(file.originFileObj);
    },
  };

  const customRequest = (options: any) => {
    const { onSuccess } = options;
    onSuccess('done');
  };

  return (
    <ModalComponent
      isModalOpen={isModalOpen}
      hide={hide}
      title='OCR tài liệu'
      handleOk={handleOk}
      width={500}
      okText='Lưu'
      className='ModalOCR'
    >
      <div className='ocr'>
        <Form layout='vertical' form={form}>
          <Form.Item
            label='Loại tài liệu'
            name='TypeDocumentComponent'
            rules={[{ required: true, message: 'Loại tài liệu không được bỏ trống' }]}
          >
            <SelectComponent dataSources={typeDocuComponent} placeholder='Chọn Loại tài liệu' classSelect='mb8' />
          </Form.Item>
          <Form.Item>
            <div className='dragger'>
              <Dragger name='files' {...uploadFileProps} customRequest={customRequest} disabled={disable}>
                <InboxOutlined />
                <span>
                  Kéo thả file vào đây để tải lên hoặc <span className='text-hight-light'>Chọn file từ thiết bị</span>
                </span>
              </Dragger>
            </div>
          </Form.Item>
        </Form>
      </div>
    </ModalComponent>
  );
};

const UploadFileAndOCR = (props: UploadFileAndOCRProps) => {
  const [form] = Form.useForm();
  const { setFileList, preview, disable = false, allowed, formDocument } = props;
  const { hideModal, modal, openModal } = useModal();
  const [fileOcr, setFileOcr] = useState<any>(null);
  const { handleError, handleSuccess } = useShowNotification();

  const uploadFileProps = {
    multiple: false,
    maxCount: 1,
    beforeUpload(file: any) {
      if (file.size > File.LIMIT_SIZE) {
        showNotification('error', t('notification.error'), 'Kích thước tập tin không quá 100MB');
        return Upload.LIST_IGNORE;
      }
      if (!allowed.exec(file.name)) {
        showNotification('error', t('notification.error'), 'Tập tin không đúng định dạng');
        return Upload.LIST_IGNORE;
      }
      return true;
    },
    onChange(info: any) {
      const { file } = info;
      setFileList(file.originFileObj);
    },
  };

  const customRequest = (options: any) => {
    const { onSuccess } = options;
    onSuccess('done');
  };

  const handleOk = (): void => {
    if (!fileOcr) {
      handleError('Bạn chưa tải file lên!');
      return;
    }
    form.validateFields().then(async () => {
      const typeDocumentComponent = form.getFieldValue('TypeDocumentComponent');
      const [file] = [fileOcr];
      const formData = new FormData();
      formData.append('FileThongTu', file as RcFile);
      if (typeDocumentComponent === DocuComponent.THONG_TU_30) {
        UserService.getOCRThongTu30ByImage(formData)
          .then(({ data }) => {
            setFileList(fileOcr);
            handleSuccess('Tài liệu OCR thành công!', undefined, hideModal);
            const regex = /([A-Z]+-[A-Z]+)/;
            const matches = regex.exec(data.data.info.sohieu);
            const result = Array.isArray(matches) ? matches[1] : '';
            formDocument.setFieldsValue({
              DocumentSymbol: result,
              Author: data.data.info.co_quan,
              NumberDocument: data.data.info.sohieu.match(/\d{2}\/\d{4}/)[0],
              Content: data.data.info.trich_yeu,
            });
          })
          .catch((error) => handleError(getErrorMessage(error)));
      }
    });
  };
  return (
    <div>
      {!preview && (
        <div className='UploadFileAndOCR'>
          <div className='upload-file'>
            <Dragger name='files' {...uploadFileProps} customRequest={customRequest} disabled={disable}>
              <UploadFile />
              <div>
                <span className='text-hight-light'>Chọn file từ thiết bị</span> hoặc kéo thả file vào đây để tải lên
                hoặc
              </div>
              <p>Kích thước file không quá 100MB, định dạng PDF, PNG, JPG, MPEG-4, AVI hoặc WMV.</p>
            </Dragger>
          </div>
          <div className={`upload-OCR ${disable ? 'disable' : ''}`}>
            <div className='item-OCR' onClick={() => (!disable ? openModal() : null)}>
              <div className='OCR'>
                <OCR />
                <div>
                  <span className='text-hight-light'>Chọn loại văn bản OCR</span>
                </div>
                <p>Kích thước file không quá 100MB, định dạng PDF.</p>
              </div>
            </div>
          </div>
        </div>
      )}
      {modal.show && (
        <ModalOCR
          hide={hideModal}
          isModalOpen={modal.show}
          handleOk={handleOk}
          form={form}
          setFileOcr={setFileOcr}
          disable={disable}
        />
      )}
    </div>
  );
};

export default UploadFileAndOCR;

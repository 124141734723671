import { WithPermission } from 'components';
import { PATH_NAME } from 'core/constants/path-name';
import { PermissionEnum } from 'core/enums/PermissionEnum.enum';
import { lazy, Suspense } from 'react';

const SystemManagement = lazy(() => import('pages/SystemManagement/SystemManagement'));
const SystemLogs = lazy(() => import('pages/SystemManagement/Children/SystemLogs/SystemLogs'));
const SystemLogsPage = WithPermission(SystemLogs);

const SYSTEM_MANAGEMENT = {
  path: `${PATH_NAME.SYSTEM_MANAGEMENT.BASE}`,
  element: (
    <Suspense fallback={'Đang tải...'}>
      <SystemManagement />
    </Suspense>
  ),
  children: [
    {
      index: true,
      element: (
        <Suspense fallback={'Đang tải...'}>
          <SystemLogsPage requiredPermissions={[PermissionEnum.SYSTEM_LOGS_VIEW]} />
        </Suspense>
      ),
    },
    {
      path: 'nhat-ky-he-thong',
      element: (
        <Suspense fallback={'Đang tải...'}>
          <SystemLogsPage requiredPermissions={[PermissionEnum.SYSTEM_LOGS_VIEW]} />
        </Suspense>
      ),
    },
  ],
};
export default SYSTEM_MANAGEMENT;

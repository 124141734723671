import { useQuery } from '@tanstack/react-query';
import { ShelfService } from 'services/own-categories';
import { ShelfPayload } from 'services/own-categories/shelf/shelf.model';

const useFetchShelf = (enabled = true, payload?: ShelfPayload) => {
  const { data: response } = useQuery({
    queryKey: ['SHELF_KEY', payload],
    queryFn: () => ShelfService.getComboBoxShelf(payload ? payload : {}),
    enabled: enabled,
  });

  const dataSource = response ? response.data.map((item) => ({ label: item.Name, value: item.Id })) : [];
  return { dataSource };
};
export default useFetchShelf;

import { useQuery } from '@tanstack/react-query';
import { BoxService } from 'services/own-categories';
import { BoxPayload } from 'services/own-categories/box/box.model';

const useFetchBox = (enabled = true, payload?: BoxPayload) => {
  const { data: response } = useQuery({
    queryKey: ['BOX_KEY', payload],
    queryFn: () => BoxService.getComboboxBox(payload ? payload : {}),
    enabled: enabled,
  });

  const dataSource = response ? response.data.map((item) => ({ label: item.Name, value: item.Id })) : [];
  return { dataSource };
};
export default useFetchBox;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

interface FilterRaise {
  dataFilter: any;
  numberFilter: number;
}
const initialState: FilterRaise = {
  dataFilter: null,
  numberFilter: 0,
};

const filterRaiseSlice = createSlice({
  name: 'filterRaise',
  initialState,
  reducers: {
    setFilterRaise: (state: FilterRaise, action: PayloadAction<any>) => {
      state.dataFilter = action.payload.newPayload;
      state.numberFilter = action.payload.numberFilter;
    },
    removeFilter: (state: FilterRaise) => {
      state.dataFilter = initialState.dataFilter;
      state.numberFilter = initialState.numberFilter;
    },
  },
});

export const { setFilterRaise, removeFilter } = filterRaiseSlice.actions;

export default filterRaiseSlice.reducer;

export const getFilterRaiseSlice = (state: RootState) => state.filter.dataFilter;
export const getNumberFilterSlice = (state: RootState) => state.filter.numberFilter;

import { Paging } from 'core/interface/paging';
import { ResponseWithPaging } from 'core/interface/response-has-paging';
import httpClient from 'services/http-client';
import {
  AdminChangePass,
  ComboBoxReader,
  User,
  UserChangePass,
  UserPayload,
  resultOCR,
} from 'services/user/user/user.model';

export const PREFIX_USER = '/api/users';

const getUserComboboxItem = (payload: UserPayload) => {
  return httpClient.post<User[]>(`${PREFIX_USER}/getComboboxItem`, payload);
};
const getComboboxItemApprover = (payload: UserPayload) => {
  return httpClient.post<User[]>(`${PREFIX_USER}/getComboboxItemApprover`, payload);
};
const updateUser = (payload: UserPayload) => {
  return httpClient.put<User[]>(`${PREFIX_USER}/update-user`, payload);
};
const updateCurrentUser = (payload: UserPayload) => {
  return httpClient.put<User[]>(`${PREFIX_USER}/update-current-user`, payload);
};
const getUserDetail = (id: number) => {
  return httpClient.get<User>(`${PREFIX_USER}/get-user-by-id/${id}`);
};
const getCurrentUserDetail = () => {
  return httpClient.get<User>(`${PREFIX_USER}`);
};
const borrowListDocument = (listId: number[]) => {
  return httpClient.post<User>(`${PREFIX_USER}/borrowListDocument`, listId);
};

const getReaderList = (payload: UserPayload, paging: Paging) => {
  return httpClient.post<ResponseWithPaging<User[]>>(`${PREFIX_USER}/SearchReader`, payload, {
    headers: {
      PageOption: JSON.stringify(paging),
    },
  });
};
const getComboBoxItemReader = (payload: UserPayload) => {
  return httpClient.post<ComboBoxReader[]>(`${PREFIX_USER}/getComboboxItemReader`, payload);
};
const getUserList = (payload: UserPayload, paging: Paging) => {
  return httpClient.post<ResponseWithPaging<User[]>>(`${PREFIX_USER}/SearchData`, payload, {
    headers: {
      PageOption: JSON.stringify(paging),
    },
  });
};
const deleteUser = (id: number) => {
  return httpClient.delete(`${PREFIX_USER}`, { params: { id: id } });
};
const createUser = (payload: User) => {
  return httpClient.post<User[]>(`${PREFIX_USER}/create-user`, payload);
};
const createReaderUser = (payload: User) => {
  return httpClient.post<User[]>(`${PREFIX_USER}/createReaderUser`, payload);
};

const getAllUser = (payload: UserPayload) => {
  return httpClient.post<User[]>(`${PREFIX_USER}/SearchData`, payload);
};

const exportFileExcel = (payload: UserPayload) => {
  return httpClient.post(`${PREFIX_USER}/ExportListManagerUser`, payload, {
    responseType: 'blob',
  });
};
const exportListUserReader = (payload: UserPayload) => {
  return httpClient.post(`${PREFIX_USER}/ExportListUserReader`, payload, {
    responseType: 'blob',
  });
};

const adminChangePass = (payload: AdminChangePass) => {
  return httpClient.put<User[]>(`${PREFIX_USER}/admin-reset-pasword`, payload);
};
const userChangePass = (payload: UserChangePass) => {
  return httpClient.put<User[]>(`${PREFIX_USER}/user-reset-pasword`, payload);
};
const firebaseRegisterFCMToken = (payload: string, token: string) => {
  return httpClient.post(`${PREFIX_USER}/fb-register`, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const firebaseUnregisterFCMToken = (payload: string) => {
  return httpClient.post(`${PREFIX_USER}/fb-unregister`, payload);
};

const deleteUserList = (idList: number[]) => {
  return httpClient.delete(`${PREFIX_USER}/DeleteList`, {
    data: idList,
  });
};
const getOCRThongTu30ByImage = (payload: FormData) => {
  return httpClient.post(`${PREFIX_USER}/getOCRThongTu30ByImage`, payload, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};
const getOCRIDCardByImage = (payload: FormData) => {
  return httpClient.post<resultOCR>(`${PREFIX_USER}/getOCRIDCardByImage`, payload, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

const UserService = {
  getUserComboboxItem,
  updateUser,
  getUserDetail,
  getAllUser,
  getUserList,
  createUser,
  deleteUser,
  exportFileExcel,
  adminChangePass,
  getReaderList,
  firebaseRegisterFCMToken,
  firebaseUnregisterFCMToken,
  userChangePass,
  deleteUserList,
  getComboboxItemApprover,
  createReaderUser,
  exportListUserReader,
  getComboBoxItemReader,
  borrowListDocument,
  getOCRThongTu30ByImage,
  getOCRIDCardByImage,
  getCurrentUserDetail,
  updateCurrentUser,
};

export default UserService;

import { DeleteOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
interface VideoViewerProps {
  preview: string;
  onRemovePdfFile?: () => void;
  handleLoadedMetadata: React.ReactEventHandler<HTMLVideoElement> | undefined;
}
const VideoViewer = (props: VideoViewerProps) => {
  const handleRemoveFile = () => {
    if (props.onRemovePdfFile) props.onRemovePdfFile();
  };
  return (
    <div className='ImageVideoViewer'>
      <div className='image-video-action'>
        <Button type='primary' ghost size='small' icon={<DeleteOutlined />} onClick={handleRemoveFile}>
          Xóa file
        </Button>
      </div>
      <div className='image-video'>
        <video width='100%' controls onLoadedMetadata={props.handleLoadedMetadata}>
          {!!props.preview && <source src={props.preview} type='video/mp4' />}
        </video>
      </div>
    </div>
  );
};

export default VideoViewer;

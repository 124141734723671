import { ComboBoxItem } from 'core/interface/combo-box-item';
import { Paging } from 'core/interface/paging';
import { ResponseWithPaging } from 'core/interface/response-has-paging';
import httpClient from 'services/http-client';
import { ArchivesPayload, ComboBoxArchives } from 'services/own-categories/archives/archives.model';

export const PREFIX_ARCHIVES = '/api/Archives';

const getArchives = (payload: ArchivesPayload, paging: Paging) => {
  return httpClient.post<ResponseWithPaging<ArchivesPayload[]>>(`${PREFIX_ARCHIVES}/search`, payload, {
    headers: {
      PageOption: JSON.stringify(paging),
    },
  });
};

const getComboBoxArchives = (payload: ArchivesPayload) => {
  return httpClient.post<ComboBoxArchives[]>(`${PREFIX_ARCHIVES}/getComboboxItem`, payload);
};

const getArchivesDetail = (id: number) => {
  return httpClient.get<ArchivesPayload>(`${PREFIX_ARCHIVES}/id=${id}`);
};
const createArchives = (payload: ArchivesPayload) => {
  return httpClient.post(`${PREFIX_ARCHIVES}`, payload);
};
const exportListArchives = (payload: ArchivesPayload) => {
  return httpClient.post(`${PREFIX_ARCHIVES}/ExportListArchives`, payload, {
    responseType: 'blob',
  });
};
const updateArchives = (payload: ArchivesPayload) => {
  return httpClient.put(`${PREFIX_ARCHIVES}`, payload);
};
const deleteArchives = (id: number) => {
  return httpClient.delete(`${PREFIX_ARCHIVES}`, { params: { id: id } });
};

const ArchivesService = {
  getArchives,
  getArchivesDetail,
  createArchives,
  updateArchives,
  deleteArchives,
  exportListArchives,
  getComboBoxArchives,
};

export default ArchivesService;

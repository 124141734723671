import { useQuery } from '@tanstack/react-query';
import { ProfileTypeService } from 'services/own-categories';

const useFetchProfileType = (enabled = true) => {
  const { data: response } = useQuery({
    queryKey: ['PROFILE_TYPE_KEY'],
    queryFn: () => ProfileTypeService.getComboBoxProfileType({}),
    enabled: enabled,
  });

  const dataSource = response ? response.data.map((item) => ({ label: item.Name, value: item.Id })) : [];
  return { dataSource };
};
export default useFetchProfileType;

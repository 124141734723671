import { WithPermission } from 'components';
import { PATH_NAME } from 'core/constants/path-name';
import { PermissionEnum } from 'core/enums/PermissionEnum.enum';
import { lazy, Suspense } from 'react';

const SubmitHistory = lazy(() => import('pages/SubmitHistory/SubmitHistory'));
const DocumentComponentDetail = lazy(() => import('pages/Share/components/DocumentComponentDetail'));
// Quản lý hồ sơ nộp lưu lịch sử
const DocumentComponentDetailPage = WithPermission(DocumentComponentDetail);
const SubmitHistoryManagement = lazy(
  () => import('pages/SubmitHistory/Children/SubmitHistoryManagement/SubmitHistoryManagement'),
);
const SubmitHistoryManagementPage = WithPermission(SubmitHistoryManagement);
const SubmitHistoryManagementDetail = lazy(
  () => import('pages/SubmitHistory/Children/SubmitHistoryManagement/SubmitHistoryManagementDetail'),
);
const SubmitHistoryManagementDetailPage = WithPermission(SubmitHistoryManagementDetail);
const SubmitHistoryManagementLayout = lazy(
  () => import('pages/SubmitHistory/Children/SubmitHistoryManagement/SubmitHistoryManagementLayout'),
);

// Duyệt hồ sơ nộp lưu lịch sử

const ReviewSubmitHistoryDetail = lazy(
  () => import('pages/SubmitHistory/Children/ReviewSubmitHistory/ReviewSubmitHistoryDetail'),
);
const ReviewSubmitHistoryDetailPage = WithPermission(ReviewSubmitHistoryDetail);
const ReviewSubmitHistoryLayout = lazy(
  () => import('pages/SubmitHistory/Children/ReviewSubmitHistory/ReviewSubmitHistoryLayout'),
);
const ReviewSubmitHistory = lazy(() => import('pages/SubmitHistory/Children/ReviewSubmitHistory/ReviewSubmitHistory'));
const ReviewSubmitHistoryPage = WithPermission(ReviewSubmitHistory);

// Danh sách hồ sơ đã nộp lưu lịch sử

const SubmittedHistory = lazy(() => import('pages/SubmitHistory/Children/SubmittedHistory/SubmittedHistory'));
const SubmittedHistoryPage = WithPermission(SubmittedHistory);
const SubmittedHistoryDetail = lazy(
  () => import('pages/SubmitHistory/Children/SubmittedHistory/SubmittedHistoryDetail'),
);
const SubmittedHistoryDetailPage = WithPermission(SubmittedHistoryDetail);
const SubmittedHistoryLayout = lazy(
  () => import('pages/SubmitHistory/Children/SubmittedHistory/SubmittedHistoryLayout'),
);

const SUBMIT_HISTORY = {
  path: `${PATH_NAME.SUBMIT_HISTORY.BASE}`,
  element: (
    <Suspense fallback={'Đang tải...'}>
      <SubmitHistory />
    </Suspense>
  ),
  children: [
    {
      index: true,
      element: (
        <Suspense fallback={'Đang tải...'}>
          <SubmitHistoryManagementPage requiredPermissions={[PermissionEnum.SUBMIT_HISTORY_MANAGEMENT_VIEW]} />
        </Suspense>
      ),
    },
    {
      path: 'quan-ly-ho-so-nop-luu-lich-su',
      element: (
        <Suspense fallback={'Đang tải...'}>
          <SubmitHistoryManagementLayout />
        </Suspense>
      ),
      children: [
        {
          index: true,
          element: (
            <Suspense fallback={'Đang tải...'}>
              <SubmitHistoryManagementPage requiredPermissions={[PermissionEnum.SUBMIT_HISTORY_MANAGEMENT_VIEW]} />
            </Suspense>
          ),
        },
        {
          path: '',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <SubmitHistoryManagementPage requiredPermissions={[PermissionEnum.SUBMIT_HISTORY_MANAGEMENT_VIEW]} />
            </Suspense>
          ),
        },
        {
          path: 'chi-tiet-ho-so/:id',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <SubmitHistoryManagementDetailPage
                requiredPermissions={[PermissionEnum.SUBMIT_HISTORY_MANAGEMENT_DETAIL]}
              />
            </Suspense>
          ),
        },
        {
          path: 'chi-tiet-tai-lieu/:id',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <DocumentComponentDetailPage
                requiredPermissions={[PermissionEnum.SUBMIT_HISTORY_MANAGEMENT_DETAIL_DOC_COMP]}
              />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: 'duyet-ho-so-nop-luu-lich-su',
      element: (
        <Suspense fallback={'Đang tải...'}>
          <ReviewSubmitHistoryLayout />
        </Suspense>
      ),
      children: [
        {
          index: true,
          element: (
            <Suspense fallback={'Đang tải...'}>
              <ReviewSubmitHistoryPage requiredPermissions={[PermissionEnum.REVIEW_SUBMIT_HISTORY_VIEW]} />
            </Suspense>
          ),
        },
        {
          path: '',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <ReviewSubmitHistoryPage requiredPermissions={[PermissionEnum.REVIEW_SUBMIT_HISTORY_VIEW]} />
            </Suspense>
          ),
        },
        {
          path: 'chi-tiet-ho-so/:id',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <ReviewSubmitHistoryDetailPage requiredPermissions={[PermissionEnum.REVIEW_SUBMIT_HISTORY_DETAIL]} />
            </Suspense>
          ),
        },
        {
          path: 'chi-tiet-tai-lieu/:id',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <DocumentComponentDetailPage
                requiredPermissions={[PermissionEnum.REVIEW_SUBMIT_HISTORY_DETAIL_DOC_COMP]}
              />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: 'danh-sach-ho-so-da-nop-luu-lich-su',
      element: (
        <Suspense fallback={'Đang tải...'}>
          <SubmittedHistoryLayout />
        </Suspense>
      ),
      children: [
        {
          index: true,
          element: (
            <Suspense fallback={'Đang tải...'}>
              <SubmittedHistoryPage requiredPermissions={[PermissionEnum.SUBMITTED_HISTORY_VIEW]} />
            </Suspense>
          ),
        },
        {
          path: '',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <SubmittedHistoryPage requiredPermissions={[PermissionEnum.SUBMITTED_HISTORY_VIEW]} />
            </Suspense>
          ),
        },
        {
          path: 'chi-tiet-ho-so/:id',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <SubmittedHistoryDetailPage requiredPermissions={[PermissionEnum.SUBMITTED_HISTORY_DETAIL]} />
            </Suspense>
          ),
        },
        {
          path: 'chi-tiet-tai-lieu/:id',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <DocumentComponentDetailPage requiredPermissions={[PermissionEnum.SUBMITTED_HISTORY_DETAIL_DOC_COMP]} />
            </Suspense>
          ),
        },
      ],
    },
  ],
};
export default SUBMIT_HISTORY;

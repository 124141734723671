import { useQueryClient } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface useCallBackNoticeProps {
  queryKey?: string;
  openModal?: (modalId?: string | number | undefined, data?: any) => void;
  modalKey?: string | number;
  pathName?: string;
}

const useCallBackNotice = ({ queryKey, openModal, modalKey, pathName }: useCallBackNoticeProps) => {
  const { state: stateLocation, pathname } = useLocation();
  const queryClient = useQueryClient();
  useEffect(() => {
    if (stateLocation && stateLocation.isNotice && !!queryKey && pathname === pathName) {
      queryClient.invalidateQueries({ queryKey: [queryKey] });
    }
    if (stateLocation && stateLocation.isModal && openModal) {
      openModal(modalKey, { mode: 'view', id: stateLocation.objectId });
    }
  }, [stateLocation]);
};

export default useCallBackNotice;

import LocationData from './location.json';

export interface Province {
  Id: string;
  Name: string;
  Districts: District[];
}

export interface District {
  Id: string;
  Name: string;
  Wards: Ward[] | [];
}

export interface Ward {
  Id: string;
  Name: string;
  Level: string;
}

const getProvinceList = (): Province[] => LocationData;

const getProvinceById = (provinceId: string): Province | undefined =>
  LocationData.find((item) => item.Id === provinceId);

const getDistrictsByProvince = (provinceId: string): District[] => {
  const foundDistricts = LocationData.find((item) => item.Id === provinceId)?.Districts ?? [];
  return foundDistricts;
};

const getWardsByDistrict = (provinceId: string, districtId: string): Ward[] => {
  const foundDistricts = LocationData.find((item) => item.Id === provinceId)?.Districts ?? [];
  const foundWards = foundDistricts.find((item) => item.Id === districtId)?.Wards ?? [];
  return foundWards;
};

const getDistrictById = (provinceId: string, districtId: string) => {
  const foundDistricts = LocationData.find((item) => item.Id === provinceId)?.Districts;
  const foundDistrict = foundDistricts?.find((district) => district.Id === districtId);
  return foundDistrict;
};

const LocationService = {
  getProvinceList,
  getProvinceById,
  getDistrictsByProvince,
  getDistrictById,
  getWardsByDistrict,
};

export default LocationService;

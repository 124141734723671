export const PATH_NAME = {
  DASHBOARD: '/dashboard',
  NOTICE: '/thong-bao',
  LOGIN: '/dang-nhap',
  FORGOT_PASSWORD: '/quen-mat-khau',
  RESET_PASSWORD: '/resetPassWord',
  COLLECTION_AND_SUBMISSION: {
    BASE: '/thu-thap-va-nop-luu',
    DOCUMENT_PLAN_MANAGEMENT: {
      BASE: '/thu-thap-va-nop-luu/quan-ly-ke-hoach-thu-thap',
      DETAIL: '/thu-thap-va-nop-luu/quan-ly-ke-hoach-thu-thap/chi-tiet-ke-hoach-thu-thap',
    },
    REVIEW_DOCUMENT_PLAN: {
      BASE: '/thu-thap-va-nop-luu/duyet-ke-hoach-thu-thap',
      DETAIL: '/thu-thap-va-nop-luu/duyet-ke-hoach-thu-thap/chi-tiet-ke-hoach-thu-thap',
    },
    DOCUMENT_MANAGEMENT: {
      BASE: '/thu-thap-va-nop-luu/quan-ly-thu-thap',
      DETAIL: '/thu-thap-va-nop-luu/quan-ly-thu-thap/chi-tiet-ho-so',
      DOCUMENT_COMPONENT: '/thu-thap-va-nop-luu/quan-ly-thu-thap/bien-muc-tai-lieu',
    },
    REVIEW_AND_SUBMIT_FILE_SUBMISSION: {
      BASE: '/thu-thap-va-nop-luu/duyet-va-gui-ho-so-nop-luu',
      DETAIL: '/thu-thap-va-nop-luu/duyet-va-gui-ho-so-nop-luu/chi-tiet-ho-so',
      DOCUMENT_COMPONENT: '/thu-thap-va-nop-luu/duyet-va-gui-ho-so-nop-luu/bien-muc-tai-lieu',
    },
    RECEIVE_DOCUMENT: {
      BASE: '/thu-thap-va-nop-luu/tiep-nhan-ho-so-nop-luu',
      DETAIL: '/thu-thap-va-nop-luu/tiep-nhan-ho-so-nop-luu/chi-tiet-ho-so',
      DOCUMENT_COMPONENT: '/thu-thap-va-nop-luu/tiep-nhan-ho-so-nop-luu/bien-muc-tai-lieu',
    },
    DOCUMENT_INDEX: {
      BASE: '/thu-thap-va-nop-luu/muc-luc-ho-so-nop-luu',
      DETAIL: '/thu-thap-va-nop-luu/muc-luc-ho-so-nop-luu/chi-tiet-ho-so',
      DOCUMENT_COMPONENT: '/thu-thap-va-nop-luu/muc-luc-ho-so-nop-luu/bien-muc-tai-lieu',
    },
    HANDOVER_DOCUMENT_MANAGEMENT: {
      BASE: '/thu-thap-va-nop-luu/quan-ly-bien-ban-ban-giao',
      DETAIL: '/thu-thap-va-nop-luu/quan-ly-bien-ban-ban-giao/chi-tiet-bien-ban',
    },
  },
  VARIABLE_BINNING: {
    BASE: '/bien-muc-chinh-ly',
    DOCUMENT_INDEXING: {
      BASE: '/bien-muc-chinh-ly/bien-muc-ho-so-tai-lieu',
      DETAIL: '/bien-muc-chinh-ly/bien-muc-ho-so-tai-lieu/chi-tiet-ho-so',
      DOCUMENT_COMPONENT: '/bien-muc-chinh-ly/bien-muc-ho-so-tai-lieu/bien-muc-tai-lieu',
    },
    REVIEW_SUPPLEMENTAL_INDEXING: {
      BASE: '/bien-muc-chinh-ly/duyet-bien-muc-bo-sung',
      DETAIL: '/bien-muc-chinh-ly/duyet-bien-muc-bo-sung/chi-tiet-ho-so',
      DOCUMENT_COMPONENT: '/bien-muc-chinh-ly/duyet-bien-muc-bo-sung/bien-muc-tai-lieu',
    },
    SUPPLEMENTAL_DOCUMENT_INDEXING: {
      BASE: '/bien-muc-chinh-ly/bien-muc-bo-sung-ho-so-tai-lieu',
      DETAIL: '/bien-muc-chinh-ly/bien-muc-bo-sung-ho-so-tai-lieu/chi-tiet-ho-so',
      DOCUMENT_COMPONENT: '/bien-muc-chinh-ly/bien-muc-bo-sung-ho-so-tai-lieu/bien-muc-tai-lieu',
    },
    SUPPLEMENTAL_INDEXING_MANAGEMENT: {
      BASE: '/bien-muc-chinh-ly/quan-ly-bien-muc-bo-sung',
      DETAIL: '/bien-muc-chinh-ly/quan-ly-bien-muc-bo-sung/chi-tiet-ho-so',
      DOCUMENT_COMPONENT: '/bien-muc-chinh-ly/quan-ly-bien-muc-bo-sung/bien-muc-tai-lieu',
    },
    VARIABLE_REPOSITORY_BROWSING: {
      BASE: '/bien-muc-chinh-ly/duyet-luu-kho',
      DETAIL: '/bien-muc-chinh-ly/duyet-luu-kho/chi-tiet-ho-so',
      DOCUMENT_COMPONENT: '/bien-muc-chinh-ly/duyet-luu-kho/bien-muc-tai-lieu',
    },
  },
  DOCUMENT_STORAGE: {
    BASE: '/kho-luu-tru',
    BARCODE: {
      BASE: '/kho-luu-tru/in-ma-vach',
      DETAIL: '/kho-luu-tru/ma-vach',
    },
    DOCUMENT_SEARCH: {
      BASE: '/kho-luu-tru/tra-cuu-ho-so',
      DETAIL: '/kho-luu-tru/tra-cuu-ho-so/chi-tiet-ho-so',
      COMPONENT: '/kho-luu-tru/tra-cuu-ho-so/chi-tiet-tai-lieu',
    },
    DOCUMENT_COMPONENT_SEARCH: {
      BASE: '/kho-luu-tru/tra-cuu-tai-lieu',
      DETAIL: '/kho-luu-tru/tra-cuu-tai-lieu/chi-tiet-tai-lieu',
    },
    SORT_BOX: {
      BASE: '/kho-luu-tru/xep-hop-cap',
    },
    MOVE_DOCUMENT: {
      BASE: '/kho-luu-tru/di-chuyen-ho-so',
      DETAIL: '/kho-luu-tru/di-chuyen-ho-so/chi-tiet-ho-so',
      COMPONENT: '/kho-luu-tru/di-chuyen-ho-so/chi-tiet-tai-lieu',
    },
    FREEZING_DOCUMENT: {
      BASE: '/kho-luu-tru/dong-bang-ho-so',
      DETAIL: '/kho-luu-tru/dong-bang-ho-so/chi-tiet-ho-so',
      COMPONENT: '/kho-luu-tru/dong-bang-ho-so/chi-tiet-tai-lieu',
    },
    TRANSFER_DOCUMENT: '/kho-luu-tru/phieu-di-chuyen-tai-lieu',
  },
  DESTROY_DOCUMENT: {
    BASE: '/tieu-huy-ho-so',
    REVIEW_DESTROY_DOCUMENT: {
      BASE: '/tieu-huy-ho-so/danh-sach-ho-so-cho-tieu-huy',
      ADD: '/tieu-huy-ho-so/danh-sach-ho-so-cho-tieu-huy/them-ho-so-tieu-huy',
      DETAIL: '/tieu-huy-ho-so/danh-sach-ho-so-cho-tieu-huy/chi-tiet-ho-so',
      COMPONENT: '/tieu-huy-ho-so/danh-sach-ho-so-cho-tieu-huy/chi-tiet-tai-lieu',
    },
    DESTROY_DECISION: '/tieu-huy-ho-so/danh-sach-quyet-dinh-tieu-huy',
    REVIEW_DESTROY_DECISION: '/tieu-huy-ho-so/duyet-quyet-dinh-tieu-huy',
    DESTROYED_DECISION: '/tieu-huy-ho-so/danh-sach-quyet-dinh-tieu-huy-da-duyet',
  },
  GENERAL_DIRECTORY: {
    BASE: '/danh-muc-chung',
    LANGUAGE: {
      BASE: '/danh-muc-chung/ngon-ngu',
    },
    SECURITY_LEVEL: {
      BASE: '/danh-muc-chung/cap-do-bao-mat',
    },
    WARRANTY_PERIOD: {
      BASE: '/danh-muc-chung/thoi-han-bao-quan',
    },
    POSITION: '/danh-muc-chung/chuc-vu',
  },
  MANAGEMENT_AGENT: {
    BASE: '/quan-tri-co-quan',
    FORM: '/quan-tri-co-quan/quan-ly-mau',
    MANAGEMENT_PERMISSION: '/quan-tri-co-quan/quan-ly-nhom-quyen',
    MANAGEMENT_GROUP_USER: '/quan-tri-co-quan/quan-ly-nhom-nguoi-dung',
    MANAGEMENT_USER: '/quan-tri-co-quan/quan-ly-nguoi-dung',
    MANAGEMENT_COMPANY: '/quan-tri-co-quan/quan-ly-co-quan',
    AUTHORIZATION_BY_FILE_TYPE: '/quan-tri-co-quan/phan-quyen-theo-phan-loai',
    MANAGEMENT_READERS: '/quan-tri-co-quan/quan-ly-doc-gia',
  },
  PRIVATE_DIRECTORY: {
    BASE: '/danh-muc-rieng',
    PHONG_LUU_TRU: '/danh-muc-rieng/phong-luu-tru',
    CATALOGUE: '/danh-muc-rieng/danh-sach-muc-luc',
    ARCHIVES: '/danh-muc-rieng/kho',
    SHELF: '/danh-muc-rieng/gia-ke',
    BOX: '/danh-muc-rieng/hop-cap',
    PROFILE_TYPE: '/danh-muc-rieng/loai-ho-so',
  },
  DOCUMENT_MINING_MANAGEMENT: {
    BASE: '/quan-ly-khai-thac',
    LEVEL_DOCUMENT_MINING_MANAGEMENT: {
      BASE: '/quan-ly-khai-thac/quan-ly-muc-do-khai-thac',
      DETAIL: '/quan-ly-khai-thac/quan-ly-muc-do-khai-thac/chi-tiet-ho-so',
      COMPONENT: '/quan-ly-khai-thac/quan-ly-muc-do-khai-thac/chi-tiet-tai-lieu',
    },
    DOCUMENT_COMPONENT_MINING: {
      BASE: '/quan-ly-khai-thac/khai-thac-tai-lieu',
      DOCUMENT_DETAIL: '/quan-ly-khai-thac/khai-thac-tai-lieu/chi-tiet-ho-so',
      DOCUMENT_COMPONENT_DETAIL: '/quan-ly-khai-thac/khai-thac-tai-lieu/chi-tiet-tai-lieu',
    },
    DOCUMENT_MINING_WAIT: {
      BASE: '/quan-ly-khai-thac/danh-sach-cho-khai-thac',
      DOCUMENT_DETAIL: '/quan-ly-khai-thac/danh-sach-cho-khai-thac/chi-tiet-ho-so',
      DOCUMENT_COMPONENT_DETAIL: '/quan-ly-khai-thac/danh-sach-cho-khai-thac/chi-tiet-tai-lieu',
    },
    DOCUMENT_MINING_TICKET: {
      BASE: '/quan-ly-khai-thac/danh-sach-phieu-khai-thac',
      DOCUMENT_DETAIL: '/quan-ly-khai-thac/danh-sach-phieu-khai-thac/chi-tiet-ho-so',
      DOCUMENT_COMPONENT_DETAIL: '/quan-ly-khai-thac/danh-sach-phieu-khai-thac/chi-tiet-tai-lieu',
    },
    REVIEW_DOCUMENT_MINING_TICKET: {
      BASE: '/quan-ly-khai-thac/phe-duyet-phieu-khai-thac',
      DOCUMENT_DETAIL: '/quan-ly-khai-thac/phe-duyet-phieu-khai-thac/chi-tiet-ho-so',
      DOCUMENT_COMPONENT_DETAIL: '/quan-ly-khai-thac/phe-duyet-phieu-khai-thac/chi-tiet-tai-lieu',
    },
    REVIEW_UNIT: {
      BASE: '/quan-ly-khai-thac/phe-duyet-don-vi',
      DOCUMENT_DETAIL: '/quan-ly-khai-thac/phe-duyet-don-vi/chi-tiet-ho-so',
      DOCUMENT_COMPONENT_DETAIL: '/quan-ly-khai-thac/phe-duyet-don-vi/chi-tiet-tai-lieu',
    },
  },
  SUBMIT_HISTORY: {
    BASE: '/nop-luu-lich-su',
    SUBMIT_HISTORY_MANAGEMENT: {
      BASE: '/nop-luu-lich-su/quan-ly-ho-so-nop-luu-lich-su',
      DETAIL: '/nop-luu-lich-su/quan-ly-ho-so-nop-luu-lich-su/chi-tiet-ho-so',
      COMPONENT: '/nop-luu-lich-su/quan-ly-ho-so-nop-luu-lich-su/chi-tiet-tai-lieu',
    },
    REVIEW_SUBMIT_HISTORY: {
      BASE: '/nop-luu-lich-su/duyet-ho-so-nop-luu-lich-su',
      DETAIL: '/nop-luu-lich-su/duyet-ho-so-nop-luu-lich-su/chi-tiet-ho-so',
      COMPONENT: '/nop-luu-lich-su/duyet-ho-so-nop-luu-lich-su/chi-tiet-tai-lieu',
    },
    SUBMITTED_HISTORY: {
      BASE: '/nop-luu-lich-su/danh-sach-ho-so-da-nop-luu-lich-su',
      DETAIL: '/nop-luu-lich-su/danh-sach-ho-so-da-nop-luu-lich-su/chi-tiet-ho-so',
      COMPONENT: '/nop-luu-lich-su/danh-sach-ho-so-da-nop-luu-lich-su/chi-tiet-tai-lieu',
    },
  },
  REPORT_STATISTICS: {
    BASE: '/bao-cao-thong-ke',
    DIGITAL_STORAGE_INVENTORY_STATUS: '/bao-cao-thong-ke/hien-trang-so-hoa-kho-luu-tru',
    BACKPACK_STATISTICS: '/bao-cao-thong-ke/thong-ke-hop-cap',
    MINING_STATISTICS: '/bao-cao-thong-ke/thong-ke-khai-thac',
  },
  SYSTEM_MANAGEMENT: {
    BASE: '/quan-tri-he-thong',
    SYSTEM_LOGS: '/quan-tri-he-thong/nhat-ky-he-thong',
  },
};

import { WithPermission } from 'components';
import { PATH_NAME } from 'core/constants/path-name';
import { PermissionEnum } from 'core/enums/PermissionEnum.enum';

import { lazy, Suspense } from 'react';

const ManagementAgent = lazy(() => import('pages/ManagementAgent/ManagementAgent'));

// Quản lý Template
const ManagementTemplate = lazy(() => import('pages/ManagementAgent/Children/ManagementTemplate/ManagementTemplate'));
const ManagementTemplatePage = WithPermission(ManagementTemplate);
// Quản lý nhóm quyền
const ManagementPermission = lazy(
  () => import('pages/ManagementAgent/Children/ManagementPermission/ManagementPermission'),
);
const ManagementPermissionPage = WithPermission(ManagementPermission);

// Quản lý đọc giả
const ManagementReaders = lazy(() => import('pages/ManagementAgent/Children/ManagementReaders/ManagementReaders'));
const ManagementReadersPage = WithPermission(ManagementReaders);

// Quản lý nhóm người dùng
const ManagementGroupUser = lazy(
  () => import('pages/ManagementAgent/Children/ManagementGroupUser/ManagementGroupUser'),
);
const ManagementGroupUserPage = WithPermission(ManagementGroupUser);

// Quản lý người dùng
const ManagementUser = lazy(() => import('pages/ManagementAgent/Children/ManagementUser/ManagementUser'));
const ManagementUserPage = WithPermission(ManagementUser);

// Quản lý cơ quan
const ManagementCompany = lazy(() => import('pages/ManagementAgent/Children/ManagementCompany/ManagementCompany'));
const ManagementCompanyPage = WithPermission(ManagementCompany);

// Phân quyền theo phân loại hồ sơ
const AuthorizationByFileType = lazy(
  () => import('pages/ManagementAgent/Children/AuthorizationByFileType/AuthorizationByFileType'),
);
const AuthorizationByFileTypePage = WithPermission(AuthorizationByFileType);

const MANAGEMENT_AGENT = {
  path: `${PATH_NAME.MANAGEMENT_AGENT.BASE}`,
  element: (
    <Suspense fallback={'Đang tải...'}>
      <ManagementAgent />
    </Suspense>
  ),
  children: [
    {
      index: true,
      element: (
        <Suspense fallback={'Đang tải...'}>
          <ManagementPermissionPage requiredPermissions={[PermissionEnum.MANAGEMENT_PERMISSION_VIEW]} />
        </Suspense>
      ),
    },
    {
      path: 'quan-ly-mau',
      element: (
        <Suspense fallback={'Đang tải...'}>
          <ManagementTemplatePage requiredPermissions={[PermissionEnum.FORM_VIEW]} />
        </Suspense>
      ),
    },
    {
      path: 'quan-ly-nhom-quyen',
      element: (
        <Suspense fallback={'Đang tải...'}>
          <ManagementPermissionPage requiredPermissions={[PermissionEnum.MANAGEMENT_PERMISSION_VIEW]} />
        </Suspense>
      ),
    },
    {
      path: 'quan-ly-nhom-nguoi-dung',
      element: (
        <Suspense fallback={'Đang tải...'}>
          <ManagementGroupUserPage requiredPermissions={[PermissionEnum.MANAGEMENT_GROUP_USER_VIEW]} />
        </Suspense>
      ),
    },
    {
      path: 'quan-ly-nguoi-dung',
      element: (
        <Suspense fallback={'Đang tải...'}>
          <ManagementUserPage requiredPermissions={[PermissionEnum.MANAGEMENT_USER_VIEW]} />
        </Suspense>
      ),
    },
    {
      path: 'phan-quyen-theo-phan-loai',
      element: (
        <Suspense fallback={'Đang tải...'}>
          <AuthorizationByFileTypePage requiredPermissions={[PermissionEnum.AUTHORIZATION_BY_FILE_TYPE_VIEW]} />
        </Suspense>
      ),
    },
    {
      path: 'quan-ly-co-quan',
      element: (
        <Suspense fallback={'Đang tải...'}>
          <ManagementCompanyPage requiredPermissions={[PermissionEnum.MANAGEMENT_COMPANY_VIEW]} />
        </Suspense>
      ),
    },
    {
      path: 'quan-ly-doc-gia',
      element: (
        <Suspense fallback={'Đang tải...'}>
          <ManagementReadersPage requiredPermissions={[PermissionEnum.MANAGEMENT_READERS_VIEW]} />
        </Suspense>
      ),
    },
  ],
};
export default MANAGEMENT_AGENT;

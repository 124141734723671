import { getMessaging, getToken, MessagePayload, onMessage } from '@firebase/messaging';
import { initializeApp } from 'firebase/app';
import { UserService } from 'services/user';
import { firebaseConfig } from './constants';

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export async function getMessagingToken(tokenUser: string) {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
    });
    await UserService.firebaseRegisterFCMToken(currentToken, tokenUser);
    return currentToken;
  } catch (error) {
    console.log('An error occurred while retrieving token. ', error);
    return;
  }
}

export const onMessageListener = (): Promise<MessagePayload> =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

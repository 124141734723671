import { useQuery } from '@tanstack/react-query';
import { CatalogueService } from 'services/own-categories';
import { CataloguePayload } from 'services/own-categories/catalogue/catalogue.model';

const useFetchCatalogue = (enabled = true, payload?: CataloguePayload) => {
  const { data: response } = useQuery({
    queryKey: ['CATALOGUE_COMBO_BOX_KEY', payload],
    queryFn: () => CatalogueService.getComboBoxCatalogue(payload ? payload : {}),
    enabled: enabled,
  });

  const dataSource = response ? response.data.map((item) => ({ label: item.CatalogueName, value: item.Id })) : [];
  return { dataSource };
};

export default useFetchCatalogue;

import { useQuery } from '@tanstack/react-query';
import { OrganizationService } from 'services';

const useFetchOrganizationOfDocumentPlan = (documentPlanId: number, enabled: boolean) => {
  const { data: response } = useQuery({
    queryKey: ['ORGANIZATION_OF_DOCUMENT_PLAN_COMBO_BOX_KEY', documentPlanId],
    queryFn: () => OrganizationService.getComboboxItemByDocumentPlan(documentPlanId),
    enabled,
  });

  const dataSource = response ? response.data.map((item) => ({ label: item.Name, id: item.Id, value: item.Code })) : [];
  return { dataSource };
};

export default useFetchOrganizationOfDocumentPlan;

import { PaginationProps, Table } from 'antd';
import { PagingOption } from 'core/constants/paging-option';
import { Paging } from 'core/interface/paging';
import { t } from 'i18next';
import React from 'react';
import type { ColumnsType } from 'antd/es/table';
import { useIsFetching } from '@tanstack/react-query';
import { ExpandableConfig } from 'antd/es/table/interface';

interface TableComponentProps {
  columns: ColumnsType<any>;
  dataSource: any;
  paging: Paging;
  totalItems: number;
  setPaging: React.Dispatch<React.SetStateAction<Paging>>;
  className?: string;
  style?: React.CSSProperties;
  scroll?: number;
  paginationSize?: 'default' | 'small';
  onChange?: any;
  onRow?: (record: any, rowIndex?: number) => { onClick: (event?: any) => void };
  rowSelection?: any;
  cursor?: string;
  expandable?: ExpandableConfig<any> | undefined;
  components?: any;
}

const TableComponent = (props: TableComponentProps) => {
  const {
    paging,
    dataSource,
    totalItems,
    columns,
    setPaging,
    className,
    style,
    scroll = 600,
    paginationSize = 'small',
    onChange,
    onRow,
    rowSelection,
    cursor,
    expandable,
    components,
  } = props;

  const isFetching = useIsFetching();

  return (
    <Table
      bordered
      size='small'
      className={className}
      style={style}
      locale={{ emptyText: t('label.emptyData') }}
      rowKey={(record) => record.Id || record.Key || record.TeamId || record.key}
      columns={columns.map((column) => ({ ...column, ellipsis: true }))}
      scroll={{ y: scroll }}
      pagination={{
        size: paginationSize,
        position: ['bottomRight'],
        defaultPageSize: paging.PageSize,
        showSizeChanger: true,
        onChange: (page, pageSize) => setPaging({ sortBy: null, CurrentPage: page, PageSize: pageSize, Paging: true }),
        total: totalItems,
        current: paging.CurrentPage,
        pageSizeOptions: PagingOption.PAGE_SIZE_OPTION,
        showTotal: (total) => `Tổng số bản ghi: ${total}`,
      }}
      rowClassName={(_, index) => (index % 2 === 0 ? `${cursor}` : `${cursor} table-row-bg`)}
      dataSource={dataSource ?? []}
      onChange={onChange}
      loading={isFetching > 0}
      onRow={onRow}
      rowSelection={rowSelection}
      expandable={expandable}
      components={components}
    />
  );
};

export default TableComponent;

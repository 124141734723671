import { getStatusDestroyDecisionValue, StatusDestroyDecision } from 'core/enums/status-destroy-decision.enum';
import React, { useMemo } from 'react';

const useGetStatusDestroyDecision = () => {
  const statusDestroyDecisionValue = getStatusDestroyDecisionValue();
  const dataSource = useMemo(
    () =>
      statusDestroyDecisionValue.map((item) => ({
        label: item?.label as string,
        value: item?.value as unknown as StatusDestroyDecision,
      })),
    [],
  );

  return { dataSource };
};

export default useGetStatusDestroyDecision;

import { WithPermission } from 'components';
import { PATH_NAME } from 'core/constants/path-name';
import { PermissionEnum } from 'core/enums/PermissionEnum.enum';
import { lazy, Suspense } from 'react';

const GeneralDirectory = lazy(() => import('pages/GeneralDirectory/GeneralDirectory'));
const LanguageList = lazy(() => import('pages/GeneralDirectory/Children/Language/LanguageList'));
const SecurityLevel = lazy(() => import('pages/GeneralDirectory/Children/SecurityLevel/SecurityLevel'));
const WarrantyPeriod = lazy(() => import('pages/GeneralDirectory/Children/WarrantyPeriod/WarrantyPeriod'));
const Position = lazy(() => import('pages/GeneralDirectory/Children/Position/Position'));
const LanguageListPage = WithPermission(LanguageList);
const SecurityLevelPage = WithPermission(SecurityLevel);
const WarrantyPeriodPage = WithPermission(WarrantyPeriod);
const PositionPage = WithPermission(Position);

const GENERAL_DIRECTORY = {
  path: `${PATH_NAME.GENERAL_DIRECTORY.BASE}`,
  element: (
    <Suspense fallback={'Đang tải...'}>
      <GeneralDirectory />
    </Suspense>
  ),
  children: [
    {
      index: true,
      element: (
        <Suspense fallback={'Đang tải...'}>
          <LanguageListPage requiredPermissions={[PermissionEnum.LANGUAGE_VIEW]} />
        </Suspense>
      ),
    },
    {
      path: 'ngon-ngu',
      element: (
        <Suspense fallback={'Đang tải...'}>
          <LanguageListPage requiredPermissions={[PermissionEnum.LANGUAGE_VIEW]} />
        </Suspense>
      ),
    },
    {
      path: 'cap-do-bao-mat',
      element: (
        <Suspense fallback={'Đang tải...'}>
          <SecurityLevelPage requiredPermissions={[PermissionEnum.SECURITY_LEVEL_VIEW]} />
        </Suspense>
      ),
    },
    {
      path: 'thoi-han-bao-quan',
      element: (
        <Suspense fallback={'Đang tải...'}>
          <WarrantyPeriodPage requiredPermissions={[PermissionEnum.WARRANTY_PERIOD_VIEW]} />
        </Suspense>
      ),
    },
    {
      path: 'chuc-vu',
      element: (
        <Suspense fallback={'Đang tải...'}>
          <PositionPage requiredPermissions={[PermissionEnum.POSITION_VIEW]} />
        </Suspense>
      ),
    },
  ],
};
export default GENERAL_DIRECTORY;

import React from 'react';

interface Props {
  className?: string;
}

const UploadFile = (props: Props) => {
  return (
    <svg height='96' width='96' fill='none' viewBox='0 0 96 96' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M32.4437 22.5208L46.5858 8.37868L46.6212 8.41404C47.4544 7.86199 48.5456 7.86199 49.3789 8.41404L49.4142 8.37868L63.5564 22.5208C64.5327 23.4971 64.5327 25.08 63.5564 26.0563C62.58 27.0327 60.9971 27.0327 60.0208 26.0563L50.5 16.5355V68.2578H50.4978C50.4986 68.2776 50.4992 68.2974 50.4996 68.3174C50.4999 68.333 50.5 68.3486 50.5 68.3642C50.5 69.7449 49.3807 70.8642 48 70.8642C46.6193 70.8642 45.5 69.7449 45.5 68.3642C45.5 68.3286 45.5007 68.2931 45.5022 68.2578H45.5V16.5355L35.9792 26.0563C35.0029 27.0327 33.42 27.0327 32.4437 26.0563C31.4673 25.08 31.4673 23.4971 32.4437 22.5208Z'
        fill='#B3B3B3'
      />
      <path
        d='M13 59V78C13 78.6566 13.1293 79.3068 13.3806 79.9134C13.6319 80.52 14.0002 81.0712 14.4645 81.5355C14.9288 81.9998 15.48 82.3681 16.0866 82.6194C16.6932 82.8707 17.3434 83 18 83H78C78.6566 83 79.3068 82.8707 79.9134 82.6194C80.52 82.3681 81.0712 81.9998 81.5355 81.5355C81.9998 81.0712 82.3681 80.5201 82.6194 79.9134C82.8707 79.3068 83 78.6566 83 78V59C83 57.6193 84.1193 56.5 85.5 56.5C86.8807 56.5 88 57.6193 88 59V78C88 79.3132 87.7413 80.6136 87.2388 81.8268C86.7363 83.0401 85.9997 84.1425 85.0711 85.0711C84.1425 85.9997 83.0401 86.7363 81.8268 87.2388C80.6136 87.7413 79.3132 88 78 88H18C16.6868 88 15.3864 87.7413 14.1732 87.2388C12.9599 86.7362 11.8575 85.9996 10.9289 85.0711C10.0003 84.1425 9.26375 83.0401 8.7612 81.8268C8.25866 80.6136 8 79.3132 8 78V59C8 57.6193 9.11929 56.5 10.5 56.5C11.8807 56.5 13 57.6193 13 59Z'
        fill='#B3B3B3'
      />
    </svg>
  );
};

export default UploadFile;

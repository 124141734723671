import { PATH_NAME } from 'core/constants/path-name';
import { PermissionEnum } from 'core/enums/PermissionEnum.enum';

export enum ScreenItemEnum {
  DOCUMENT_PLAN_MANAGEMENT,
  REVIEW_DOCUMENT_PLAN,
  DOCUMENT_MANAGEMENT,
  REVIEW_AND_SUBMIT_FILE_SUBMISSION,
  RECEIVE_DOCUMENT,
  HANDOVER_DOCUMENT_MANAGEMENT = 50,
  DOCUMENT_INDEX = 5,
  DOCUMENT_INDEXING,
  REVIEW_SUPPLEMENTAL_INDEXING,
  SUPPLEMENTAL_DOCUMENT_INDEXING,
  SUPPLEMENTAL_INDEXING_MANAGEMENT,
  VARIABLE_REPOSITORY_BROWSING,
  BARCODE,
  DOCUMENT_SEARCH,
  DOCUMENT_COMPONENT_SEARCH,
  MOVE_DOCUMENT,
  FREEZING_DOCUMENT,
  TRANSFER_DOCUMENT,
  LANGUAGE,
  SECURITY_LEVEL,
  WARRANTY_PERIOD,
  POSITION,
  FORM,
  MANAGEMENT_PERMISSION,
  MANAGEMENT_GROUP_USER,
  MANAGEMENT_USER,
  MANAGEMENT_COMPANY,
  AUTHORIZATION_BY_FILE_TYPE,
  MANAGEMENT_READERS,
  PHONG_LUU_TRU,
  CATALOGUE,
  ARCHIVES,
  SHELF,
  BOX,
  PROFILE_TYPE,
  LEVEL_DOCUMENT_MINING_MANAGEMENT,
  DOCUMENT_COMPONENT_MINING,
  DOCUMENT_MINING_WAIT,
  DOCUMENT_MINING_TICKET,
  REVIEW_DOCUMENT_MINING_TICKET,
  REVIEW_UNIT,
  SUBMIT_HISTORY_MANAGEMENT,
  REVIEW_SUBMIT_HISTORY,
  SUBMITTED_HISTORY,
  DIGITAL_STORAGE_INVENTORY_STATUS,
  BACKPACK_STATISTICS,
  MINING_STATISTICS,
  REVIEW_DESTROY_DOCUMENT,
  DESTROY_DECISION,
  REVIEW_DESTROY_DECISION,
  DESTROYED_DECISION,
}

export enum TypeScreen {
  PAGE = 'PAGE',
  MODAL = 'MODAL',
}

export const ScreenItemEnumValue = new Map<
  number,
  { path: string; label: string; type: TypeScreen; permission: number[] }
>([
  [
    ScreenItemEnum.DOCUMENT_PLAN_MANAGEMENT,
    {
      path: PATH_NAME.COLLECTION_AND_SUBMISSION.DOCUMENT_PLAN_MANAGEMENT.DETAIL,
      label: 'Quản lý kế hoạch thu thập',
      type: TypeScreen.PAGE,
      permission: [PermissionEnum.DOCUMENT_PLAN_DETAIL],
    },
  ],
  [
    ScreenItemEnum.REVIEW_DOCUMENT_PLAN,
    {
      path: PATH_NAME.COLLECTION_AND_SUBMISSION.REVIEW_DOCUMENT_PLAN.DETAIL,
      label: 'Duyệt kế hoạch thu thập',
      type: TypeScreen.PAGE,
      permission: [PermissionEnum.REVIEW_DOCUMENT_OF_DOC_PLAN_DETAIL],
    },
  ],
  [
    ScreenItemEnum.DOCUMENT_MANAGEMENT,
    {
      path: PATH_NAME.COLLECTION_AND_SUBMISSION.DOCUMENT_MANAGEMENT.DETAIL,
      label: 'Quản lý thu thập',
      type: TypeScreen.PAGE,
      permission: [PermissionEnum.DOCUMENT_MANAGEMENT_DETAIL],
    },
  ],
  [
    ScreenItemEnum.REVIEW_AND_SUBMIT_FILE_SUBMISSION,
    {
      path: PATH_NAME.COLLECTION_AND_SUBMISSION.REVIEW_AND_SUBMIT_FILE_SUBMISSION.DETAIL,
      label: 'Duyệt và gửi hồ sơ nộp lưu',

      type: TypeScreen.PAGE,
      permission: [PermissionEnum.REVIEW_AND_SUBMIT_DOC_DETAIL],
    },
  ],
  [
    ScreenItemEnum.RECEIVE_DOCUMENT,
    {
      path: PATH_NAME.COLLECTION_AND_SUBMISSION.RECEIVE_DOCUMENT.DETAIL,
      label: 'Tiếp nhận hồ sơ nộp lưu',
      type: TypeScreen.PAGE,
      permission: [PermissionEnum.RECEIVE_DOC_DETAIL],
    },
  ],
  [
    ScreenItemEnum.DOCUMENT_INDEX,
    {
      path: PATH_NAME.COLLECTION_AND_SUBMISSION.DOCUMENT_INDEX.DETAIL,
      label: 'Mục lục hồ sơ nộp lưu',
      type: TypeScreen.PAGE,
      permission: [PermissionEnum.DOC_INDEX_DETAIL],
    },
  ],
  [
    ScreenItemEnum.HANDOVER_DOCUMENT_MANAGEMENT,
    {
      path: PATH_NAME.COLLECTION_AND_SUBMISSION.HANDOVER_DOCUMENT_MANAGEMENT.DETAIL,
      label: 'Quản lý biên bản bàn giao',
      type: TypeScreen.PAGE,
      permission: [PermissionEnum.HANDOVER_DOCUMENT_DETAIL],
    },
  ],
  [
    ScreenItemEnum.DOCUMENT_INDEXING,
    {
      path: PATH_NAME.VARIABLE_BINNING.DOCUMENT_INDEXING.DETAIL,
      label: 'Biên mục hồ sơ tài liệu',
      type: TypeScreen.PAGE,
      permission: [PermissionEnum.DOC_INDEXING_DETAIL],
    },
  ],
  [
    ScreenItemEnum.REVIEW_SUPPLEMENTAL_INDEXING,
    {
      path: PATH_NAME.VARIABLE_BINNING.REVIEW_SUPPLEMENTAL_INDEXING.DETAIL,
      label: 'Duyệt biên mục bổ sung',
      type: TypeScreen.PAGE,
      permission: [PermissionEnum.VARIABLE_REPOSITORY_BROWSING_DETAIL],
    },
  ],
  [
    ScreenItemEnum.SUPPLEMENTAL_DOCUMENT_INDEXING,
    {
      path: PATH_NAME.VARIABLE_BINNING.SUPPLEMENTAL_DOCUMENT_INDEXING.DETAIL,
      label: 'Biên mục bổ sung hồ sơ tài liệu',
      type: TypeScreen.PAGE,
      permission: [PermissionEnum.SUPPLEMENTAL_DOCUMENT_INDEXING_DETAIL],
    },
  ],
  [
    ScreenItemEnum.SUPPLEMENTAL_INDEXING_MANAGEMENT,
    {
      path: PATH_NAME.VARIABLE_BINNING.SUPPLEMENTAL_INDEXING_MANAGEMENT.DETAIL,
      label: 'Quản lý biên mục bổ sung',
      type: TypeScreen.PAGE,
      permission: [PermissionEnum.SUPPLEMENTAL_INDEXING_MANAGEMENT_DETAIL],
    },
  ],
  [
    ScreenItemEnum.VARIABLE_REPOSITORY_BROWSING,
    {
      path: PATH_NAME.VARIABLE_BINNING.VARIABLE_REPOSITORY_BROWSING.DETAIL,
      label: 'Duyệt lưu kho',
      type: TypeScreen.PAGE,
      permission: [PermissionEnum.VARIABLE_REPOSITORY_BROWSING_DETAIL],
    },
  ],
  [
    ScreenItemEnum.BARCODE,
    {
      path: PATH_NAME.DOCUMENT_STORAGE.BARCODE.DETAIL,
      label: 'In mã vạch',
      type: TypeScreen.MODAL,
      permission: [PermissionEnum.BARCODE_VIEW],
    },
  ],
  [
    ScreenItemEnum.DOCUMENT_SEARCH,
    {
      path: PATH_NAME.DOCUMENT_STORAGE.DOCUMENT_SEARCH.DETAIL,
      label: 'Tra cứu hồ sơ',
      type: TypeScreen.PAGE,
      permission: [PermissionEnum.DOCUMENT_SEARCH_DETAIL],
    },
  ],
  [
    ScreenItemEnum.DOCUMENT_COMPONENT_SEARCH,
    {
      path: PATH_NAME.DOCUMENT_STORAGE.DOCUMENT_COMPONENT_SEARCH.DETAIL,
      label: 'Tra cứu tài liệu',
      type: TypeScreen.PAGE,
      permission: [PermissionEnum.DOCUMENT_COMPONENT_SEARCH_DETAIL],
    },
  ],
  [
    ScreenItemEnum.MOVE_DOCUMENT,
    {
      path: PATH_NAME.DOCUMENT_STORAGE.MOVE_DOCUMENT.DETAIL,
      label: 'Di chuyển hồ sơ',
      type: TypeScreen.PAGE,
      permission: [PermissionEnum.MOVE_DOCUMENT_DETAIL],
    },
  ],
  [
    ScreenItemEnum.FREEZING_DOCUMENT,
    {
      path: PATH_NAME.DOCUMENT_STORAGE.FREEZING_DOCUMENT.DETAIL,
      label: 'Đóng băng hồ sơ',
      type: TypeScreen.PAGE,
      permission: [PermissionEnum.FREEZING_DOCUMENT_DETAIL],
    },
  ],
  [
    ScreenItemEnum.TRANSFER_DOCUMENT,
    {
      path: PATH_NAME.DOCUMENT_STORAGE.TRANSFER_DOCUMENT,
      label: 'Phiếu di chuyển tài liệu',
      type: TypeScreen.MODAL,
      permission: [PermissionEnum.TRANSFER_DOCUMENT_DETAIL],
    },
  ],
  [
    ScreenItemEnum.LANGUAGE,
    {
      path: PATH_NAME.GENERAL_DIRECTORY.LANGUAGE.BASE,
      label: 'Ngôn ngữ',
      type: TypeScreen.MODAL,
      permission: [PermissionEnum.LANGUAGE_DETAIL],
    },
  ],
  [
    ScreenItemEnum.SECURITY_LEVEL,
    {
      path: PATH_NAME.GENERAL_DIRECTORY.SECURITY_LEVEL.BASE,
      label: 'Cấp độ bảo mật',
      type: TypeScreen.MODAL,
      permission: [PermissionEnum.SECURITY_LEVEL_DETAIL],
    },
  ],
  [
    ScreenItemEnum.WARRANTY_PERIOD,
    {
      path: PATH_NAME.GENERAL_DIRECTORY.WARRANTY_PERIOD.BASE,
      label: 'Thời hạn bảo quản',
      type: TypeScreen.MODAL,
      permission: [PermissionEnum.WARRANTY_PERIOD_DETAIL],
    },
  ],
  [
    ScreenItemEnum.POSITION,
    {
      path: PATH_NAME.GENERAL_DIRECTORY.POSITION,
      label: 'Chức vụ',
      type: TypeScreen.PAGE,
      permission: [PermissionEnum.POSITION_DETAIL],
    },
  ],
  [
    ScreenItemEnum.FORM,
    {
      path: PATH_NAME.MANAGEMENT_AGENT.FORM,
      label: 'Quản lý mẫu',
      type: TypeScreen.MODAL,
      permission: [PermissionEnum.FORM_DETAIL],
    },
  ],
  [
    ScreenItemEnum.MANAGEMENT_PERMISSION,
    {
      path: PATH_NAME.MANAGEMENT_AGENT.MANAGEMENT_PERMISSION,
      label: 'Quản lý nhóm quyền',
      type: TypeScreen.MODAL,
      permission: [PermissionEnum.MANAGEMENT_PERMISSION_DETAIL],
    },
  ],
  [
    ScreenItemEnum.MANAGEMENT_GROUP_USER,
    {
      path: PATH_NAME.MANAGEMENT_AGENT.MANAGEMENT_GROUP_USER,
      label: 'Quản lý nhóm người dùng',
      type: TypeScreen.MODAL,
      permission: [PermissionEnum.MANAGEMENT_GROUP_USER_DETAIL],
    },
  ],
  [
    ScreenItemEnum.MANAGEMENT_USER,
    {
      path: PATH_NAME.MANAGEMENT_AGENT.MANAGEMENT_USER,
      label: 'Quản lý người dùng',
      type: TypeScreen.MODAL,
      permission: [PermissionEnum.MANAGEMENT_USER_DETAIL],
    },
  ],
  [
    ScreenItemEnum.MANAGEMENT_COMPANY,
    {
      path: PATH_NAME.MANAGEMENT_AGENT.MANAGEMENT_COMPANY,
      label: 'Quản lý cơ quan',
      type: TypeScreen.MODAL,
      permission: [PermissionEnum.MANAGEMENT_COMPANY_DETAIL],
    },
  ],
  [
    ScreenItemEnum.AUTHORIZATION_BY_FILE_TYPE,
    {
      path: PATH_NAME.MANAGEMENT_AGENT.AUTHORIZATION_BY_FILE_TYPE,
      label: 'Phân quyền theo phân loại',
      type: TypeScreen.MODAL,
      permission: [PermissionEnum.AUTHORIZATION_BY_FILE_TYPE_DETAIL],
    },
  ],
  [
    ScreenItemEnum.MANAGEMENT_READERS,
    {
      path: PATH_NAME.MANAGEMENT_AGENT.MANAGEMENT_READERS,
      label: 'Quản lý đọc giả',
      type: TypeScreen.MODAL,
      permission: [PermissionEnum.MANAGEMENT_READERS_DETAIL],
    },
  ],
  [
    ScreenItemEnum.PHONG_LUU_TRU,
    {
      path: PATH_NAME.PRIVATE_DIRECTORY.PHONG_LUU_TRU,
      label: 'Phông lưu trữ',
      type: TypeScreen.MODAL,
      permission: [PermissionEnum.PHONG_LUU_TRU_DETAIL],
    },
  ],
  [
    ScreenItemEnum.CATALOGUE,
    {
      path: PATH_NAME.PRIVATE_DIRECTORY.CATALOGUE,
      label: 'Danh sách mục lục',
      type: TypeScreen.MODAL,
      permission: [PermissionEnum.CATALOGUE_DETAIL],
    },
  ],
  [
    ScreenItemEnum.ARCHIVES,
    {
      path: PATH_NAME.PRIVATE_DIRECTORY.ARCHIVES,
      label: 'Kho',
      type: TypeScreen.MODAL,
      permission: [PermissionEnum.ARCHIVES_DETAIL],
    },
  ],
  [
    ScreenItemEnum.SHELF,
    {
      path: PATH_NAME.PRIVATE_DIRECTORY.SHELF,
      label: 'Giá/kệ',
      type: TypeScreen.MODAL,
      permission: [PermissionEnum.SHELF_DETAIL],
    },
  ],
  [
    ScreenItemEnum.BOX,
    {
      path: PATH_NAME.PRIVATE_DIRECTORY.BOX,
      label: 'Hộp/cặp',
      type: TypeScreen.MODAL,
      permission: [PermissionEnum.BOX_DETAIL],
    },
  ],
  [
    ScreenItemEnum.PROFILE_TYPE,
    {
      path: PATH_NAME.PRIVATE_DIRECTORY.PROFILE_TYPE,
      label: 'Loại hồ sơ',
      type: TypeScreen.MODAL,
      permission: [PermissionEnum.PROFILE_TYPE_DETAIL],
    },
  ],
  [
    ScreenItemEnum.LEVEL_DOCUMENT_MINING_MANAGEMENT,
    {
      path: PATH_NAME.DOCUMENT_MINING_MANAGEMENT.LEVEL_DOCUMENT_MINING_MANAGEMENT.DETAIL,
      label: 'Quản lý mức độ khai thác',

      type: TypeScreen.MODAL,
      permission: [PermissionEnum.LEVEL_DOCUMENT_MINING_MGMT_DETAIL],
    },
  ],
  [
    ScreenItemEnum.DOCUMENT_COMPONENT_MINING,
    {
      path: PATH_NAME.DOCUMENT_MINING_MANAGEMENT.DOCUMENT_COMPONENT_MINING.DOCUMENT_DETAIL,
      label: 'Khai thác tài liệu',

      type: TypeScreen.MODAL,
      permission: [PermissionEnum.DOCUMENT_COMPONENT_MINING_DETAIL_DOC_COMP],
    },
  ],
  [
    ScreenItemEnum.DOCUMENT_MINING_WAIT,
    {
      path: PATH_NAME.DOCUMENT_MINING_MANAGEMENT.DOCUMENT_MINING_WAIT.DOCUMENT_DETAIL,
      label: 'Danh sách chờ khai thác',
      type: TypeScreen.MODAL,
      permission: [PermissionEnum.DOCUMENT_COMPONENT_MINING_VIEW],
    },
  ],
  [
    ScreenItemEnum.DOCUMENT_MINING_TICKET,
    {
      path: PATH_NAME.DOCUMENT_MINING_MANAGEMENT.DOCUMENT_MINING_TICKET.BASE,
      label: 'Danh sách phiếu khai thác',
      type: TypeScreen.MODAL,
      permission: [PermissionEnum.DOCUMENT_MINING_TICKET_DETAIL],
    },
  ],
  [
    ScreenItemEnum.REVIEW_DOCUMENT_MINING_TICKET,
    {
      path: PATH_NAME.DOCUMENT_MINING_MANAGEMENT.REVIEW_DOCUMENT_MINING_TICKET.BASE,
      label: 'Phê duyệt phiếu khai thác',
      type: TypeScreen.MODAL,
      permission: [PermissionEnum.REVIEW_DOCUMENT_MINING_TICKET_DETAIL],
    },
  ],
  [
    ScreenItemEnum.REVIEW_UNIT,
    {
      path: PATH_NAME.DOCUMENT_MINING_MANAGEMENT.REVIEW_UNIT.BASE,
      label: 'Phê duyệt đơn vị',
      type: TypeScreen.MODAL,
      permission: [PermissionEnum.REVIEW_UNIT_DETAIL],
    },
  ],
  [
    ScreenItemEnum.SUBMIT_HISTORY_MANAGEMENT,
    {
      path: PATH_NAME.SUBMIT_HISTORY.SUBMIT_HISTORY_MANAGEMENT.DETAIL,
      label: 'Quản lý hồ sơ nộp lưu lịch sử',
      type: TypeScreen.PAGE,
      permission: [PermissionEnum.SUBMIT_HISTORY_MANAGEMENT_DETAIL],
    },
  ],
  [
    ScreenItemEnum.REVIEW_SUBMIT_HISTORY,
    {
      path: PATH_NAME.SUBMIT_HISTORY.REVIEW_SUBMIT_HISTORY.DETAIL,
      label: 'Duyệt hồ sơ nộp lưu lịch sử',
      type: TypeScreen.PAGE,
      permission: [PermissionEnum.REVIEW_SUBMIT_HISTORY_DETAIL],
    },
  ],
  [
    ScreenItemEnum.SUBMITTED_HISTORY,
    {
      path: PATH_NAME.SUBMIT_HISTORY.SUBMITTED_HISTORY.DETAIL,
      label: 'Danh sách hồ sơ đã nộp lưu lịch sử',
      type: TypeScreen.PAGE,
      permission: [PermissionEnum.SUBMITTED_HISTORY_DETAIL],
    },
  ],
  [
    ScreenItemEnum.DIGITAL_STORAGE_INVENTORY_STATUS,
    {
      path: PATH_NAME.REPORT_STATISTICS.DIGITAL_STORAGE_INVENTORY_STATUS,
      label: 'Hiện trạng số hóa kho lưu trữ',
      type: TypeScreen.PAGE,
      permission: [PermissionEnum.DIGITAL_STORAGE_INVENTORY_STATUS_VIEW],
    },
  ],
  [
    ScreenItemEnum.BACKPACK_STATISTICS,
    {
      path: PATH_NAME.REPORT_STATISTICS.BACKPACK_STATISTICS,
      label: 'Thống kê hộp cặp',
      type: TypeScreen.PAGE,
      permission: [PermissionEnum.BACKPACK_STATISTICS_VIEW],
    },
  ],
  [
    ScreenItemEnum.MINING_STATISTICS,
    {
      path: PATH_NAME.REPORT_STATISTICS.MINING_STATISTICS,
      label: 'Thống kê khai thác',
      type: TypeScreen.PAGE,
      permission: [PermissionEnum.MINING_STATISTICS_VIEW],
    },
  ],
  [
    ScreenItemEnum.REVIEW_DESTROY_DOCUMENT,
    {
      path: PATH_NAME.DESTROY_DOCUMENT.REVIEW_DESTROY_DOCUMENT.DETAIL,
      label: 'Danh sách hồ sơ chờ tiêu hủy',
      type: TypeScreen.PAGE,
      permission: [PermissionEnum.REVIEW_DESTROY_DOCUMENT_DETAIL],
    },
  ],
  [
    ScreenItemEnum.DESTROY_DECISION,
    {
      path: PATH_NAME.DESTROY_DOCUMENT.DESTROY_DECISION,
      label: 'Danh sách quyết định tiêu hủy',
      type: TypeScreen.MODAL,
      permission: [PermissionEnum.DESTROY_DECISION_DETAIL],
    },
  ],
  [
    ScreenItemEnum.REVIEW_DESTROY_DECISION,
    {
      path: PATH_NAME.DESTROY_DOCUMENT.REVIEW_DESTROY_DECISION,
      label: 'Duyệt quyết định tiêu hủy',
      type: TypeScreen.MODAL,
      permission: [PermissionEnum.REVIEW_DESTROY_DECISION_DETAIL],
    },
  ],
  [
    ScreenItemEnum.DESTROYED_DECISION,
    {
      path: PATH_NAME.DESTROY_DOCUMENT.DESTROY_DECISION,
      label: 'Danh sách quyết định tiêu hủy đã duyệt',
      type: TypeScreen.MODAL,
      permission: [PermissionEnum.DESTROYED_DECISION_DETAIL],
    },
  ],
]);

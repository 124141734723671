import { ColumnDocumentComponent, getColumnVideoValue } from 'core/enums/column-document-component';
import { useMemo } from 'react';

const useGetColumnVideo = () => {
  const columnVideo = getColumnVideoValue();
  const dataSource = useMemo(
    () =>
      columnVideo.map((item) => ({
        label: item?.label as string,
        value: item?.value as unknown as ColumnDocumentComponent,
      })),
    [],
  );
  return { dataSource };
};

export default useGetColumnVideo;

import { RightOutlined } from '@ant-design/icons';
import { Breadcrumb } from 'antd';
import { Link, useLocation, useParams } from 'react-router-dom';

const BreadcrumbComponent = () => {
  const location = useLocation();
  const { id } = useParams();
  const { state } = useLocation();
  const breadcrumbNameMap: Record<string, string> = {
    // Tổng quan
    '/dashboard': 'Dashboard',

    // Thu thập và nộp lưu
    '/thu-thap-va-nop-luu': 'Thu thập và nộp lưu',
    '/thu-thap-va-nop-luu/quan-ly-ke-hoach-thu-thap': 'Quản lý kế hoạch thu thập',
    '/thu-thap-va-nop-luu/duyet-ke-hoach-thu-thap': 'Duyệt kế hoạch thu thập',
    '/thu-thap-va-nop-luu/quan-ly-thu-thap': 'Quản lý thu thập',
    '/thu-thap-va-nop-luu/quan-ly-thu-thap/bien-muc-tai-lieu': 'Biên mục tài liệu',
    '/thu-thap-va-nop-luu/duyet-va-gui-ho-so-nop-luu': 'Duyệt và gửi hồ sơ nộp lưu',
    '/thu-thap-va-nop-luu/tiep-nhan-ho-so-nop-luu': 'Tiếp nhận hồ sơ nộp lưu',
    '/thu-thap-va-nop-luu/muc-luc-ho-so-nop-luu': 'Mục lục hồ sơ nhận lưu',
    '/thu-thap-va-nop-luu/tiep-nhan-ho-so-nop-luu-tru-dien-tu': 'Tiếp nhận hồ sơ nộp lưu trữ điện tử',
    '/thu-thap-va-nop-luu/danh-sach-ho-so-da-nhan-nop-luu': 'Danh sách hồ sơ đã nhận nộp lưu',
    '/thu-thap-va-nop-luu/quan-ly-bien-ban-ban-giao': 'Quản lý biên bản bàn giao',

    // Biên mục chỉnh lý
    '/bien-muc-chinh-ly': 'Biên mục chỉnh lý',
    '/bien-muc-chinh-ly/bien-muc-ho-so-tai-lieu': 'Biên mục hồ sơ tài liệu',
    '/bien-muc-chinh-ly/duyet-bien-muc-bo-sung': 'Duyệt biên mục bổ sung',
    '/bien-muc-chinh-ly/bien-muc-bo-sung-ho-so-tai-lieu': 'Biên mục bổ sung hồ sơ tài liệu',
    '/bien-muc-chinh-ly/quan-ly-bien-muc-bo-sung': 'Quản lý biên mục bổ sung',
    '/bien-muc-chinh-ly/duyet-luu-kho': 'Duyệt lưu kho',
    '/bien-muc-chinh-ly/duyet-luu-kho/bien-muc-tai-lieu': 'Biên mục tài liệu',

    // Kho lưu trữ
    '/kho-luu-tru': 'Kho lưu trữ',
    '/kho-luu-tru/in-ma-vach': 'In mã vạch',
    '/kho-luu-tru/xep-hop-cap': 'Xếp hộp cặp',
    '/kho-luu-tru/tra-cuu-ho-so': 'Tra cứu hồ sơ',
    '/kho-luu-tru/tra-cuu-tai-lieu': 'Tra cứu tài liệu',
    '/kho-luu-tru/sap-xep-cap-ho-so': 'Sắp xếp cặp hồ sơ',
    '/kho-luu-tru/di-chuyen-ho-so': 'Di chuyển hồ sơ',
    '/kho-luu-tru/dong-bang-ho-so': 'Đóng băng hồ sơ',
    '/kho-luu-tru/phieu-di-chuyen-tai-lieu': 'Phiếu di chuyển tài liệu',

    // Danh mục chung
    '/danh-muc-chung': 'Danh mục chung',
    '/danh-muc-chung/ngon-ngu': 'Ngôn ngữ',
    '/danh-muc-chung/cap-do-bao-mat': 'Cấp độ bảo mật',
    '/danh-muc-chung/thoi-han-bao-quan': 'Thời hạn bảo quản',
    '/danh-muc-chung/chuc-vu': 'Chức vụ',
    '/danh-muc-chung/cong-viec': 'Công việc',

    // Tiêu hủy hồ sơ
    '/tieu-huy-ho-so': 'Tiêu hủy hồ sơ',
    '/tieu-huy-ho-so/danh-sach-ho-so-cho-tieu-huy': 'Danh sách hồ sơ chờ tiêu hủy',
    '/tieu-huy-ho-so/danh-sach-ho-so-cho-tieu-huy/them-ho-so-tieu-huy': 'Thêm hồ sơ tiêu hủy',
    '/tieu-huy-ho-so/danh-sach-quyet-dinh-tieu-huy': 'Danh sách quyết định tiêu hủy',
    '/tieu-huy-ho-so/duyet-quyet-dinh-tieu-huy': 'Duyệt quyết định tiêu hủy',
    '/tieu-huy-ho-so/danh-sach-quyet-dinh-tieu-huy-da-duyet': 'Danh sách quyết định tiêu hủy đã duyệt',

    // Quản trị cơ quan
    '/quan-tri-co-quan': 'Quản trị cơ quan',
    '/quan-tri-co-quan/quan-ly-mau': 'Quản lý Template',
    '/quan-tri-co-quan/quan-ly-nhom-quyen': 'Quản lý nhóm quyền',
    '/quan-tri-co-quan/quan-ly-nhom-nguoi-dung': 'Quản lý nhóm người dùng',
    '/quan-tri-co-quan/quan-ly-nguoi-dung': 'Quản lý người dùng',
    '/quan-tri-co-quan/phan-quyen-theo-phan-loai': 'Phân quyền theo phân loại hồ sơ',
    '/quan-tri-co-quan/quan-ly-co-quan': 'Quản lý cơ quan',
    '/quan-tri-co-quan/quan-ly-doc-gia': 'Quản lý độc giả',

    // Danh mục riêng
    '/danh-muc-rieng': 'Danh mục riêng',
    '/danh-muc-rieng/phong-luu-tru': 'Phông lưu trữ',
    '/danh-muc-rieng/danh-sach-muc-luc': 'Danh sách mục lục',
    '/danh-muc-rieng/kho': 'Kho',
    '/danh-muc-rieng/gia-ke': 'Giá/ kệ',
    '/danh-muc-rieng/hop-cap': 'Hộp/ cặp',
    '/danh-muc-rieng/loai-ho-so': 'Loại hồ sơ',

    // Quản lý khai thác
    '/quan-ly-khai-thac': 'Quản lý khai thác',
    '/quan-ly-khai-thac/quan-ly-muc-do-khai-thac': 'Quản lý mức độ khai thác',
    '/quan-ly-khai-thac/khai-thac-tai-lieu': 'Khai thác tài liệu',
    '/quan-ly-khai-thac/danh-sach-cho-khai-thac': 'Danh sách chờ khai thác',
    '/quan-ly-khai-thac/danh-sach-phieu-khai-thac': 'Danh sách phiếu khai thác',
    '/quan-ly-khai-thac/phe-duyet-phieu-khai-thac': 'Phê duyệt phiếu khai thác',
    '/quan-ly-khai-thac/phe-duyet-don-vi': 'Phê duyệt đơn vị',

    // Nộp lưu lịch sử
    '/nop-luu-lich-su': 'Nộp lưu lịch sử',
    '/nop-luu-lich-su/quan-ly-ho-so-nop-luu-lich-su': 'Quản lý hồ sơ nộp lưu lịch sử',
    '/nop-luu-lich-su/duyet-ho-so-nop-luu-lich-su': 'Duyệt hồ sơ nộp lưu lịch sử',
    '/nop-luu-lich-su/danh-sach-ho-so-da-nop-luu-lich-su': 'Danh sách hồ sơ đã nộp lưu lịch sử',

    // báo cáo thống kê
    '/bao-cao-thong-ke': 'Báo cáo thống kê',
    '/bao-cao-thong-ke/hien-trang-so-hoa-kho-luu-tru': 'Hiện trạng số hoá kho lưu trữ',
    '/bao-cao-thong-ke/thong-ke-hop-cap': 'Thống kê hộp cặp',
    '/bao-cao-thong-ke/thong-ke-khai-thac': 'Thống kê khai thác',

    // Quản lý quy trình
    '/quan-ly-quy-trinh': 'Quản lý quy trình',
    '/quan-ly-quy-trinh/quy-trinh-so-hoa': ' Quản lý quy trình nghiệp vụ số hóa',

    // Quản trị hệ thống
    '/quan-tri-he-thong': 'Quản trị hệ thống',
    '/quan-tri-he-thong/nhat-ky-he-thong': 'Nhật ký hệ thống',
  };
  let pathSnippets: string[] = [];
  pathSnippets = location.pathname.split('/').filter((i) => i);
  if (id) {
    pathSnippets.pop();
  }
  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
    return (
      <Breadcrumb.Item key={url}>
        {pathSnippets.length - 1 === index ? breadcrumbNameMap[url] : <Link to={url}>{breadcrumbNameMap[url]}</Link>}
      </Breadcrumb.Item>
    );
  });
  let breadcrumbItems = [];

  breadcrumbItems = [
    <Breadcrumb.Item key={'/dashboard'}>
      <Link to={'/dashboard'} style={{ color: '#FF5400' }}>
        Tổng quan
      </Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);

  // Nếu có id và state thì thêm tên của chi tiết đằng sau
  if (state && id) {
    breadcrumbItems = [...breadcrumbItems, <Breadcrumb.Item key={''}>{state.name}</Breadcrumb.Item>];
  }

  return <Breadcrumb separator={<RightOutlined />}>{breadcrumbItems}</Breadcrumb>;
};

export default BreadcrumbComponent;

import { UserOutlined } from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';
import { Button, Col, Form, Input, Row, Typography } from 'antd';
import { useSetDocumentTitle, useShowNotification } from 'hooks';
import { Link } from 'react-router-dom';
import { AuthService } from 'services/user';
import { getErrorMessage } from 'utils';

const ForgotPassword = () => {
  const [form] = Form.useForm();

  const { handleError, handleSuccess } = useShowNotification();

  const forgotPasswordMutation = useMutation({
    mutationFn: (username: string) => AuthService.forgotPassword(username),
  });

  const handleFinish = ({ UserName }: any) => {
    forgotPasswordMutation
      .mutateAsync(UserName)
      .then(() => handleSuccess('Vui lòng kiểm tra email để tạo lại mật khẩu'))
      .catch((error) => handleError(getErrorMessage(error)));
  };

  useSetDocumentTitle('Quên mật khẩu');

  return (
    <div className='Login'>
      <Row>
        <Col span={10} className='FormLogin'>
          <Form className='login-form' layout='vertical' onFinish={handleFinish} form={form}>
            <img srcSet='/logos/logo-full-dman.png 2x' alt='bst-logo' />
            <Typography.Title className='login-title'>Quên mật khẩu</Typography.Title>
            <Form.Item
              name='UserName'
              label='Tên đăng nhập'
              rules={[
                {
                  whitespace: true,
                  required: true,
                  message: 'Tên đăng nhập không được bỏ trống',
                },
              ]}
            >
              <Input prefix={<UserOutlined />} placeholder='Nhập tên đăng nhập' />
            </Form.Item>

            <Button type='primary' htmlType='submit'>
              Yêu cầu tạo lại mật khẩu
            </Button>
            <Link className='mt4' to='/dang-nhap'>
              Đăng nhập
            </Link>
            <span className='mt20 copyright'>Một sản phẩm của công ty TC Software</span>
          </Form>
        </Col>
        <Col span={14}>
          <div className='image'>
            <div>
              <Typography.Title level={2}>Hệ thống quản lý</Typography.Title>
              <Typography.Title level={1} style={{ margin: 0 }}>
                LƯU TRỮ ĐIỆN TỬ
              </Typography.Title>
            </div>
            <img srcSet='/images/login-background.png 3x' alt='login-background' />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ForgotPassword;

import { DashboardResult } from 'services/dashboard/dashboard.model';
import httpClient from 'services/http-client';
export const PREFIX_DASHBOARD = '/api/DashBroad';

const getDataDashboard = (payload: { OrganizationId: number }) => {
  return httpClient.post<DashboardResult>(`${PREFIX_DASHBOARD}/getData`, payload);
};
const DashboardService = {
  getDataDashboard,
};

export default DashboardService;

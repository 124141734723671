import { Paging } from 'core/interface/paging';
import { ResponseWithPaging } from 'core/interface/response-has-paging';
import httpClient from 'services/http-client';
import {
  ComboBoxPhong,
  PhongLuuTru,
  PhongLuuTruPayload,
} from 'services/own-categories/phong-luu-tru/phong-luu-tru.model';

export const PREFIX_AUTH = '/api/PhongLuuTru';

const getPhongLuuTru = (payload: PhongLuuTruPayload, paging: Paging) => {
  return httpClient.post<ResponseWithPaging<PhongLuuTru[]>>(`${PREFIX_AUTH}/search`, payload, {
    headers: {
      PageOption: JSON.stringify(paging),
    },
  });
};
const getComboBoxPhongLuuTru = (payload: PhongLuuTruPayload) => {
  return httpClient.post<ComboBoxPhong[]>(`${PREFIX_AUTH}/getComboboxItem`, payload);
};
const getPhongLuuTruDetail = (id: number) => {
  return httpClient.get<PhongLuuTru>(`${PREFIX_AUTH}/id=${id}`);
};
const createPhongLuuTru = (payload: PhongLuuTruPayload) => {
  return httpClient.post(`${PREFIX_AUTH}`, payload);
};
const exportPhongLuuTru = (payload: PhongLuuTruPayload) => {
  return httpClient.post(`${PREFIX_AUTH}/ExportPhongLuuTru`, payload, {
    responseType: 'blob',
  });
};
const updatePhongLuuTru = (payload: PhongLuuTruPayload) => {
  return httpClient.put(`${PREFIX_AUTH}`, payload);
};
const deletePhongLuuTru = (id: number) => {
  return httpClient.delete(`${PREFIX_AUTH}`, { params: { id: id } });
};

const PhongLuuTruService = {
  getPhongLuuTru,
  getPhongLuuTruDetail,
  createPhongLuuTru,
  updatePhongLuuTru,
  deletePhongLuuTru,
  getComboBoxPhongLuuTru,
  exportPhongLuuTru,
};

export default PhongLuuTruService;

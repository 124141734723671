export enum StatusDocument {
  BACK_UP,
  DANG_THU_THAP,
  HOAN_THANH,
  CHO_DUYET,
  CHO_DUYET_NOP_LUU,
  DUYET_NOP_LUU,
  DA_BAN_GIAO,
  TU_CHOI_THU_THAP,
  TAO_MOI,
  CHO_DUYET_LUU_KHO,
  TU_CHOI_LUU_KHO,
  THEM_MOI,
  CHO_DUYET_BO_SUNG,
  TU_CHO_BO_SUNG,
  DA_DUYET_BO_SUNG,
  BIEN_MUC_BO_SUNG,
  LUU_KHO,
  HET_GIA_TRI,
  DA_TIEU_HUY,
  DA_HET_HAN,
  DA_KHOI_PHUC,
}

export const StatusDocumentValue = new Map<number, { label: string; value: StatusDocument; color: string }>([
  [StatusDocument.HOAN_THANH, { label: 'Hoàn thành', value: StatusDocument.HOAN_THANH, color: 'green' }],
  [
    StatusDocument.TU_CHOI_THU_THAP,
    { label: 'Từ chối thu thập', value: StatusDocument.TU_CHOI_THU_THAP, color: 'red' },
  ],
  [StatusDocument.DANG_THU_THAP, { label: 'Đang thu thập', value: StatusDocument.DANG_THU_THAP, color: 'blue' }],
  [StatusDocument.CHO_DUYET, { label: 'Chờ duyệt ', value: StatusDocument.CHO_DUYET, color: 'gold' }],
  [
    StatusDocument.CHO_DUYET_NOP_LUU,
    { label: 'Chờ duyệt nộp lưu ', value: StatusDocument.CHO_DUYET_NOP_LUU, color: 'blue' },
  ],
  [StatusDocument.DA_BAN_GIAO, { label: 'Đã bàn giao', value: StatusDocument.DA_BAN_GIAO, color: 'blue' }],
  [StatusDocument.DUYET_NOP_LUU, { label: 'Duyệt nộp lưu', value: StatusDocument.DUYET_NOP_LUU, color: 'green' }],
  [StatusDocument.TAO_MOI, { label: 'Tạo mới', value: StatusDocument.TAO_MOI, color: 'blue' }],
  [
    StatusDocument.CHO_DUYET_LUU_KHO,
    { label: 'Chờ duyệt lưu kho', value: StatusDocument.CHO_DUYET_LUU_KHO, color: 'gold' },
  ],
  [StatusDocument.TU_CHOI_LUU_KHO, { label: 'Từ chối lưu kho', value: StatusDocument.TU_CHOI_LUU_KHO, color: 'red' }],
  [StatusDocument.THEM_MOI, { label: 'Thêm mới', value: StatusDocument.THEM_MOI, color: 'blue' }],
  [
    StatusDocument.CHO_DUYET_BO_SUNG,
    { label: 'Chờ duyệt bổ sung', value: StatusDocument.CHO_DUYET_BO_SUNG, color: 'gold' },
  ],
  [StatusDocument.TU_CHO_BO_SUNG, { label: 'Từ chối bổ sung', value: StatusDocument.TU_CHO_BO_SUNG, color: 'red' }],
  [
    StatusDocument.DA_DUYET_BO_SUNG,
    { label: 'Đã duyệt bổ sung', value: StatusDocument.DA_DUYET_BO_SUNG, color: 'green' },
  ],
  [
    StatusDocument.BIEN_MUC_BO_SUNG,
    { label: 'Biên mục bổ sung', value: StatusDocument.BIEN_MUC_BO_SUNG, color: 'bule' },
  ],
  [StatusDocument.LUU_KHO, { label: 'Lưu kho', value: StatusDocument.LUU_KHO, color: 'green' }],
  [StatusDocument.HET_GIA_TRI, { label: 'Hết giá trị', value: StatusDocument.HET_GIA_TRI, color: 'blue' }],
  [StatusDocument.DA_KHOI_PHUC, { label: 'Đã khôi phục', value: StatusDocument.DA_KHOI_PHUC, color: 'green' }],
  [StatusDocument.DA_TIEU_HUY, { label: 'Đã tiêu hủy', value: StatusDocument.DA_TIEU_HUY, color: 'purple' }],
  [StatusDocument.DA_HET_HAN, { label: 'Đã hết hạn', value: StatusDocument.DA_HET_HAN, color: 'gold' }],
]);
export const getStatusDocumentValue = () => {
  return Object.values(StatusDocument)
    .filter((item) => typeof item === 'number')
    .map((item: any) => StatusDocumentValue.get(item));
};

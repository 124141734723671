import { PATH_NAME } from 'core/constants/path-name';
import { AppLayout } from 'layouts';
import ResultComponent from 'pages/ResultComponent';
import ForgotPassword from 'pages/User/ForgotPassword';
import Login from 'pages/User/Login';
import PersistLogin from 'pages/User/PersistLogin';
import ProtectedRoute from 'pages/User/ProtectedRoute';
import ResetPassword from 'pages/User/ResetPassword';
import COLLECTION_AND_SUBMISSION from 'pages/CollectionAndSubmission/collection-and-submission.routes';
import VARIABLE_BINNING from 'pages/VariableBinning/variable-binning.routes';
import { lazy, Suspense } from 'react';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';
import './App.less';
import './assets/scss/main.scss';
import DOCUMENT_STORAGE from 'pages/DocumentStorage/document-storage.routes';
import GENERAL_DIRECTORY from 'pages/GeneralDirectory/general-directory.routes';
import DESTROY_DOCUMENT from 'pages/DestroyDocument/destroy-document.routes';
import MANAGEMENT_AGENT from 'pages/ManagementAgent/management-agent.routes';
import PRIVATE_DIRECTORY from 'pages/PrivateDirectory/private-directory.routes';
import DOCUMENT_MINING_MANAGEMENT from 'pages/DocumentMiningManagement/document-mining-management.routes';
import SUBMIT_HISTORY from 'pages/SubmitHistory/submit-history.routes';
import REPORT_STATISTICS_ROUTE from 'pages/ReportStatistics/report-statistic.routes';
import logErrorToElasticsearch from 'utils/logger';
import { ErrorBoundary } from 'react-error-boundary';
import { WithPermission } from 'components';
import { ScreenPermissionEnum } from 'core/enums/PermissionEnum.enum';
import SYSTEM_MANAGEMENT from 'pages/SystemManagement/system-management.routes';

const Dashboard = lazy(() => import('pages/Dashboard/Dashboard'));
const NotificationPage = lazy(() => import('pages/NotificationPage/NotificationPage'));
const DashboardPage = WithPermission(Dashboard);
const NotificationPagePermission = WithPermission(NotificationPage);
const ErrorFallback = () => {
  return <></>;
};

const myErrorHandler = (error: Error) => {
  window.location.reload();
  logErrorToElasticsearch({ message: error.message, stack: error.stack, name: error.name, cause: error.cause });
};

// parent routes
const routes: RouteObject[] = [
  // public router
  { path: PATH_NAME.LOGIN, element: <Login /> },

  { path: PATH_NAME.FORGOT_PASSWORD, element: <ForgotPassword /> },

  { path: PATH_NAME.RESET_PASSWORD, element: <ResetPassword /> },

  { index: true, element: <Navigate to={PATH_NAME.DASHBOARD} replace /> },

  // private router
  {
    element: <PersistLogin />,
    children: [
      {
        element: <ProtectedRoute />,
        children: [
          {
            element: <AppLayout />,
            children: [
              {
                path: PATH_NAME.DASHBOARD,
                element: (
                  <Suspense fallback={'Đang tải...'}>
                    <DashboardPage requiredPermissions={[ScreenPermissionEnum.DASHBOARD]} />
                  </Suspense>
                ),
              },
              {
                path: PATH_NAME.NOTICE,
                element: (
                  <Suspense fallback={'Đang tải...'}>
                    <NotificationPagePermission requiredPermissions={[ScreenPermissionEnum.NOTICE]} />
                  </Suspense>
                ),
              },
              COLLECTION_AND_SUBMISSION,
              VARIABLE_BINNING,
              DOCUMENT_STORAGE,
              GENERAL_DIRECTORY,
              PRIVATE_DIRECTORY,
              DESTROY_DOCUMENT,
              DOCUMENT_MINING_MANAGEMENT,
              MANAGEMENT_AGENT,
              SUBMIT_HISTORY,
              REPORT_STATISTICS_ROUTE,
              SYSTEM_MANAGEMENT,
            ],
          },
        ],
      },
    ],
  },

  // internal server
  { path: '/500', element: <ResultComponent status={500} title='500' subTitle='Xin lỗi, Có lỗi xảy ra.' /> },
  // not unauthorized
  {
    path: '/403',
    element: (
      <ResultComponent
        status={403}
        title='403'
        subTitle='Xin lỗi, Bạn không có quyền truy cập tới trang này, Vui lòng liên hệ Quản trị viên để được cấp quyền truy cập.'
      />
    ),
  },
  //  catch all routes
  {
    path: '*',
    element: <ResultComponent status={404} title='404' subTitle='Xin lỗi, Trang truy cập không tồn tại.' />,
  },
];

function App() {
  const element = useRoutes(routes);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={myErrorHandler}>
      {element}
    </ErrorBoundary>
  );
}

export default App;

import { useMutation } from '@tanstack/react-query';
import { Button, Col, Form, Input, Row, Typography } from 'antd';
import { PATH_NAME } from 'core/constants/path-name';
import { SYSTEM_PROPERTIES } from 'core/constants/system-properties';
import { useSetDocumentTitle, useShowNotification } from 'hooks';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { AuthService } from 'services/user';
import { ResetPasswordByUserPayload } from 'services/user/auth/auth.model';
import { getErrorMessage } from 'utils';

const ResetPassword = () => {
  const [form] = Form.useForm();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { handleError, handleSuccess } = useShowNotification();

  const resetPasswordMutation = useMutation({
    mutationFn: (payload: ResetPasswordByUserPayload) => AuthService.resetPassword(payload),
  });

  const handleFinish = ({ NewPassword, ReNewPassword }: ResetPasswordByUserPayload) => {
    const payload: ResetPasswordByUserPayload = {
      NewPassword,
      ReNewPassword,
      Token: searchParams.get('token') as string,
    };

    resetPasswordMutation
      .mutateAsync(payload)
      .then(() => {
        handleSuccess('Thay đổi mật khẩu thành công. Vui lòng đăng nhập lại');
        navigate(PATH_NAME.LOGIN);
      })
      .catch((error) => handleError(getErrorMessage(error)));
  };

  useSetDocumentTitle('Thay đổi mật khẩu');

  if (!searchParams.get('token')) {
    return <Navigate to='/404' />;
  }

  return (
    <div className='Login'>
      <Row>
        <Col span={10} className='FormLogin'>
          <Form className='login-form' layout='vertical' onFinish={handleFinish} form={form}>
            <img srcSet='/logos/logo-full-dman.png 2x' alt='bst-logo' />
            <Typography.Title className='login-title'>Tạo mật khẩu</Typography.Title>
            <Form.Item
              name='NewPassword'
              label='Mật khẩu mới'
              rules={[
                { required: true, pattern: SYSTEM_PROPERTIES.PASSWORD, message: 'Mật khẩu không đúng định dạng' },
              ]}
            >
              <Input.Password allowClear placeholder='Nhập mật khẩu mới' />
            </Form.Item>

            <Form.Item
              name='ReNewPassword'
              label='Xác nhận mật khẩu'
              dependencies={['NewPassword']}
              rules={[
                {
                  required: true,
                  message: 'Mật khẩu không đúng định dạng',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('NewPassword') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Hai mật khẩu bạn đã nhập không khớp.'));
                  },
                }),
              ]}
            >
              <Input.Password allowClear placeholder='Nhập xác nhận mật khẩu' />
            </Form.Item>

            <Button type='primary' htmlType='submit'>
              Tạo lại mật khẩu
            </Button>
            <span className='mt20 copyright'>Một sản phẩm của công ty TC Software</span>
          </Form>
        </Col>
        <Col span={14}>
          <div className='image'>
            <div>
              <Typography.Title level={2}>Hệ thống quản lý</Typography.Title>
              <Typography.Title level={1} style={{ margin: 0 }}>
                LƯU TRỮ ĐIỆN TỬ
              </Typography.Title>
            </div>
            <img srcSet='/images/login-background.png 3x' alt='login-background' />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ResetPassword;

export enum UsageModeEnum {
  HAN_CHE,
  KHONG_HAN_CHE,
}

export const UsageModeEnumValue = new Map<number, { label: string; value: UsageModeEnum }>([
  [UsageModeEnum.HAN_CHE, { label: 'Hạn chế', value: UsageModeEnum.HAN_CHE }],
  [UsageModeEnum.KHONG_HAN_CHE, { label: 'Không hạn chế', value: UsageModeEnum.KHONG_HAN_CHE }],
]);
export const getUsageModeEnumValue = () => {
  return Object.values(UsageModeEnum)
    .filter((item) => typeof item === 'number')
    .map((item: any) => UsageModeEnumValue.get(item));
};

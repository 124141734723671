import { ColumnDocumentComponent, getColumnImageValue } from 'core/enums/column-document-component';
import { useMemo } from 'react';

const useGetColumnImage = () => {
  const columnImage = getColumnImageValue();
  const dataSource = useMemo(
    () =>
      columnImage.map((item) => ({
        label: item?.label as string,
        value: item?.value as unknown as ColumnDocumentComponent,
      })),
    [],
  );
  return { dataSource };
};

export default useGetColumnImage;

import { LockOutlined, ReloadOutlined, UserOutlined } from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';
import { Button, Checkbox, Col, Form, Input, Row, Typography } from 'antd';
import { getTokenFirebase, selectTokenFirebase, setCredentials } from 'app/auth/auth.slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { PATH_NAME } from 'core/constants/path-name';
import { PermissionEnum, ScreenPermissionEnum } from 'core/enums/PermissionEnum.enum';
import { useLocalStorage, useSetDocumentTitle, useShowNotification } from 'hooks';
import { t } from 'i18next';
import { useEffect, useRef, useState } from 'react';
import Captcha from 'react-captcha-code';
import { Link, useNavigate } from 'react-router-dom';
import { UserService } from 'services/user';
import { LoginPayload } from 'services/user/auth/auth.model';
import AuthService from 'services/user/auth/auth.service';
import { getErrorMessage, showNotification } from 'utils';
import { getMessagingToken } from './../../firebase';

const hasPermission = (permissions: any[], permission: any) => permissions.includes(permission);

export const getPathName = (permissions: any[]) => {
  if (hasPermission(permissions, ScreenPermissionEnum.ADMIN)) return PATH_NAME.DASHBOARD;
  if (hasPermission(permissions, ScreenPermissionEnum.DASHBOARD)) return PATH_NAME.DASHBOARD;

  // Thu thập và nộp lưu
  if (hasPermission(permissions, PermissionEnum.DOC_INDEX_VIEW)) {
    return PATH_NAME.COLLECTION_AND_SUBMISSION.DOCUMENT_INDEX.BASE;
  }
  if (hasPermission(permissions, PermissionEnum.DOCUMENT_MANAGEMENT_VIEW)) {
    return PATH_NAME.COLLECTION_AND_SUBMISSION.DOCUMENT_MANAGEMENT.BASE;
  }
  if (hasPermission(permissions, PermissionEnum.DOCUMENT_PLAN_VIEW)) {
    return PATH_NAME.COLLECTION_AND_SUBMISSION.DOCUMENT_PLAN_MANAGEMENT.BASE;
  }
  if (hasPermission(permissions, PermissionEnum.HANDOVER_DOCUMENT_VIEW)) {
    return PATH_NAME.COLLECTION_AND_SUBMISSION.HANDOVER_DOCUMENT_MANAGEMENT.BASE;
  }
  if (hasPermission(permissions, PermissionEnum.RECEIVE_DOC_VIEW)) {
    return PATH_NAME.COLLECTION_AND_SUBMISSION.RECEIVE_DOCUMENT.BASE;
  }
  if (hasPermission(permissions, PermissionEnum.REVIEW_AND_SUBMIT_DOC_VIEW)) {
    return PATH_NAME.COLLECTION_AND_SUBMISSION.REVIEW_AND_SUBMIT_FILE_SUBMISSION.BASE;
  }
  if (hasPermission(permissions, PermissionEnum.REVIEW_DOCUMENT_PLAN_VIEW)) {
    return PATH_NAME.COLLECTION_AND_SUBMISSION.REVIEW_DOCUMENT_PLAN.BASE;
  }

  // Biên mục chỉnh lý
  if (hasPermission(permissions, PermissionEnum.DOC_INDEXING_VIEW)) {
    return PATH_NAME.VARIABLE_BINNING.DOCUMENT_INDEXING.BASE;
  }
  if (hasPermission(permissions, PermissionEnum.VARIABLE_REPOSITORY_BROWSING_VIEW)) {
    return PATH_NAME.VARIABLE_BINNING.VARIABLE_REPOSITORY_BROWSING.BASE;
  }
  if (hasPermission(permissions, PermissionEnum.SUPPLEMENTAL_INDEXING_MANAGEMENT_VIEW)) {
    return PATH_NAME.VARIABLE_BINNING.SUPPLEMENTAL_INDEXING_MANAGEMENT.BASE;
  }
  if (hasPermission(permissions, PermissionEnum.REVIEW_SUPPLEMENTAL_INDEXING_VIEW)) {
    return PATH_NAME.VARIABLE_BINNING.REVIEW_SUPPLEMENTAL_INDEXING.BASE;
  }
  if (hasPermission(permissions, PermissionEnum.SUPPLEMENTAL_DOCUMENT_INDEXING_VIEW)) {
    return PATH_NAME.VARIABLE_BINNING.SUPPLEMENTAL_DOCUMENT_INDEXING.BASE;
  }

  // kho lưu trữ
  if (hasPermission(permissions, PermissionEnum.BARCODE_VIEW)) {
    return PATH_NAME.DOCUMENT_STORAGE.BARCODE.BASE;
  }
  if (hasPermission(permissions, PermissionEnum.DOCUMENT_SEARCH_VIEW)) {
    return PATH_NAME.DOCUMENT_STORAGE.DOCUMENT_SEARCH.BASE;
  }
  if (hasPermission(permissions, PermissionEnum.DOCUMENT_COMPONENT_SEARCH_VIEW)) {
    return PATH_NAME.DOCUMENT_STORAGE.DOCUMENT_COMPONENT_SEARCH.BASE;
  }
  if (hasPermission(permissions, PermissionEnum.MOVE_DOCUMENT_VIEW)) {
    return PATH_NAME.DOCUMENT_STORAGE.MOVE_DOCUMENT.BASE;
  }
  if (hasPermission(permissions, PermissionEnum.FREEZING_DOCUMENT_VIEW)) {
    return PATH_NAME.DOCUMENT_STORAGE.FREEZING_DOCUMENT.BASE;
  }
  if (hasPermission(permissions, PermissionEnum.TRANSFER_DOCUMENT_VIEW)) {
    return PATH_NAME.DOCUMENT_STORAGE.TRANSFER_DOCUMENT;
  }

  // Tiêu hủy hồ sơ
  if (hasPermission(permissions, PermissionEnum.REVIEW_DESTROY_DOCUMENT_VIEW)) {
    return PATH_NAME.DESTROY_DOCUMENT.REVIEW_DESTROY_DOCUMENT.BASE;
  }
  if (hasPermission(permissions, PermissionEnum.DESTROY_DECISION_VIEW)) {
    return PATH_NAME.DESTROY_DOCUMENT.DESTROY_DECISION;
  }
  if (hasPermission(permissions, PermissionEnum.REVIEW_DESTROY_DECISION_VIEW)) {
    return PATH_NAME.DESTROY_DOCUMENT.REVIEW_DESTROY_DECISION;
  }
  if (hasPermission(permissions, PermissionEnum.DESTROYED_DECISION_VIEW)) {
    return PATH_NAME.DESTROY_DOCUMENT.DESTROYED_DECISION;
  }

  // Quản lý khai thác
  if (hasPermission(permissions, PermissionEnum.LEVEL_DOCUMENT_MINING_MGMT_VIEW)) {
    return PATH_NAME.DOCUMENT_MINING_MANAGEMENT.LEVEL_DOCUMENT_MINING_MANAGEMENT.BASE;
  }
  if (hasPermission(permissions, PermissionEnum.DOCUMENT_COMPONENT_MINING_VIEW)) {
    return PATH_NAME.DOCUMENT_MINING_MANAGEMENT.DOCUMENT_COMPONENT_MINING.BASE;
  }

  if (hasPermission(permissions, PermissionEnum.DOCUMENT_MINING_TICKET_VIEW)) {
    return PATH_NAME.DOCUMENT_MINING_MANAGEMENT.DOCUMENT_MINING_TICKET.BASE;
  }
  if (hasPermission(permissions, PermissionEnum.REVIEW_DOCUMENT_MINING_TICKET_VIEW)) {
    return PATH_NAME.DOCUMENT_MINING_MANAGEMENT.REVIEW_DOCUMENT_MINING_TICKET.BASE;
  }
  if (hasPermission(permissions, PermissionEnum.REVIEW_UNIT_VIEW)) {
    return PATH_NAME.DOCUMENT_MINING_MANAGEMENT.REVIEW_UNIT.BASE;
  }

  // Báo cáo thống kê
  if (hasPermission(permissions, PermissionEnum.DIGITAL_STORAGE_INVENTORY_STATUS_VIEW)) {
    return PATH_NAME.REPORT_STATISTICS.DIGITAL_STORAGE_INVENTORY_STATUS;
  }
  if (hasPermission(permissions, PermissionEnum.BACKPACK_STATISTICS_VIEW)) {
    return PATH_NAME.REPORT_STATISTICS.BACKPACK_STATISTICS;
  }
  if (hasPermission(permissions, PermissionEnum.MINING_STATISTICS_VIEW)) {
    return PATH_NAME.REPORT_STATISTICS.MINING_STATISTICS;
  }

  // Danh mục riêng
  if (hasPermission(permissions, PermissionEnum.PHONG_LUU_TRU_VIEW)) {
    return PATH_NAME.PRIVATE_DIRECTORY.PHONG_LUU_TRU;
  }
  if (hasPermission(permissions, PermissionEnum.CATALOGUE_VIEW)) {
    return PATH_NAME.PRIVATE_DIRECTORY.CATALOGUE;
  }
  if (hasPermission(permissions, PermissionEnum.ARCHIVES_VIEW)) {
    return PATH_NAME.PRIVATE_DIRECTORY.ARCHIVES;
  }
  if (hasPermission(permissions, PermissionEnum.SHELF_VIEW)) {
    return PATH_NAME.PRIVATE_DIRECTORY.SHELF;
  }
  if (hasPermission(permissions, PermissionEnum.BOX_VIEW)) {
    return PATH_NAME.PRIVATE_DIRECTORY.BOX;
  }
  if (hasPermission(permissions, PermissionEnum.PROFILE_TYPE_VIEW)) {
    return PATH_NAME.PRIVATE_DIRECTORY.PROFILE_TYPE;
  }

  // Danh mục chung
  if (hasPermission(permissions, PermissionEnum.SECURITY_LEVEL_VIEW)) {
    return PATH_NAME.GENERAL_DIRECTORY.SECURITY_LEVEL.BASE;
  }
  if (hasPermission(permissions, PermissionEnum.WARRANTY_PERIOD_VIEW)) {
    return PATH_NAME.GENERAL_DIRECTORY.WARRANTY_PERIOD.BASE;
  }
  if (hasPermission(permissions, PermissionEnum.POSITION_VIEW)) {
    return PATH_NAME.GENERAL_DIRECTORY.POSITION;
  }
  if (hasPermission(permissions, PermissionEnum.LANGUAGE_VIEW)) {
    return PATH_NAME.GENERAL_DIRECTORY.LANGUAGE.BASE;
  }

  // Quản trị cơ quan
  if (hasPermission(permissions, PermissionEnum.FORM_VIEW)) {
    return PATH_NAME.MANAGEMENT_AGENT.FORM;
  }
  if (hasPermission(permissions, PermissionEnum.MANAGEMENT_PERMISSION_VIEW)) {
    return PATH_NAME.MANAGEMENT_AGENT.MANAGEMENT_PERMISSION;
  }
  if (hasPermission(permissions, PermissionEnum.MANAGEMENT_GROUP_USER_VIEW)) {
    return PATH_NAME.MANAGEMENT_AGENT.MANAGEMENT_GROUP_USER;
  }
  if (hasPermission(permissions, PermissionEnum.MANAGEMENT_USER_VIEW)) {
    return PATH_NAME.MANAGEMENT_AGENT.MANAGEMENT_USER;
  }
  if (hasPermission(permissions, PermissionEnum.MANAGEMENT_COMPANY_VIEW)) {
    return PATH_NAME.MANAGEMENT_AGENT.MANAGEMENT_COMPANY;
  }
  if (hasPermission(permissions, PermissionEnum.AUTHORIZATION_BY_FILE_TYPE_VIEW)) {
    return PATH_NAME.MANAGEMENT_AGENT.AUTHORIZATION_BY_FILE_TYPE;
  }
  if (hasPermission(permissions, PermissionEnum.MANAGEMENT_READERS_VIEW)) {
    return PATH_NAME.MANAGEMENT_AGENT.MANAGEMENT_READERS;
  }

  // Nộp lưu lịch sử
  if (hasPermission(permissions, PermissionEnum.SUBMIT_HISTORY_MANAGEMENT_VIEW)) {
    return PATH_NAME.SUBMIT_HISTORY.SUBMIT_HISTORY_MANAGEMENT.BASE;
  }
  if (hasPermission(permissions, PermissionEnum.REVIEW_SUBMIT_HISTORY_VIEW)) {
    return PATH_NAME.SUBMIT_HISTORY.REVIEW_SUBMIT_HISTORY.BASE;
  }
  if (hasPermission(permissions, PermissionEnum.SUBMITTED_HISTORY_VIEW)) {
    return PATH_NAME.SUBMIT_HISTORY.SUBMITTED_HISTORY.BASE;
  }
  return '/403';
};
const Login = () => {
  const captchaRef = useRef<any>();
  const [, setCaptcha] = useState('');
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { handleError } = useShowNotification();
  const [refreshToken, setRefreshToken] = useLocalStorage('refreshToken', '');
  const [failedLoginCount, setFailedLoginCount] = useLocalStorage('failedLoginCount', 0);
  const tokenFirebase = useAppSelector(selectTokenFirebase);

  const loginMutation = useMutation({
    mutationFn: (payload: LoginPayload) => AuthService.login(payload),
  });
  const isRemember = Form.useWatch('remember', form);
  const handleFinish = ({ UserName, Password }: LoginPayload) => {
    loginMutation
      .mutateAsync({ UserName, Password, IsKeepLogin: isRemember })
      .then(async (res) => {
        const {
          AccessToken,
          RefreshToken,
          ExpirationTime,
          UserName,
          UserId,
          Permission,
          Phonenumber,
          Email,
          FullName,
          AvatarLink,
          CustomerType,
          Location,
          OrganizationId,
          OrganizationName,
          OrganizationCode,
          UnitOrganizationName,
          LocationDetail,
          Passport,
        } = res.data;

        const tokenFirebase = await getMessagingToken(AccessToken);
        if (tokenFirebase) {
          dispatch(getTokenFirebase(tokenFirebase));
        }

        dispatch(
          setCredentials({
            accessToken: AccessToken,
            expirationTime: ExpirationTime,
            email: Email,
            fullName: FullName,
            location: Location,
            permission: Permission,
            phoneNumber: Phonenumber,
            userId: UserId,
            userName: UserName,
            customerType: CustomerType,
            avatarLink: AvatarLink,
            organizationId: OrganizationId,
            organizationName: OrganizationName,
            organizationCode: OrganizationCode,
            unitOrganizationName: UnitOrganizationName,
            locationDetail: LocationDetail,
            passport: Passport,
          }),
        );

        setRefreshToken(RefreshToken);
        setFailedLoginCount(0);
        const pathname = getPathName(Permission);
        navigate(pathname, { replace: true });
      })
      .catch((error) => {
        handleError(getErrorMessage(error));
        setFailedLoginCount((value) => value + 1);
        failedLoginCount > 4 && captchaRef.current.refresh();
        form.resetFields(['Captcha']);
      });
  };
  useSetDocumentTitle('Đăng nhập');

  useEffect(() => {
    const unFirebase = async () => {
      if (tokenFirebase) {
        await UserService.firebaseUnregisterFCMToken(tokenFirebase).catch(() =>
          showNotification('error', t('notification.error'), 'Có lỗi xảy ra!'),
        );
      }
    };
    unFirebase();
    localStorage.clear();
  }, []);

  return (
    <div className='Login'>
      <Row>
        <Col span={10} className='FormLogin'>
          <Form
            className='login-form'
            layout='vertical'
            onFinish={handleFinish}
            form={form}
            initialValues={{
              remember: false,
            }}
          >
            <img srcSet='/logos/logo-full-dman.png 2x' alt='bst-logo' />
            <Typography.Title className='login-title'>Đăng nhập</Typography.Title>
            <Form.Item
              name='UserName'
              label='Tên đăng nhập'
              rules={[
                {
                  whitespace: true,
                  required: true,
                  message: 'Tên đăng nhập không được bỏ trống',
                },
              ]}
            >
              <Input prefix={<UserOutlined />} placeholder='Nhập tên đăng nhập' />
            </Form.Item>

            <Form.Item
              label='Mật khẩu'
              name='Password'
              rules={[
                {
                  whitespace: true,
                  required: true,
                  message: 'Mật khẩu không được bỏ trống',
                },
              ]}
            >
              <Input.Password prefix={<LockOutlined />} placeholder='Nhập passowrd' />
            </Form.Item>

            {failedLoginCount > 4 && (
              <>
                <Form.Item
                  label='Mã xác thực'
                  name='captcha'
                  rules={[{ required: true, whitespace: true, message: 'Nhập xác thực từ hình ảnh!' }]}
                >
                  <Input placeholder='Nhập mã xác thực' />
                </Form.Item>

                <div className='captcha mb16'>
                  <Captcha ref={captchaRef} onChange={(value) => setCaptcha(value)} width={200} />
                  <ReloadOutlined className='ml8' type='primary' onClick={() => captchaRef.current.refresh()} />
                </div>
              </>
            )}

            <div className='remember-forget'>
              <Form.Item name='remember' valuePropName='checked' wrapperCol={{ span: 24 }}>
                <Checkbox>Ghi nhớ đăng nhập</Checkbox>
              </Form.Item>
              <Link className='mt4' to='/quen-mat-khau'>
                Quên mật khẩu
              </Link>
            </div>

            <Button type='primary' htmlType='submit'>
              Đăng nhập
            </Button>

            <span className='mt20 copyright'>Một sản phẩm của công ty TC Software</span>
          </Form>
        </Col>
        <Col span={14}>
          <div className='image'>
            <div>
              <Typography.Title level={2}>Hệ thống quản lý</Typography.Title>
              <Typography.Title level={1} style={{ margin: 0 }}>
                LƯU TRỮ ĐIỆN TỬ
              </Typography.Title>
            </div>
            <img srcSet='/images/login-background.png 3x' alt='login-background' />
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default Login;

import { Select } from 'antd';
import { FocusEventHandler } from 'react';
import { handleFilter } from 'utils';

interface SelectComponentProps {
  bordered?: boolean;
  value?: any;
  dataSources: any;
  placeholder: string;
  classSelect?: string;
  isDisable?: boolean;
  mode?: 'multiple' | 'tags' | undefined;
  optionFilterProp?: string;
  onChange?: (
    value: any,
    option:
      | {
          label: string;
          disabled?: boolean;
          value: any;
        }
      | {
          label: string;
          disabled?: boolean;
          value: any;
        }[],
  ) => void;
  style?: React.CSSProperties;
  sort?: boolean;
  onFocus?: FocusEventHandler<HTMLElement>;
  defaultValue?: any;
}

const SelectComponent = (props: SelectComponentProps) => {
  const {
    placeholder,
    dataSources,
    onChange,
    mode,
    classSelect,
    isDisable,
    style,
    optionFilterProp,
    bordered,
    sort,
    onFocus,
    defaultValue,
  } = props;

  return (
    <Select
      defaultValue={defaultValue}
      bordered={bordered}
      optionFilterProp={optionFilterProp}
      className={classSelect}
      placeholder={placeholder}
      options={dataSources}
      disabled={isDisable}
      value={props.value}
      onChange={onChange}
      mode={mode}
      maxTagCount={5}
      allowClear
      showSearch
      filterOption={handleFilter}
      filterSort={(firstOptions, secondOption) =>
        sort
          ? parseInt(firstOptions.label) - parseInt(secondOption.label)
          : firstOptions.label?.localeCompare(secondOption.label)
      }
      style={style}
      onFocus={onFocus}
    />
  );
};

export default SelectComponent;

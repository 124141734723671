import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Pagination, Space } from 'antd';
import React, { useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

interface PDFViewerProps {
  preview: any;
  onRemovePdfFile?: () => void;
  mode?: string;
}

const processViewFile = (url: string) => {
  const pdfWindow = window.open('');
  pdfWindow?.document.write(
    `
		<iframe width='100%' height='100%' src=${encodeURI(url)} ></iframe>,
		`,
  );
};

const PDFViewer = (props: PDFViewerProps) => {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const handleChangePage = (page: number) => {
    setPageNumber(page);
  };

  const handleRemoveFile = () => {
    if (props.onRemovePdfFile) props.onRemovePdfFile();
  };

  const handleViewFile = () => {
    processViewFile(props.preview);
  };

  return (
    <div className='pdf-viewer-wrapper'>
      <Document
        file={props.preview}
        onLoadSuccess={onDocumentLoadSuccess}
        loading='Đang tải file'
        error='Có lỗi xảy ra.'
      >
        <Page pageNumber={pageNumber} />
      </Document>
      {numPages > 0 && (
        <Pagination
          className='pdf-pagination'
          size='small'
          simple
          defaultCurrent={1}
          defaultPageSize={1}
          total={numPages}
          onChange={handleChangePage}
        />
      )}
      {numPages > 0 && (
        <Space className='pdf-action'>
          <Button
            type='default'
            className='mr5'
            size='small'
            icon={<EyeOutlined />}
            onClick={handleViewFile}
            disabled={false}
          >
            Xem file
          </Button>

          <Button type='primary' ghost size='small' icon={<DeleteOutlined />} onClick={handleRemoveFile}>
            Xóa file
          </Button>
        </Space>
      )}
    </div>
  );
};

export default PDFViewer;

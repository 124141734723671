import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { useDebounce } from 'hooks';
import { useState } from 'react';

const useFullTextSearch = (placeholder?: string) => {
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearch = useDebounce(searchValue, 1000);
  const inputSearch = (
    <Input
      style={{ width: '30%' }}
      placeholder={placeholder ?? 'Nhập thông tin tìm kiếm'}
      suffix={<SearchOutlined />}
      onChange={(event) => setSearchValue(event.target.value)}
      allowClear
    />
  );
  return {
    debouncedSearch,
    inputSearch,
  };
};

export default useFullTextSearch;

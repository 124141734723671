export enum StatusBorrowTicket {
  TAO_MOI,
  CHO_QUAN_LY_KHO_DUYET,
  CHO_LANH_DAO_DUYET,
  QUAN_LY_KHO_TU_CHOI,
  LANH_DAO_TU_CHOI,
  HOAN_TAT_PHE_DUYET,
  HUY,
}
export const StatusBorrowTicketValue = new Map<number, { label: string; value: StatusBorrowTicket; color: string }>([
  [
    StatusBorrowTicket.CHO_QUAN_LY_KHO_DUYET,
    { label: 'Chờ quản lý kho duyệt', value: StatusBorrowTicket.CHO_QUAN_LY_KHO_DUYET, color: 'gold' },
  ],
  [
    StatusBorrowTicket.HOAN_TAT_PHE_DUYET,
    { label: 'Hoàn tất phê duyệt', value: StatusBorrowTicket.HOAN_TAT_PHE_DUYET, color: 'green' },
  ],
  [
    StatusBorrowTicket.QUAN_LY_KHO_TU_CHOI,
    { label: 'Quản lý kho từ chối', value: StatusBorrowTicket.QUAN_LY_KHO_TU_CHOI, color: 'red' },
  ],
  [StatusBorrowTicket.HUY, { label: 'Hủy', value: StatusBorrowTicket.HUY, color: 'purple' }],
  [
    StatusBorrowTicket.CHO_LANH_DAO_DUYET,
    { label: 'Chờ lãnh đạo duyệt', value: StatusBorrowTicket.CHO_LANH_DAO_DUYET, color: 'gold' },
  ],
  [
    StatusBorrowTicket.LANH_DAO_TU_CHOI,
    { label: 'Lãnh đạo từ chối', value: StatusBorrowTicket.LANH_DAO_TU_CHOI, color: 'red' },
  ],
  [StatusBorrowTicket.TAO_MOI, { label: 'Tạo mới', value: StatusBorrowTicket.TAO_MOI, color: 'blue' }],
]);
export const getStatusBorrowTicketValue = () => {
  return Object.values(StatusBorrowTicket)
    .filter((item) => typeof item === 'number')
    .map((item: any) => StatusBorrowTicketValue.get(item));
};

import { setCredentials } from 'app/auth/auth.slice';
import { store } from 'app/store';
import axios, { AxiosError, AxiosInstance } from 'axios';
import { PATH_NAME } from 'core/constants/path-name';
import { t } from 'i18next';
import { router } from 'index';
import { AuthService } from 'services/user';
import { getErrorMessage, showNotification } from 'utils';
import logErrorToElasticsearch from 'utils/logger';

export class HttpClient {
  instance: AxiosInstance;
  constructor() {
    this.instance = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
      timeout: 10000,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    this.instance.interceptors.request.use(
      (config) => {
        config.headers = config.headers ?? {};
        const accessToken = store.getState().auth.accessToken;
        if (accessToken) {
          config.headers['Authorization'] = `Bearer ${accessToken}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );
    this.instance.interceptors.response.use(
      (response) => {
        return response;
      },
      async (err: AxiosError) => {
        const status = err.response?.status ?? 0;
        const originalConfig = (err?.config as any) ?? {};

        // khi response có lỗi trả về, thực hiện ghi log tới hệ thống ELK
        logErrorToElasticsearch(err.response?.data);

        // khi token hiện tại hết hạn , thực hiện lấy token mới
        if (originalConfig?.url !== '/api/users/login' && err.response) {
          if (err.response.status === 401 && !originalConfig._retry) {
            originalConfig._retry = true;
            const refreshToken = localStorage.getItem('refreshToken');

            // Nếu không tồn tại token trong memory, navigate về trang login
            if (!refreshToken) {
              router.navigate(PATH_NAME.LOGIN);
              return;
            }

            try {
              const response = await AuthService.refreshToken({
                RefreshToken: JSON.parse(refreshToken),
              });

              const {
                AccessToken,
                RefreshToken,
                AvatarLink,
                Email,
                FullName,
                Location,
                Permission,
                Phonenumber,
                UserId,
                UserName,
                CustomerType,
                ExpirationTime,
                OrganizationId,
                OrganizationName,
                OrganizationCode,
                UnitOrganizationName,
                LocationDetail,
                Passport,
              } = response.data;

              store.dispatch(
                setCredentials({
                  accessToken: AccessToken,
                  avatarLink: AvatarLink,
                  email: Email,
                  fullName: FullName,
                  location: Location,
                  permission: Permission,
                  phoneNumber: Phonenumber,
                  userId: UserId,
                  userName: UserName,
                  customerType: CustomerType,
                  expirationTime: ExpirationTime,
                  organizationId: OrganizationId,
                  organizationName: OrganizationName,
                  organizationCode: OrganizationCode,
                  unitOrganizationName: UnitOrganizationName,
                  locationDetail: LocationDetail,
                  passport: Passport,
                }),
              );

              localStorage.setItem('refreshToken', RefreshToken);

              return this.instance(originalConfig);
            } catch (error) {
              return Promise.reject(error);
            }
          }
        }

        // khi tk đổi mk or bị khoá, or thay đổi quyền sẽ thực hiện navigate về trang đăng nhập
        if (status === 403) {
          localStorage.clear();
          showNotification('error', t('notification.error'), getErrorMessage(err));
          router.navigate(PATH_NAME.LOGIN);
        }
        if (status >= 500 && status <= 599) {
          window.location.href = '/500';
        }

        return Promise.reject(err);
      },
    );
  }
}

const httpClient = new HttpClient().instance;

export default httpClient;

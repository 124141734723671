import { Button, Space, Typography } from 'antd';
import Confirmation from 'assets/icons/Confirmation';
import { ModalComponent } from 'components';
import { BaseModal } from 'core/interface/base-modal';
import React from 'react';
interface ConfirmModalProps extends BaseModal {
  icon?: React.ReactNode;
  title: string;
  content?: React.ReactNode;
  handleOk?: any;
  cancelText?: string;
  okText?: string;
  BtnColor?: string;
  children?: React.ReactNode;
  width?: number;
  modeBtnOk?: 'view';
}
const ConfirmModal = ({
  hide,
  isModalOpen,
  title,
  content,
  handleOk,
  icon,
  okText,
  cancelText = 'Hủy',
  BtnColor = '#D60000',
  children,
  width = 500,
  modeBtnOk,
}: ConfirmModalProps) => {
  return (
    <ModalComponent
      isModalOpen={isModalOpen}
      hide={hide}
      width={width}
      className='ConfirmModal'
      footer={
        <Space key='action'>
          <Button
            type='default'
            onClick={() => {
              hide();
            }}
          >
            {cancelText}
          </Button>
          {modeBtnOk !== 'view' && (
            <Button type='primary' onClick={handleOk} style={{ backgroundColor: BtnColor, borderColor: BtnColor }}>
              {okText}
            </Button>
          )}
        </Space>
      }
    >
      <div className='container'>
        {icon}
        <Typography.Title level={4}>{title}</Typography.Title>
        <Typography.Text>{content}</Typography.Text>
      </div>
      {/* Input lý do từ chối duyệt hoặc người xác nhận */}
      {children}
    </ModalComponent>
  );
};

export default ConfirmModal;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

interface SortBox {
  local: {
    archives: number;
    shelf: number;
    box: number;
  } | null;
}
const initialState: SortBox = {
  local: null,
};

const sortBoxSlice = createSlice({
  name: 'sortBox',
  initialState,
  reducers: {
    saveBox: (state: SortBox, action: PayloadAction<{ archives: number; shelf: number; box: number }>) => {
      state.local = action.payload;
    },
    removeBox: (state: SortBox) => {
      state.local = null;
    },
  },
});

export const { saveBox, removeBox } = sortBoxSlice.actions;
export const getNewBox = (state: RootState) => state.sortBox.local;

export default sortBoxSlice.reducer;

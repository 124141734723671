import React from 'react';

interface Props {
  className?: string;
}

const FolderCompress = (props: Props) => {
  return (
    <svg height='24' width='24' fill='none' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M19 5.5H12.72L12.4 4.5C12.1926 3.91323 11.8077 3.4055 11.2989 3.04716C10.7901 2.68882 10.1824 2.4976 9.56 2.5H5C4.20435 2.5 3.44129 2.81607 2.87868 3.37868C2.31607 3.94129 2 4.70435 2 5.5V18.5C2 19.2956 2.31607 20.0587 2.87868 20.6213C3.44129 21.1839 4.20435 21.5 5 21.5H19C19.7956 21.5 20.5587 21.1839 21.1213 20.6213C21.6839 20.0587 22 19.2956 22 18.5V8.5C22 7.70435 21.6839 6.94129 21.1213 6.37868C20.5587 5.81607 19.7956 5.5 19 5.5ZM20 18.5C20 18.7652 19.8946 19.0196 19.7071 19.2071C19.5196 19.3946 19.2652 19.5 19 19.5H5C4.73478 19.5 4.48043 19.3946 4.29289 19.2071C4.10536 19.0196 4 18.7652 4 18.5V5.5C4 5.23478 4.10536 4.98043 4.29289 4.79289C4.48043 4.60536 4.73478 4.5 5 4.5H9.56C9.76964 4.49946 9.97416 4.56482 10.1446 4.68684C10.3151 4.80886 10.4429 4.98138 10.51 5.18L11.05 6.82C11.1171 7.01862 11.2449 7.19113 11.4154 7.31316C11.5858 7.43518 11.7904 7.50054 12 7.5H19C19.2652 7.5 19.5196 7.60536 19.7071 7.79289C19.8946 7.98043 20 8.23478 20 8.5V18.5Z'
        fill='#4D4D4D'
      />
      <path
        d='M10 4C10 3.44772 9.55228 3 9 3C8.44772 3 8 3.44772 8 4V8H7C6.44772 8 6 8.44772 6 9C6 9.55228 6.44772 10 7 10H8V12H7C6.44772 12 6 12.4477 6 13C6 13.5523 6.44772 14 7 14H8V16H7C6.44772 16 6 16.4477 6 17C6 17.5523 6.44772 18 7 18H8V21H10V18V16H11C11.5523 16 12 15.5523 12 15C12 14.4477 11.5523 14 11 14H10V12H11C11.5523 12 12 11.5523 12 11C12 10.4477 11.5523 10 11 10H10V8V4Z'
        fill='#4D4D4D'
        fillRule='evenodd'
      />
    </svg>
  );
};

export default FolderCompress;

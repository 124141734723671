import { WithPermission } from 'components';
import { PATH_NAME } from 'core/constants/path-name';
import { PermissionEnum } from 'core/enums/PermissionEnum.enum';
import { lazy, Suspense } from 'react';

const DocumentMiningManagement = lazy(() => import('pages/DocumentMiningManagement/DocumentMiningManagement'));
const DocumentDetail = lazy(() => import('pages/DocumentMiningManagement/share/components/DocumentDetail'));
const DocumentDetailPage = WithPermission(DocumentDetail);

// Quản lý mức độ khai thác

const LevelDocumentMiningManagement = lazy(
  () => import('pages/DocumentMiningManagement/Children/LevelDocumentMiningManagement/LevelDocumentMiningManagement'),
);
const LevelDocumentMiningManagementPage = WithPermission(LevelDocumentMiningManagement);
const DocumentComponentDetail = lazy(() => import('pages/Share/components/DocumentComponentDetail'));
const DocumentComponentDetailPage = WithPermission(DocumentComponentDetail);

const LevelDocumentMiningManagementLayout = lazy(
  () =>
    import('pages/DocumentMiningManagement/Children/LevelDocumentMiningManagement/LevelDocumentMiningManagementLayout'),
);

const LevelDocumentMiningManagementDetail = lazy(
  () =>
    import('pages/DocumentMiningManagement/Children/LevelDocumentMiningManagement/LevelDocumentMiningManagementDetail'),
);
const LevelDocumentMiningManagementDetailPage = WithPermission(LevelDocumentMiningManagementDetail);

// Khai thác tài liệu

const BorrowDocumentDetail = lazy(
  () => import('pages/DocumentMiningManagement/Children/DocumentComponentMining/BorrowDocumentDetail'),
);
const BorrowDocumentDetailPage = WithPermission(BorrowDocumentDetail);
const DocumentComponentMiningLayout = lazy(
  () => import('pages/DocumentMiningManagement/Children/DocumentComponentMining/DocumentComponentMiningLayout'),
);
const DocumentComponentMining = lazy(
  () => import('pages/DocumentMiningManagement/Children/DocumentComponentMining/DocumentComponentMining'),
);
const DocumentComponentMiningPage = WithPermission(DocumentComponentMining);

// Danh sách chờ khai thác

const DocumentMiningWait = lazy(
  () => import('pages/DocumentMiningManagement/Children/DocumentMiningWait/DocumentMiningWait'),
);
const DocumentMiningWaitPage = WithPermission(DocumentMiningWait);

const DocumentMiningWaitLayout = lazy(
  () => import('pages/DocumentMiningManagement/Children/DocumentMiningWait/DocumentMiningWaitLayout'),
);

// Danh sách phiếu khai thác

const DocumentMiningTicketLayout = lazy(
  () => import('pages/DocumentMiningManagement/Children/DocumentMiningTicket/DocumentMiningTicketLayout'),
);
const DocumentMiningTicket = lazy(
  () => import('pages/DocumentMiningManagement/Children/DocumentMiningTicket/DocumentMiningTicket'),
);
const DocumentMiningTicketPage = WithPermission(DocumentMiningTicket);

// Phê duyệt đơn vị

const ReviewUnitLayout = lazy(() => import('pages/DocumentMiningManagement/Children/ReviewUnit/ReviewUnitLayout'));
const ReviewUnit = lazy(() => import('pages/DocumentMiningManagement/Children/ReviewUnit/ReviewUnit'));
const ReviewUnitPage = WithPermission(ReviewUnit);

// Phê duyệt phiếu khai thác

const ReviewDocumentMiningTicketLayout = lazy(
  () => import('pages/DocumentMiningManagement/Children/ReviewDocumentMiningTicket/ReviewDocumentMiningTicketLayout'),
);
const ReviewDocumentMiningTicket = lazy(
  () => import('pages/DocumentMiningManagement/Children/ReviewDocumentMiningTicket/ReviewDocumentMiningTicket'),
);
const ReviewDocumentMiningTicketPage = WithPermission(ReviewDocumentMiningTicket);

const DOCUMENT_MINING_MANAGEMENT = {
  path: `${PATH_NAME.DOCUMENT_MINING_MANAGEMENT.BASE}`,
  element: (
    <Suspense fallback={'Đang tải...'}>
      <DocumentMiningManagement />
    </Suspense>
  ),
  children: [
    {
      index: true,
      element: (
        <Suspense fallback={'Đang tải...'}>
          <LevelDocumentMiningManagementPage requiredPermissions={[PermissionEnum.LEVEL_DOCUMENT_MINING_MGMT_VIEW]} />
        </Suspense>
      ),
    },
    {
      path: 'quan-ly-muc-do-khai-thac',
      element: (
        <Suspense fallback={'Đang tải...'}>
          <LevelDocumentMiningManagementLayout />
        </Suspense>
      ),
      children: [
        {
          index: true,
          element: (
            <Suspense fallback={'Đang tải...'}>
              <LevelDocumentMiningManagementPage
                requiredPermissions={[PermissionEnum.LEVEL_DOCUMENT_MINING_MGMT_VIEW]}
              />
            </Suspense>
          ),
        },
        {
          path: 'chi-tiet-ho-so/:id',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <LevelDocumentMiningManagementDetailPage
                requiredPermissions={[PermissionEnum.LEVEL_DOCUMENT_MINING_MGMT_DETAIL]}
              />
            </Suspense>
          ),
        },
        {
          path: 'chi-tiet-tai-lieu/:id',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <DocumentComponentDetailPage
                requiredPermissions={[PermissionEnum.LEVEL_DOCUMENT_MINING_MGMT_DETAIL_DOC_COMP]}
              />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: 'khai-thac-tai-lieu',
      element: (
        <Suspense fallback={'Đang tải...'}>
          <DocumentComponentMiningLayout />
        </Suspense>
      ),
      children: [
        {
          index: true,
          element: (
            <Suspense fallback={'Đang tải...'}>
              <DocumentComponentMiningPage requiredPermissions={[PermissionEnum.DOCUMENT_COMPONENT_MINING_VIEW]} />
            </Suspense>
          ),
        },
        {
          path: 'chi-tiet-ho-so/:id',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <BorrowDocumentDetailPage
                requiredPermissions={[PermissionEnum.DOCUMENT_COMPONENT_MINING_DETAIL_DOC_COMP]}
              />
            </Suspense>
          ),
        },
        {
          path: 'chi-tiet-tai-lieu/:id',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <DocumentComponentDetailPage requiredPermissions={[PermissionEnum.DOCUMENT_COMPONENT_MINING_DETAIL]} />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: 'danh-sach-cho-khai-thac',
      element: (
        <Suspense fallback={'Đang tải...'}>
          <DocumentMiningWaitLayout />
        </Suspense>
      ),
      children: [
        {
          index: true,
          element: (
            <Suspense fallback={'Đang tải...'}>
              <DocumentMiningWaitPage requiredPermissions={[PermissionEnum.DOCUMENT_COMPONENT_MINING_VIEW]} />
            </Suspense>
          ),
        },
        {
          path: '',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <DocumentMiningWaitPage requiredPermissions={[PermissionEnum.DOCUMENT_COMPONENT_MINING_VIEW]} />
            </Suspense>
          ),
        },
        {
          path: 'chi-tiet-ho-so/:id',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <BorrowDocumentDetailPage requiredPermissions={[PermissionEnum.DOCUMENT_COMPONENT_MINING_VIEW]} />
            </Suspense>
          ),
        },
        {
          path: 'chi-tiet-tai-lieu/:id',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <DocumentComponentDetailPage requiredPermissions={[PermissionEnum.DOCUMENT_COMPONENT_MINING_VIEW]} />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: 'danh-sach-phieu-khai-thac',
      element: (
        <Suspense fallback={'Đang tải...'}>
          <DocumentMiningTicketLayout />
        </Suspense>
      ),
      children: [
        {
          index: true,
          element: (
            <Suspense fallback={'Đang tải...'}>
              <DocumentMiningTicketPage requiredPermissions={[PermissionEnum.DOCUMENT_MINING_TICKET_VIEW]} />
            </Suspense>
          ),
        },
        {
          path: '',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <DocumentMiningWaitPage requiredPermissions={[PermissionEnum.DOCUMENT_MINING_TICKET_VIEW]} />
            </Suspense>
          ),
        },
        {
          path: 'chi-tiet-ho-so/:id',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <DocumentDetailPage requiredPermissions={[PermissionEnum.DOCUMENT_MINING_TICKET_DETAIL_DOC]} />
            </Suspense>
          ),
        },
        {
          path: 'chi-tiet-tai-lieu/:id',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <DocumentComponentDetailPage
                requiredPermissions={[PermissionEnum.DOCUMENT_MINING_TICKET_DETAIL_DOC_COMP]}
              />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: 'phe-duyet-phieu-khai-thac',
      element: (
        <Suspense fallback={'Đang tải...'}>
          <ReviewDocumentMiningTicketLayout />
        </Suspense>
      ),
      children: [
        {
          index: true,
          element: (
            <Suspense fallback={'Đang tải...'}>
              <ReviewDocumentMiningTicketPage
                requiredPermissions={[PermissionEnum.REVIEW_DOCUMENT_MINING_TICKET_VIEW]}
              />
            </Suspense>
          ),
        },
        {
          path: '',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <ReviewDocumentMiningTicketPage
                requiredPermissions={[PermissionEnum.REVIEW_DOCUMENT_MINING_TICKET_VIEW]}
              />
            </Suspense>
          ),
        },
        {
          path: 'chi-tiet-ho-so/:id',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <DocumentDetailPage requiredPermissions={[PermissionEnum.REVIEW_DOCUMENT_MINING_TICKET_DETAIL_DOC]} />
            </Suspense>
          ),
        },
        {
          path: 'chi-tiet-tai-lieu/:id',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <DocumentComponentDetailPage
                requiredPermissions={[PermissionEnum.REVIEW_DOCUMENT_MINING_TICKET_DETAIL_DOC_COMP]}
              />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: 'phe-duyet-don-vi',
      element: (
        <Suspense fallback={'Đang tải...'}>
          <ReviewUnitLayout />
        </Suspense>
      ),
      children: [
        {
          index: true,
          element: (
            <Suspense fallback={'Đang tải...'}>
              <ReviewUnitPage requiredPermissions={[PermissionEnum.REVIEW_UNIT_VIEW]} />
            </Suspense>
          ),
        },
        {
          path: '',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <ReviewUnitPage requiredPermissions={[PermissionEnum.REVIEW_UNIT_VIEW]} />
            </Suspense>
          ),
        },
        {
          path: 'chi-tiet-ho-so/:id',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <DocumentDetailPage requiredPermissions={[PermissionEnum.REVIEW_UNIT_DETAIL_DOC]} />
            </Suspense>
          ),
        },
        {
          path: 'chi-tiet-tai-lieu/:id',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <DocumentComponentDetailPage requiredPermissions={[PermissionEnum.REVIEW_UNIT_DETAIL_DOC_COMP]} />
            </Suspense>
          ),
        },
      ],
    },
  ],
};
export default DOCUMENT_MINING_MANAGEMENT;

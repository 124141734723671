import { Paging } from 'core/interface/paging';
import { ResponseWithPaging } from 'core/interface/response-has-paging';
import httpClient from 'services/http-client';
import { TemplatePayload, Template } from './Template.model';
import { ComboBoxItem } from 'core/interface/combo-box-item';

export const PREFIX_TEMPLATE = '/api/Template';

const getTemplate = (payload: TemplatePayload) => {
  return httpClient.post<Template[]>(`${PREFIX_TEMPLATE}/search`, payload);
};

const getTemplateList = (payload: TemplatePayload, paging: Paging) => {
  return httpClient.post<ResponseWithPaging<Template[]>>(`${PREFIX_TEMPLATE}/search`, payload, {
    headers: {
      PageOption: JSON.stringify(paging),
    },
  });
};
const getComboBoxTemplate = (payload: TemplatePayload) => {
  return httpClient.post<ComboBoxItem[]>(`${PREFIX_TEMPLATE}/getComboboxItem`, payload);
};
const getTemplateDetail = (id: number) => {
  return httpClient.get<Template>(`${PREFIX_TEMPLATE}/id=${id}`);
};

const createTemplate = (payload: Template) => {
  return httpClient.post(`${PREFIX_TEMPLATE}`, payload);
};

const updateTemplate = (payload: Template) => {
  return httpClient.put(`${PREFIX_TEMPLATE}`, payload);
};

const deleteTemplate = (id: number) => {
  return httpClient.delete(`${PREFIX_TEMPLATE}`, { params: { id: id } });
};
const deleteTemplateList = (idLst: number[]) => {
  return httpClient.delete(`${PREFIX_TEMPLATE}/DeleteList`, { data: idLst });
};

const exportFileExcel = (payload: Template) => {
  return httpClient.post(`${PREFIX_TEMPLATE}/ExportListTemplate`, payload, {
    responseType: 'blob',
  });
};

const TemplateService = {
  getTemplate,
  deleteTemplate,
  getTemplateList,
  createTemplate,
  updateTemplate,
  getTemplateDetail,
  exportFileExcel,
  getComboBoxTemplate,
  deleteTemplateList,
};

export default TemplateService;

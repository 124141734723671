import LocationService from 'data';
import { useMemo } from 'react';

const useFetchDistrictList = (provinceId: string) => {
  const districtsByProvinceId = useMemo(() => LocationService.getDistrictsByProvince(provinceId), [provinceId]);

  const dataSource = useMemo(
    () =>
      districtsByProvinceId.map((district) => ({
        key: district.Id,
        label: district.Name,
        value: district.Id,
      })),
    [districtsByProvinceId],
  );

  return { dataSource };
};

export default useFetchDistrictList;

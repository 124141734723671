import React from 'react';
interface Props {
  className?: string;
}
const TongQuan = (props: Props) => {
  return (
    <svg
      height='24'
      width='24'
      fill='none'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      className={props.className}
    >
      <rect height='24' width='24' fill='white' />
      <path
        d='M3 2C2.44772 2 2 2.44772 2 3V10C2 10.5523 2.44772 11 3 11H10C10.5523 11 11 10.5523 11 10V3C11 2.44772 10.5523 2 10 2H3ZM4.5 4C4.22386 4 4 4.22386 4 4.5V8.5C4 8.77614 4.22386 9 4.5 9H8.5C8.77614 9 9 8.77614 9 8.5V4.5C9 4.22386 8.77614 4 8.5 4H4.5Z'
        fill='#4D4D4D'
        fillRule='evenodd'
      />
      <path
        d='M14 2C13.4477 2 13 2.44772 13 3V10C13 10.5523 13.4477 11 14 11H21C21.5523 11 22 10.5523 22 10V3C22 2.44772 21.5523 2 21 2H14ZM15.5 4C15.2239 4 15 4.22386 15 4.5V8.5C15 8.77614 15.2239 9 15.5 9H19.5C19.7761 9 20 8.77614 20 8.5V4.5C20 4.22386 19.7761 4 19.5 4H15.5Z'
        fill='#4D4D4D'
        fillRule='evenodd'
      />
      <path
        d='M14 13C13.4477 13 13 13.4477 13 14V21C13 21.5523 13.4477 22 14 22H21C21.5523 22 22 21.5523 22 21V14C22 13.4477 21.5523 13 21 13H14ZM15.5 15C15.2239 15 15 15.2239 15 15.5V19.5C15 19.7761 15.2239 20 15.5 20H19.5C19.7761 20 20 19.7761 20 19.5V15.5C20 15.2239 19.7761 15 19.5 15H15.5Z'
        fill='#4D4D4D'
        fillRule='evenodd'
      />
      <path
        d='M3 13C2.44772 13 2 13.4477 2 14V21C2 21.5523 2.44772 22 3 22H10C10.5523 22 11 21.5523 11 21V14C11 13.4477 10.5523 13 10 13H3ZM4.5 15C4.22386 15 4 15.2239 4 15.5V19.5C4 19.7761 4.22386 20 4.5 20H8.5C8.77614 20 9 19.7761 9 19.5V15.5C9 15.2239 8.77614 15 8.5 15H4.5Z'
        fill='#4D4D4D'
        fillRule='evenodd'
      />
    </svg>
  );
};

export default TongQuan;

export enum BorrowObjectEnum {
  NGUOI_DUNG_NOI_BO,
  NGUOI_DUNG_BEN_NGOAI,
}

export const BorrowObjectEnumValue = new Map<number, { label: string; value: BorrowObjectEnum }>([
  [
    BorrowObjectEnum.NGUOI_DUNG_BEN_NGOAI,
    { label: 'Người dùng bên ngoài', value: BorrowObjectEnum.NGUOI_DUNG_BEN_NGOAI },
  ],
  [BorrowObjectEnum.NGUOI_DUNG_NOI_BO, { label: 'Người dùng nội bộ', value: BorrowObjectEnum.NGUOI_DUNG_NOI_BO }],
]);
export const getBorrowObjectEnumValue = () => {
  return Object.values(BorrowObjectEnum)
    .filter((item) => typeof item === 'number')
    .map((item: any) => BorrowObjectEnumValue.get(item));
};

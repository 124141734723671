export enum ScreenPermissionEnum {
  DASHBOARD = 400,
  COLLECTION_AND_SUBMISSION,
  VARIABLE_BINNING,
  DOCUMENT_STORAGE,
  DESTROY_DOCUMENT,
  DOCUMENT_MINING_MANAGEMENT,
  REPORT_STATISTICS,
  GENERAL_DIRECTORY,
  PRIVATE_DIRECTORY,
  MANAGEMENT_AGENT,
  SUBMIT_HISTORY,
  NOTICE = 277,
  FILE = 411,
  UNIT_ORGANIZATION,
  SYSTEM_MANAGEMENT,
  ADMIN = 999, // Admin
}
export enum ParentPermissionEnum {
  // Thu thập và nộp lưu
  DOCUMENT_PLAN_MANAGEMENT = 300,
  REVIEW_DOCUMENT_PLAN,
  DOCUMENT_MANAGEMENT,
  REVIEW_AND_SUBMIT_FILE_SUBMISSION,
  RECEIVE_DOCUMENT,
  DOCUMENT_INDEX,
  HANDOVER_DOCUMENT_MANAGEMENT,

  // Biên mục chỉnh lý
  DOCUMENT_INDEXING,
  REVIEW_SUPPLEMENTAL_INDEXING,
  SUPPLEMENTAL_DOCUMENT_INDEXING,
  SUPPLEMENTAL_INDEXING_MANAGEMENT,
  VARIABLE_REPOSITORY_BROWSING,

  // Kho lưu trữ
  BARCODE,
  DOCUMENT_SEARCH,
  DOCUMENT_COMPONENT_SEARCH,
  MOVE_DOCUMENT,
  FREEZING_DOCUMENT,
  TRANSFER_DOCUMENT,

  // Tiêu hủy hồ sơ
  REVIEW_DESTROY_DOCUMENT,
  DESTROY_DECISION,
  REVIEW_DESTROY_DECISION,
  DESTROYED_DECISION,

  // Danh mục chung
  LANGUAGE,
  SECURITY_LEVEL,
  WARRANTY_PERIOD,
  POSITION,

  // Quản trị cơ quan
  FORM,
  MANAGEMENT_PERMISSION,
  MANAGEMENT_GROUP_USER,
  MANAGEMENT_USER,
  MANAGEMENT_COMPANY,
  AUTHORIZATION_BY_FILE_TYPE,
  MANAGEMENT_READERS,

  // Danh mục riêng
  PHONG_LUU_TRU,
  CATALOGUE,
  ARCHIVES,
  SHELF,
  BOX,
  PROFILE_TYPE,

  // Khai thác tài liệu
  LEVEL_DOCUMENT_MINING_MANAGEMENT,
  DOCUMENT_COMPONENT_MINING,
  DOCUMENT_MINING_TICKET,
  REVIEW_DOCUMENT_MINING_TICKET,
  REVIEW_UNIT,

  // Nộp lưu lịch sử
  SUBMIT_HISTORY_MANAGEMENT,
  REVIEW_SUBMIT_HISTORY,
  SUBMITTED_HISTORY,

  // Báo cáo thống kê
  DIGITAL_STORAGE_INVENTORY_STATUS,
  BACKPACK_STATISTICS,
  MINING_STATISTICS,

  // file
  FILE_VIEW = 273,
  FILE_CREATE,
  FILE_EDIT,
  FILE_DELETE,

  // Xử lý phòng ban
  UNIT_ORGANIZATION_VIEW = 281,
  UNIT_ORGANIZATION_CREATE,
  UNIT_ORGANIZATION_EDIT,
  UNIT_ORGANIZATION_DELETE,

  // Xếp hộp cặp
  SORT_BOX,

  // Nhật ký hệ thống
  SYSTEM_LOGS,
}

export enum PermissionEnum {
  // Quản lý kế hoạch thu thập
  DOCUMENT_PLAN_VIEW = 1,
  DOCUMENT_PLAN_CREATE,
  DOCUMENT_PLAN_EDIT,
  DOCUMENT_PLAN_DELETE,
  DOCUMENT_PLAN_EXPORT,
  DOCUMENT_PLAN_DETAIL,
  DOCUMENT_OF_DOC_PLAN_DETAIL,
  DOCUMENT_PLAN_EXPORT_DOCUMENT,
  DOCUMENT_PLAN_SEND,

  // Duyệt kế hoạch thu thập
  REVIEW_DOCUMENT_PLAN_VIEW,
  REVIEW_DOCUMENT_PLAN_EXPORT,
  REVIEW_DOCUMENT_PLAN_DETAIL,
  REVIEW_DOCUMENT_OF_DOC_PLAN_DETAIL,
  REVIEW_DOCUMENT_PLAN_REFUSE,
  REVIEW_DOCUMENT_PLAN_APPROVE,

  // Quản lý thu thập
  DOCUMENT_MANAGEMENT_VIEW,
  DOCUMENT_MANAGEMENT_EXPORT,
  DOCUMENT_MANAGEMENT_CREATE,
  DOCUMENT_MANAGEMENT_DETAIL,
  DOC_COMP_OF_DOC_MGMT_DETAIL,
  DOC_COMP_OF_DOC_MGMT_CREATE,
  DOC_COMP_OF_DOC_MGMT_IMPORT,
  DOC_COMP_OF_DOC_MGMT_OCR,
  DOC_COMP_OF_DOC_MGMT_EXPORT,
  DOC_COMP_OF_DOC_MGMT_DELETE,
  DOC_COMP_OF_DOC_MGMT_EDIT,
  DOCUMENT_MANAGEMENT_EDIT,
  DOCUMENT_MANAGEMENT_DELETE,
  DOCUMENT_MANAGEMENT_SEND,

  //  Duyệt và gửi hồ sơ nộp lưu
  REVIEW_AND_SUBMIT_DOC_VIEW,
  REVIEW_AND_SUBMIT_DOC_DETAIL,
  DOC_COMP_REVIEW_AND_SUBMIT_DOC_DETAIL,
  REVIEW_AND_SUBMIT_DOC_REFUSE,
  REVIEW_AND_SUBMIT_DOC_APPROVE,

  //  Tiếp nhận hồ sơ nộp lưu
  RECEIVE_DOC_VIEW,
  RECEIVE_DOC_EXPORT,
  RECEIVE_DOC_DETAIL,
  DOC_COMP_RECEIVE_DOC_DETAIL,
  RECEIVE_DOC_REFUSE,
  RECEIVE_DOC_APPROVE,

  //  Mục lục hồ sơ đã nộp lưu
  DOC_INDEX_VIEW,
  DOC_INDEX_EXPORT,
  DOC_INDEX_EXPORT_REPORT,
  DOC_COMP_DOC_INDEX_DETAIL,
  DOC_INDEX_DETAIL,

  // Quản lý biên bản bàn giao
  HANDOVER_DOCUMENT_VIEW,
  HANDOVER_DOCUMENT_CREATE,
  HANDOVER_DOCUMENT_EDIT,
  HANDOVER_DOCUMENT_DELETE,
  HANDOVER_DOCUMENT_DOWNLOAD,
  HANDOVER_DOCUMENT_EXPORT,
  HANDOVER_DOCUMENT_SEND,
  HANDOVER_DOCUMENT_DETAIL = 285,

  // Biên mục hồ sơ, tài liệu
  DOC_INDEXING_VIEW = 53,
  DOC_INDEXING_CREATE,
  DOC_INDEXING_DETAIL,
  DOC_COMP_OF_DOC_INDEXING_DETAIL,
  DOC_COMP_OF_DOC_INDEXING_CREATE,
  DOC_COMP_OF_DOC_INDEXING_IMPORT,
  DOC_COMP_OF_DOC_INDEXING_OCR,
  DOC_COMP_OF_DOC_INDEXING_EXPORT,
  DOC_COMP_OF_DOC_INDEXING_DELETE,
  DOC_COMP_OF_DOC_INDEXING_EDIT,
  DOC_INDEXING_EDIT,
  DOC_INDEXING_DELETE,
  DOC_INDEXING_SEND,

  // Duyệt lưu kho
  VARIABLE_REPOSITORY_BROWSING_VIEW,
  VARIABLE_REPOSITORY_BROWSING_REFUSE,
  VARIABLE_REPOSITORY_BROWSING_DETAIL,
  DOC_COMP_VARIABLE_REPOSITORY_BROWSING_DETAIL,
  VARIABLE_REPOSITORY_BROWSING_APPROVE,

  // Quản lý biên mục bổ sung
  SUPPLEMENTAL_INDEXING_MANAGEMENT_VIEW,
  SUPPLEMENTAL_INDEXING_MANAGEMENT_DETAIL,
  SUPPLEMENTAL_INDEXING_MANAGEMENT_CREATE,
  SUPPLEMENTAL_INDEXING_MANAGEMENT_SEND,
  SUPPLEMENTAL_INDEXING_MANAGEMENT_DELETE,

  // Duyệt biên mục bổ sung
  REVIEW_SUPPLEMENTAL_INDEXING_VIEW,
  REVIEW_SUPPLEMENTAL_INDEXING_DETAIL,
  REVIEW_SUPPLEMENTAL_INDEXING_REFUSE,
  REVIEW_SUPPLEMENTAL_INDEXING_APPROVE,

  // Biên mục bổ sung hồ sơ tài liệu
  SUPPLEMENTAL_DOCUMENT_INDEXING_VIEW,
  SUPPLEMENTAL_DOCUMENT_INDEXING_DETAIL,
  SUPPLEMENTAL_DOCUMENT_INDEXING_DETAIL_DOC_COMP,
  SUPPLEMENTAL_DOCUMENT_INDEXING_EDIT,
  SUPPLEMENTAL_DOCUMENT_INDEXING_SEND,

  // In barcode
  BARCODE_VIEW,
  BARCODE_IN,

  // Tra cứu hồ sơ
  DOCUMENT_SEARCH_VIEW,
  DOCUMENT_SEARCH_DETAIL,
  DOCUMENT_SEARCH_EXPORT_DOC,
  DOCUMENT_SEARCH_EXPORT_DOC_COMP,
  DOCUMENT_SEARCH_DETAIL_DOC_COMP,
  DOCUMENT_SEARCH_ZIP,

  // Tra cứu tài liệu
  DOCUMENT_COMPONENT_SEARCH_VIEW,
  DOCUMENT_COMPONENT_SEARCH_DETAIL,

  // Di chuyển hồ sơ
  MOVE_DOCUMENT_VIEW,
  MOVE_DOCUMENT_EXPORT,
  MOVE_DOCUMENT_DETAIL,
  MOVE_DOCUMENT_DETAIL_DOC_COMP,
  MOVE_DOCUMENT_LEAVE_DOC_COMP,
  MOVE_DOCUMENT_UNSORTED,
  MOVE_DOCUMENT_LEAVE,

  // Đóng băng hồ sơ
  FREEZING_DOCUMENT_VIEW = 103,
  FREEZING_DOCUMENT_DETAIL,
  FREEZING_DOCUMENT_DETAIL_DOC_COMP,
  FREEZING_DOCUMENT_UNFREEZING,
  FREEZING_DOCUMENT_FREEZING,

  // Phiếu di chuyển tài liệu
  TRANSFER_DOCUMENT_VIEW,
  TRANSFER_DOCUMENT_DETAIL,

  // Danh sách hồ sơ chờ tiêu hủy
  REVIEW_DESTROY_DOCUMENT_VIEW,
  REVIEW_DESTROY_DOCUMENT_DETAIL,
  REVIEW_DESTROY_DOCUMENT_CREATE_DOC,
  REVIEW_DESTROY_DOCUMENT_CREATE_DECISION,
  REVIEW_DESTROY_DOCUMENT_DELETE,

  // Danh sách quyết định tiêu hủy
  DESTROY_DECISION_VIEW,
  DESTROY_DECISION_DETAIL,
  DESTROY_DECISION_CREATE,
  DESTROY_DECISION_EDIT,
  DESTROY_DECISION_SEND,
  DESTROY_DECISION_DELETE,

  // Duyệt quyết định tiêu hủy
  REVIEW_DESTROY_DECISION_VIEW,
  REVIEW_DESTROY_DECISION_DETAIL,
  REVIEW_DESTROY_DECISION_REFUSE,
  REVIEW_DESTROY_DECISION_APPROVE,

  // Danh sách quyết định đã tiêu hủy
  DESTROYED_DECISION_VIEW,
  DESTROYED_DECISION_DETAIL,
  DESTROYED_DECISION_RESTORE,

  // Ngôn ngữ
  LANGUAGE_VIEW,
  LANGUAGE_CREATE,
  LANGUAGE_EDIT,
  LANGUAGE_DETAIL,
  LANGUAGE_DELETE,

  // Cấp độ bảo mật
  SECURITY_LEVEL_VIEW,
  SECURITY_LEVEL_CREATE,
  SECURITY_LEVEL_EDIT,
  SECURITY_LEVEL_DETAIL,
  SECURITY_LEVEL_DELETE,

  // Thời gian bảo quản
  WARRANTY_PERIOD_VIEW,
  WARRANTY_PERIOD_CREATE,
  WARRANTY_PERIOD_EDIT,
  WARRANTY_PERIOD_DETAIL,
  WARRANTY_PERIOD_DELETE,

  // Chức vụ
  POSITION_VIEW,
  POSITION_DETAIL,
  POSITION_CREATE,
  POSITION_DELETE,
  POSITION_EDIT,

  // Quản lý template
  FORM_VIEW,
  FORM_DETAIL,
  FORM_EDIT,
  FORM_CREATE,
  FORM_DELETE,
  FORM_EXPORT,

  // Quản lý phân quyền
  MANAGEMENT_PERMISSION_VIEW,
  MANAGEMENT_PERMISSION_DETAIL,
  MANAGEMENT_PERMISSION_CREATE,
  MANAGEMENT_PERMISSION_EDIT,
  MANAGEMENT_PERMISSION_DELETE,
  MANAGEMENT_PERMISSION_EXPORT,

  // Quản lý nhóm người dùng
  MANAGEMENT_GROUP_USER_VIEW,
  MANAGEMENT_GROUP_USER_DETAIL,
  MANAGEMENT_GROUP_USER_EDIT,
  MANAGEMENT_GROUP_USER_CREATE,
  MANAGEMENT_GROUP_USER_DELETE,
  MANAGEMENT_GROUP_USER_EXPORT,

  // Quản lý người dùng
  MANAGEMENT_USER_VIEW,
  MANAGEMENT_USER_DETAIL,
  MANAGEMENT_USER_CREATE,
  MANAGEMENT_USER_EDIT,
  MANAGEMENT_USER_DELETE,
  MANAGEMENT_USER_EXPORT,

  // Quản lý cơ quan
  MANAGEMENT_COMPANY_VIEW,
  MANAGEMENT_COMPANY_DETAIL,
  MANAGEMENT_COMPANY_CREATE,
  MANAGEMENT_COMPANY_EDIT,
  MANAGEMENT_COMPANY_DELETE,
  MANAGEMENT_COMPANY_EXPORT,

  // Phân quyền theo loại hồ sơ
  AUTHORIZATION_BY_FILE_TYPE_VIEW,
  AUTHORIZATION_BY_FILE_TYPE_DETAIL,
  AUTHORIZATION_BY_FILE_TYPE_EDIT,
  AUTHORIZATION_BY_FILE_TYPE_DELETE,

  // Quản lý độc giả
  MANAGEMENT_READERS_VIEW,
  MANAGEMENT_READERS_DELETE,
  MANAGEMENT_READERS_DETAIL,
  MANAGEMENT_READERS_CREATE,
  MANAGEMENT_READERS_EDIT,
  MANAGEMENT_READERS_EXPORT,

  // Phông lưu trữ
  PHONG_LUU_TRU_VIEW,
  PHONG_LUU_TRU_DETAIL,
  PHONG_LUU_TRU_CREATE,
  PHONG_LUU_TRU_DELETE,
  PHONG_LUU_TRU_EXPORT,
  PHONG_LUU_TRU_EDIT,

  // Danh sách mục lục
  CATALOGUE_VIEW,
  CATALOGUE_DETAIL,
  CATALOGUE_CREATE,
  CATALOGUE_DELETE,
  CATALOGUE_EXPORT,
  CATALOGUE_CREATE_LIST,
  CATALOGUE_EDIT,

  // Kho
  ARCHIVES_VIEW,
  ARCHIVES_DETAIL,
  ARCHIVES_CREATE,
  ARCHIVES_DELETE,
  ARCHIVES_EXPORT,
  ARCHIVES_EDIT,

  // Giá/kệ
  SHELF_VIEW,
  SHELF_DETAIL,
  SHELF_CREATE,
  SHELF_DELETE,
  SHELF_EXPORT,
  SHELF_EDIT,

  // Hộp/cặp
  BOX_VIEW,
  BOX_DETAIL,
  BOX_CREATE,
  BOX_DELETE,
  BOX_EXPORT,
  BOX_EDIT,

  // Loại hồ sơ
  PROFILE_TYPE_VIEW,
  PROFILE_TYPE_DETAIL,
  PROFILE_TYPE_CREATE,
  PROFILE_TYPE_DELETE,
  PROFILE_TYPE_EXPORT,
  PROFILE_TYPE_EDIT,

  // Quản lý khai thác mức độ
  LEVEL_DOCUMENT_MINING_MGMT_VIEW,
  LEVEL_DOCUMENT_MINING_MGMT_DETAIL,
  LEVEL_DOCUMENT_MINING_MGMT_DETAIL_DOC_COMP,
  LEVEL_DOCUMENT_MINING_MGMT_MINING,

  // Khai thác tài liệu
  DOCUMENT_COMPONENT_MINING_VIEW,
  DOCUMENT_COMPONENT_MINING_DETAIL,
  DOCUMENT_COMPONENT_MINING_DETAIL_DOC_COMP,
  DOCUMENT_COMPONENT_MINING_BORROW_DOC,
  DOCUMENT_COMPONENT_MINING_BORROW_DOC_COMP,

  // Danh sách phiếu khai thác
  DOCUMENT_MINING_TICKET_VIEW,
  DOCUMENT_MINING_TICKET_DETAIL,
  DOCUMENT_MINING_TICKET_DETAIL_DOC,
  DOCUMENT_MINING_TICKET_DETAIL_DOC_COMP,
  DOCUMENT_MINING_TICKET_CANCEL,

  // Phê duyệt phiếu khai thác
  REVIEW_DOCUMENT_MINING_TICKET_VIEW,
  REVIEW_DOCUMENT_MINING_TICKET_DETAIL,
  REVIEW_DOCUMENT_MINING_TICKET_DETAIL_DOC,
  REVIEW_DOCUMENT_MINING_TICKET_DETAIL_DOC_COMP,
  REVIEW_DOCUMENT_MINING_TICKET_REFUSE,
  REVIEW_DOCUMENT_MINING_TICKET_APPROVE,

  // Phê duyệt đơn vị
  REVIEW_UNIT_VIEW,
  REVIEW_UNIT_DETAIL,
  REVIEW_UNIT_DETAIL_DOC,
  REVIEW_UNIT_DETAIL_DOC_COMP,
  REVIEW_UNIT_REFUSE,
  REVIEW_UNIT_APPROVE,

  // Quản lý hồ sơ nộp lưu lịch sử
  SUBMIT_HISTORY_MANAGEMENT_VIEW,
  SUBMIT_HISTORY_MANAGEMENT_DETAIL,
  SUBMIT_HISTORY_MANAGEMENT_DETAIL_DOC_COMP,
  SUBMIT_HISTORY_MANAGEMENT_EXPORT,
  SUBMIT_HISTORY_MANAGEMENT_SEND,

  // Duyệt lưu hồ sơ nộp lưu lịch sử
  REVIEW_SUBMIT_HISTORY_VIEW,
  REVIEW_SUBMIT_HISTORY_DETAIL,
  REVIEW_SUBMIT_HISTORY_DETAIL_DOC_COMP,
  REVIEW_SUBMIT_HISTORY_EXPORT,
  REVIEW_SUBMIT_HISTORY_REFUSE,
  REVIEW_SUBMIT_HISTORY_APPROVE,

  // Danh sách hồ sơ đã nộp lưu lịch sử
  SUBMITTED_HISTORY_VIEW,
  SUBMITTED_HISTORY_DETAIL,
  SUBMITTED_HISTORY_DETAIL_DOC_COMP,
  SUBMITTED_HISTORY_EXPORT,

  // Hiện trạng số hóa kho lưu trữ
  DIGITAL_STORAGE_INVENTORY_STATUS_VIEW,

  // Thống kê hộp cặp
  BACKPACK_STATISTICS_VIEW,
  BACKPACK_STATISTICS_EXPORT,

  // Thống kê khai thác
  MINING_STATISTICS_VIEW,
  MINING_STATISTICS_DETAIL,
  MINING_STATISTICS_EXPORT,
  MINING_STATISTICS_EXPORT_DETAIL,

  // Xếp hộp cặp
  SORT_BOX_VIEW = 288,
  SORT_BOX_SORT,
  SORT_BOX_UNSORT,
  SORT_BOX_VIEW_CREATE,

  // Nhật ký hệ thống
  SYSTEM_LOGS_VIEW,
  SYSTEM_LOGS_DETAIL,
}

export const ScreenPermissionEnumValue = new Map<
  number,
  { label: string; Id: ScreenPermissionEnum; value: ScreenPermissionEnum }
>([
  [
    ScreenPermissionEnum.DASHBOARD,
    {
      Id: ScreenPermissionEnum.DASHBOARD,
      label: 'Tổng quan',
      value: ScreenPermissionEnum.DASHBOARD,
    },
  ],
  [
    ScreenPermissionEnum.COLLECTION_AND_SUBMISSION,
    {
      Id: ScreenPermissionEnum.COLLECTION_AND_SUBMISSION,
      label: 'Thu thập và nộp lưu',
      value: ScreenPermissionEnum.COLLECTION_AND_SUBMISSION,
    },
  ],
  [
    ScreenPermissionEnum.VARIABLE_BINNING,
    {
      Id: ScreenPermissionEnum.VARIABLE_BINNING,
      label: 'Biên mục chỉnh lý',
      value: ScreenPermissionEnum.VARIABLE_BINNING,
    },
  ],
  [
    ScreenPermissionEnum.DOCUMENT_STORAGE,
    {
      Id: ScreenPermissionEnum.DOCUMENT_STORAGE,
      label: 'Kho lưu trữ',
      value: ScreenPermissionEnum.DOCUMENT_STORAGE,
    },
  ],
  [
    ScreenPermissionEnum.DESTROY_DOCUMENT,
    {
      Id: ScreenPermissionEnum.DESTROY_DOCUMENT,
      label: 'Tiêu hủy hồ sơ',
      value: ScreenPermissionEnum.DESTROY_DOCUMENT,
    },
  ],
  [
    ScreenPermissionEnum.DOCUMENT_MINING_MANAGEMENT,
    {
      Id: ScreenPermissionEnum.DOCUMENT_MINING_MANAGEMENT,
      label: 'Quản lý khai thác',
      value: ScreenPermissionEnum.DOCUMENT_MINING_MANAGEMENT,
    },
  ],
  [
    ScreenPermissionEnum.REPORT_STATISTICS,
    {
      Id: ScreenPermissionEnum.REPORT_STATISTICS,
      label: 'Báo cáo thông kê',
      value: ScreenPermissionEnum.REPORT_STATISTICS,
    },
  ],
  [
    ScreenPermissionEnum.PRIVATE_DIRECTORY,
    {
      Id: ScreenPermissionEnum.PRIVATE_DIRECTORY,
      label: 'Danh mục riêng',
      value: ScreenPermissionEnum.PRIVATE_DIRECTORY,
    },
  ],
  [
    ScreenPermissionEnum.GENERAL_DIRECTORY,
    {
      Id: ScreenPermissionEnum.GENERAL_DIRECTORY,
      label: 'Danh mục chung',
      value: ScreenPermissionEnum.GENERAL_DIRECTORY,
    },
  ],
  [
    ScreenPermissionEnum.MANAGEMENT_AGENT,
    {
      Id: ScreenPermissionEnum.MANAGEMENT_AGENT,
      label: 'Quản trị cơ quan',
      value: ScreenPermissionEnum.MANAGEMENT_AGENT,
    },
  ],
  [
    ScreenPermissionEnum.SUBMIT_HISTORY,
    {
      Id: ScreenPermissionEnum.SUBMIT_HISTORY,
      label: 'Nộp lưu lịch sử',
      value: ScreenPermissionEnum.SUBMIT_HISTORY,
    },
  ],
  [
    ScreenPermissionEnum.ADMIN,
    {
      Id: ScreenPermissionEnum.ADMIN,
      label: 'Admin',
      value: ScreenPermissionEnum.ADMIN,
    },
  ],

  [
    ScreenPermissionEnum.NOTICE,
    {
      Id: ScreenPermissionEnum.NOTICE,
      label: 'Thông báo',
      value: ScreenPermissionEnum.NOTICE,
    },
  ],
  [
    ScreenPermissionEnum.FILE,
    {
      Id: ScreenPermissionEnum.FILE,
      label: 'Xử lý file',
      value: ScreenPermissionEnum.FILE,
    },
  ],
  [
    ScreenPermissionEnum.UNIT_ORGANIZATION,
    {
      Id: ScreenPermissionEnum.UNIT_ORGANIZATION,
      label: 'Xử lý phòng ban',
      value: ScreenPermissionEnum.UNIT_ORGANIZATION,
    },
  ],
  [
    ScreenPermissionEnum.SYSTEM_MANAGEMENT,
    {
      Id: ScreenPermissionEnum.SYSTEM_MANAGEMENT,
      label: 'Quản trị hệ thống',
      value: ScreenPermissionEnum.SYSTEM_MANAGEMENT,
    },
  ],
]);

export const ParentPermissionEnumValue = new Map<
  number,
  { label: string; Id: ParentPermissionEnum; value: ParentPermissionEnum; idParent: ScreenPermissionEnum }
>([
  [
    ParentPermissionEnum.DOCUMENT_PLAN_MANAGEMENT,
    {
      Id: ParentPermissionEnum.DOCUMENT_PLAN_MANAGEMENT,
      label: 'Quản lý kế hoạch',
      value: ParentPermissionEnum.DOCUMENT_PLAN_MANAGEMENT,
      idParent: ScreenPermissionEnum.COLLECTION_AND_SUBMISSION,
    },
  ],

  [
    ParentPermissionEnum.REVIEW_DOCUMENT_PLAN,
    {
      Id: ParentPermissionEnum.REVIEW_DOCUMENT_PLAN,
      label: 'Duyệt kế hoạch thu thập',
      value: ParentPermissionEnum.REVIEW_DOCUMENT_PLAN,
      idParent: ScreenPermissionEnum.COLLECTION_AND_SUBMISSION,
    },
  ],
  [
    ParentPermissionEnum.DOCUMENT_MANAGEMENT,
    {
      Id: ParentPermissionEnum.DOCUMENT_MANAGEMENT,
      label: 'Quản lý thu thập',
      value: ParentPermissionEnum.DOCUMENT_MANAGEMENT,
      idParent: ScreenPermissionEnum.COLLECTION_AND_SUBMISSION,
    },
  ],
  [
    ParentPermissionEnum.REVIEW_AND_SUBMIT_FILE_SUBMISSION,
    {
      Id: ParentPermissionEnum.REVIEW_AND_SUBMIT_FILE_SUBMISSION,
      label: 'Duyệt và gửi hồ sơ',
      value: ParentPermissionEnum.REVIEW_AND_SUBMIT_FILE_SUBMISSION,
      idParent: ScreenPermissionEnum.COLLECTION_AND_SUBMISSION,
    },
  ],
  [
    ParentPermissionEnum.RECEIVE_DOCUMENT,
    {
      Id: ParentPermissionEnum.RECEIVE_DOCUMENT,
      label: 'Tiếp nhận hồ sơ',
      value: ParentPermissionEnum.RECEIVE_DOCUMENT,
      idParent: ScreenPermissionEnum.COLLECTION_AND_SUBMISSION,
    },
  ],
  [
    ParentPermissionEnum.DOCUMENT_INDEX,
    {
      Id: ParentPermissionEnum.DOCUMENT_INDEX,
      label: 'Mục lục hồ sơ',
      value: ParentPermissionEnum.DOCUMENT_INDEX,
      idParent: ScreenPermissionEnum.COLLECTION_AND_SUBMISSION,
    },
  ],
  [
    ParentPermissionEnum.HANDOVER_DOCUMENT_MANAGEMENT,
    {
      Id: ParentPermissionEnum.HANDOVER_DOCUMENT_MANAGEMENT,
      label: 'Biên bản bàn giao',
      value: ParentPermissionEnum.HANDOVER_DOCUMENT_MANAGEMENT,
      idParent: ScreenPermissionEnum.COLLECTION_AND_SUBMISSION,
    },
  ],
  [
    ParentPermissionEnum.DOCUMENT_INDEXING,
    {
      Id: ParentPermissionEnum.DOCUMENT_INDEXING,
      label: 'Biên mục hồ sơ, tài liệu',
      value: ParentPermissionEnum.DOCUMENT_INDEXING,
      idParent: ScreenPermissionEnum.VARIABLE_BINNING,
    },
  ],
  [
    ParentPermissionEnum.REVIEW_SUPPLEMENTAL_INDEXING,
    {
      Id: ParentPermissionEnum.REVIEW_SUPPLEMENTAL_INDEXING,
      label: 'Duyệt biên mục bổ sung',
      value: ParentPermissionEnum.REVIEW_SUPPLEMENTAL_INDEXING,
      idParent: ScreenPermissionEnum.VARIABLE_BINNING,
    },
  ],
  [
    ParentPermissionEnum.SUPPLEMENTAL_DOCUMENT_INDEXING,
    {
      Id: ParentPermissionEnum.SUPPLEMENTAL_DOCUMENT_INDEXING,
      label: 'Biên mục bổ sung hồ sơ tài liệu',
      value: ParentPermissionEnum.SUPPLEMENTAL_DOCUMENT_INDEXING,
      idParent: ScreenPermissionEnum.VARIABLE_BINNING,
    },
  ],
  [
    ParentPermissionEnum.SUPPLEMENTAL_INDEXING_MANAGEMENT,
    {
      Id: ParentPermissionEnum.SUPPLEMENTAL_INDEXING_MANAGEMENT,
      label: 'Quản lý biên mục bổ sung',
      value: ParentPermissionEnum.SUPPLEMENTAL_INDEXING_MANAGEMENT,
      idParent: ScreenPermissionEnum.VARIABLE_BINNING,
    },
  ],
  [
    ParentPermissionEnum.VARIABLE_REPOSITORY_BROWSING,
    {
      Id: ParentPermissionEnum.VARIABLE_REPOSITORY_BROWSING,
      label: 'Duyệt lưu kho',
      value: ParentPermissionEnum.VARIABLE_REPOSITORY_BROWSING,
      idParent: ScreenPermissionEnum.VARIABLE_BINNING,
    },
  ],
  [
    ParentPermissionEnum.BARCODE,
    {
      Id: ParentPermissionEnum.BARCODE,
      label: 'In mã vạch',
      value: ParentPermissionEnum.BARCODE,
      idParent: ScreenPermissionEnum.DOCUMENT_STORAGE,
    },
  ],
  [
    ParentPermissionEnum.DOCUMENT_SEARCH,
    {
      Id: ParentPermissionEnum.DOCUMENT_SEARCH,
      label: 'Tra cứu hồ sơ',
      value: ParentPermissionEnum.DOCUMENT_SEARCH,
      idParent: ScreenPermissionEnum.DOCUMENT_STORAGE,
    },
  ],
  [
    ParentPermissionEnum.DOCUMENT_COMPONENT_SEARCH,
    {
      Id: ParentPermissionEnum.DOCUMENT_COMPONENT_SEARCH,
      label: 'Tra cứu tài liệu',
      value: ParentPermissionEnum.DOCUMENT_COMPONENT_SEARCH,
      idParent: ScreenPermissionEnum.DOCUMENT_STORAGE,
    },
  ],
  [
    ParentPermissionEnum.MOVE_DOCUMENT,
    {
      Id: ParentPermissionEnum.MOVE_DOCUMENT,
      label: 'Di chuyển hồ sơ, tài liệu',
      value: ParentPermissionEnum.MOVE_DOCUMENT,
      idParent: ScreenPermissionEnum.DOCUMENT_STORAGE,
    },
  ],
  [
    ParentPermissionEnum.FREEZING_DOCUMENT,
    {
      Id: ParentPermissionEnum.FREEZING_DOCUMENT,
      label: 'Đóng băng hồ sơ',
      value: ParentPermissionEnum.FREEZING_DOCUMENT,
      idParent: ScreenPermissionEnum.DOCUMENT_STORAGE,
    },
  ],
  [
    ParentPermissionEnum.TRANSFER_DOCUMENT,
    {
      Id: ParentPermissionEnum.TRANSFER_DOCUMENT,
      label: 'Phiếu di chuyển hồ sơ',
      value: ParentPermissionEnum.TRANSFER_DOCUMENT,
      idParent: ScreenPermissionEnum.DOCUMENT_STORAGE,
    },
  ],
  [
    ParentPermissionEnum.REVIEW_DESTROY_DOCUMENT,
    {
      Id: ParentPermissionEnum.REVIEW_DESTROY_DOCUMENT,
      label: 'Danh sách hồ sơ chờ tiêu hủy',
      value: ParentPermissionEnum.REVIEW_DESTROY_DOCUMENT,
      idParent: ScreenPermissionEnum.DESTROY_DOCUMENT,
    },
  ],
  [
    ParentPermissionEnum.DESTROY_DECISION,
    {
      Id: ParentPermissionEnum.DESTROY_DECISION,
      label: 'Danh sách quyết định tiêu hủy',
      value: ParentPermissionEnum.DESTROY_DECISION,
      idParent: ScreenPermissionEnum.DESTROY_DOCUMENT,
    },
  ],
  [
    ParentPermissionEnum.REVIEW_DESTROY_DECISION,
    {
      Id: ParentPermissionEnum.REVIEW_DESTROY_DECISION,
      label: 'Duyệt quyết định tiêu hủy',
      value: ParentPermissionEnum.REVIEW_DESTROY_DECISION,
      idParent: ScreenPermissionEnum.DESTROY_DOCUMENT,
    },
  ],
  [
    ParentPermissionEnum.DESTROYED_DECISION,
    {
      Id: ParentPermissionEnum.DESTROYED_DECISION,
      label: 'Quyết định tiêu hủy đã duyệt',
      value: ParentPermissionEnum.DESTROYED_DECISION,
      idParent: ScreenPermissionEnum.DESTROY_DOCUMENT,
    },
  ],
  [
    ParentPermissionEnum.LANGUAGE,
    {
      Id: ParentPermissionEnum.LANGUAGE,
      label: 'Ngôn ngữ',
      value: ParentPermissionEnum.LANGUAGE,
      idParent: ScreenPermissionEnum.GENERAL_DIRECTORY,
    },
  ],
  [
    ParentPermissionEnum.SECURITY_LEVEL,
    {
      Id: ParentPermissionEnum.SECURITY_LEVEL,
      label: 'Cấp độ bảo mật',
      value: ParentPermissionEnum.SECURITY_LEVEL,
      idParent: ScreenPermissionEnum.GENERAL_DIRECTORY,
    },
  ],
  [
    ParentPermissionEnum.WARRANTY_PERIOD,
    {
      Id: ParentPermissionEnum.WARRANTY_PERIOD,
      label: 'Thời hạn bảo quản',
      value: ParentPermissionEnum.WARRANTY_PERIOD,
      idParent: ScreenPermissionEnum.GENERAL_DIRECTORY,
    },
  ],
  [
    ParentPermissionEnum.POSITION,
    {
      Id: ParentPermissionEnum.POSITION,
      label: 'Chức vụ',
      value: ParentPermissionEnum.POSITION,
      idParent: ScreenPermissionEnum.GENERAL_DIRECTORY,
    },
  ],
  [
    ParentPermissionEnum.FORM,
    {
      Id: ParentPermissionEnum.FORM,
      label: 'Quản lý Template',
      value: ParentPermissionEnum.FORM,
      idParent: ScreenPermissionEnum.MANAGEMENT_AGENT,
    },
  ],
  [
    ParentPermissionEnum.MANAGEMENT_PERMISSION,
    {
      Id: ParentPermissionEnum.MANAGEMENT_PERMISSION,
      label: 'Quản lý nhóm quyền',
      value: ParentPermissionEnum.MANAGEMENT_PERMISSION,
      idParent: ScreenPermissionEnum.MANAGEMENT_AGENT,
    },
  ],
  [
    ParentPermissionEnum.MANAGEMENT_GROUP_USER,
    {
      Id: ParentPermissionEnum.MANAGEMENT_GROUP_USER,
      label: 'Quản lý nhóm người dùng',
      value: ParentPermissionEnum.MANAGEMENT_GROUP_USER,
      idParent: ScreenPermissionEnum.MANAGEMENT_AGENT,
    },
  ],
  [
    ParentPermissionEnum.MANAGEMENT_USER,
    {
      Id: ParentPermissionEnum.MANAGEMENT_USER,
      label: 'Quản lý người dùng',
      value: ParentPermissionEnum.MANAGEMENT_USER,
      idParent: ScreenPermissionEnum.MANAGEMENT_AGENT,
    },
  ],
  [
    ParentPermissionEnum.MANAGEMENT_COMPANY,
    {
      Id: ParentPermissionEnum.MANAGEMENT_COMPANY,
      label: 'Quản lý cơ quan',
      value: ParentPermissionEnum.MANAGEMENT_COMPANY,
      idParent: ScreenPermissionEnum.MANAGEMENT_AGENT,
    },
  ],
  [
    ParentPermissionEnum.AUTHORIZATION_BY_FILE_TYPE,
    {
      Id: ParentPermissionEnum.AUTHORIZATION_BY_FILE_TYPE,
      label: 'Phân quyền theo loại hồ sơ',
      value: ParentPermissionEnum.AUTHORIZATION_BY_FILE_TYPE,
      idParent: ScreenPermissionEnum.MANAGEMENT_AGENT,
    },
  ],
  [
    ParentPermissionEnum.MANAGEMENT_READERS,
    {
      Id: ParentPermissionEnum.MANAGEMENT_READERS,
      label: 'Quản lý độc giả',
      value: ParentPermissionEnum.MANAGEMENT_READERS,
      idParent: ScreenPermissionEnum.MANAGEMENT_AGENT,
    },
  ],
  [
    ParentPermissionEnum.PHONG_LUU_TRU,
    {
      Id: ParentPermissionEnum.PHONG_LUU_TRU,
      label: 'Phông lưu trữ',
      value: ParentPermissionEnum.PHONG_LUU_TRU,
      idParent: ScreenPermissionEnum.PRIVATE_DIRECTORY,
    },
  ],
  [
    ParentPermissionEnum.CATALOGUE,
    {
      Id: ParentPermissionEnum.CATALOGUE,
      label: 'Quản lý mục lục',
      value: ParentPermissionEnum.CATALOGUE,
      idParent: ScreenPermissionEnum.PRIVATE_DIRECTORY,
    },
  ],
  [
    ParentPermissionEnum.ARCHIVES,
    {
      Id: ParentPermissionEnum.ARCHIVES,
      label: 'Quản lý kho',
      value: ParentPermissionEnum.ARCHIVES,
      idParent: ScreenPermissionEnum.PRIVATE_DIRECTORY,
    },
  ],
  [
    ParentPermissionEnum.SHELF,
    {
      Id: ParentPermissionEnum.SHELF,
      label: 'Quản lý giá/kệ',
      value: ParentPermissionEnum.SHELF,
      idParent: ScreenPermissionEnum.PRIVATE_DIRECTORY,
    },
  ],
  [
    ParentPermissionEnum.BOX,
    {
      Id: ParentPermissionEnum.BOX,
      label: 'Quản lý hộp/cặp',
      value: ParentPermissionEnum.BOX,
      idParent: ScreenPermissionEnum.PRIVATE_DIRECTORY,
    },
  ],
  [
    ParentPermissionEnum.PROFILE_TYPE,
    {
      Id: ParentPermissionEnum.PROFILE_TYPE,
      label: 'Loại hồ sơ',
      value: ParentPermissionEnum.PROFILE_TYPE,
      idParent: ScreenPermissionEnum.PRIVATE_DIRECTORY,
    },
  ],
  [
    ParentPermissionEnum.LEVEL_DOCUMENT_MINING_MANAGEMENT,
    {
      Id: ParentPermissionEnum.LEVEL_DOCUMENT_MINING_MANAGEMENT,
      label: 'Quản lý mức độ khai thác',
      value: ParentPermissionEnum.LEVEL_DOCUMENT_MINING_MANAGEMENT,
      idParent: ScreenPermissionEnum.DOCUMENT_MINING_MANAGEMENT,
    },
  ],
  [
    ParentPermissionEnum.DOCUMENT_COMPONENT_MINING,
    {
      Id: ParentPermissionEnum.DOCUMENT_COMPONENT_MINING,
      label: 'Khai thác tài liệu',
      value: ParentPermissionEnum.DOCUMENT_COMPONENT_MINING,
      idParent: ScreenPermissionEnum.DOCUMENT_MINING_MANAGEMENT,
    },
  ],
  [
    ParentPermissionEnum.DOCUMENT_MINING_TICKET,
    {
      Id: ParentPermissionEnum.DOCUMENT_MINING_TICKET,
      label: 'Danh sách phiếu khai thác',
      value: ParentPermissionEnum.DOCUMENT_MINING_TICKET,
      idParent: ScreenPermissionEnum.DOCUMENT_MINING_MANAGEMENT,
    },
  ],
  [
    ParentPermissionEnum.REVIEW_UNIT,
    {
      Id: ParentPermissionEnum.REVIEW_UNIT,
      label: 'Phê duyệt đơn vị',
      value: ParentPermissionEnum.REVIEW_UNIT,
      idParent: ScreenPermissionEnum.DOCUMENT_MINING_MANAGEMENT,
    },
  ],
  [
    ParentPermissionEnum.REVIEW_DOCUMENT_MINING_TICKET,
    {
      Id: ParentPermissionEnum.REVIEW_DOCUMENT_MINING_TICKET,
      label: 'Phê duyệt phiếu khai thác',
      value: ParentPermissionEnum.REVIEW_DOCUMENT_MINING_TICKET,
      idParent: ScreenPermissionEnum.DOCUMENT_MINING_MANAGEMENT,
    },
  ],
  [
    ParentPermissionEnum.SUBMIT_HISTORY_MANAGEMENT,
    {
      Id: ParentPermissionEnum.SUBMIT_HISTORY_MANAGEMENT,
      label: 'Quản lý hồ sơ nộp lưu lịch sử',
      value: ParentPermissionEnum.SUBMIT_HISTORY_MANAGEMENT,
      idParent: ScreenPermissionEnum.SUBMIT_HISTORY,
    },
  ],
  [
    ParentPermissionEnum.REVIEW_SUBMIT_HISTORY,
    {
      Id: ParentPermissionEnum.REVIEW_SUBMIT_HISTORY,
      label: 'Duyệt hồ sơ nộp lưu lịch sử',
      value: ParentPermissionEnum.REVIEW_SUBMIT_HISTORY,
      idParent: ScreenPermissionEnum.SUBMIT_HISTORY,
    },
  ],
  [
    ParentPermissionEnum.SUBMITTED_HISTORY,
    {
      Id: ParentPermissionEnum.SUBMITTED_HISTORY,
      label: 'Danh sách hồ sơ đã nộp lưu lịch sử',
      value: ParentPermissionEnum.SUBMITTED_HISTORY,
      idParent: ScreenPermissionEnum.SUBMIT_HISTORY,
    },
  ],
  [
    ParentPermissionEnum.DIGITAL_STORAGE_INVENTORY_STATUS,
    {
      Id: ParentPermissionEnum.DIGITAL_STORAGE_INVENTORY_STATUS,
      label: 'Hiện trạng số hóa kho lưu trữ',
      value: ParentPermissionEnum.DIGITAL_STORAGE_INVENTORY_STATUS,
      idParent: ScreenPermissionEnum.REPORT_STATISTICS,
    },
  ],
  [
    ParentPermissionEnum.BACKPACK_STATISTICS,
    {
      Id: ParentPermissionEnum.BACKPACK_STATISTICS,
      label: 'Thông kê hộp cặp',
      value: ParentPermissionEnum.BACKPACK_STATISTICS,
      idParent: ScreenPermissionEnum.REPORT_STATISTICS,
    },
  ],
  [
    ParentPermissionEnum.MINING_STATISTICS,
    {
      Id: ParentPermissionEnum.MINING_STATISTICS,
      label: 'Thông kê khai thác',
      value: ParentPermissionEnum.MINING_STATISTICS,
      idParent: ScreenPermissionEnum.REPORT_STATISTICS,
    },
  ],

  [
    ParentPermissionEnum.UNIT_ORGANIZATION_VIEW,
    {
      Id: ParentPermissionEnum.UNIT_ORGANIZATION_VIEW,
      label: 'Xem danh sách phòng ban',
      value: ParentPermissionEnum.UNIT_ORGANIZATION_VIEW,
      idParent: ScreenPermissionEnum.UNIT_ORGANIZATION,
    },
  ],
  [
    ParentPermissionEnum.UNIT_ORGANIZATION_CREATE,
    {
      Id: ParentPermissionEnum.UNIT_ORGANIZATION_CREATE,
      label: 'Tạo phòng ban',
      value: ParentPermissionEnum.UNIT_ORGANIZATION_CREATE,
      idParent: ScreenPermissionEnum.UNIT_ORGANIZATION,
    },
  ],
  [
    ParentPermissionEnum.UNIT_ORGANIZATION_EDIT,
    {
      Id: ParentPermissionEnum.UNIT_ORGANIZATION_EDIT,
      label: 'Sửa phòng ban',
      value: ParentPermissionEnum.UNIT_ORGANIZATION_EDIT,
      idParent: ScreenPermissionEnum.UNIT_ORGANIZATION,
    },
  ],
  [
    ParentPermissionEnum.UNIT_ORGANIZATION_DELETE,
    {
      Id: ParentPermissionEnum.UNIT_ORGANIZATION_DELETE,
      label: 'Xóa phòng ban',
      value: ParentPermissionEnum.UNIT_ORGANIZATION_DELETE,
      idParent: ScreenPermissionEnum.UNIT_ORGANIZATION,
    },
  ],
  [
    ParentPermissionEnum.FILE_VIEW,
    {
      Id: ParentPermissionEnum.FILE_VIEW,
      label: 'Xem file',
      value: ParentPermissionEnum.FILE_VIEW,
      idParent: ScreenPermissionEnum.FILE,
    },
  ],
  [
    ParentPermissionEnum.FILE_CREATE,
    {
      Id: ParentPermissionEnum.FILE_CREATE,
      label: 'Thêm file',
      value: ParentPermissionEnum.FILE_CREATE,
      idParent: ScreenPermissionEnum.FILE,
    },
  ],
  [
    ParentPermissionEnum.FILE_EDIT,
    {
      Id: ParentPermissionEnum.FILE_EDIT,
      label: 'Sửa file',
      value: ParentPermissionEnum.FILE_EDIT,
      idParent: ScreenPermissionEnum.FILE,
    },
  ],
  [
    ParentPermissionEnum.FILE_DELETE,
    {
      Id: ParentPermissionEnum.FILE_DELETE,
      label: 'Xóa file',
      value: ParentPermissionEnum.FILE_DELETE,
      idParent: ScreenPermissionEnum.FILE,
    },
  ],
  [
    ParentPermissionEnum.SORT_BOX,
    {
      Id: ParentPermissionEnum.SORT_BOX,
      label: 'Xếp hộp cặp',
      value: ParentPermissionEnum.SORT_BOX,
      idParent: ScreenPermissionEnum.DOCUMENT_STORAGE,
    },
  ],
  [
    ParentPermissionEnum.SYSTEM_LOGS,
    {
      Id: ParentPermissionEnum.SYSTEM_LOGS,
      label: 'Nhật ký hệ thống',
      value: ParentPermissionEnum.SYSTEM_LOGS,
      idParent: ScreenPermissionEnum.SYSTEM_MANAGEMENT,
    },
  ],
]);

export const PermissionEnumValue = new Map<
  number,
  { label: string; Id: PermissionEnum; value: PermissionEnum; idParent: ParentPermissionEnum }
>([
  [
    PermissionEnum.DOCUMENT_PLAN_VIEW,
    {
      Id: PermissionEnum.DOCUMENT_PLAN_VIEW,
      label: 'Xem danh sách kế hoạch',
      value: PermissionEnum.DOCUMENT_PLAN_VIEW,
      idParent: ParentPermissionEnum.DOCUMENT_PLAN_MANAGEMENT,
    },
  ],
  [
    PermissionEnum.DOCUMENT_PLAN_CREATE,
    {
      Id: PermissionEnum.DOCUMENT_PLAN_CREATE,
      label: 'Tạo kế hoạch',
      value: PermissionEnum.DOCUMENT_PLAN_CREATE,
      idParent: ParentPermissionEnum.DOCUMENT_PLAN_MANAGEMENT,
    },
  ],
  [
    PermissionEnum.DOCUMENT_PLAN_EDIT,
    {
      Id: PermissionEnum.DOCUMENT_PLAN_EDIT,
      label: 'Chỉnh sửa kế hoạch',
      value: PermissionEnum.DOCUMENT_PLAN_EDIT,
      idParent: ParentPermissionEnum.DOCUMENT_PLAN_MANAGEMENT,
    },
  ],
  [
    PermissionEnum.DOCUMENT_PLAN_DELETE,
    {
      Id: PermissionEnum.DOCUMENT_PLAN_DELETE,
      label: 'Xóa kế hoạch',
      value: PermissionEnum.DOCUMENT_PLAN_DELETE,
      idParent: ParentPermissionEnum.DOCUMENT_PLAN_MANAGEMENT,
    },
  ],
  [
    PermissionEnum.DOCUMENT_PLAN_EXPORT,
    {
      Id: PermissionEnum.DOCUMENT_PLAN_EXPORT,
      label: 'Xuất file kế hoạch',
      value: PermissionEnum.DOCUMENT_PLAN_EXPORT,
      idParent: ParentPermissionEnum.DOCUMENT_PLAN_MANAGEMENT,
    },
  ],
  [
    PermissionEnum.DOCUMENT_PLAN_DETAIL,
    {
      Id: PermissionEnum.DOCUMENT_PLAN_DETAIL,
      label: 'Xem chi tiết kế hoạch',
      value: PermissionEnum.DOCUMENT_PLAN_DETAIL,
      idParent: ParentPermissionEnum.DOCUMENT_PLAN_MANAGEMENT,
    },
  ],
  [
    PermissionEnum.DOCUMENT_PLAN_EXPORT_DOCUMENT,
    {
      Id: PermissionEnum.DOCUMENT_PLAN_EXPORT_DOCUMENT,
      label: 'Xuất file hồ sơ của kế hoạch',
      value: PermissionEnum.DOCUMENT_PLAN_EXPORT_DOCUMENT,
      idParent: ParentPermissionEnum.DOCUMENT_PLAN_MANAGEMENT,
    },
  ],
  [
    PermissionEnum.DOCUMENT_PLAN_SEND,
    {
      Id: PermissionEnum.DOCUMENT_PLAN_SEND,
      label: 'Gửi kế hoạch',
      value: PermissionEnum.DOCUMENT_PLAN_SEND,
      idParent: ParentPermissionEnum.DOCUMENT_PLAN_MANAGEMENT,
    },
  ],
  [
    PermissionEnum.DOCUMENT_OF_DOC_PLAN_DETAIL,
    {
      Id: PermissionEnum.DOCUMENT_OF_DOC_PLAN_DETAIL,
      label: 'Xem chi tiết hồ sơ',
      value: PermissionEnum.DOCUMENT_OF_DOC_PLAN_DETAIL,
      idParent: ParentPermissionEnum.DOCUMENT_PLAN_MANAGEMENT,
    },
  ],
  [
    PermissionEnum.REVIEW_DOCUMENT_PLAN_VIEW,
    {
      Id: PermissionEnum.REVIEW_DOCUMENT_PLAN_VIEW,
      label: 'Xem danh sách kế hoạch',
      value: PermissionEnum.REVIEW_DOCUMENT_PLAN_VIEW,
      idParent: ParentPermissionEnum.REVIEW_DOCUMENT_PLAN,
    },
  ],
  [
    PermissionEnum.REVIEW_DOCUMENT_PLAN_EXPORT,
    {
      Id: PermissionEnum.REVIEW_DOCUMENT_PLAN_EXPORT,
      label: 'Xuất file danh sách kế hoạch',
      value: PermissionEnum.REVIEW_DOCUMENT_PLAN_EXPORT,
      idParent: ParentPermissionEnum.REVIEW_DOCUMENT_PLAN,
    },
  ],
  [
    PermissionEnum.REVIEW_DOCUMENT_PLAN_DETAIL,
    {
      Id: PermissionEnum.REVIEW_DOCUMENT_PLAN_DETAIL,
      label: 'Xem chi tiết kế hoạch',
      value: PermissionEnum.REVIEW_DOCUMENT_PLAN_DETAIL,
      idParent: ParentPermissionEnum.REVIEW_DOCUMENT_PLAN,
    },
  ],
  [
    PermissionEnum.REVIEW_DOCUMENT_OF_DOC_PLAN_DETAIL,
    {
      Id: PermissionEnum.REVIEW_DOCUMENT_OF_DOC_PLAN_DETAIL,
      label: 'Xem chi tiết hồ sơ',
      value: PermissionEnum.REVIEW_DOCUMENT_OF_DOC_PLAN_DETAIL,
      idParent: ParentPermissionEnum.REVIEW_DOCUMENT_PLAN,
    },
  ],
  [
    PermissionEnum.REVIEW_DOCUMENT_PLAN_REFUSE,
    {
      Id: PermissionEnum.REVIEW_DOCUMENT_PLAN_REFUSE,
      label: 'Duyệt kệ hoạch',
      value: PermissionEnum.REVIEW_DOCUMENT_PLAN_REFUSE,
      idParent: ParentPermissionEnum.REVIEW_DOCUMENT_PLAN,
    },
  ],
  [
    PermissionEnum.REVIEW_DOCUMENT_PLAN_APPROVE,
    {
      Id: PermissionEnum.REVIEW_DOCUMENT_PLAN_APPROVE,
      label: 'Từ chối duyệt',
      value: PermissionEnum.REVIEW_DOCUMENT_PLAN_APPROVE,
      idParent: ParentPermissionEnum.REVIEW_DOCUMENT_PLAN,
    },
  ],
  [
    PermissionEnum.DOCUMENT_MANAGEMENT_VIEW,
    {
      Id: PermissionEnum.DOCUMENT_MANAGEMENT_VIEW,
      label: 'Xem danh sách hồ sơ',
      value: PermissionEnum.DOCUMENT_MANAGEMENT_VIEW,
      idParent: ParentPermissionEnum.DOCUMENT_MANAGEMENT,
    },
  ],
  [
    PermissionEnum.DOCUMENT_MANAGEMENT_EXPORT,
    {
      Id: PermissionEnum.DOCUMENT_MANAGEMENT_EXPORT,
      label: 'Xuất file danh sách hồ sơ',
      value: PermissionEnum.DOCUMENT_MANAGEMENT_EXPORT,
      idParent: ParentPermissionEnum.DOCUMENT_MANAGEMENT,
    },
  ],
  [
    PermissionEnum.DOCUMENT_MANAGEMENT_CREATE,
    {
      Id: PermissionEnum.DOCUMENT_MANAGEMENT_CREATE,
      label: 'Thêm mới hồ sơ',
      value: PermissionEnum.DOCUMENT_MANAGEMENT_CREATE,
      idParent: ParentPermissionEnum.DOCUMENT_MANAGEMENT,
    },
  ],
  [
    PermissionEnum.DOCUMENT_MANAGEMENT_DETAIL,
    {
      Id: PermissionEnum.DOCUMENT_MANAGEMENT_DETAIL,
      label: 'Xem chi tiết hồ sơ',
      value: PermissionEnum.DOCUMENT_MANAGEMENT_DETAIL,
      idParent: ParentPermissionEnum.DOCUMENT_MANAGEMENT,
    },
  ],
  [
    PermissionEnum.DOC_COMP_OF_DOC_MGMT_DETAIL,
    {
      Id: PermissionEnum.DOC_COMP_OF_DOC_MGMT_DETAIL,
      label: 'Xem chi tiết tài liệu',
      value: PermissionEnum.DOC_COMP_OF_DOC_MGMT_DETAIL,
      idParent: ParentPermissionEnum.DOCUMENT_MANAGEMENT,
    },
  ],
  [
    PermissionEnum.DOC_COMP_OF_DOC_MGMT_CREATE,
    {
      Id: PermissionEnum.DOC_COMP_OF_DOC_MGMT_CREATE,
      label: 'Tạo tài liệu',
      value: PermissionEnum.DOC_COMP_OF_DOC_MGMT_CREATE,
      idParent: ParentPermissionEnum.DOCUMENT_MANAGEMENT,
    },
  ],
  [
    PermissionEnum.DOC_COMP_OF_DOC_MGMT_IMPORT,
    {
      Id: PermissionEnum.DOC_COMP_OF_DOC_MGMT_IMPORT,
      label: 'Tải tài liệu lên',
      value: PermissionEnum.DOC_COMP_OF_DOC_MGMT_IMPORT,
      idParent: ParentPermissionEnum.DOCUMENT_MANAGEMENT,
    },
  ],
  [
    PermissionEnum.DOC_COMP_OF_DOC_MGMT_OCR,
    {
      Id: PermissionEnum.DOC_COMP_OF_DOC_MGMT_OCR,
      label: 'Auto OCR',
      value: PermissionEnum.DOC_COMP_OF_DOC_MGMT_OCR,
      idParent: ParentPermissionEnum.DOCUMENT_MANAGEMENT,
    },
  ],
  [
    PermissionEnum.DOC_COMP_OF_DOC_MGMT_EXPORT,
    {
      Id: PermissionEnum.DOC_COMP_OF_DOC_MGMT_EXPORT,
      label: 'Xuất file tài liệu',
      value: PermissionEnum.DOC_COMP_OF_DOC_MGMT_EXPORT,
      idParent: ParentPermissionEnum.DOCUMENT_MANAGEMENT,
    },
  ],
  [
    PermissionEnum.DOC_COMP_OF_DOC_MGMT_DELETE,
    {
      Id: PermissionEnum.DOC_COMP_OF_DOC_MGMT_DELETE,
      label: 'Xóa tài liệu',
      value: PermissionEnum.DOC_COMP_OF_DOC_MGMT_DELETE,
      idParent: ParentPermissionEnum.DOCUMENT_MANAGEMENT,
    },
  ],
  [
    PermissionEnum.DOC_COMP_OF_DOC_MGMT_EDIT,
    {
      Id: PermissionEnum.DOC_COMP_OF_DOC_MGMT_EDIT,
      label: 'Chỉnh sửa tài liệu',
      value: PermissionEnum.DOC_COMP_OF_DOC_MGMT_EDIT,
      idParent: ParentPermissionEnum.DOCUMENT_MANAGEMENT,
    },
  ],
  [
    PermissionEnum.DOCUMENT_MANAGEMENT_EDIT,
    {
      Id: PermissionEnum.DOCUMENT_MANAGEMENT_EDIT,
      label: 'Chỉnh sửa hồ sơ',
      value: PermissionEnum.DOCUMENT_MANAGEMENT_EDIT,
      idParent: ParentPermissionEnum.DOCUMENT_MANAGEMENT,
    },
  ],
  [
    PermissionEnum.DOCUMENT_MANAGEMENT_DELETE,
    {
      Id: PermissionEnum.DOCUMENT_MANAGEMENT_DELETE,
      label: 'Xóa hồ sơ',
      value: PermissionEnum.DOCUMENT_MANAGEMENT_DELETE,
      idParent: ParentPermissionEnum.DOCUMENT_MANAGEMENT,
    },
  ],
  [
    PermissionEnum.DOCUMENT_MANAGEMENT_SEND,
    {
      Id: PermissionEnum.DOCUMENT_MANAGEMENT_SEND,
      label: 'Gửi duyệt hồ sơ',
      value: PermissionEnum.DOCUMENT_MANAGEMENT_SEND,
      idParent: ParentPermissionEnum.DOCUMENT_MANAGEMENT,
    },
  ],
  [
    PermissionEnum.REVIEW_AND_SUBMIT_DOC_VIEW,
    {
      Id: PermissionEnum.REVIEW_AND_SUBMIT_DOC_VIEW,
      label: 'Xem danh sách hồ sơ',
      value: PermissionEnum.REVIEW_AND_SUBMIT_DOC_VIEW,
      idParent: ParentPermissionEnum.REVIEW_AND_SUBMIT_FILE_SUBMISSION,
    },
  ],
  [
    PermissionEnum.REVIEW_AND_SUBMIT_DOC_DETAIL,
    {
      Id: PermissionEnum.REVIEW_AND_SUBMIT_DOC_DETAIL,
      label: 'Xem chi tiết hồ sơ',
      value: PermissionEnum.REVIEW_AND_SUBMIT_DOC_DETAIL,
      idParent: ParentPermissionEnum.REVIEW_AND_SUBMIT_FILE_SUBMISSION,
    },
  ],
  [
    PermissionEnum.DOC_COMP_REVIEW_AND_SUBMIT_DOC_DETAIL,
    {
      Id: PermissionEnum.DOC_COMP_REVIEW_AND_SUBMIT_DOC_DETAIL,
      label: 'Xem chi tiết tài liệu',
      value: PermissionEnum.DOC_COMP_REVIEW_AND_SUBMIT_DOC_DETAIL,
      idParent: ParentPermissionEnum.REVIEW_AND_SUBMIT_FILE_SUBMISSION,
    },
  ],
  [
    PermissionEnum.REVIEW_AND_SUBMIT_DOC_REFUSE,
    {
      Id: PermissionEnum.REVIEW_AND_SUBMIT_DOC_REFUSE,
      label: 'Từ chối hồ sơ',
      value: PermissionEnum.REVIEW_AND_SUBMIT_DOC_REFUSE,
      idParent: ParentPermissionEnum.REVIEW_AND_SUBMIT_FILE_SUBMISSION,
    },
  ],
  [
    PermissionEnum.REVIEW_AND_SUBMIT_DOC_APPROVE,
    {
      Id: PermissionEnum.REVIEW_AND_SUBMIT_DOC_APPROVE,
      label: 'Phê duyệt hồ sơ',
      value: PermissionEnum.REVIEW_AND_SUBMIT_DOC_APPROVE,
      idParent: ParentPermissionEnum.REVIEW_AND_SUBMIT_FILE_SUBMISSION,
    },
  ],
  [
    PermissionEnum.RECEIVE_DOC_VIEW,
    {
      Id: PermissionEnum.RECEIVE_DOC_VIEW,
      label: 'Xem danh sách hồ sơ',
      value: PermissionEnum.RECEIVE_DOC_VIEW,
      idParent: ParentPermissionEnum.RECEIVE_DOCUMENT,
    },
  ],
  [
    PermissionEnum.RECEIVE_DOC_DETAIL,
    {
      Id: PermissionEnum.RECEIVE_DOC_DETAIL,
      label: 'Xem chi tiết hồ sơ',
      value: PermissionEnum.RECEIVE_DOC_DETAIL,
      idParent: ParentPermissionEnum.RECEIVE_DOCUMENT,
    },
  ],
  [
    PermissionEnum.DOC_COMP_RECEIVE_DOC_DETAIL,
    {
      Id: PermissionEnum.DOC_COMP_RECEIVE_DOC_DETAIL,
      label: 'Xem chi tiết tài liệu',
      value: PermissionEnum.DOC_COMP_RECEIVE_DOC_DETAIL,
      idParent: ParentPermissionEnum.RECEIVE_DOCUMENT,
    },
  ],
  [
    PermissionEnum.RECEIVE_DOC_REFUSE,
    {
      Id: PermissionEnum.RECEIVE_DOC_REFUSE,
      label: 'Từ chối hồ sơ',
      value: PermissionEnum.RECEIVE_DOC_REFUSE,
      idParent: ParentPermissionEnum.RECEIVE_DOCUMENT,
    },
  ],
  [
    PermissionEnum.RECEIVE_DOC_APPROVE,
    {
      Id: PermissionEnum.RECEIVE_DOC_APPROVE,
      label: 'Phê duyệt hồ sơ',
      value: PermissionEnum.RECEIVE_DOC_APPROVE,
      idParent: ParentPermissionEnum.RECEIVE_DOCUMENT,
    },
  ],
  [
    PermissionEnum.RECEIVE_DOC_EXPORT,
    {
      Id: PermissionEnum.RECEIVE_DOC_EXPORT,
      label: 'Xuất file hồ sơ',
      value: PermissionEnum.RECEIVE_DOC_EXPORT,
      idParent: ParentPermissionEnum.RECEIVE_DOCUMENT,
    },
  ],
  [
    PermissionEnum.DOC_INDEX_VIEW,
    {
      Id: PermissionEnum.DOC_INDEX_VIEW,
      label: 'Xem danh sách hồ sơ',
      value: PermissionEnum.DOC_INDEX_VIEW,
      idParent: ParentPermissionEnum.DOCUMENT_INDEX,
    },
  ],
  [
    PermissionEnum.DOC_INDEX_EXPORT,
    {
      Id: PermissionEnum.DOC_INDEX_EXPORT,
      label: 'Xuất file hồ sơ',
      value: PermissionEnum.DOC_INDEX_EXPORT,
      idParent: ParentPermissionEnum.DOCUMENT_INDEX,
    },
  ],
  [
    PermissionEnum.DOC_INDEX_EXPORT_REPORT,
    {
      Id: PermissionEnum.DOC_INDEX_EXPORT_REPORT,
      label: 'Xuất file báo cáo',
      value: PermissionEnum.DOC_INDEX_EXPORT_REPORT,
      idParent: ParentPermissionEnum.DOCUMENT_INDEX,
    },
  ],
  [
    PermissionEnum.DOC_COMP_DOC_INDEX_DETAIL,
    {
      Id: PermissionEnum.DOC_COMP_DOC_INDEX_DETAIL,
      label: 'Xem chi tiết tài liệu',
      value: PermissionEnum.DOC_COMP_DOC_INDEX_DETAIL,
      idParent: ParentPermissionEnum.DOCUMENT_INDEX,
    },
  ],
  [
    PermissionEnum.DOC_INDEX_DETAIL,
    {
      Id: PermissionEnum.DOC_INDEX_DETAIL,
      label: 'Xem chi tiết hồ sơ',
      value: PermissionEnum.DOC_INDEX_DETAIL,
      idParent: ParentPermissionEnum.DOCUMENT_INDEX,
    },
  ],
  [
    PermissionEnum.HANDOVER_DOCUMENT_VIEW,
    {
      Id: PermissionEnum.HANDOVER_DOCUMENT_VIEW,
      label: 'Xem danh sách biên bản',
      value: PermissionEnum.HANDOVER_DOCUMENT_VIEW,
      idParent: ParentPermissionEnum.HANDOVER_DOCUMENT_MANAGEMENT,
    },
  ],
  [
    PermissionEnum.HANDOVER_DOCUMENT_DETAIL,
    {
      Id: PermissionEnum.HANDOVER_DOCUMENT_DETAIL,
      label: 'Xem chi tiết biên bản',
      value: PermissionEnum.HANDOVER_DOCUMENT_DETAIL,
      idParent: ParentPermissionEnum.HANDOVER_DOCUMENT_MANAGEMENT,
    },
  ],
  [
    PermissionEnum.HANDOVER_DOCUMENT_CREATE,
    {
      Id: PermissionEnum.HANDOVER_DOCUMENT_CREATE,
      label: 'Tạo biên bản',
      value: PermissionEnum.HANDOVER_DOCUMENT_CREATE,
      idParent: ParentPermissionEnum.HANDOVER_DOCUMENT_MANAGEMENT,
    },
  ],
  [
    PermissionEnum.HANDOVER_DOCUMENT_EDIT,
    {
      Id: PermissionEnum.HANDOVER_DOCUMENT_EDIT,
      label: 'Sửa biên bản',
      value: PermissionEnum.HANDOVER_DOCUMENT_EDIT,
      idParent: ParentPermissionEnum.HANDOVER_DOCUMENT_MANAGEMENT,
    },
  ],
  [
    PermissionEnum.HANDOVER_DOCUMENT_DELETE,
    {
      Id: PermissionEnum.HANDOVER_DOCUMENT_DELETE,
      label: 'Xóa biên bản',
      value: PermissionEnum.HANDOVER_DOCUMENT_DELETE,
      idParent: ParentPermissionEnum.HANDOVER_DOCUMENT_MANAGEMENT,
    },
  ],
  [
    PermissionEnum.HANDOVER_DOCUMENT_DOWNLOAD,
    {
      Id: PermissionEnum.HANDOVER_DOCUMENT_DOWNLOAD,
      label: 'Tải biên bản',
      value: PermissionEnum.HANDOVER_DOCUMENT_DOWNLOAD,
      idParent: ParentPermissionEnum.HANDOVER_DOCUMENT_MANAGEMENT,
    },
  ],
  [
    PermissionEnum.HANDOVER_DOCUMENT_EXPORT,
    {
      Id: PermissionEnum.HANDOVER_DOCUMENT_EXPORT,
      label: 'Xuất file biên bản bàn giao',
      value: PermissionEnum.HANDOVER_DOCUMENT_EXPORT,
      idParent: ParentPermissionEnum.HANDOVER_DOCUMENT_MANAGEMENT,
    },
  ],
  [
    PermissionEnum.HANDOVER_DOCUMENT_SEND,
    {
      Id: PermissionEnum.HANDOVER_DOCUMENT_SEND,
      label: 'Gửi duyệt',
      value: PermissionEnum.HANDOVER_DOCUMENT_SEND,
      idParent: ParentPermissionEnum.HANDOVER_DOCUMENT_MANAGEMENT,
    },
  ],
  [
    PermissionEnum.DOC_INDEXING_VIEW,
    {
      Id: PermissionEnum.DOC_INDEXING_VIEW,
      label: 'Xem danh sách hồ sơ',
      value: PermissionEnum.DOC_INDEXING_VIEW,
      idParent: ParentPermissionEnum.DOCUMENT_INDEXING,
    },
  ],
  [
    PermissionEnum.DOC_INDEXING_CREATE,
    {
      Id: PermissionEnum.DOC_INDEXING_CREATE,
      label: 'Tạo hồ sơ',
      value: PermissionEnum.DOC_INDEXING_CREATE,
      idParent: ParentPermissionEnum.DOCUMENT_INDEXING,
    },
  ],
  [
    PermissionEnum.DOC_INDEXING_DETAIL,
    {
      Id: PermissionEnum.DOC_INDEXING_DETAIL,
      label: 'Xem chi tiết hồ sơ',
      value: PermissionEnum.DOC_INDEXING_DETAIL,
      idParent: ParentPermissionEnum.DOCUMENT_INDEXING,
    },
  ],
  [
    PermissionEnum.DOC_COMP_OF_DOC_INDEXING_DETAIL,
    {
      Id: PermissionEnum.DOC_COMP_OF_DOC_INDEXING_DETAIL,
      label: 'Xem chi tiết tài liệu',
      value: PermissionEnum.DOC_COMP_OF_DOC_INDEXING_DETAIL,
      idParent: ParentPermissionEnum.DOCUMENT_INDEXING,
    },
  ],
  [
    PermissionEnum.DOC_COMP_OF_DOC_INDEXING_CREATE,
    {
      Id: PermissionEnum.DOC_COMP_OF_DOC_INDEXING_CREATE,
      label: 'Tạo tài liệu',
      value: PermissionEnum.DOC_COMP_OF_DOC_INDEXING_CREATE,
      idParent: ParentPermissionEnum.DOCUMENT_INDEXING,
    },
  ],
  [
    PermissionEnum.DOC_COMP_OF_DOC_INDEXING_IMPORT,
    {
      Id: PermissionEnum.DOC_COMP_OF_DOC_INDEXING_IMPORT,
      label: 'Tải tài liệu lên',
      value: PermissionEnum.DOC_COMP_OF_DOC_INDEXING_IMPORT,
      idParent: ParentPermissionEnum.DOCUMENT_INDEXING,
    },
  ],
  [
    PermissionEnum.DOC_COMP_OF_DOC_INDEXING_OCR,
    {
      Id: PermissionEnum.DOC_COMP_OF_DOC_INDEXING_OCR,
      label: 'Auto OCR',
      value: PermissionEnum.DOC_COMP_OF_DOC_INDEXING_OCR,
      idParent: ParentPermissionEnum.DOCUMENT_INDEXING,
    },
  ],
  [
    PermissionEnum.DOC_COMP_OF_DOC_INDEXING_EXPORT,
    {
      Id: PermissionEnum.DOC_COMP_OF_DOC_INDEXING_EXPORT,
      label: 'Xuất file tài liệu',
      value: PermissionEnum.DOC_COMP_OF_DOC_INDEXING_EXPORT,
      idParent: ParentPermissionEnum.DOCUMENT_INDEXING,
    },
  ],
  [
    PermissionEnum.DOC_COMP_OF_DOC_INDEXING_DELETE,
    {
      Id: PermissionEnum.DOC_COMP_OF_DOC_INDEXING_DELETE,
      label: 'Xóa tài liệu',
      value: PermissionEnum.DOC_COMP_OF_DOC_INDEXING_DELETE,
      idParent: ParentPermissionEnum.DOCUMENT_INDEXING,
    },
  ],
  [
    PermissionEnum.DOC_COMP_OF_DOC_INDEXING_EDIT,
    {
      Id: PermissionEnum.DOC_COMP_OF_DOC_INDEXING_EDIT,
      label: 'Sửa tài liệu',
      value: PermissionEnum.DOC_COMP_OF_DOC_INDEXING_EDIT,
      idParent: ParentPermissionEnum.DOCUMENT_INDEXING,
    },
  ],
  [
    PermissionEnum.DOC_INDEXING_EDIT,
    {
      Id: PermissionEnum.DOC_INDEXING_EDIT,
      label: 'Sửa hồ sơ',
      value: PermissionEnum.DOC_INDEXING_EDIT,
      idParent: ParentPermissionEnum.DOCUMENT_INDEXING,
    },
  ],
  [
    PermissionEnum.DOC_INDEXING_DELETE,
    {
      Id: PermissionEnum.DOC_INDEXING_DELETE,
      label: 'Xóa hồ sơ',
      value: PermissionEnum.DOC_INDEXING_DELETE,
      idParent: ParentPermissionEnum.DOCUMENT_INDEXING,
    },
  ],
  [
    PermissionEnum.DOC_INDEXING_SEND,
    {
      Id: PermissionEnum.DOC_INDEXING_SEND,
      label: 'Gửi duyệt',
      value: PermissionEnum.DOC_INDEXING_SEND,
      idParent: ParentPermissionEnum.DOCUMENT_INDEXING,
    },
  ],
  [
    PermissionEnum.VARIABLE_REPOSITORY_BROWSING_VIEW,
    {
      Id: PermissionEnum.VARIABLE_REPOSITORY_BROWSING_VIEW,
      label: 'Xem danh sách hồ sơ',
      value: PermissionEnum.VARIABLE_REPOSITORY_BROWSING_VIEW,
      idParent: ParentPermissionEnum.VARIABLE_REPOSITORY_BROWSING,
    },
  ],
  [
    PermissionEnum.VARIABLE_REPOSITORY_BROWSING_REFUSE,
    {
      Id: PermissionEnum.VARIABLE_REPOSITORY_BROWSING_REFUSE,
      label: 'Từ chối lưu kho',
      value: PermissionEnum.VARIABLE_REPOSITORY_BROWSING_REFUSE,
      idParent: ParentPermissionEnum.VARIABLE_REPOSITORY_BROWSING,
    },
  ],
  [
    PermissionEnum.VARIABLE_REPOSITORY_BROWSING_DETAIL,
    {
      Id: PermissionEnum.VARIABLE_REPOSITORY_BROWSING_DETAIL,
      label: 'Xem chi tiết hồ sơ',
      value: PermissionEnum.VARIABLE_REPOSITORY_BROWSING_DETAIL,
      idParent: ParentPermissionEnum.VARIABLE_REPOSITORY_BROWSING,
    },
  ],
  [
    PermissionEnum.DOC_COMP_VARIABLE_REPOSITORY_BROWSING_DETAIL,
    {
      Id: PermissionEnum.DOC_COMP_VARIABLE_REPOSITORY_BROWSING_DETAIL,
      label: 'Xem chi tiết tài liệu',
      value: PermissionEnum.DOC_COMP_VARIABLE_REPOSITORY_BROWSING_DETAIL,
      idParent: ParentPermissionEnum.VARIABLE_REPOSITORY_BROWSING,
    },
  ],
  [
    PermissionEnum.VARIABLE_REPOSITORY_BROWSING_APPROVE,
    {
      Id: PermissionEnum.VARIABLE_REPOSITORY_BROWSING_APPROVE,
      label: 'Duyệt lưu kho',
      value: PermissionEnum.VARIABLE_REPOSITORY_BROWSING_APPROVE,
      idParent: ParentPermissionEnum.VARIABLE_REPOSITORY_BROWSING,
    },
  ],
  [
    PermissionEnum.SUPPLEMENTAL_INDEXING_MANAGEMENT_VIEW,
    {
      Id: PermissionEnum.SUPPLEMENTAL_INDEXING_MANAGEMENT_VIEW,
      label: 'Xem danh sách hồ sơ biên mục bổ sung',
      value: PermissionEnum.SUPPLEMENTAL_INDEXING_MANAGEMENT_VIEW,
      idParent: ParentPermissionEnum.SUPPLEMENTAL_INDEXING_MANAGEMENT,
    },
  ],
  [
    PermissionEnum.SUPPLEMENTAL_INDEXING_MANAGEMENT_DETAIL,
    {
      Id: PermissionEnum.SUPPLEMENTAL_INDEXING_MANAGEMENT_DETAIL,
      label: 'Xem chi tiết thông tin hồ sơ',
      value: PermissionEnum.SUPPLEMENTAL_INDEXING_MANAGEMENT_DETAIL,
      idParent: ParentPermissionEnum.SUPPLEMENTAL_INDEXING_MANAGEMENT,
    },
  ],
  [
    PermissionEnum.SUPPLEMENTAL_INDEXING_MANAGEMENT_CREATE,
    {
      Id: PermissionEnum.SUPPLEMENTAL_INDEXING_MANAGEMENT_CREATE,
      label: 'Thêm hồ sơ',
      value: PermissionEnum.SUPPLEMENTAL_INDEXING_MANAGEMENT_CREATE,
      idParent: ParentPermissionEnum.SUPPLEMENTAL_INDEXING_MANAGEMENT,
    },
  ],
  [
    PermissionEnum.SUPPLEMENTAL_INDEXING_MANAGEMENT_SEND,
    {
      Id: PermissionEnum.SUPPLEMENTAL_INDEXING_MANAGEMENT_SEND,
      label: 'Gửi duyệt',
      value: PermissionEnum.SUPPLEMENTAL_INDEXING_MANAGEMENT_SEND,
      idParent: ParentPermissionEnum.SUPPLEMENTAL_INDEXING_MANAGEMENT,
    },
  ],
  [
    PermissionEnum.SUPPLEMENTAL_INDEXING_MANAGEMENT_DELETE,
    {
      Id: PermissionEnum.SUPPLEMENTAL_INDEXING_MANAGEMENT_DELETE,
      label: 'Xóa hồ sơ biên mục bổ sung',
      value: PermissionEnum.SUPPLEMENTAL_INDEXING_MANAGEMENT_DELETE,
      idParent: ParentPermissionEnum.SUPPLEMENTAL_INDEXING_MANAGEMENT,
    },
  ],
  [
    PermissionEnum.REVIEW_SUPPLEMENTAL_INDEXING_VIEW,
    {
      Id: PermissionEnum.REVIEW_SUPPLEMENTAL_INDEXING_VIEW,
      label: 'Xem danh sách hồ sơ chờ duyệt',
      value: PermissionEnum.REVIEW_SUPPLEMENTAL_INDEXING_VIEW,
      idParent: ParentPermissionEnum.REVIEW_SUPPLEMENTAL_INDEXING,
    },
  ],
  [
    PermissionEnum.REVIEW_SUPPLEMENTAL_INDEXING_DETAIL,
    {
      Id: PermissionEnum.REVIEW_SUPPLEMENTAL_INDEXING_DETAIL,
      label: 'Xem chi tiết hồ sơ',
      value: PermissionEnum.REVIEW_SUPPLEMENTAL_INDEXING_DETAIL,
      idParent: ParentPermissionEnum.REVIEW_SUPPLEMENTAL_INDEXING,
    },
  ],
  [
    PermissionEnum.REVIEW_SUPPLEMENTAL_INDEXING_REFUSE,
    {
      Id: PermissionEnum.REVIEW_SUPPLEMENTAL_INDEXING_REFUSE,
      label: 'Từ chối',
      value: PermissionEnum.REVIEW_SUPPLEMENTAL_INDEXING_REFUSE,
      idParent: ParentPermissionEnum.REVIEW_SUPPLEMENTAL_INDEXING,
    },
  ],
  [
    PermissionEnum.REVIEW_SUPPLEMENTAL_INDEXING_APPROVE,
    {
      Id: PermissionEnum.REVIEW_SUPPLEMENTAL_INDEXING_APPROVE,
      label: 'Phê duyệt',
      value: PermissionEnum.REVIEW_SUPPLEMENTAL_INDEXING_APPROVE,
      idParent: ParentPermissionEnum.REVIEW_SUPPLEMENTAL_INDEXING,
    },
  ],
  [
    PermissionEnum.SUPPLEMENTAL_DOCUMENT_INDEXING_VIEW,
    {
      Id: PermissionEnum.SUPPLEMENTAL_DOCUMENT_INDEXING_VIEW,
      label: 'Xem danh sách hồ sơ biên mục bổ sung',
      value: PermissionEnum.SUPPLEMENTAL_DOCUMENT_INDEXING_VIEW,
      idParent: ParentPermissionEnum.SUPPLEMENTAL_DOCUMENT_INDEXING,
    },
  ],
  [
    PermissionEnum.SUPPLEMENTAL_DOCUMENT_INDEXING_DETAIL,
    {
      Id: PermissionEnum.SUPPLEMENTAL_DOCUMENT_INDEXING_DETAIL,
      label: 'Xem chi tiết hồ sơ',
      value: PermissionEnum.SUPPLEMENTAL_DOCUMENT_INDEXING_DETAIL,
      idParent: ParentPermissionEnum.SUPPLEMENTAL_DOCUMENT_INDEXING,
    },
  ],
  [
    PermissionEnum.SUPPLEMENTAL_DOCUMENT_INDEXING_DETAIL_DOC_COMP,
    {
      Id: PermissionEnum.SUPPLEMENTAL_DOCUMENT_INDEXING_DETAIL_DOC_COMP,
      label: 'Xem chi tiết tài liệu',
      value: PermissionEnum.SUPPLEMENTAL_DOCUMENT_INDEXING_DETAIL_DOC_COMP,
      idParent: ParentPermissionEnum.SUPPLEMENTAL_DOCUMENT_INDEXING,
    },
  ],
  [
    PermissionEnum.SUPPLEMENTAL_DOCUMENT_INDEXING_EDIT,
    {
      Id: PermissionEnum.SUPPLEMENTAL_DOCUMENT_INDEXING_EDIT,
      label: 'Sửa hồ sơ',
      value: PermissionEnum.SUPPLEMENTAL_DOCUMENT_INDEXING_EDIT,
      idParent: ParentPermissionEnum.SUPPLEMENTAL_DOCUMENT_INDEXING,
    },
  ],
  [
    PermissionEnum.SUPPLEMENTAL_DOCUMENT_INDEXING_SEND,
    {
      Id: PermissionEnum.SUPPLEMENTAL_DOCUMENT_INDEXING_SEND,
      label: 'Gửi duyệt',
      value: PermissionEnum.SUPPLEMENTAL_DOCUMENT_INDEXING_SEND,
      idParent: ParentPermissionEnum.SUPPLEMENTAL_DOCUMENT_INDEXING,
    },
  ],
  [
    PermissionEnum.BARCODE_VIEW,
    {
      Id: PermissionEnum.BARCODE_VIEW,
      label: 'Danh sách hồ sơ',
      value: PermissionEnum.BARCODE_VIEW,
      idParent: ParentPermissionEnum.BARCODE,
    },
  ],
  [
    PermissionEnum.BARCODE_IN,
    {
      Id: PermissionEnum.BARCODE_IN,
      label: 'In mã vạch',
      value: PermissionEnum.BARCODE_IN,
      idParent: ParentPermissionEnum.BARCODE,
    },
  ],
  [
    PermissionEnum.DOCUMENT_SEARCH_VIEW,
    {
      Id: PermissionEnum.DOCUMENT_SEARCH_VIEW,
      label: 'Xem danh sách hồ sơ',
      value: PermissionEnum.DOCUMENT_SEARCH_VIEW,
      idParent: ParentPermissionEnum.DOCUMENT_SEARCH,
    },
  ],
  [
    PermissionEnum.DOCUMENT_SEARCH_DETAIL,
    {
      Id: PermissionEnum.DOCUMENT_SEARCH_DETAIL,
      label: 'Xem chi tiết hồ sơ',
      value: PermissionEnum.DOCUMENT_SEARCH_DETAIL,
      idParent: ParentPermissionEnum.DOCUMENT_SEARCH,
    },
  ],
  [
    PermissionEnum.DOCUMENT_SEARCH_EXPORT_DOC,
    {
      Id: PermissionEnum.DOCUMENT_SEARCH_EXPORT_DOC,
      label: 'Xuất file hồ sơ',
      value: PermissionEnum.DOCUMENT_SEARCH_EXPORT_DOC,
      idParent: ParentPermissionEnum.DOCUMENT_SEARCH,
    },
  ],
  [
    PermissionEnum.DOCUMENT_SEARCH_EXPORT_DOC_COMP,
    {
      Id: PermissionEnum.DOCUMENT_SEARCH_EXPORT_DOC_COMP,
      label: 'Xuất file tài liệu',
      value: PermissionEnum.DOCUMENT_SEARCH_EXPORT_DOC_COMP,
      idParent: ParentPermissionEnum.DOCUMENT_SEARCH,
    },
  ],
  [
    PermissionEnum.DOCUMENT_SEARCH_DETAIL_DOC_COMP,
    {
      Id: PermissionEnum.DOCUMENT_SEARCH_DETAIL_DOC_COMP,
      label: 'Xem chi tiết tài liệu',
      value: PermissionEnum.DOCUMENT_SEARCH_DETAIL_DOC_COMP,
      idParent: ParentPermissionEnum.DOCUMENT_SEARCH,
    },
  ],
  [
    PermissionEnum.DOCUMENT_SEARCH_ZIP,
    {
      Id: PermissionEnum.DOCUMENT_SEARCH_ZIP,
      label: 'Kết xuất hồ sơ',
      value: PermissionEnum.DOCUMENT_SEARCH_ZIP,
      idParent: ParentPermissionEnum.DOCUMENT_SEARCH,
    },
  ],
  [
    PermissionEnum.DOCUMENT_COMPONENT_SEARCH_VIEW,
    {
      Id: PermissionEnum.DOCUMENT_COMPONENT_SEARCH_VIEW,
      label: 'Xem danh sách tài liệu',
      value: PermissionEnum.DOCUMENT_COMPONENT_SEARCH_VIEW,
      idParent: ParentPermissionEnum.DOCUMENT_COMPONENT_SEARCH,
    },
  ],
  [
    PermissionEnum.DOCUMENT_COMPONENT_SEARCH_DETAIL,
    {
      Id: PermissionEnum.DOCUMENT_COMPONENT_SEARCH_DETAIL,
      label: 'Xem chi tiết tài liệu',
      value: PermissionEnum.DOCUMENT_COMPONENT_SEARCH_DETAIL,
      idParent: ParentPermissionEnum.DOCUMENT_COMPONENT_SEARCH,
    },
  ],
  [
    PermissionEnum.MOVE_DOCUMENT_VIEW,
    {
      Id: PermissionEnum.MOVE_DOCUMENT_VIEW,
      label: 'Xem danh sách hồ sơ',
      value: PermissionEnum.MOVE_DOCUMENT_VIEW,
      idParent: ParentPermissionEnum.MOVE_DOCUMENT,
    },
  ],
  [
    PermissionEnum.MOVE_DOCUMENT_EXPORT,
    {
      Id: PermissionEnum.MOVE_DOCUMENT_EXPORT,
      label: 'Xuất file hồ sơ',
      value: PermissionEnum.MOVE_DOCUMENT_EXPORT,
      idParent: ParentPermissionEnum.MOVE_DOCUMENT,
    },
  ],
  [
    PermissionEnum.MOVE_DOCUMENT_DETAIL,
    {
      Id: PermissionEnum.MOVE_DOCUMENT_DETAIL,
      label: 'Xem chi tiết hồ sơ',
      value: PermissionEnum.MOVE_DOCUMENT_DETAIL,
      idParent: ParentPermissionEnum.MOVE_DOCUMENT,
    },
  ],
  [
    PermissionEnum.MOVE_DOCUMENT_DETAIL_DOC_COMP,
    {
      Id: PermissionEnum.MOVE_DOCUMENT_DETAIL_DOC_COMP,
      label: 'Xem chi tiết tài liệu',
      value: PermissionEnum.MOVE_DOCUMENT_DETAIL_DOC_COMP,
      idParent: ParentPermissionEnum.MOVE_DOCUMENT,
    },
  ],
  [
    PermissionEnum.MOVE_DOCUMENT_LEAVE_DOC_COMP,
    {
      Id: PermissionEnum.MOVE_DOCUMENT_LEAVE_DOC_COMP,
      label: 'Di chuyển tài liệu',
      value: PermissionEnum.MOVE_DOCUMENT_LEAVE_DOC_COMP,
      idParent: ParentPermissionEnum.MOVE_DOCUMENT,
    },
  ],
  [
    PermissionEnum.MOVE_DOCUMENT_UNSORTED,
    {
      Id: PermissionEnum.MOVE_DOCUMENT_UNSORTED,
      label: 'Bỏ xếp hộp/cặp',
      value: PermissionEnum.MOVE_DOCUMENT_UNSORTED,
      idParent: ParentPermissionEnum.MOVE_DOCUMENT,
    },
  ],
  [
    PermissionEnum.MOVE_DOCUMENT_LEAVE,
    {
      Id: PermissionEnum.MOVE_DOCUMENT_LEAVE,
      label: 'Di chuyển hồ sơ',
      value: PermissionEnum.MOVE_DOCUMENT_LEAVE,
      idParent: ParentPermissionEnum.MOVE_DOCUMENT,
    },
  ],
  [
    PermissionEnum.FREEZING_DOCUMENT_VIEW,
    {
      Id: PermissionEnum.FREEZING_DOCUMENT_VIEW,
      label: 'Xem danh sách hồ sơ',
      value: PermissionEnum.FREEZING_DOCUMENT_VIEW,
      idParent: ParentPermissionEnum.FREEZING_DOCUMENT,
    },
  ],
  [
    PermissionEnum.FREEZING_DOCUMENT_DETAIL,
    {
      Id: PermissionEnum.FREEZING_DOCUMENT_DETAIL,
      label: 'Xem chi tiết hồ sơ',
      value: PermissionEnum.FREEZING_DOCUMENT_DETAIL,
      idParent: ParentPermissionEnum.FREEZING_DOCUMENT,
    },
  ],
  [
    PermissionEnum.FREEZING_DOCUMENT_DETAIL_DOC_COMP,
    {
      Id: PermissionEnum.FREEZING_DOCUMENT_DETAIL_DOC_COMP,
      label: 'Xem chi tiết tài liệu',
      value: PermissionEnum.FREEZING_DOCUMENT_DETAIL_DOC_COMP,
      idParent: ParentPermissionEnum.FREEZING_DOCUMENT,
    },
  ],
  [
    PermissionEnum.FREEZING_DOCUMENT_UNFREEZING,
    {
      Id: PermissionEnum.FREEZING_DOCUMENT_UNFREEZING,
      label: 'Bỏ đóng băng',
      value: PermissionEnum.FREEZING_DOCUMENT_UNFREEZING,
      idParent: ParentPermissionEnum.FREEZING_DOCUMENT,
    },
  ],
  [
    PermissionEnum.FREEZING_DOCUMENT_FREEZING,
    {
      Id: PermissionEnum.FREEZING_DOCUMENT_FREEZING,
      label: 'Đóng băng',
      value: PermissionEnum.FREEZING_DOCUMENT_FREEZING,
      idParent: ParentPermissionEnum.FREEZING_DOCUMENT,
    },
  ],
  [
    PermissionEnum.TRANSFER_DOCUMENT_VIEW,
    {
      Id: PermissionEnum.TRANSFER_DOCUMENT_VIEW,
      label: 'Xem danh sách phiếu di chuyển tài liệu',
      value: PermissionEnum.TRANSFER_DOCUMENT_VIEW,
      idParent: ParentPermissionEnum.TRANSFER_DOCUMENT,
    },
  ],
  [
    PermissionEnum.TRANSFER_DOCUMENT_DETAIL,
    {
      Id: PermissionEnum.TRANSFER_DOCUMENT_DETAIL,
      label: 'Xem chi tiết phiếu di chuyển tài liệu',
      value: PermissionEnum.TRANSFER_DOCUMENT_DETAIL,
      idParent: ParentPermissionEnum.TRANSFER_DOCUMENT,
    },
  ],
  [
    PermissionEnum.REVIEW_DESTROY_DOCUMENT_VIEW,
    {
      Id: PermissionEnum.REVIEW_DESTROY_DOCUMENT_VIEW,
      label: 'Xem danh sách hồ sơ chờ tiêu hủy',
      value: PermissionEnum.REVIEW_DESTROY_DOCUMENT_VIEW,
      idParent: ParentPermissionEnum.REVIEW_DESTROY_DOCUMENT,
    },
  ],
  [
    PermissionEnum.REVIEW_DESTROY_DOCUMENT_DETAIL,
    {
      Id: PermissionEnum.REVIEW_DESTROY_DOCUMENT_DETAIL,
      label: 'Xem chi tiết hồ sơ',
      value: PermissionEnum.REVIEW_DESTROY_DOCUMENT_DETAIL,
      idParent: ParentPermissionEnum.REVIEW_DESTROY_DOCUMENT,
    },
  ],
  [
    PermissionEnum.REVIEW_DESTROY_DOCUMENT_CREATE_DOC,
    {
      Id: PermissionEnum.REVIEW_DESTROY_DOCUMENT_CREATE_DOC,
      label: 'Thêm hồ sơ tiêu hủy',
      value: PermissionEnum.REVIEW_DESTROY_DOCUMENT_CREATE_DOC,
      idParent: ParentPermissionEnum.REVIEW_DESTROY_DOCUMENT,
    },
  ],
  [
    PermissionEnum.REVIEW_DESTROY_DOCUMENT_CREATE_DECISION,
    {
      Id: PermissionEnum.REVIEW_DESTROY_DOCUMENT_CREATE_DECISION,
      label: 'Thêm quyết định tiêu hủy',
      value: PermissionEnum.REVIEW_DESTROY_DOCUMENT_CREATE_DECISION,
      idParent: ParentPermissionEnum.REVIEW_DESTROY_DOCUMENT,
    },
  ],
  [
    PermissionEnum.REVIEW_DESTROY_DOCUMENT_DELETE,
    {
      Id: PermissionEnum.REVIEW_DESTROY_DOCUMENT_DELETE,
      label: 'Xóa hồ sơ chờ tiêu hủy',
      value: PermissionEnum.REVIEW_DESTROY_DOCUMENT_DELETE,
      idParent: ParentPermissionEnum.REVIEW_DESTROY_DOCUMENT,
    },
  ],
  [
    PermissionEnum.DESTROY_DECISION_VIEW,
    {
      Id: PermissionEnum.DESTROY_DECISION_VIEW,
      label: 'Xem danh sách quyết định tiêu hủy',
      value: PermissionEnum.DESTROY_DECISION_VIEW,
      idParent: ParentPermissionEnum.DESTROY_DECISION,
    },
  ],
  [
    PermissionEnum.DESTROY_DECISION_DETAIL,
    {
      Id: PermissionEnum.DESTROY_DECISION_DETAIL,
      label: 'Xem chi tiết quyết định tiêu hủy',
      value: PermissionEnum.DESTROY_DECISION_DETAIL,
      idParent: ParentPermissionEnum.DESTROY_DECISION,
    },
  ],
  [
    PermissionEnum.DESTROY_DECISION_CREATE,
    {
      Id: PermissionEnum.DESTROY_DECISION_CREATE,
      label: 'Tạo quyết định tiêu hủy',
      value: PermissionEnum.DESTROY_DECISION_CREATE,
      idParent: ParentPermissionEnum.DESTROY_DECISION,
    },
  ],
  [
    PermissionEnum.DESTROY_DECISION_EDIT,
    {
      Id: PermissionEnum.DESTROY_DECISION_EDIT,
      label: 'Chỉnh sửa quyết định tiêu hủy',
      value: PermissionEnum.DESTROY_DECISION_EDIT,
      idParent: ParentPermissionEnum.DESTROY_DECISION,
    },
  ],
  [
    PermissionEnum.DESTROY_DECISION_SEND,
    {
      Id: PermissionEnum.DESTROY_DECISION_SEND,
      label: 'Gửi duyệt quyết định tiêu hủy',
      value: PermissionEnum.DESTROY_DECISION_SEND,
      idParent: ParentPermissionEnum.DESTROY_DECISION,
    },
  ],
  [
    PermissionEnum.DESTROY_DECISION_DELETE,
    {
      Id: PermissionEnum.DESTROY_DECISION_DELETE,
      label: 'Xóa quyết định tiêu hủy',
      value: PermissionEnum.DESTROY_DECISION_DELETE,
      idParent: ParentPermissionEnum.DESTROY_DECISION,
    },
  ],
  [
    PermissionEnum.REVIEW_DESTROY_DECISION_VIEW,
    {
      Id: PermissionEnum.REVIEW_DESTROY_DECISION_VIEW,
      label: 'Xem danh sách quyết định tiêu hủy',
      value: PermissionEnum.REVIEW_DESTROY_DECISION_VIEW,
      idParent: ParentPermissionEnum.REVIEW_DESTROY_DECISION,
    },
  ],
  [
    PermissionEnum.REVIEW_DESTROY_DECISION_DETAIL,
    {
      Id: PermissionEnum.REVIEW_DESTROY_DECISION_DETAIL,
      label: 'Xem chi tiết quyết định',
      value: PermissionEnum.REVIEW_DESTROY_DECISION_DETAIL,
      idParent: ParentPermissionEnum.REVIEW_DESTROY_DECISION,
    },
  ],
  [
    PermissionEnum.REVIEW_DESTROY_DECISION_REFUSE,
    {
      Id: PermissionEnum.REVIEW_DESTROY_DECISION_REFUSE,
      label: 'Từ chối',
      value: PermissionEnum.REVIEW_DESTROY_DECISION_REFUSE,
      idParent: ParentPermissionEnum.REVIEW_DESTROY_DECISION,
    },
  ],
  [
    PermissionEnum.REVIEW_DESTROY_DECISION_APPROVE,
    {
      Id: PermissionEnum.REVIEW_DESTROY_DECISION_APPROVE,
      label: 'Phê duyệt',
      value: PermissionEnum.REVIEW_DESTROY_DECISION_APPROVE,
      idParent: ParentPermissionEnum.REVIEW_DESTROY_DECISION,
    },
  ],
  [
    PermissionEnum.DESTROYED_DECISION_VIEW,
    {
      Id: PermissionEnum.DESTROYED_DECISION_VIEW,
      label: 'Xem danh sách quyết định',
      value: PermissionEnum.DESTROYED_DECISION_VIEW,
      idParent: ParentPermissionEnum.DESTROYED_DECISION,
    },
  ],
  [
    PermissionEnum.DESTROYED_DECISION_DETAIL,
    {
      Id: PermissionEnum.DESTROYED_DECISION_DETAIL,
      label: 'Xem chi tiết quyết định',
      value: PermissionEnum.DESTROYED_DECISION_DETAIL,
      idParent: ParentPermissionEnum.DESTROYED_DECISION,
    },
  ],
  [
    PermissionEnum.DESTROYED_DECISION_RESTORE,
    {
      Id: PermissionEnum.DESTROYED_DECISION_RESTORE,
      label: 'Khôi phục dữ liệu',
      value: PermissionEnum.DESTROYED_DECISION_RESTORE,
      idParent: ParentPermissionEnum.DESTROYED_DECISION,
    },
  ],
  [
    PermissionEnum.LANGUAGE_VIEW,
    {
      Id: PermissionEnum.LANGUAGE_VIEW,
      label: 'Xem danh sách ngôn ngữ',
      value: PermissionEnum.LANGUAGE_VIEW,
      idParent: ParentPermissionEnum.LANGUAGE,
    },
  ],
  [
    PermissionEnum.LANGUAGE_DETAIL,
    {
      Id: PermissionEnum.LANGUAGE_DETAIL,
      label: 'Xem chi tiết ngôn ngữ',
      value: PermissionEnum.LANGUAGE_DETAIL,
      idParent: ParentPermissionEnum.LANGUAGE,
    },
  ],
  [
    PermissionEnum.LANGUAGE_CREATE,
    {
      Id: PermissionEnum.LANGUAGE_CREATE,
      label: 'Tạo ngôn ngữ ',
      value: PermissionEnum.LANGUAGE_CREATE,
      idParent: ParentPermissionEnum.LANGUAGE,
    },
  ],
  [
    PermissionEnum.LANGUAGE_EDIT,
    {
      Id: PermissionEnum.LANGUAGE_EDIT,
      label: 'Sửa ngôn ngữ',
      value: PermissionEnum.LANGUAGE_EDIT,
      idParent: ParentPermissionEnum.LANGUAGE,
    },
  ],
  [
    PermissionEnum.LANGUAGE_DELETE,
    {
      Id: PermissionEnum.LANGUAGE_DELETE,
      label: 'Xóa ngôn ngữ',
      value: PermissionEnum.LANGUAGE_DELETE,
      idParent: ParentPermissionEnum.LANGUAGE,
    },
  ],
  [
    PermissionEnum.SECURITY_LEVEL_VIEW,
    {
      Id: PermissionEnum.SECURITY_LEVEL_VIEW,
      label: 'Xem danh sách cấp độ bảo mật',
      value: PermissionEnum.SECURITY_LEVEL_VIEW,
      idParent: ParentPermissionEnum.SECURITY_LEVEL,
    },
  ],
  [
    PermissionEnum.SECURITY_LEVEL_DETAIL,
    {
      Id: PermissionEnum.SECURITY_LEVEL_DETAIL,
      label: 'Xem chi tiết cấp độ bảo mật',
      value: PermissionEnum.SECURITY_LEVEL_DETAIL,
      idParent: ParentPermissionEnum.SECURITY_LEVEL,
    },
  ],
  [
    PermissionEnum.SECURITY_LEVEL_CREATE,
    {
      Id: PermissionEnum.SECURITY_LEVEL_CREATE,
      label: 'Tạo cấp độ bảo mật',
      value: PermissionEnum.SECURITY_LEVEL_CREATE,
      idParent: ParentPermissionEnum.SECURITY_LEVEL,
    },
  ],
  [
    PermissionEnum.SECURITY_LEVEL_EDIT,
    {
      Id: PermissionEnum.SECURITY_LEVEL_EDIT,
      label: 'Sửa cấp độ bảo mật',
      value: PermissionEnum.SECURITY_LEVEL_EDIT,
      idParent: ParentPermissionEnum.SECURITY_LEVEL,
    },
  ],
  [
    PermissionEnum.SECURITY_LEVEL_DELETE,
    {
      Id: PermissionEnum.SECURITY_LEVEL_DELETE,
      label: 'Xóa cấp độ bảo mật',
      value: PermissionEnum.SECURITY_LEVEL_DELETE,
      idParent: ParentPermissionEnum.SECURITY_LEVEL,
    },
  ],
  [
    PermissionEnum.WARRANTY_PERIOD_VIEW,
    {
      Id: PermissionEnum.WARRANTY_PERIOD_VIEW,
      label: 'Xem danh sách thời gian bảo quản',
      value: PermissionEnum.WARRANTY_PERIOD_VIEW,
      idParent: ParentPermissionEnum.WARRANTY_PERIOD,
    },
  ],
  [
    PermissionEnum.WARRANTY_PERIOD_DETAIL,
    {
      Id: PermissionEnum.WARRANTY_PERIOD_DETAIL,
      label: 'Xem chi tiết thời gian bảo quản',
      value: PermissionEnum.WARRANTY_PERIOD_DETAIL,
      idParent: ParentPermissionEnum.WARRANTY_PERIOD,
    },
  ],
  [
    PermissionEnum.WARRANTY_PERIOD_CREATE,
    {
      Id: PermissionEnum.WARRANTY_PERIOD_CREATE,
      label: 'Tạo thời gian bảo quản',
      value: PermissionEnum.WARRANTY_PERIOD_CREATE,
      idParent: ParentPermissionEnum.WARRANTY_PERIOD,
    },
  ],
  [
    PermissionEnum.WARRANTY_PERIOD_EDIT,
    {
      Id: PermissionEnum.WARRANTY_PERIOD_EDIT,
      label: 'Sửa thời gian bảo quản',
      value: PermissionEnum.WARRANTY_PERIOD_EDIT,
      idParent: ParentPermissionEnum.WARRANTY_PERIOD,
    },
  ],
  [
    PermissionEnum.WARRANTY_PERIOD_DELETE,
    {
      Id: PermissionEnum.WARRANTY_PERIOD_DELETE,
      label: 'Xóa thời gian bảo quản',
      value: PermissionEnum.WARRANTY_PERIOD_DELETE,
      idParent: ParentPermissionEnum.WARRANTY_PERIOD,
    },
  ],
  [
    PermissionEnum.POSITION_VIEW,
    {
      Id: PermissionEnum.POSITION_VIEW,
      label: 'Xem danh sách chức vụ',
      value: PermissionEnum.POSITION_VIEW,
      idParent: ParentPermissionEnum.POSITION,
    },
  ],
  [
    PermissionEnum.POSITION_DETAIL,
    {
      Id: PermissionEnum.POSITION_DETAIL,
      label: 'Xem chi tiết chức vụ',
      value: PermissionEnum.POSITION_DETAIL,
      idParent: ParentPermissionEnum.POSITION,
    },
  ],
  [
    PermissionEnum.POSITION_CREATE,
    {
      Id: PermissionEnum.POSITION_CREATE,
      label: 'Tạo chức vụ',
      value: PermissionEnum.POSITION_CREATE,
      idParent: ParentPermissionEnum.POSITION,
    },
  ],
  [
    PermissionEnum.POSITION_EDIT,
    {
      Id: PermissionEnum.POSITION_EDIT,
      label: 'Sửa chức vụ',
      value: PermissionEnum.POSITION_EDIT,
      idParent: ParentPermissionEnum.POSITION,
    },
  ],
  [
    PermissionEnum.POSITION_DELETE,
    {
      Id: PermissionEnum.POSITION_DELETE,
      label: 'Xóa chức vụ',
      value: PermissionEnum.POSITION_DELETE,
      idParent: ParentPermissionEnum.POSITION,
    },
  ],
  [
    PermissionEnum.FORM_VIEW,
    {
      Id: PermissionEnum.FORM_VIEW,
      label: 'Xem danh sách template',
      value: PermissionEnum.FORM_VIEW,
      idParent: ParentPermissionEnum.FORM,
    },
  ],
  [
    PermissionEnum.FORM_DETAIL,
    {
      Id: PermissionEnum.FORM_DETAIL,
      label: 'Xem chi tiết template',
      value: PermissionEnum.FORM_DETAIL,
      idParent: ParentPermissionEnum.FORM,
    },
  ],
  [
    PermissionEnum.FORM_CREATE,
    {
      Id: PermissionEnum.FORM_CREATE,
      label: 'Tạo template',
      value: PermissionEnum.FORM_CREATE,
      idParent: ParentPermissionEnum.FORM,
    },
  ],
  [
    PermissionEnum.FORM_EDIT,
    {
      Id: PermissionEnum.FORM_EDIT,
      label: 'Sửa template',
      value: PermissionEnum.FORM_EDIT,
      idParent: ParentPermissionEnum.FORM,
    },
  ],
  [
    PermissionEnum.FORM_DELETE,
    {
      Id: PermissionEnum.FORM_DELETE,
      label: 'Xóa template',
      value: PermissionEnum.FORM_DELETE,
      idParent: ParentPermissionEnum.FORM,
    },
  ],
  [
    PermissionEnum.FORM_EXPORT,
    {
      Id: PermissionEnum.FORM_EXPORT,
      label: 'Xuất file template',
      value: PermissionEnum.FORM_EXPORT,
      idParent: ParentPermissionEnum.FORM,
    },
  ],
  [
    PermissionEnum.MANAGEMENT_PERMISSION_VIEW,
    {
      Id: PermissionEnum.MANAGEMENT_PERMISSION_VIEW,
      label: 'Xem danh sách nhóm quyền',
      value: PermissionEnum.MANAGEMENT_PERMISSION_VIEW,
      idParent: ParentPermissionEnum.MANAGEMENT_PERMISSION,
    },
  ],
  [
    PermissionEnum.MANAGEMENT_PERMISSION_DETAIL,
    {
      Id: PermissionEnum.MANAGEMENT_PERMISSION_DETAIL,
      label: 'Xem chi tiết nhóm quyền',
      value: PermissionEnum.MANAGEMENT_PERMISSION_DETAIL,
      idParent: ParentPermissionEnum.MANAGEMENT_PERMISSION,
    },
  ],
  [
    PermissionEnum.MANAGEMENT_PERMISSION_CREATE,
    {
      Id: PermissionEnum.MANAGEMENT_PERMISSION_CREATE,
      label: 'Tạo nhóm quyền',
      value: PermissionEnum.MANAGEMENT_PERMISSION_CREATE,
      idParent: ParentPermissionEnum.MANAGEMENT_PERMISSION,
    },
  ],
  [
    PermissionEnum.MANAGEMENT_PERMISSION_EDIT,
    {
      Id: PermissionEnum.MANAGEMENT_PERMISSION_EDIT,
      label: 'Sửa nhóm quyền',
      value: PermissionEnum.MANAGEMENT_PERMISSION_EDIT,
      idParent: ParentPermissionEnum.MANAGEMENT_PERMISSION,
    },
  ],
  [
    PermissionEnum.MANAGEMENT_PERMISSION_DELETE,
    {
      Id: PermissionEnum.MANAGEMENT_PERMISSION_DELETE,
      label: 'Xóa nhóm quyền',
      value: PermissionEnum.MANAGEMENT_PERMISSION_DELETE,
      idParent: ParentPermissionEnum.MANAGEMENT_PERMISSION,
    },
  ],
  [
    PermissionEnum.MANAGEMENT_PERMISSION_EXPORT,
    {
      Id: PermissionEnum.MANAGEMENT_PERMISSION_EXPORT,
      label: 'Xuất file nhóm quyền',
      value: PermissionEnum.MANAGEMENT_PERMISSION_EXPORT,
      idParent: ParentPermissionEnum.MANAGEMENT_PERMISSION,
    },
  ],
  [
    PermissionEnum.MANAGEMENT_GROUP_USER_VIEW,
    {
      Id: PermissionEnum.MANAGEMENT_GROUP_USER_VIEW,
      label: 'Xem danh sách nhóm người dùng',
      value: PermissionEnum.MANAGEMENT_GROUP_USER_VIEW,
      idParent: ParentPermissionEnum.MANAGEMENT_GROUP_USER,
    },
  ],
  [
    PermissionEnum.MANAGEMENT_GROUP_USER_DETAIL,
    {
      Id: PermissionEnum.MANAGEMENT_GROUP_USER_DETAIL,
      label: 'Xem chi tiết nhóm người dùng',
      value: PermissionEnum.MANAGEMENT_GROUP_USER_DETAIL,
      idParent: ParentPermissionEnum.MANAGEMENT_GROUP_USER,
    },
  ],
  [
    PermissionEnum.MANAGEMENT_GROUP_USER_CREATE,
    {
      Id: PermissionEnum.MANAGEMENT_GROUP_USER_CREATE,
      label: 'Tạo nhóm người dùng',
      value: PermissionEnum.MANAGEMENT_GROUP_USER_CREATE,
      idParent: ParentPermissionEnum.MANAGEMENT_GROUP_USER,
    },
  ],
  [
    PermissionEnum.MANAGEMENT_GROUP_USER_EDIT,
    {
      Id: PermissionEnum.MANAGEMENT_GROUP_USER_EDIT,
      label: 'Sửa nhóm người dùng',
      value: PermissionEnum.MANAGEMENT_GROUP_USER_EDIT,
      idParent: ParentPermissionEnum.MANAGEMENT_GROUP_USER,
    },
  ],
  [
    PermissionEnum.MANAGEMENT_GROUP_USER_DELETE,
    {
      Id: PermissionEnum.MANAGEMENT_GROUP_USER_DELETE,
      label: 'Xóa nhóm người dùng',
      value: PermissionEnum.MANAGEMENT_GROUP_USER_DELETE,
      idParent: ParentPermissionEnum.MANAGEMENT_GROUP_USER,
    },
  ],
  [
    PermissionEnum.MANAGEMENT_GROUP_USER_EXPORT,
    {
      Id: PermissionEnum.MANAGEMENT_GROUP_USER_EXPORT,
      label: 'Xuất file nhóm người dùng',
      value: PermissionEnum.MANAGEMENT_GROUP_USER_EXPORT,
      idParent: ParentPermissionEnum.MANAGEMENT_GROUP_USER,
    },
  ],

  [
    PermissionEnum.MANAGEMENT_USER_VIEW,
    {
      Id: PermissionEnum.MANAGEMENT_USER_VIEW,
      label: 'Xem danh sách người dùng',
      value: PermissionEnum.MANAGEMENT_USER_VIEW,
      idParent: ParentPermissionEnum.MANAGEMENT_USER,
    },
  ],
  [
    PermissionEnum.MANAGEMENT_USER_DETAIL,
    {
      Id: PermissionEnum.MANAGEMENT_USER_DETAIL,
      label: 'Xem chi tiết người dùng',
      value: PermissionEnum.MANAGEMENT_USER_DETAIL,
      idParent: ParentPermissionEnum.MANAGEMENT_USER,
    },
  ],
  [
    PermissionEnum.MANAGEMENT_USER_CREATE,
    {
      Id: PermissionEnum.MANAGEMENT_USER_CREATE,
      label: 'Tạo người dùng',
      value: PermissionEnum.MANAGEMENT_USER_CREATE,
      idParent: ParentPermissionEnum.MANAGEMENT_USER,
    },
  ],
  [
    PermissionEnum.MANAGEMENT_USER_EDIT,
    {
      Id: PermissionEnum.MANAGEMENT_USER_EDIT,
      label: 'Sửa người dùng',
      value: PermissionEnum.MANAGEMENT_USER_EDIT,
      idParent: ParentPermissionEnum.MANAGEMENT_USER,
    },
  ],
  [
    PermissionEnum.MANAGEMENT_USER_DELETE,
    {
      Id: PermissionEnum.MANAGEMENT_USER_DELETE,
      label: 'Xóa người dùng',
      value: PermissionEnum.MANAGEMENT_USER_DELETE,
      idParent: ParentPermissionEnum.MANAGEMENT_USER,
    },
  ],
  [
    PermissionEnum.MANAGEMENT_USER_EXPORT,
    {
      Id: PermissionEnum.MANAGEMENT_USER_EXPORT,
      label: 'Xuất file người dùng',
      value: PermissionEnum.MANAGEMENT_USER_EXPORT,
      idParent: ParentPermissionEnum.MANAGEMENT_USER,
    },
  ],

  [
    PermissionEnum.MANAGEMENT_COMPANY_VIEW,
    {
      Id: PermissionEnum.MANAGEMENT_COMPANY_VIEW,
      label: 'Xem danh sách cơ quan',
      value: PermissionEnum.MANAGEMENT_COMPANY_VIEW,
      idParent: ParentPermissionEnum.MANAGEMENT_COMPANY,
    },
  ],
  [
    PermissionEnum.MANAGEMENT_COMPANY_DETAIL,
    {
      Id: PermissionEnum.MANAGEMENT_COMPANY_DETAIL,
      label: 'Xem chi tiết cơ quan',
      value: PermissionEnum.MANAGEMENT_COMPANY_DETAIL,
      idParent: ParentPermissionEnum.MANAGEMENT_COMPANY,
    },
  ],
  [
    PermissionEnum.MANAGEMENT_COMPANY_CREATE,
    {
      Id: PermissionEnum.MANAGEMENT_COMPANY_CREATE,
      label: 'Tạo cơ quan',
      value: PermissionEnum.MANAGEMENT_COMPANY_CREATE,
      idParent: ParentPermissionEnum.MANAGEMENT_COMPANY,
    },
  ],
  [
    PermissionEnum.MANAGEMENT_COMPANY_EDIT,
    {
      Id: PermissionEnum.MANAGEMENT_COMPANY_EDIT,
      label: 'Sửa cơ quan',
      value: PermissionEnum.MANAGEMENT_COMPANY_EDIT,
      idParent: ParentPermissionEnum.MANAGEMENT_COMPANY,
    },
  ],
  [
    PermissionEnum.MANAGEMENT_COMPANY_DELETE,
    {
      Id: PermissionEnum.MANAGEMENT_COMPANY_DELETE,
      label: 'Xóa cơ quan',
      value: PermissionEnum.MANAGEMENT_COMPANY_DELETE,
      idParent: ParentPermissionEnum.MANAGEMENT_COMPANY,
    },
  ],
  [
    PermissionEnum.MANAGEMENT_COMPANY_EXPORT,
    {
      Id: PermissionEnum.MANAGEMENT_COMPANY_EXPORT,
      label: 'Xuất file cơ quan',
      value: PermissionEnum.MANAGEMENT_COMPANY_EXPORT,
      idParent: ParentPermissionEnum.MANAGEMENT_COMPANY,
    },
  ],

  [
    PermissionEnum.AUTHORIZATION_BY_FILE_TYPE_VIEW,
    {
      Id: PermissionEnum.AUTHORIZATION_BY_FILE_TYPE_VIEW,
      label: 'Xem danh sách phân quyền theo loại hồ sơ',
      value: PermissionEnum.AUTHORIZATION_BY_FILE_TYPE_VIEW,
      idParent: ParentPermissionEnum.AUTHORIZATION_BY_FILE_TYPE,
    },
  ],
  [
    PermissionEnum.AUTHORIZATION_BY_FILE_TYPE_DETAIL,
    {
      Id: PermissionEnum.AUTHORIZATION_BY_FILE_TYPE_DETAIL,
      label: 'Xem chi tiết phân quyền theo loại hồ sơ',
      value: PermissionEnum.AUTHORIZATION_BY_FILE_TYPE_DETAIL,
      idParent: ParentPermissionEnum.AUTHORIZATION_BY_FILE_TYPE,
    },
  ],
  [
    PermissionEnum.AUTHORIZATION_BY_FILE_TYPE_EDIT,
    {
      Id: PermissionEnum.AUTHORIZATION_BY_FILE_TYPE_EDIT,
      label: 'Sửa phân quyền theo loại hồ sơ',
      value: PermissionEnum.AUTHORIZATION_BY_FILE_TYPE_EDIT,
      idParent: ParentPermissionEnum.AUTHORIZATION_BY_FILE_TYPE,
    },
  ],
  [
    PermissionEnum.AUTHORIZATION_BY_FILE_TYPE_DELETE,
    {
      Id: PermissionEnum.AUTHORIZATION_BY_FILE_TYPE_DELETE,
      label: 'Xóa phân quyền theo loại hồ sơ',
      value: PermissionEnum.AUTHORIZATION_BY_FILE_TYPE_DELETE,
      idParent: ParentPermissionEnum.AUTHORIZATION_BY_FILE_TYPE,
    },
  ],

  [
    PermissionEnum.MANAGEMENT_READERS_VIEW,
    {
      Id: PermissionEnum.MANAGEMENT_READERS_VIEW,
      label: 'Xem danh sách độc giả',
      value: PermissionEnum.MANAGEMENT_READERS_VIEW,
      idParent: ParentPermissionEnum.MANAGEMENT_READERS,
    },
  ],
  [
    PermissionEnum.MANAGEMENT_READERS_DETAIL,
    {
      Id: PermissionEnum.MANAGEMENT_READERS_DETAIL,
      label: 'Xem chi tiết độc giả',
      value: PermissionEnum.MANAGEMENT_READERS_DETAIL,
      idParent: ParentPermissionEnum.MANAGEMENT_READERS,
    },
  ],
  [
    PermissionEnum.MANAGEMENT_READERS_CREATE,
    {
      Id: PermissionEnum.MANAGEMENT_READERS_CREATE,
      label: 'Tạo độc giả',
      value: PermissionEnum.MANAGEMENT_READERS_CREATE,
      idParent: ParentPermissionEnum.MANAGEMENT_READERS,
    },
  ],
  [
    PermissionEnum.MANAGEMENT_READERS_EDIT,
    {
      Id: PermissionEnum.MANAGEMENT_READERS_EDIT,
      label: 'Sửa độc giả',
      value: PermissionEnum.MANAGEMENT_READERS_EDIT,
      idParent: ParentPermissionEnum.MANAGEMENT_READERS,
    },
  ],
  [
    PermissionEnum.MANAGEMENT_READERS_DELETE,
    {
      Id: PermissionEnum.MANAGEMENT_READERS_DELETE,
      label: 'Xóa độc giả',
      value: PermissionEnum.MANAGEMENT_READERS_DELETE,
      idParent: ParentPermissionEnum.MANAGEMENT_READERS,
    },
  ],
  [
    PermissionEnum.MANAGEMENT_READERS_EXPORT,
    {
      Id: PermissionEnum.MANAGEMENT_READERS_EXPORT,
      label: 'Xuất file độc giả',
      value: PermissionEnum.MANAGEMENT_READERS_EXPORT,
      idParent: ParentPermissionEnum.MANAGEMENT_READERS,
    },
  ],

  [
    PermissionEnum.PHONG_LUU_TRU_VIEW,
    {
      Id: PermissionEnum.PHONG_LUU_TRU_VIEW,
      label: 'Xem danh sách phông lưu trữ',
      value: PermissionEnum.PHONG_LUU_TRU_VIEW,
      idParent: ParentPermissionEnum.PHONG_LUU_TRU,
    },
  ],
  [
    PermissionEnum.PHONG_LUU_TRU_DETAIL,
    {
      Id: PermissionEnum.PHONG_LUU_TRU_DETAIL,
      label: 'Xem chi tiết phông lưu trữ',
      value: PermissionEnum.PHONG_LUU_TRU_DETAIL,
      idParent: ParentPermissionEnum.PHONG_LUU_TRU,
    },
  ],
  [
    PermissionEnum.PHONG_LUU_TRU_CREATE,
    {
      Id: PermissionEnum.PHONG_LUU_TRU_CREATE,
      label: 'Tạo phông lưu trữ',
      value: PermissionEnum.PHONG_LUU_TRU_CREATE,
      idParent: ParentPermissionEnum.PHONG_LUU_TRU,
    },
  ],
  [
    PermissionEnum.PHONG_LUU_TRU_EDIT,
    {
      Id: PermissionEnum.PHONG_LUU_TRU_EDIT,
      label: 'Sửa phông lưu trữ',
      value: PermissionEnum.PHONG_LUU_TRU_EDIT,
      idParent: ParentPermissionEnum.PHONG_LUU_TRU,
    },
  ],
  [
    PermissionEnum.PHONG_LUU_TRU_DELETE,
    {
      Id: PermissionEnum.PHONG_LUU_TRU_DELETE,
      label: 'Xóa phông lưu trữ',
      value: PermissionEnum.PHONG_LUU_TRU_DELETE,
      idParent: ParentPermissionEnum.PHONG_LUU_TRU,
    },
  ],
  [
    PermissionEnum.PHONG_LUU_TRU_EXPORT,
    {
      Id: PermissionEnum.PHONG_LUU_TRU_EXPORT,
      label: 'Xuất file phông lưu trữ',
      value: PermissionEnum.PHONG_LUU_TRU_EXPORT,
      idParent: ParentPermissionEnum.PHONG_LUU_TRU,
    },
  ],

  [
    PermissionEnum.CATALOGUE_VIEW,
    {
      Id: PermissionEnum.CATALOGUE_VIEW,
      label: 'Xem danh sách mục lục',
      value: PermissionEnum.CATALOGUE_VIEW,
      idParent: ParentPermissionEnum.CATALOGUE,
    },
  ],
  [
    PermissionEnum.CATALOGUE_DETAIL,
    {
      Id: PermissionEnum.CATALOGUE_DETAIL,
      label: 'Xem chi tiết mục lục',
      value: PermissionEnum.CATALOGUE_DETAIL,
      idParent: ParentPermissionEnum.CATALOGUE,
    },
  ],
  [
    PermissionEnum.CATALOGUE_CREATE,
    {
      Id: PermissionEnum.CATALOGUE_CREATE,
      label: 'Tạo mục lục',
      value: PermissionEnum.CATALOGUE_CREATE,
      idParent: ParentPermissionEnum.CATALOGUE,
    },
  ],
  [
    PermissionEnum.CATALOGUE_EDIT,
    {
      Id: PermissionEnum.CATALOGUE_EDIT,
      label: 'Sửa mục lục',
      value: PermissionEnum.CATALOGUE_EDIT,
      idParent: ParentPermissionEnum.CATALOGUE,
    },
  ],
  [
    PermissionEnum.CATALOGUE_DELETE,
    {
      Id: PermissionEnum.CATALOGUE_DELETE,
      label: 'Xóa mục lục',
      value: PermissionEnum.CATALOGUE_DELETE,
      idParent: ParentPermissionEnum.CATALOGUE,
    },
  ],
  [
    PermissionEnum.CATALOGUE_EXPORT,
    {
      Id: PermissionEnum.CATALOGUE_EXPORT,
      label: 'Xuất file mục lục',
      value: PermissionEnum.CATALOGUE_EXPORT,
      idParent: ParentPermissionEnum.CATALOGUE,
    },
  ],
  [
    PermissionEnum.CATALOGUE_CREATE_LIST,
    {
      Id: PermissionEnum.CATALOGUE_CREATE_LIST,
      label: 'Tạo list mục lục',
      value: PermissionEnum.CATALOGUE_CREATE_LIST,
      idParent: ParentPermissionEnum.CATALOGUE,
    },
  ],

  [
    PermissionEnum.ARCHIVES_VIEW,
    {
      Id: PermissionEnum.ARCHIVES_VIEW,
      label: 'Xem danh sách kho',
      value: PermissionEnum.ARCHIVES_VIEW,
      idParent: ParentPermissionEnum.ARCHIVES,
    },
  ],
  [
    PermissionEnum.ARCHIVES_DETAIL,
    {
      Id: PermissionEnum.ARCHIVES_DETAIL,
      label: 'Xem chi tiết kho',
      value: PermissionEnum.ARCHIVES_DETAIL,
      idParent: ParentPermissionEnum.ARCHIVES,
    },
  ],
  [
    PermissionEnum.ARCHIVES_CREATE,
    {
      Id: PermissionEnum.ARCHIVES_CREATE,
      label: 'Tạo kho',
      value: PermissionEnum.ARCHIVES_CREATE,
      idParent: ParentPermissionEnum.ARCHIVES,
    },
  ],
  [
    PermissionEnum.ARCHIVES_EDIT,
    {
      Id: PermissionEnum.ARCHIVES_EDIT,
      label: 'Sửa kho',
      value: PermissionEnum.ARCHIVES_EDIT,
      idParent: ParentPermissionEnum.ARCHIVES,
    },
  ],
  [
    PermissionEnum.ARCHIVES_DELETE,
    {
      Id: PermissionEnum.ARCHIVES_DELETE,
      label: 'Xóa kho',
      value: PermissionEnum.ARCHIVES_DELETE,
      idParent: ParentPermissionEnum.ARCHIVES,
    },
  ],
  [
    PermissionEnum.ARCHIVES_EXPORT,
    {
      Id: PermissionEnum.ARCHIVES_EXPORT,
      label: 'Xuất file kho',
      value: PermissionEnum.ARCHIVES_EXPORT,
      idParent: ParentPermissionEnum.ARCHIVES,
    },
  ],

  [
    PermissionEnum.SHELF_VIEW,
    {
      Id: PermissionEnum.SHELF_VIEW,
      label: 'Xem danh sách giá/kệ',
      value: PermissionEnum.SHELF_VIEW,
      idParent: ParentPermissionEnum.SHELF,
    },
  ],
  [
    PermissionEnum.SHELF_DETAIL,
    {
      Id: PermissionEnum.SHELF_DETAIL,
      label: 'Xem chi tiết giá/kệ',
      value: PermissionEnum.SHELF_DETAIL,
      idParent: ParentPermissionEnum.SHELF,
    },
  ],
  [
    PermissionEnum.SHELF_CREATE,
    {
      Id: PermissionEnum.SHELF_CREATE,
      label: 'Tạo giá/kệ',
      value: PermissionEnum.SHELF_CREATE,
      idParent: ParentPermissionEnum.SHELF,
    },
  ],
  [
    PermissionEnum.SHELF_EDIT,
    {
      Id: PermissionEnum.SHELF_EDIT,
      label: 'Sửa giá/kệ',
      value: PermissionEnum.SHELF_EDIT,
      idParent: ParentPermissionEnum.SHELF,
    },
  ],
  [
    PermissionEnum.SHELF_DELETE,
    {
      Id: PermissionEnum.SHELF_DELETE,
      label: 'Xóa giá/kệ',
      value: PermissionEnum.SHELF_DELETE,
      idParent: ParentPermissionEnum.SHELF,
    },
  ],
  [
    PermissionEnum.SHELF_EXPORT,
    {
      Id: PermissionEnum.SHELF_EXPORT,
      label: 'Xuất file giá/kệ',
      value: PermissionEnum.SHELF_EXPORT,
      idParent: ParentPermissionEnum.SHELF,
    },
  ],

  [
    PermissionEnum.BOX_VIEW,
    {
      Id: PermissionEnum.BOX_VIEW,
      label: 'Xem danh sách hộp/cặp',
      value: PermissionEnum.BOX_VIEW,
      idParent: ParentPermissionEnum.BOX,
    },
  ],
  [
    PermissionEnum.BOX_DETAIL,
    {
      Id: PermissionEnum.BOX_DETAIL,
      label: 'Xem chi tiết hộp/cặp',
      value: PermissionEnum.BOX_DETAIL,
      idParent: ParentPermissionEnum.BOX,
    },
  ],
  [
    PermissionEnum.BOX_CREATE,
    {
      Id: PermissionEnum.BOX_CREATE,
      label: 'Tạo hộp/cặp',
      value: PermissionEnum.BOX_CREATE,
      idParent: ParentPermissionEnum.BOX,
    },
  ],
  [
    PermissionEnum.BOX_EDIT,
    {
      Id: PermissionEnum.BOX_EDIT,
      label: 'Sửa hộp/cặp',
      value: PermissionEnum.BOX_EDIT,
      idParent: ParentPermissionEnum.BOX,
    },
  ],
  [
    PermissionEnum.BOX_DELETE,
    {
      Id: PermissionEnum.BOX_DELETE,
      label: 'Xóa hộp/cặp',
      value: PermissionEnum.BOX_DELETE,
      idParent: ParentPermissionEnum.BOX,
    },
  ],
  [
    PermissionEnum.BOX_EXPORT,
    {
      Id: PermissionEnum.BOX_EXPORT,
      label: 'Xuất file hộp/cặp',
      value: PermissionEnum.BOX_EXPORT,
      idParent: ParentPermissionEnum.BOX,
    },
  ],

  [
    PermissionEnum.PROFILE_TYPE_VIEW,
    {
      Id: PermissionEnum.PROFILE_TYPE_VIEW,
      label: 'Xem danh sách loại hồ sơ',
      value: PermissionEnum.PROFILE_TYPE_VIEW,
      idParent: ParentPermissionEnum.PROFILE_TYPE,
    },
  ],
  [
    PermissionEnum.PROFILE_TYPE_DETAIL,
    {
      Id: PermissionEnum.PROFILE_TYPE_DETAIL,
      label: 'Xem chi tiết loại hồ sơ',
      value: PermissionEnum.PROFILE_TYPE_DETAIL,
      idParent: ParentPermissionEnum.PROFILE_TYPE,
    },
  ],
  [
    PermissionEnum.PROFILE_TYPE_CREATE,
    {
      Id: PermissionEnum.PROFILE_TYPE_CREATE,
      label: 'Tạo loại hồ sơ',
      value: PermissionEnum.PROFILE_TYPE_CREATE,
      idParent: ParentPermissionEnum.PROFILE_TYPE,
    },
  ],
  [
    PermissionEnum.PROFILE_TYPE_EDIT,
    {
      Id: PermissionEnum.PROFILE_TYPE_EDIT,
      label: 'Sửa loại hồ sơ',
      value: PermissionEnum.PROFILE_TYPE_EDIT,
      idParent: ParentPermissionEnum.PROFILE_TYPE,
    },
  ],
  [
    PermissionEnum.PROFILE_TYPE_DELETE,
    {
      Id: PermissionEnum.PROFILE_TYPE_DELETE,
      label: 'Xóa loại hồ sơ',
      value: PermissionEnum.PROFILE_TYPE_DELETE,
      idParent: ParentPermissionEnum.PROFILE_TYPE,
    },
  ],
  [
    PermissionEnum.PROFILE_TYPE_EXPORT,
    {
      Id: PermissionEnum.PROFILE_TYPE_EXPORT,
      label: 'Xuất file loại hồ sơ',
      value: PermissionEnum.PROFILE_TYPE_EXPORT,
      idParent: ParentPermissionEnum.PROFILE_TYPE,
    },
  ],

  [
    PermissionEnum.LEVEL_DOCUMENT_MINING_MGMT_VIEW,
    {
      Id: PermissionEnum.LEVEL_DOCUMENT_MINING_MGMT_VIEW,
      label: 'Xem danh sách hồ sơ khai thác',
      value: PermissionEnum.LEVEL_DOCUMENT_MINING_MGMT_VIEW,
      idParent: ParentPermissionEnum.LEVEL_DOCUMENT_MINING_MANAGEMENT,
    },
  ],
  [
    PermissionEnum.LEVEL_DOCUMENT_MINING_MGMT_DETAIL,
    {
      Id: PermissionEnum.LEVEL_DOCUMENT_MINING_MGMT_DETAIL,
      label: 'Xem chi tiết hồ sơ khai thác',
      value: PermissionEnum.LEVEL_DOCUMENT_MINING_MGMT_DETAIL,
      idParent: ParentPermissionEnum.LEVEL_DOCUMENT_MINING_MANAGEMENT,
    },
  ],
  [
    PermissionEnum.LEVEL_DOCUMENT_MINING_MGMT_DETAIL_DOC_COMP,
    {
      Id: PermissionEnum.LEVEL_DOCUMENT_MINING_MGMT_DETAIL_DOC_COMP,
      label: 'Xem chi tiết tài liệu',
      value: PermissionEnum.LEVEL_DOCUMENT_MINING_MGMT_DETAIL_DOC_COMP,
      idParent: ParentPermissionEnum.LEVEL_DOCUMENT_MINING_MANAGEMENT,
    },
  ],
  [
    PermissionEnum.LEVEL_DOCUMENT_MINING_MGMT_MINING,
    {
      Id: PermissionEnum.LEVEL_DOCUMENT_MINING_MGMT_MINING,
      label: 'Cập nhật mức độ khai thác',
      value: PermissionEnum.LEVEL_DOCUMENT_MINING_MGMT_MINING,
      idParent: ParentPermissionEnum.LEVEL_DOCUMENT_MINING_MANAGEMENT,
    },
  ],

  [
    PermissionEnum.DOCUMENT_COMPONENT_MINING_VIEW,
    {
      Id: PermissionEnum.DOCUMENT_COMPONENT_MINING_VIEW,
      label: 'Xem danh sách tài liệu khai thác',
      value: PermissionEnum.DOCUMENT_COMPONENT_MINING_VIEW,
      idParent: ParentPermissionEnum.DOCUMENT_COMPONENT_MINING,
    },
  ],
  [
    PermissionEnum.DOCUMENT_COMPONENT_MINING_DETAIL,
    {
      Id: PermissionEnum.DOCUMENT_COMPONENT_MINING_DETAIL,
      label: 'Xem chi tiết tài liệu',
      value: PermissionEnum.DOCUMENT_COMPONENT_MINING_DETAIL,
      idParent: ParentPermissionEnum.DOCUMENT_COMPONENT_MINING,
    },
  ],
  [
    PermissionEnum.DOCUMENT_COMPONENT_MINING_DETAIL_DOC_COMP,
    {
      Id: PermissionEnum.DOCUMENT_COMPONENT_MINING_DETAIL_DOC_COMP,
      label: 'Xem chi tiết hồ sơ',
      value: PermissionEnum.DOCUMENT_COMPONENT_MINING_DETAIL_DOC_COMP,
      idParent: ParentPermissionEnum.DOCUMENT_COMPONENT_MINING,
    },
  ],
  [
    PermissionEnum.DOCUMENT_COMPONENT_MINING_BORROW_DOC,
    {
      Id: PermissionEnum.DOCUMENT_COMPONENT_MINING_BORROW_DOC,
      label: 'Mượn hồ sơ',
      value: PermissionEnum.DOCUMENT_COMPONENT_MINING_BORROW_DOC,
      idParent: ParentPermissionEnum.DOCUMENT_COMPONENT_MINING,
    },
  ],
  [
    PermissionEnum.DOCUMENT_COMPONENT_MINING_BORROW_DOC_COMP,
    {
      Id: PermissionEnum.DOCUMENT_COMPONENT_MINING_BORROW_DOC_COMP,
      label: 'Mượn tài liệu',
      value: PermissionEnum.DOCUMENT_COMPONENT_MINING_BORROW_DOC_COMP,
      idParent: ParentPermissionEnum.DOCUMENT_COMPONENT_MINING,
    },
  ],

  [
    PermissionEnum.DOCUMENT_MINING_TICKET_VIEW,
    {
      Id: PermissionEnum.DOCUMENT_MINING_TICKET_VIEW,
      label: 'Xem danh sách phiếu mượn',
      value: PermissionEnum.DOCUMENT_MINING_TICKET_VIEW,
      idParent: ParentPermissionEnum.DOCUMENT_MINING_TICKET,
    },
  ],
  [
    PermissionEnum.DOCUMENT_MINING_TICKET_DETAIL,
    {
      Id: PermissionEnum.DOCUMENT_MINING_TICKET_DETAIL,
      label: 'Xem chi tiết phiếu mượn',
      value: PermissionEnum.DOCUMENT_MINING_TICKET_DETAIL,
      idParent: ParentPermissionEnum.DOCUMENT_MINING_TICKET,
    },
  ],
  [
    PermissionEnum.DOCUMENT_MINING_TICKET_DETAIL_DOC,
    {
      Id: PermissionEnum.DOCUMENT_MINING_TICKET_DETAIL_DOC,
      label: 'Xem chi tiết hồ sơ',
      value: PermissionEnum.DOCUMENT_MINING_TICKET_DETAIL_DOC,
      idParent: ParentPermissionEnum.DOCUMENT_MINING_TICKET,
    },
  ],
  [
    PermissionEnum.DOCUMENT_MINING_TICKET_DETAIL_DOC_COMP,
    {
      Id: PermissionEnum.DOCUMENT_MINING_TICKET_DETAIL_DOC_COMP,
      label: 'Xem chi tiết tài liệu',
      value: PermissionEnum.DOCUMENT_MINING_TICKET_DETAIL_DOC_COMP,
      idParent: ParentPermissionEnum.DOCUMENT_MINING_TICKET,
    },
  ],
  [
    PermissionEnum.DOCUMENT_MINING_TICKET_CANCEL,
    {
      Id: PermissionEnum.DOCUMENT_MINING_TICKET_CANCEL,
      label: 'Hủy phiếu',
      value: PermissionEnum.DOCUMENT_MINING_TICKET_CANCEL,
      idParent: ParentPermissionEnum.DOCUMENT_MINING_TICKET,
    },
  ],

  [
    PermissionEnum.REVIEW_DOCUMENT_MINING_TICKET_VIEW,
    {
      Id: PermissionEnum.REVIEW_DOCUMENT_MINING_TICKET_VIEW,
      label: 'Xem danh sách phiếu mượn',
      value: PermissionEnum.REVIEW_DOCUMENT_MINING_TICKET_VIEW,
      idParent: ParentPermissionEnum.REVIEW_DOCUMENT_MINING_TICKET,
    },
  ],
  [
    PermissionEnum.REVIEW_DOCUMENT_MINING_TICKET_DETAIL,
    {
      Id: PermissionEnum.REVIEW_DOCUMENT_MINING_TICKET_DETAIL,
      label: 'Xem chi tiết phiếu mượn',
      value: PermissionEnum.REVIEW_DOCUMENT_MINING_TICKET_DETAIL,
      idParent: ParentPermissionEnum.REVIEW_DOCUMENT_MINING_TICKET,
    },
  ],
  [
    PermissionEnum.REVIEW_DOCUMENT_MINING_TICKET_DETAIL_DOC,
    {
      Id: PermissionEnum.REVIEW_DOCUMENT_MINING_TICKET_DETAIL_DOC,
      label: 'Xem chi tiết hồ sơ',
      value: PermissionEnum.REVIEW_DOCUMENT_MINING_TICKET_DETAIL_DOC,
      idParent: ParentPermissionEnum.REVIEW_DOCUMENT_MINING_TICKET,
    },
  ],
  [
    PermissionEnum.REVIEW_DOCUMENT_MINING_TICKET_DETAIL_DOC_COMP,
    {
      Id: PermissionEnum.REVIEW_DOCUMENT_MINING_TICKET_DETAIL_DOC_COMP,
      label: 'Xem chi tiết tài liệu',
      value: PermissionEnum.REVIEW_DOCUMENT_MINING_TICKET_DETAIL_DOC_COMP,
      idParent: ParentPermissionEnum.REVIEW_DOCUMENT_MINING_TICKET,
    },
  ],
  [
    PermissionEnum.REVIEW_DOCUMENT_MINING_TICKET_REFUSE,
    {
      Id: PermissionEnum.REVIEW_DOCUMENT_MINING_TICKET_REFUSE,
      label: 'Từ chối',
      value: PermissionEnum.REVIEW_DOCUMENT_MINING_TICKET_REFUSE,
      idParent: ParentPermissionEnum.REVIEW_DOCUMENT_MINING_TICKET,
    },
  ],
  [
    PermissionEnum.REVIEW_DOCUMENT_MINING_TICKET_APPROVE,
    {
      Id: PermissionEnum.REVIEW_DOCUMENT_MINING_TICKET_APPROVE,
      label: 'Phê duyệt',
      value: PermissionEnum.REVIEW_DOCUMENT_MINING_TICKET_APPROVE,
      idParent: ParentPermissionEnum.REVIEW_DOCUMENT_MINING_TICKET,
    },
  ],

  [
    PermissionEnum.REVIEW_UNIT_VIEW,
    {
      Id: PermissionEnum.REVIEW_UNIT_VIEW,
      label: 'Xem danh sách phiếu mượn',
      value: PermissionEnum.REVIEW_UNIT_VIEW,
      idParent: ParentPermissionEnum.REVIEW_UNIT,
    },
  ],
  [
    PermissionEnum.REVIEW_UNIT_DETAIL,
    {
      Id: PermissionEnum.REVIEW_UNIT_DETAIL,
      label: 'Xem chi tiết phiếu mượn',
      value: PermissionEnum.REVIEW_UNIT_DETAIL,
      idParent: ParentPermissionEnum.REVIEW_UNIT,
    },
  ],
  [
    PermissionEnum.REVIEW_UNIT_DETAIL_DOC,
    {
      Id: PermissionEnum.REVIEW_UNIT_DETAIL_DOC,
      label: 'Xem chi tiết hồ sơ',
      value: PermissionEnum.REVIEW_UNIT_DETAIL_DOC,
      idParent: ParentPermissionEnum.REVIEW_UNIT,
    },
  ],
  [
    PermissionEnum.REVIEW_UNIT_DETAIL_DOC_COMP,
    {
      Id: PermissionEnum.REVIEW_UNIT_DETAIL_DOC_COMP,
      label: 'Xem chi tiết tài liệu',
      value: PermissionEnum.REVIEW_UNIT_DETAIL_DOC_COMP,
      idParent: ParentPermissionEnum.REVIEW_UNIT,
    },
  ],
  [
    PermissionEnum.REVIEW_UNIT_REFUSE,
    {
      Id: PermissionEnum.REVIEW_UNIT_REFUSE,
      label: 'Từ chối',
      value: PermissionEnum.REVIEW_UNIT_REFUSE,
      idParent: ParentPermissionEnum.REVIEW_UNIT,
    },
  ],
  [
    PermissionEnum.REVIEW_UNIT_APPROVE,
    {
      Id: PermissionEnum.REVIEW_UNIT_APPROVE,
      label: 'Phê duyệt',
      value: PermissionEnum.REVIEW_UNIT_APPROVE,
      idParent: ParentPermissionEnum.REVIEW_UNIT,
    },
  ],

  [
    PermissionEnum.SUBMIT_HISTORY_MANAGEMENT_VIEW,
    {
      Id: PermissionEnum.SUBMIT_HISTORY_MANAGEMENT_VIEW,
      label: 'Xem danh sách hồ sơ',
      value: PermissionEnum.SUBMIT_HISTORY_MANAGEMENT_VIEW,
      idParent: ParentPermissionEnum.SUBMIT_HISTORY_MANAGEMENT,
    },
  ],
  [
    PermissionEnum.SUBMIT_HISTORY_MANAGEMENT_DETAIL,
    {
      Id: PermissionEnum.SUBMIT_HISTORY_MANAGEMENT_DETAIL,
      label: 'Xem chi tiết hồ sơ',
      value: PermissionEnum.SUBMIT_HISTORY_MANAGEMENT_DETAIL,
      idParent: ParentPermissionEnum.SUBMIT_HISTORY_MANAGEMENT,
    },
  ],
  [
    PermissionEnum.SUBMIT_HISTORY_MANAGEMENT_DETAIL_DOC_COMP,
    {
      Id: PermissionEnum.SUBMIT_HISTORY_MANAGEMENT_DETAIL_DOC_COMP,
      label: 'Xem chi tiết tài liệu',
      value: PermissionEnum.SUBMIT_HISTORY_MANAGEMENT_DETAIL_DOC_COMP,
      idParent: ParentPermissionEnum.SUBMIT_HISTORY_MANAGEMENT,
    },
  ],
  [
    PermissionEnum.SUBMIT_HISTORY_MANAGEMENT_EXPORT,
    {
      Id: PermissionEnum.SUBMIT_HISTORY_MANAGEMENT_EXPORT,
      label: 'Xuất file hồ sơ',
      value: PermissionEnum.SUBMIT_HISTORY_MANAGEMENT_EXPORT,
      idParent: ParentPermissionEnum.SUBMIT_HISTORY_MANAGEMENT,
    },
  ],
  [
    PermissionEnum.SUBMIT_HISTORY_MANAGEMENT_SEND,
    {
      Id: PermissionEnum.SUBMIT_HISTORY_MANAGEMENT_SEND,
      label: 'Gửi duyệt',
      value: PermissionEnum.SUBMIT_HISTORY_MANAGEMENT_SEND,
      idParent: ParentPermissionEnum.SUBMIT_HISTORY_MANAGEMENT,
    },
  ],

  [
    PermissionEnum.REVIEW_SUBMIT_HISTORY_VIEW,
    {
      Id: PermissionEnum.REVIEW_SUBMIT_HISTORY_VIEW,
      label: 'Xem danh sách hồ sơ',
      value: PermissionEnum.REVIEW_SUBMIT_HISTORY_VIEW,
      idParent: ParentPermissionEnum.REVIEW_SUBMIT_HISTORY,
    },
  ],
  [
    PermissionEnum.REVIEW_SUBMIT_HISTORY_DETAIL,
    {
      Id: PermissionEnum.REVIEW_SUBMIT_HISTORY_DETAIL,
      label: 'Xem chi tiết hồ sơ',
      value: PermissionEnum.REVIEW_SUBMIT_HISTORY_DETAIL,
      idParent: ParentPermissionEnum.REVIEW_SUBMIT_HISTORY,
    },
  ],
  [
    PermissionEnum.REVIEW_SUBMIT_HISTORY_DETAIL_DOC_COMP,
    {
      Id: PermissionEnum.REVIEW_SUBMIT_HISTORY_DETAIL_DOC_COMP,
      label: 'Xem chi tiết tài liệu',
      value: PermissionEnum.REVIEW_SUBMIT_HISTORY_DETAIL_DOC_COMP,
      idParent: ParentPermissionEnum.REVIEW_SUBMIT_HISTORY,
    },
  ],
  [
    PermissionEnum.REVIEW_SUBMIT_HISTORY_EXPORT,
    {
      Id: PermissionEnum.REVIEW_SUBMIT_HISTORY_EXPORT,
      label: 'Xuất file hồ sơ',
      value: PermissionEnum.REVIEW_SUBMIT_HISTORY_EXPORT,
      idParent: ParentPermissionEnum.REVIEW_SUBMIT_HISTORY,
    },
  ],
  [
    PermissionEnum.REVIEW_SUBMIT_HISTORY_REFUSE,
    {
      Id: PermissionEnum.REVIEW_SUBMIT_HISTORY_REFUSE,
      label: 'Từ chối',
      value: PermissionEnum.REVIEW_SUBMIT_HISTORY_REFUSE,
      idParent: ParentPermissionEnum.REVIEW_SUBMIT_HISTORY,
    },
  ],
  [
    PermissionEnum.REVIEW_SUBMIT_HISTORY_APPROVE,
    {
      Id: PermissionEnum.REVIEW_SUBMIT_HISTORY_APPROVE,
      label: 'Phê duyệt',
      value: PermissionEnum.REVIEW_SUBMIT_HISTORY_APPROVE,
      idParent: ParentPermissionEnum.REVIEW_SUBMIT_HISTORY,
    },
  ],

  [
    PermissionEnum.SUBMITTED_HISTORY_VIEW,
    {
      Id: PermissionEnum.SUBMITTED_HISTORY_VIEW,
      label: 'Xem danh sách hồ sơ',
      value: PermissionEnum.SUBMITTED_HISTORY_VIEW,
      idParent: ParentPermissionEnum.SUBMITTED_HISTORY,
    },
  ],
  [
    PermissionEnum.SUBMITTED_HISTORY_DETAIL,
    {
      Id: PermissionEnum.SUBMITTED_HISTORY_DETAIL,
      label: 'Xem chi tiết hồ sơ',
      value: PermissionEnum.SUBMITTED_HISTORY_DETAIL,
      idParent: ParentPermissionEnum.SUBMITTED_HISTORY,
    },
  ],
  [
    PermissionEnum.SUBMITTED_HISTORY_DETAIL_DOC_COMP,
    {
      Id: PermissionEnum.SUBMITTED_HISTORY_DETAIL_DOC_COMP,
      label: 'Xem chi tài liệu',
      value: PermissionEnum.SUBMITTED_HISTORY_DETAIL_DOC_COMP,
      idParent: ParentPermissionEnum.SUBMITTED_HISTORY,
    },
  ],
  [
    PermissionEnum.SUBMITTED_HISTORY_EXPORT,
    {
      Id: PermissionEnum.SUBMITTED_HISTORY_EXPORT,
      label: 'Xuất file hồ sơ',
      value: PermissionEnum.SUBMITTED_HISTORY_EXPORT,
      idParent: ParentPermissionEnum.SUBMITTED_HISTORY,
    },
  ],

  [
    PermissionEnum.DIGITAL_STORAGE_INVENTORY_STATUS_VIEW,
    {
      Id: PermissionEnum.DIGITAL_STORAGE_INVENTORY_STATUS_VIEW,
      label: 'Xem hiện trạng số hóa',
      value: PermissionEnum.DIGITAL_STORAGE_INVENTORY_STATUS_VIEW,
      idParent: ParentPermissionEnum.DIGITAL_STORAGE_INVENTORY_STATUS,
    },
  ],
  [
    PermissionEnum.BACKPACK_STATISTICS_VIEW,
    {
      Id: PermissionEnum.BACKPACK_STATISTICS_VIEW,
      label: 'Xem danh sách thống kê hộp/cặp',
      value: PermissionEnum.BACKPACK_STATISTICS_VIEW,
      idParent: ParentPermissionEnum.BACKPACK_STATISTICS,
    },
  ],
  [
    PermissionEnum.BACKPACK_STATISTICS_EXPORT,
    {
      Id: PermissionEnum.BACKPACK_STATISTICS_EXPORT,
      label: 'Xuất file thống kê hộp cặp',
      value: PermissionEnum.BACKPACK_STATISTICS_EXPORT,
      idParent: ParentPermissionEnum.BACKPACK_STATISTICS,
    },
  ],
  [
    PermissionEnum.MINING_STATISTICS_VIEW,
    {
      Id: PermissionEnum.MINING_STATISTICS_VIEW,
      label: 'Xem danh sách thống kê khai thác',
      value: PermissionEnum.MINING_STATISTICS_VIEW,
      idParent: ParentPermissionEnum.MINING_STATISTICS,
    },
  ],
  [
    PermissionEnum.MINING_STATISTICS_DETAIL,
    {
      Id: PermissionEnum.MINING_STATISTICS_DETAIL,
      label: 'Xem chi tiết hồ sơ khai thác',
      value: PermissionEnum.MINING_STATISTICS_DETAIL,
      idParent: ParentPermissionEnum.MINING_STATISTICS,
    },
  ],
  [
    PermissionEnum.MINING_STATISTICS_EXPORT,
    {
      Id: PermissionEnum.MINING_STATISTICS_EXPORT,
      label: 'Xuất báo cáo',
      value: PermissionEnum.MINING_STATISTICS_EXPORT,
      idParent: ParentPermissionEnum.MINING_STATISTICS,
    },
  ],
  [
    PermissionEnum.MINING_STATISTICS_EXPORT_DETAIL,
    {
      Id: PermissionEnum.MINING_STATISTICS_EXPORT_DETAIL,
      label: 'Xuất báo cáo chi tiết',
      value: PermissionEnum.MINING_STATISTICS_EXPORT_DETAIL,
      idParent: ParentPermissionEnum.MINING_STATISTICS,
    },
  ],
  [
    PermissionEnum.SORT_BOX_VIEW,
    {
      Id: PermissionEnum.SORT_BOX_VIEW,
      label: 'Xem danh sách hồ sơ',
      value: PermissionEnum.SORT_BOX_VIEW,
      idParent: ParentPermissionEnum.SORT_BOX,
    },
  ],
  [
    PermissionEnum.SORT_BOX_SORT,
    {
      Id: PermissionEnum.SORT_BOX_SORT,
      label: 'Xếp hộp cặp',
      value: PermissionEnum.SORT_BOX_SORT,
      idParent: ParentPermissionEnum.SORT_BOX,
    },
  ],
  [
    PermissionEnum.SORT_BOX_UNSORT,
    {
      Id: PermissionEnum.SORT_BOX_UNSORT,
      label: 'Bỏ xếp hộp cặp',
      value: PermissionEnum.SORT_BOX_UNSORT,
      idParent: ParentPermissionEnum.SORT_BOX,
    },
  ],
  [
    PermissionEnum.SORT_BOX_VIEW_CREATE,
    {
      Id: PermissionEnum.SORT_BOX_VIEW_CREATE,
      label: 'Tạo hộp cặp mới',
      value: PermissionEnum.SORT_BOX_VIEW_CREATE,
      idParent: ParentPermissionEnum.SORT_BOX,
    },
  ],
  [
    PermissionEnum.SYSTEM_LOGS_VIEW,
    {
      Id: PermissionEnum.SYSTEM_LOGS_VIEW,
      label: 'Xem danh sách nhật ký hệ thống',
      value: PermissionEnum.SYSTEM_LOGS_VIEW,
      idParent: ParentPermissionEnum.SYSTEM_LOGS,
    },
  ],
  [
    PermissionEnum.SYSTEM_LOGS_DETAIL,
    {
      Id: PermissionEnum.SYSTEM_LOGS_DETAIL,
      label: 'Xem chi tiết nhật ký hệ thống',
      value: PermissionEnum.SYSTEM_LOGS_DETAIL,
      idParent: ParentPermissionEnum.SYSTEM_LOGS,
    },
  ],
]);

export const getPermissionEnumValue = () => {
  return Object.values(PermissionEnum)
    .filter((item) => typeof item === 'number')
    .map((item: any) => PermissionEnumValue.get(item));
};

export const getParentPermissionEnumValue = () => {
  return Object.values(ParentPermissionEnum)
    .filter((item) => typeof item === 'number')
    .map((item: any) => ParentPermissionEnumValue.get(item));
};

export const getScreenPermissionEnumValue = () => {
  return Object.values(ScreenPermissionEnum)
    .filter((item) => typeof item === 'number')
    .map((item: any) => ScreenPermissionEnumValue.get(item));
};

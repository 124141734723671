import { WithPermission } from 'components';
import { PATH_NAME } from 'core/constants/path-name';
import { PermissionEnum } from 'core/enums/PermissionEnum.enum';
import { lazy, Suspense } from 'react';

const PrivateDirectory = lazy(() => import('pages/PrivateDirectory/PrivateDirectory'));
const CatalogueList = lazy(() => import('pages/PrivateDirectory/Children/Catalogue/CatalogueList'));
const ArchivesList = lazy(() => import('pages/PrivateDirectory/Children/Archives/ArchivesList'));
const BoxList = lazy(() => import('pages/PrivateDirectory/Children/Box/BoxList'));
const ProfileTypeList = lazy(() => import('pages/PrivateDirectory/Children/ProfileType/ProfileTypeList'));
const ShelfList = lazy(() => import('pages/PrivateDirectory/Children/Shelf/ShelfList'));
const PhongLuuTruList = lazy(() => import('pages/PrivateDirectory/Children/PhongLuuTru/PhongLuuTruList'));

const PhongLuuTruListPage = WithPermission(PhongLuuTruList);
const CatalogueListPage = WithPermission(CatalogueList);
const ArchivesListPage = WithPermission(ArchivesList);
const BoxListPage = WithPermission(BoxList);
const ProfileTypeListPage = WithPermission(ProfileTypeList);
const ShelfListPage = WithPermission(ShelfList);
const PRIVATE_DIRECTORY = {
  path: `${PATH_NAME.PRIVATE_DIRECTORY.BASE}`,
  element: (
    <Suspense fallback={'Đang tải...'}>
      <PrivateDirectory />
    </Suspense>
  ),
  children: [
    {
      index: true,
      element: (
        <Suspense fallback={'Đang tải...'}>
          <PhongLuuTruListPage requiredPermissions={[PermissionEnum.PHONG_LUU_TRU_VIEW]} />
        </Suspense>
      ),
    },
    {
      path: 'phong-luu-tru',
      element: (
        <Suspense fallback={'Đang tải...'}>
          <PhongLuuTruListPage requiredPermissions={[PermissionEnum.PHONG_LUU_TRU_VIEW]} />
        </Suspense>
      ),
    },
    {
      path: 'danh-sach-muc-luc',
      element: (
        <Suspense fallback={'Đang tải...'}>
          <CatalogueListPage requiredPermissions={[PermissionEnum.CATALOGUE_VIEW]} />
        </Suspense>
      ),
    },
    {
      path: 'loai-ho-so',
      element: (
        <Suspense fallback={'Đang tải...'}>
          <ProfileTypeListPage requiredPermissions={[PermissionEnum.PROFILE_TYPE_VIEW]} />
        </Suspense>
      ),
    },
    {
      path: 'kho',
      element: (
        <Suspense fallback={'Đang tải...'}>
          <ArchivesListPage requiredPermissions={[PermissionEnum.ARCHIVES_VIEW]} />
        </Suspense>
      ),
    },
    {
      path: 'gia-ke',
      element: (
        <Suspense fallback={'Đang tải...'}>
          <ShelfListPage requiredPermissions={[PermissionEnum.SHELF_VIEW]} />
        </Suspense>
      ),
    },
    {
      path: 'hop-cap',
      element: (
        <Suspense fallback={'Đang tải...'}>
          <BoxListPage requiredPermissions={[PermissionEnum.BOX_VIEW]} />
        </Suspense>
      ),
    },
  ],
};

export default PRIVATE_DIRECTORY;

import { getStatusBorrowTicketValue } from 'core/enums/status-borrow-ticket.enum';
import { useMemo } from 'react';

const useGetStatusBorrowTicket = () => {
  const BorrowTicketEnumValue = getStatusBorrowTicketValue();
  const dataSource = useMemo(
    () =>
      BorrowTicketEnumValue.map((item) => ({
        label: item?.label as string,
        value: item?.value as unknown as string,
      })),
    [],
  );

  return { dataSource };
};

export default useGetStatusBorrowTicket;

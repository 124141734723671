import { WithPermission } from 'components';
import { PATH_NAME } from 'core/constants/path-name';
import { PermissionEnum } from 'core/enums/PermissionEnum.enum';
import { lazy, Suspense } from 'react';

const DestroyDocument = lazy(() => import('pages/DestroyDocument/DestroyDocument'));

// Danh sách hồ sơ chờ tiêu hủy
const ReviewDestroyDocumentList = lazy(
  () => import('pages/DestroyDocument/Children/ReviewDestroyDocument/ReviewDestroyDocumentList'),
);
const ReviewDestroyDocumentListPage = WithPermission(ReviewDestroyDocumentList);
const ReviewDestroyDocumentLayout = lazy(
  () => import('pages/DestroyDocument/Children/ReviewDestroyDocument/ReviewDestroyDocumentLayout'),
);
const AddDestroyDocument = lazy(
  () => import('pages/DestroyDocument/Children/ReviewDestroyDocument/AddDestroyDocument'),
);
const AddDestroyDocumentPage = WithPermission(AddDestroyDocument);
const ReviewDestroyDocumentDetail = lazy(
  () => import('pages/DestroyDocument/Children/ReviewDestroyDocument/ReviewDestroyDocumentDetail'),
);
const ReviewDestroyDocumentDetailPage = WithPermission(ReviewDestroyDocumentDetail);
const DocumentComponentDetail = lazy(() => import('pages/Share/components/DocumentComponentDetail'));
const DocumentComponentDetailPage = WithPermission(DocumentComponentDetail);

// Danh sách quyết định tiêu hủy
const DestroyDecision = lazy(() => import('pages/DestroyDocument/Children/DestroyDecision'));
const DestroyDecisionPage = WithPermission(DestroyDecision);

// Duyệt quyết định tiêu hủy
const ReviewDestroyDecision = lazy(() => import('pages/DestroyDocument/Children/ReviewDestroyDecision'));
const ReviewDestroyDecisionPage = WithPermission(ReviewDestroyDecision);

// Danh sách quyết định tiêu hủy đã duyệt
const DestroyedDecision = lazy(() => import('pages/DestroyDocument/Children/DestroyedDecision'));
const DestroyedDecisionPage = WithPermission(DestroyedDecision);

const DESTROY_DOCUMENT = {
  path: `${PATH_NAME.DESTROY_DOCUMENT.BASE}`,
  element: (
    <Suspense fallback={'Đang tải...'}>
      <DestroyDocument />
    </Suspense>
  ),
  children: [
    {
      index: true,
      element: (
        <Suspense fallback={'Đang tải...'}>
          <ReviewDestroyDocumentListPage requiredPermissions={[PermissionEnum.REVIEW_DESTROY_DOCUMENT_VIEW]} />
        </Suspense>
      ),
    },
    {
      path: 'danh-sach-ho-so-cho-tieu-huy',
      element: (
        <Suspense fallback={'Đang tải...'}>
          <ReviewDestroyDocumentLayout />
        </Suspense>
      ),
      children: [
        {
          index: true,
          element: (
            <Suspense fallback={'Đang tải...'}>
              <ReviewDestroyDocumentListPage requiredPermissions={[PermissionEnum.REVIEW_DESTROY_DOCUMENT_VIEW]} />
            </Suspense>
          ),
        },
        {
          path: '',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <ReviewDestroyDocumentListPage requiredPermissions={[PermissionEnum.REVIEW_DESTROY_DOCUMENT_VIEW]} />
            </Suspense>
          ),
        },
        {
          path: 'them-ho-so-tieu-huy',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <AddDestroyDocumentPage requiredPermissions={[PermissionEnum.REVIEW_DESTROY_DOCUMENT_CREATE_DOC]} />
            </Suspense>
          ),
        },
        {
          path: 'chi-tiet-ho-so/:id',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <ReviewDestroyDocumentDetailPage requiredPermissions={[PermissionEnum.REVIEW_DESTROY_DOCUMENT_DETAIL]} />
            </Suspense>
          ),
        },
        {
          path: 'chi-tiet-tai-lieu/:id',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <DocumentComponentDetailPage requiredPermissions={[PermissionEnum.REVIEW_DESTROY_DOCUMENT_DETAIL]} />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: 'danh-sach-quyet-dinh-tieu-huy',
      element: (
        <Suspense fallback={'Đang tải...'}>
          <DestroyDecisionPage requiredPermissions={[PermissionEnum.DESTROY_DECISION_VIEW]} />
        </Suspense>
      ),
    },
    {
      path: 'duyet-quyet-dinh-tieu-huy',
      element: (
        <Suspense fallback={'Đang tải...'}>
          <ReviewDestroyDecisionPage requiredPermissions={[PermissionEnum.REVIEW_DESTROY_DECISION_VIEW]} />
        </Suspense>
      ),
    },
    {
      path: 'danh-sach-quyet-dinh-tieu-huy-da-duyet',
      element: (
        <Suspense fallback={'Đang tải...'}>
          <DestroyedDecisionPage requiredPermissions={[PermissionEnum.DESTROYED_DECISION_VIEW]} />
        </Suspense>
      ),
    },
  ],
};

export default DESTROY_DOCUMENT;

import React from 'react';

interface Props {
  className?: string;
  color?: string;
}

const FolderAdd = ({ className, color = '#4D4D4D' }: Props) => {
  return (
    <svg
      height='24'
      width='24'
      fill='none'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M14 12.5H13V11.5C13 11.2348 12.8946 10.9804 12.7071 10.7929C12.5196 10.6054 12.2652 10.5 12 10.5C11.7348 10.5 11.4804 10.6054 11.2929 10.7929C11.1054 10.9804 11 11.2348 11 11.5V12.5H10C9.73478 12.5 9.48043 12.6054 9.29289 12.7929C9.10536 12.9804 9 13.2348 9 13.5C9 13.7652 9.10536 14.0196 9.29289 14.2071C9.48043 14.3946 9.73478 14.5 10 14.5H11V15.5C11 15.7652 11.1054 16.0196 11.2929 16.2071C11.4804 16.3946 11.7348 16.5 12 16.5C12.2652 16.5 12.5196 16.3946 12.7071 16.2071C12.8946 16.0196 13 15.7652 13 15.5V14.5H14C14.2652 14.5 14.5196 14.3946 14.7071 14.2071C14.8946 14.0196 15 13.7652 15 13.5C15 13.2348 14.8946 12.9804 14.7071 12.7929C14.5196 12.6054 14.2652 12.5 14 12.5ZM19 5.5H12.72L12.4 4.5C12.1926 3.91323 11.8077 3.4055 11.2989 3.04716C10.7901 2.68882 10.1824 2.4976 9.56 2.5H5C4.20435 2.5 3.44129 2.81607 2.87868 3.37868C2.31607 3.94129 2 4.70435 2 5.5V18.5C2 19.2956 2.31607 20.0587 2.87868 20.6213C3.44129 21.1839 4.20435 21.5 5 21.5H19C19.7956 21.5 20.5587 21.1839 21.1213 20.6213C21.6839 20.0587 22 19.2956 22 18.5V8.5C22 7.70435 21.6839 6.94129 21.1213 6.37868C20.5587 5.81607 19.7956 5.5 19 5.5ZM20 18.5C20 18.7652 19.8946 19.0196 19.7071 19.2071C19.5196 19.3946 19.2652 19.5 19 19.5H5C4.73478 19.5 4.48043 19.3946 4.29289 19.2071C4.10536 19.0196 4 18.7652 4 18.5V5.5C4 5.23478 4.10536 4.98043 4.29289 4.79289C4.48043 4.60536 4.73478 4.5 5 4.5H9.56C9.76964 4.49946 9.97416 4.56482 10.1446 4.68684C10.3151 4.80886 10.4429 4.98138 10.51 5.18L11.05 6.82C11.1171 7.01862 11.2449 7.19113 11.4154 7.31316C11.5858 7.43518 11.7904 7.50054 12 7.5H19C19.2652 7.5 19.5196 7.60536 19.7071 7.79289C19.8946 7.98043 20 8.23478 20 8.5V18.5Z'
        fill={color}
      />
    </svg>
  );
};

export default FolderAdd;

import { getUsageModeEnumValue } from 'core/enums/usage-mode.enum';
import React, { useMemo } from 'react';

const useGetUsageMode = () => {
  const usageModeEnumValue = getUsageModeEnumValue();
  const dataSource = useMemo(
    () =>
      usageModeEnumValue.map((item) => ({
        label: item?.label as string,
        value: item?.value as unknown as number,
      })),
    [],
  );

  return { dataSource };
};

export default useGetUsageMode;

import { useState } from 'react';

const useRowTableSelection = (rowKeys: React.Key[]) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>(rowKeys);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    preserveSelectedRowKeys: true,
  };

  return { rowSelection, selectedRowKeys };
};

export default useRowTableSelection;

import LocationService from 'data';
import { useMemo } from 'react';

const useFetchProvinceList = () => {
  const provinceList = useMemo(() => LocationService.getProvinceList(), []);
  const dataSource = useMemo(
    () =>
      provinceList.map((province) => ({
        key: province.Id,
        label: province.Name,
        value: province.Id,
      })),
    [provinceList],
  );

  return { dataSource };
};

export default useFetchProvinceList;

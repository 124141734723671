import { Menu, MenuProps, Typography } from 'antd';
import {
  BaoCao,
  BienMucHoSo,
  DanhMucChung,
  DanhMucRieng,
  Dot,
  MultiUsers,
  NopLuuLichSu,
  QLKhaiThac,
  QLKho,
  QuanTriCoQuan,
  ThuThapNopLuu,
  TieuHuyTaiLieu,
} from 'assets/icons';
import TongQuan from 'assets/icons/TongQuan';
import { PATH_NAME } from 'core/constants/path-name';
import { ParentPermissionEnum, PermissionEnum, ScreenPermissionEnum } from 'core/enums/PermissionEnum.enum';
import { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import checkPermission from 'utils/check-permission';

const SideBar = () => {
  const { pathname } = useLocation();
  let currentPath = '';
  const [, parentPath, childPath] = pathname.split('/');

  const COLLECTION_AND_SUBMISSION = useMemo(
    () => ({
      icon: <ThuThapNopLuu />,
      key: PATH_NAME.COLLECTION_AND_SUBMISSION.BASE,
      label: (
        <Typography.Title level={5} style={{ margin: 0, fontWeight: 500, fontSize: '14px' }}>
          Thu thập và nộp lưu
        </Typography.Title>
      ),
      disabled: !checkPermission([
        ScreenPermissionEnum.COLLECTION_AND_SUBMISSION,
        ParentPermissionEnum.DOCUMENT_PLAN_MANAGEMENT,
        ParentPermissionEnum.REVIEW_DOCUMENT_PLAN,
        ParentPermissionEnum.REVIEW_AND_SUBMIT_FILE_SUBMISSION,
        ParentPermissionEnum.RECEIVE_DOCUMENT,
        ParentPermissionEnum.HANDOVER_DOCUMENT_MANAGEMENT,
        ParentPermissionEnum.DOCUMENT_MANAGEMENT,
        ParentPermissionEnum.DOCUMENT_INDEX,
        PermissionEnum.DOCUMENT_PLAN_VIEW,
        PermissionEnum.REVIEW_DOCUMENT_PLAN_VIEW,
        PermissionEnum.DOCUMENT_MANAGEMENT_VIEW,
        PermissionEnum.REVIEW_AND_SUBMIT_DOC_VIEW,
        PermissionEnum.RECEIVE_DOC_VIEW,
        PermissionEnum.DOC_INDEX_VIEW,
        PermissionEnum.HANDOVER_DOCUMENT_VIEW,
      ]),

      children: [
        {
          key: PATH_NAME.COLLECTION_AND_SUBMISSION.DOCUMENT_PLAN_MANAGEMENT.BASE,
          icon: <Dot />,
          label: (
            <Link to={{ pathname: PATH_NAME.COLLECTION_AND_SUBMISSION.DOCUMENT_PLAN_MANAGEMENT.BASE }}>
              Quản lý kế hoạch thu thập
            </Link>
          ),
          disabled: !checkPermission([
            ParentPermissionEnum.DOCUMENT_PLAN_MANAGEMENT,
            PermissionEnum.DOCUMENT_PLAN_VIEW,
          ]),
        },
        {
          key: PATH_NAME.COLLECTION_AND_SUBMISSION.REVIEW_DOCUMENT_PLAN.BASE,
          icon: <Dot />,
          label: (
            <Link to={{ pathname: PATH_NAME.COLLECTION_AND_SUBMISSION.REVIEW_DOCUMENT_PLAN.BASE }}>
              Duyệt kế hoạch thu thập
            </Link>
          ),
          disabled: !checkPermission([
            ParentPermissionEnum.REVIEW_DOCUMENT_PLAN,
            PermissionEnum.REVIEW_DOCUMENT_PLAN_VIEW,
          ]),
        },
        {
          key: PATH_NAME.COLLECTION_AND_SUBMISSION.DOCUMENT_MANAGEMENT.BASE,
          icon: <Dot />,
          label: (
            <Link to={{ pathname: PATH_NAME.COLLECTION_AND_SUBMISSION.DOCUMENT_MANAGEMENT.BASE }}>
              Quản lý thu thập
            </Link>
          ),
          disabled: !checkPermission([
            ParentPermissionEnum.DOCUMENT_MANAGEMENT,
            PermissionEnum.DOCUMENT_MANAGEMENT_VIEW,
          ]),
        },
        {
          key: PATH_NAME.COLLECTION_AND_SUBMISSION.REVIEW_AND_SUBMIT_FILE_SUBMISSION.BASE,
          icon: <Dot />,
          label: (
            <Link to={{ pathname: PATH_NAME.COLLECTION_AND_SUBMISSION.REVIEW_AND_SUBMIT_FILE_SUBMISSION.BASE }}>
              Duyệt và gửi hồ sơ nộp lưu
            </Link>
          ),
          disabled: !checkPermission([
            ParentPermissionEnum.REVIEW_AND_SUBMIT_FILE_SUBMISSION,
            PermissionEnum.REVIEW_AND_SUBMIT_DOC_VIEW,
          ]),
        },
        {
          key: PATH_NAME.COLLECTION_AND_SUBMISSION.RECEIVE_DOCUMENT.BASE,
          icon: <Dot />,
          label: (
            <Link to={{ pathname: PATH_NAME.COLLECTION_AND_SUBMISSION.RECEIVE_DOCUMENT.BASE }}>
              Tiếp nhận hồ sơ nộp lưu
            </Link>
          ),
          disabled: !checkPermission([ParentPermissionEnum.RECEIVE_DOCUMENT, PermissionEnum.RECEIVE_DOC_VIEW]),
        },
        {
          key: PATH_NAME.COLLECTION_AND_SUBMISSION.DOCUMENT_INDEX.BASE,
          icon: <Dot />,
          label: (
            <Link to={{ pathname: PATH_NAME.COLLECTION_AND_SUBMISSION.DOCUMENT_INDEX.BASE }}>
              Mục lục hồ sơ nộp lưu
            </Link>
          ),
          disabled: !checkPermission([ParentPermissionEnum.DOCUMENT_INDEX, PermissionEnum.DOC_INDEX_VIEW]),
        },
        {
          key: PATH_NAME.COLLECTION_AND_SUBMISSION.HANDOVER_DOCUMENT_MANAGEMENT.BASE,
          icon: <Dot />,
          label: (
            <Link to={{ pathname: PATH_NAME.COLLECTION_AND_SUBMISSION.HANDOVER_DOCUMENT_MANAGEMENT.BASE }}>
              Quản lý biên bản bàn giao
            </Link>
          ),
          disabled: !checkPermission([
            ParentPermissionEnum.HANDOVER_DOCUMENT_MANAGEMENT,
            PermissionEnum.HANDOVER_DOCUMENT_VIEW,
          ]),
        },
      ].filter((item) => !item.disabled),
    }),
    [],
  );

  const VARIABLE_BINNING = useMemo(
    () => ({
      icon: <BienMucHoSo />,
      key: PATH_NAME.VARIABLE_BINNING.BASE,
      label: (
        <Typography.Title level={5} style={{ margin: 0, fontWeight: 500, fontSize: '14px' }}>
          Biên mục chỉnh lý
        </Typography.Title>
      ),
      disabled: !checkPermission([
        ScreenPermissionEnum.VARIABLE_BINNING,
        ParentPermissionEnum.DOCUMENT_INDEXING,
        ParentPermissionEnum.VARIABLE_REPOSITORY_BROWSING,
        ParentPermissionEnum.SUPPLEMENTAL_INDEXING_MANAGEMENT,
        ParentPermissionEnum.REVIEW_SUPPLEMENTAL_INDEXING,
        ParentPermissionEnum.SUPPLEMENTAL_DOCUMENT_INDEXING,
        PermissionEnum.DOC_INDEXING_VIEW,
        PermissionEnum.VARIABLE_REPOSITORY_BROWSING_VIEW,
        PermissionEnum.SUPPLEMENTAL_INDEXING_MANAGEMENT_VIEW,
        PermissionEnum.REVIEW_SUPPLEMENTAL_INDEXING_VIEW,
        PermissionEnum.SUPPLEMENTAL_DOCUMENT_INDEXING_VIEW,
      ]),
      children: [
        {
          key: PATH_NAME.VARIABLE_BINNING.DOCUMENT_INDEXING.BASE,
          icon: <Dot />,
          label: (
            <Link to={{ pathname: PATH_NAME.VARIABLE_BINNING.DOCUMENT_INDEXING.BASE }}>Biên mục hồ sơ, tài liệu</Link>
          ),
          disabled: !checkPermission([ParentPermissionEnum.DOCUMENT_INDEXING, PermissionEnum.DOC_INDEXING_VIEW]),
        },
        {
          key: PATH_NAME.VARIABLE_BINNING.VARIABLE_REPOSITORY_BROWSING.BASE,
          icon: <Dot />,
          label: (
            <Link to={{ pathname: PATH_NAME.VARIABLE_BINNING.VARIABLE_REPOSITORY_BROWSING.BASE }}>Duyệt lưu kho</Link>
          ),
          disabled: !checkPermission([
            ParentPermissionEnum.VARIABLE_REPOSITORY_BROWSING,
            PermissionEnum.VARIABLE_REPOSITORY_BROWSING_VIEW,
          ]),
        },
        {
          key: PATH_NAME.VARIABLE_BINNING.SUPPLEMENTAL_INDEXING_MANAGEMENT.BASE,
          icon: <Dot />,
          label: (
            <Link to={{ pathname: PATH_NAME.VARIABLE_BINNING.SUPPLEMENTAL_INDEXING_MANAGEMENT.BASE }}>
              Quản lý biên mục bổ sung
            </Link>
          ),
          disabled: !checkPermission([
            ParentPermissionEnum.SUPPLEMENTAL_INDEXING_MANAGEMENT,
            PermissionEnum.SUPPLEMENTAL_INDEXING_MANAGEMENT_VIEW,
          ]),
        },
        {
          key: PATH_NAME.VARIABLE_BINNING.REVIEW_SUPPLEMENTAL_INDEXING.BASE,
          icon: <Dot />,
          label: (
            <Link to={{ pathname: PATH_NAME.VARIABLE_BINNING.REVIEW_SUPPLEMENTAL_INDEXING.BASE }}>
              Duyệt biên mục bổ sung
            </Link>
          ),
          disabled: !checkPermission([
            ParentPermissionEnum.REVIEW_SUPPLEMENTAL_INDEXING,
            PermissionEnum.REVIEW_SUPPLEMENTAL_INDEXING_VIEW,
          ]),
        },
        {
          key: PATH_NAME.VARIABLE_BINNING.SUPPLEMENTAL_DOCUMENT_INDEXING.BASE,
          icon: <Dot />,
          label: (
            <Link to={{ pathname: PATH_NAME.VARIABLE_BINNING.SUPPLEMENTAL_DOCUMENT_INDEXING.BASE }}>
              Biên mục bổ sung hồ sơ tài liệu
            </Link>
          ),
          disabled: !checkPermission([
            ParentPermissionEnum.SUPPLEMENTAL_DOCUMENT_INDEXING,
            PermissionEnum.SUPPLEMENTAL_DOCUMENT_INDEXING_VIEW,
          ]),
        },
      ].filter((item) => !item.disabled),
    }),
    [],
  );

  const DOCUMENT_STORAGE = useMemo(
    () => ({
      key: PATH_NAME.DOCUMENT_STORAGE.BASE,
      label: (
        <Typography.Title level={5} style={{ margin: 0, fontWeight: 500, fontSize: '14px' }}>
          Kho lưu trữ
        </Typography.Title>
      ),
      icon: <QLKho />,
      disabled: !checkPermission([
        ScreenPermissionEnum.DOCUMENT_STORAGE,
        ParentPermissionEnum.BARCODE,
        ParentPermissionEnum.DOCUMENT_SEARCH,
        ParentPermissionEnum.DOCUMENT_COMPONENT_SEARCH,
        ParentPermissionEnum.MOVE_DOCUMENT,
        ParentPermissionEnum.FREEZING_DOCUMENT,
        ParentPermissionEnum.TRANSFER_DOCUMENT,
        PermissionEnum.BARCODE_VIEW,
        PermissionEnum.DOCUMENT_SEARCH_VIEW,
        PermissionEnum.DOCUMENT_COMPONENT_SEARCH_VIEW,
        PermissionEnum.MOVE_DOCUMENT_VIEW,
        PermissionEnum.FREEZING_DOCUMENT_VIEW,
        PermissionEnum.TRANSFER_DOCUMENT_VIEW,
      ]),
      children: [
        {
          key: PATH_NAME.DOCUMENT_STORAGE.BARCODE.BASE,
          icon: <Dot />,
          label: <Link to={{ pathname: PATH_NAME.DOCUMENT_STORAGE.BARCODE.BASE }}>In mã vạch</Link>,
          disabled: !checkPermission([ParentPermissionEnum.BARCODE, PermissionEnum.BARCODE_VIEW]),
        },
        {
          key: PATH_NAME.DOCUMENT_STORAGE.DOCUMENT_SEARCH.BASE,
          icon: <Dot />,
          label: <Link to={{ pathname: PATH_NAME.DOCUMENT_STORAGE.DOCUMENT_SEARCH.BASE }}>Tra cứu hồ sơ</Link>,
          disabled: !checkPermission([ParentPermissionEnum.DOCUMENT_SEARCH, PermissionEnum.DOCUMENT_SEARCH_VIEW]),
        },
        {
          key: PATH_NAME.DOCUMENT_STORAGE.DOCUMENT_COMPONENT_SEARCH.BASE,
          icon: <Dot />,
          label: (
            <Link to={{ pathname: PATH_NAME.DOCUMENT_STORAGE.DOCUMENT_COMPONENT_SEARCH.BASE }}>Tra cứu tài liệu</Link>
          ),
          disabled: !checkPermission([
            ParentPermissionEnum.DOCUMENT_COMPONENT_SEARCH,
            PermissionEnum.DOCUMENT_COMPONENT_SEARCH_VIEW,
          ]),
        },
        {
          key: PATH_NAME.DOCUMENT_STORAGE.SORT_BOX.BASE,
          icon: <Dot />,
          label: <Link to={{ pathname: PATH_NAME.DOCUMENT_STORAGE.SORT_BOX.BASE }}>Xếp hộp cặp</Link>,
          disabled: !checkPermission([ParentPermissionEnum.SORT_BOX, PermissionEnum.SORT_BOX_VIEW]),
        },
        {
          key: PATH_NAME.DOCUMENT_STORAGE.MOVE_DOCUMENT.BASE,
          icon: <Dot />,
          label: (
            <Link to={{ pathname: PATH_NAME.DOCUMENT_STORAGE.MOVE_DOCUMENT.BASE }}>Di chuyển hồ sơ, tài liệu</Link>
          ),
          disabled: !checkPermission([ParentPermissionEnum.MOVE_DOCUMENT, PermissionEnum.MOVE_DOCUMENT_VIEW]),
        },
        {
          key: PATH_NAME.DOCUMENT_STORAGE.FREEZING_DOCUMENT.BASE,
          icon: <Dot />,
          label: <Link to={{ pathname: PATH_NAME.DOCUMENT_STORAGE.FREEZING_DOCUMENT.BASE }}>Đóng băng hồ sơ</Link>,
          disabled: !checkPermission([ParentPermissionEnum.FREEZING_DOCUMENT, PermissionEnum.FREEZING_DOCUMENT_VIEW]),
        },
        {
          key: PATH_NAME.DOCUMENT_STORAGE.TRANSFER_DOCUMENT,
          icon: <Dot />,
          label: <Link to={{ pathname: PATH_NAME.DOCUMENT_STORAGE.TRANSFER_DOCUMENT }}>Phiếu di chuyển tài liệu</Link>,
          disabled: !checkPermission([ParentPermissionEnum.TRANSFER_DOCUMENT, PermissionEnum.TRANSFER_DOCUMENT_VIEW]),
        },
      ].filter((item) => !item.disabled),
    }),
    [],
  );
  const REVIEW_DESTROY_DOCUMENT = useMemo(
    () => ({
      key: PATH_NAME.DESTROY_DOCUMENT.BASE,
      label: (
        <Typography.Title level={5} style={{ margin: 0, fontWeight: 500, fontSize: '14px' }}>
          Tiêu hủy hồ sơ
        </Typography.Title>
      ),
      icon: <TieuHuyTaiLieu />,
      disabled: !checkPermission([
        ScreenPermissionEnum.DESTROY_DOCUMENT,
        ParentPermissionEnum.REVIEW_DESTROY_DOCUMENT,
        ParentPermissionEnum.DESTROY_DECISION,
        ParentPermissionEnum.REVIEW_DESTROY_DECISION,
        ParentPermissionEnum.DESTROYED_DECISION,
        PermissionEnum.REVIEW_DESTROY_DOCUMENT_VIEW,
        PermissionEnum.REVIEW_DESTROY_DECISION_VIEW,
        PermissionEnum.DESTROY_DECISION_VIEW,
        PermissionEnum.DESTROYED_DECISION_VIEW,
      ]),
      children: [
        {
          key: PATH_NAME.DESTROY_DOCUMENT.REVIEW_DESTROY_DOCUMENT.BASE,
          icon: <Dot />,
          label: (
            <Link to={{ pathname: PATH_NAME.DESTROY_DOCUMENT.REVIEW_DESTROY_DOCUMENT.BASE }}>
              Danh sách hồ sơ chờ tiêu hủy
            </Link>
          ),
          disabled: !checkPermission([
            ParentPermissionEnum.REVIEW_DESTROY_DOCUMENT,
            PermissionEnum.REVIEW_DESTROY_DOCUMENT_VIEW,
          ]),
        },
        {
          key: PATH_NAME.DESTROY_DOCUMENT.DESTROY_DECISION,
          icon: <Dot />,
          label: (
            <Link to={{ pathname: PATH_NAME.DESTROY_DOCUMENT.DESTROY_DECISION }}>Danh sách quyết định tiêu hủy</Link>
          ),
          disabled: !checkPermission([ParentPermissionEnum.DESTROY_DECISION, PermissionEnum.DESTROY_DECISION_VIEW]),
        },
        {
          key: PATH_NAME.DESTROY_DOCUMENT.REVIEW_DESTROY_DECISION,
          icon: <Dot />,
          label: (
            <Link to={{ pathname: PATH_NAME.DESTROY_DOCUMENT.REVIEW_DESTROY_DECISION }}>Duyệt quyết định tiêu hủy</Link>
          ),
          disabled: !checkPermission([
            ParentPermissionEnum.REVIEW_DESTROY_DECISION,
            PermissionEnum.REVIEW_DESTROY_DECISION_VIEW,
          ]),
        },
        {
          key: PATH_NAME.DESTROY_DOCUMENT.DESTROYED_DECISION,
          icon: <Dot />,
          label: (
            <Link to={{ pathname: PATH_NAME.DESTROY_DOCUMENT.DESTROYED_DECISION }}>
              Danh sách quyết định tiêu hủy đã duyệt
            </Link>
          ),
          disabled: !checkPermission([ParentPermissionEnum.DESTROYED_DECISION, PermissionEnum.DESTROYED_DECISION_VIEW]),
        },
      ].filter((item) => !item.disabled),
    }),
    [],
  );

  const DOCUMENT_MINING_MANAGEMENT = useMemo(
    () => ({
      key: PATH_NAME.DOCUMENT_MINING_MANAGEMENT.BASE,
      label: (
        <Typography.Title level={5} style={{ margin: 0, fontWeight: 500, fontSize: '14px' }}>
          Quản lý khai thác
        </Typography.Title>
      ),
      icon: <QLKhaiThac />,
      disabled: !checkPermission([
        ScreenPermissionEnum.DOCUMENT_MINING_MANAGEMENT,
        ParentPermissionEnum.LEVEL_DOCUMENT_MINING_MANAGEMENT,
        ParentPermissionEnum.DOCUMENT_COMPONENT_MINING,
        ParentPermissionEnum.DOCUMENT_MINING_TICKET,
        ParentPermissionEnum.REVIEW_DOCUMENT_MINING_TICKET,
        ParentPermissionEnum.REVIEW_UNIT,
        PermissionEnum.LEVEL_DOCUMENT_MINING_MGMT_VIEW,
        PermissionEnum.DOCUMENT_COMPONENT_MINING_VIEW,
        PermissionEnum.DOCUMENT_MINING_TICKET_VIEW,
        PermissionEnum.REVIEW_DOCUMENT_MINING_TICKET_VIEW,
        PermissionEnum.REVIEW_UNIT_VIEW,
      ]),
      children: [
        {
          key: PATH_NAME.DOCUMENT_MINING_MANAGEMENT.LEVEL_DOCUMENT_MINING_MANAGEMENT.BASE,
          icon: <Dot />,
          label: (
            <Link to={{ pathname: PATH_NAME.DOCUMENT_MINING_MANAGEMENT.LEVEL_DOCUMENT_MINING_MANAGEMENT.BASE }}>
              Quản lý mức độ khai thác
            </Link>
          ),
          disabled: !checkPermission([
            ParentPermissionEnum.LEVEL_DOCUMENT_MINING_MANAGEMENT,
            PermissionEnum.LEVEL_DOCUMENT_MINING_MGMT_VIEW,
          ]),
        },
        {
          key: PATH_NAME.DOCUMENT_MINING_MANAGEMENT.DOCUMENT_COMPONENT_MINING.BASE,
          icon: <Dot />,
          label: (
            <Link to={{ pathname: PATH_NAME.DOCUMENT_MINING_MANAGEMENT.DOCUMENT_COMPONENT_MINING.BASE }}>
              Khai thác tài liệu
            </Link>
          ),
          disabled: !checkPermission([
            ParentPermissionEnum.DOCUMENT_COMPONENT_MINING,
            PermissionEnum.DOCUMENT_COMPONENT_MINING_VIEW,
          ]),
        },
        {
          key: PATH_NAME.DOCUMENT_MINING_MANAGEMENT.DOCUMENT_MINING_WAIT.BASE,
          icon: <Dot />,
          label: (
            <Link to={{ pathname: PATH_NAME.DOCUMENT_MINING_MANAGEMENT.DOCUMENT_MINING_WAIT.BASE }}>
              Danh sách chờ khai thác
            </Link>
          ),
          disabled: !checkPermission([
            ParentPermissionEnum.DOCUMENT_COMPONENT_MINING,
            PermissionEnum.DOCUMENT_COMPONENT_MINING_VIEW,
          ]),
        },
        {
          key: PATH_NAME.DOCUMENT_MINING_MANAGEMENT.DOCUMENT_MINING_TICKET.BASE,
          icon: <Dot />,
          label: (
            <Link to={{ pathname: PATH_NAME.DOCUMENT_MINING_MANAGEMENT.DOCUMENT_MINING_TICKET.BASE }}>
              Danh sách phiếu khai thác
            </Link>
          ),
          disabled: !checkPermission([
            ParentPermissionEnum.DOCUMENT_MINING_TICKET,
            PermissionEnum.DOCUMENT_MINING_TICKET_VIEW,
          ]),
        },
        {
          key: PATH_NAME.DOCUMENT_MINING_MANAGEMENT.REVIEW_UNIT.BASE,
          icon: <Dot />,
          label: <Link to={{ pathname: PATH_NAME.DOCUMENT_MINING_MANAGEMENT.REVIEW_UNIT.BASE }}>Phê duyệt đơn vị</Link>,
          disabled: !checkPermission([ParentPermissionEnum.REVIEW_UNIT, PermissionEnum.REVIEW_UNIT_VIEW]),
        },
        {
          key: PATH_NAME.DOCUMENT_MINING_MANAGEMENT.REVIEW_DOCUMENT_MINING_TICKET.BASE,
          icon: <Dot />,
          label: (
            <Link to={{ pathname: PATH_NAME.DOCUMENT_MINING_MANAGEMENT.REVIEW_DOCUMENT_MINING_TICKET.BASE }}>
              Phê duyệt phiếu khai thác
            </Link>
          ),
          disabled: !checkPermission([
            ParentPermissionEnum.REVIEW_DOCUMENT_MINING_TICKET,
            PermissionEnum.REVIEW_DOCUMENT_MINING_TICKET_VIEW,
          ]),
        },
      ].filter((item) => !item.disabled),
    }),
    [],
  );

  const REPORT_STATISTICS = useMemo(() => {
    return {
      key: PATH_NAME.REPORT_STATISTICS.BASE,
      label: (
        <Typography.Title level={5} style={{ margin: 0, fontWeight: 500, fontSize: '14px' }}>
          Báo cáo thống kê
        </Typography.Title>
      ),
      icon: <BaoCao />,
      disabled: !checkPermission([
        ScreenPermissionEnum.REPORT_STATISTICS,
        ParentPermissionEnum.DIGITAL_STORAGE_INVENTORY_STATUS,
        ParentPermissionEnum.BACKPACK_STATISTICS,
        ParentPermissionEnum.MINING_STATISTICS,
        PermissionEnum.DIGITAL_STORAGE_INVENTORY_STATUS_VIEW,
        PermissionEnum.BACKPACK_STATISTICS_VIEW,
        PermissionEnum.MINING_STATISTICS_VIEW,
      ]),
      children: [
        {
          key: PATH_NAME.REPORT_STATISTICS.DIGITAL_STORAGE_INVENTORY_STATUS,
          icon: <Dot />,
          label: (
            <Link to={{ pathname: PATH_NAME.REPORT_STATISTICS.DIGITAL_STORAGE_INVENTORY_STATUS }}>
              Hiện trạng số hoá kho lưu trữ
            </Link>
          ),
          disabled: !checkPermission([
            ParentPermissionEnum.DIGITAL_STORAGE_INVENTORY_STATUS,
            PermissionEnum.DIGITAL_STORAGE_INVENTORY_STATUS_VIEW,
          ]),
        },
        {
          key: PATH_NAME.REPORT_STATISTICS.BACKPACK_STATISTICS,
          icon: <Dot />,
          label: <Link to={{ pathname: PATH_NAME.REPORT_STATISTICS.BACKPACK_STATISTICS }}>Thống kê hộp cặp</Link>,
          disabled: !checkPermission([
            ParentPermissionEnum.BACKPACK_STATISTICS,
            PermissionEnum.BACKPACK_STATISTICS_VIEW,
          ]),
        },
        {
          key: PATH_NAME.REPORT_STATISTICS.MINING_STATISTICS,
          icon: <Dot />,
          label: <Link to={{ pathname: PATH_NAME.REPORT_STATISTICS.MINING_STATISTICS }}>Thống kê khai thác</Link>,
          disabled: !checkPermission([ParentPermissionEnum.MINING_STATISTICS, PermissionEnum.MINING_STATISTICS_VIEW]),
        },
      ].filter((item) => !item.disabled),
    };
  }, []);

  const PRIVATE_DIRECTORY = useMemo(
    () => ({
      key: PATH_NAME.PRIVATE_DIRECTORY.BASE,
      label: (
        <Typography.Title level={5} style={{ margin: 0, fontWeight: 500, fontSize: '14px' }}>
          Danh mục riêng
        </Typography.Title>
      ),
      icon: <DanhMucRieng />,
      disabled: !checkPermission([
        ScreenPermissionEnum.PRIVATE_DIRECTORY,
        ParentPermissionEnum.PHONG_LUU_TRU,
        ParentPermissionEnum.CATALOGUE,
        ParentPermissionEnum.ARCHIVES,
        ParentPermissionEnum.SHELF,
        ParentPermissionEnum.BOX,
        ParentPermissionEnum.PROFILE_TYPE,
        PermissionEnum.PHONG_LUU_TRU_VIEW,
        PermissionEnum.CATALOGUE_VIEW,
        PermissionEnum.ARCHIVES_VIEW,
        PermissionEnum.SHELF_VIEW,
        PermissionEnum.BOX_VIEW,
        PermissionEnum.PROFILE_TYPE_VIEW,
      ]),
      children: [
        {
          key: PATH_NAME.PRIVATE_DIRECTORY.PHONG_LUU_TRU,
          icon: <Dot />,
          label: <Link to={{ pathname: PATH_NAME.PRIVATE_DIRECTORY.PHONG_LUU_TRU }}>Phông lưu trữ</Link>,
          disabled: !checkPermission([ParentPermissionEnum.PHONG_LUU_TRU, PermissionEnum.PHONG_LUU_TRU_VIEW]),
        },
        {
          key: PATH_NAME.PRIVATE_DIRECTORY.CATALOGUE,
          icon: <Dot />,
          label: <Link to={{ pathname: PATH_NAME.PRIVATE_DIRECTORY.CATALOGUE }}>Danh sách mục lục</Link>,
          disabled: !checkPermission([ParentPermissionEnum.CATALOGUE, PermissionEnum.CATALOGUE_VIEW]),
        },
        {
          key: PATH_NAME.PRIVATE_DIRECTORY.ARCHIVES,
          icon: <Dot />,
          label: <Link to={{ pathname: PATH_NAME.PRIVATE_DIRECTORY.ARCHIVES }}>Kho</Link>,
          disabled: !checkPermission([ParentPermissionEnum.ARCHIVES, PermissionEnum.ARCHIVES_VIEW]),
        },
        {
          key: PATH_NAME.PRIVATE_DIRECTORY.SHELF,
          icon: <Dot />,
          label: <Link to={{ pathname: PATH_NAME.PRIVATE_DIRECTORY.SHELF }}>Giá/ kệ</Link>,
          disabled: !checkPermission([ParentPermissionEnum.SHELF, PermissionEnum.SHELF_VIEW]),
        },
        {
          key: PATH_NAME.PRIVATE_DIRECTORY.BOX,
          icon: <Dot />,
          label: <Link to={{ pathname: PATH_NAME.PRIVATE_DIRECTORY.BOX }}>Hộp/ cặp</Link>,
          disabled: !checkPermission([ParentPermissionEnum.BOX, PermissionEnum.BOX_VIEW]),
        },
        {
          key: PATH_NAME.PRIVATE_DIRECTORY.PROFILE_TYPE,
          icon: <Dot />,
          label: <Link to={{ pathname: PATH_NAME.PRIVATE_DIRECTORY.PROFILE_TYPE }}>Loại hồ sơ</Link>,
          disabled: !checkPermission([ParentPermissionEnum.PROFILE_TYPE, PermissionEnum.PROFILE_TYPE_VIEW]),
        },
      ].filter((item) => !item.disabled),
    }),
    [],
  );

  const GENERAL_DIRECTORY = useMemo(
    () => ({
      key: PATH_NAME.GENERAL_DIRECTORY.BASE,
      label: (
        <Typography.Title level={5} style={{ margin: 0, fontWeight: 500, fontSize: '14px' }}>
          Danh mục chung
        </Typography.Title>
      ),
      icon: <DanhMucChung />,
      disabled: !checkPermission([
        ScreenPermissionEnum.GENERAL_DIRECTORY,
        ParentPermissionEnum.LANGUAGE,
        ParentPermissionEnum.SECURITY_LEVEL,
        ParentPermissionEnum.WARRANTY_PERIOD,
        ParentPermissionEnum.POSITION,
        PermissionEnum.LANGUAGE_VIEW,
        PermissionEnum.SECURITY_LEVEL_VIEW,
        PermissionEnum.WARRANTY_PERIOD_VIEW,
        PermissionEnum.POSITION_VIEW,
      ]),
      children: [
        {
          key: PATH_NAME.GENERAL_DIRECTORY.LANGUAGE.BASE,
          title: 'Ngôn ngữ',
          icon: <Dot />,
          label: <Link to={{ pathname: PATH_NAME.GENERAL_DIRECTORY.LANGUAGE.BASE }}>Ngôn ngữ</Link>,
          disabled: !checkPermission([ParentPermissionEnum.LANGUAGE, PermissionEnum.LANGUAGE_VIEW]),
        },
        {
          key: PATH_NAME.GENERAL_DIRECTORY.SECURITY_LEVEL.BASE,
          title: 'Cấp độ bảo mật',
          icon: <Dot />,
          label: <Link to={{ pathname: PATH_NAME.GENERAL_DIRECTORY.SECURITY_LEVEL.BASE }}>Cấp độ bảo mật</Link>,
          disabled: !checkPermission([ParentPermissionEnum.SECURITY_LEVEL, PermissionEnum.SECURITY_LEVEL_VIEW]),
        },
        {
          key: PATH_NAME.GENERAL_DIRECTORY.WARRANTY_PERIOD.BASE,
          title: 'Thời hạn bảo quản',
          icon: <Dot />,
          label: <Link to={{ pathname: PATH_NAME.GENERAL_DIRECTORY.WARRANTY_PERIOD.BASE }}>Thời hạn bảo quản</Link>,
          disabled: !checkPermission([ParentPermissionEnum.WARRANTY_PERIOD, PermissionEnum.WARRANTY_PERIOD_VIEW]),
        },
        {
          key: PATH_NAME.GENERAL_DIRECTORY.POSITION,
          title: 'Chức vụ',
          icon: <Dot />,
          label: <Link to={{ pathname: PATH_NAME.GENERAL_DIRECTORY.POSITION }}>Chức vụ</Link>,
          disabled: !checkPermission([ParentPermissionEnum.POSITION, PermissionEnum.POSITION_VIEW]),
        },
      ].filter((item) => !item.disabled),
    }),
    [],
  );

  const MANAGEMENT_AGENT = useMemo(
    () => ({
      key: PATH_NAME.MANAGEMENT_AGENT.BASE,
      label: (
        <Typography.Title level={5} style={{ margin: 0, fontWeight: 500, fontSize: '14px' }}>
          Quản trị cơ quan
        </Typography.Title>
      ),
      icon: <QuanTriCoQuan />,
      disabled: !checkPermission([
        ScreenPermissionEnum.MANAGEMENT_AGENT,
        ParentPermissionEnum.FORM,
        ParentPermissionEnum.MANAGEMENT_PERMISSION,
        ParentPermissionEnum.MANAGEMENT_GROUP_USER,
        ParentPermissionEnum.MANAGEMENT_USER,
        ParentPermissionEnum.MANAGEMENT_COMPANY,
        ParentPermissionEnum.AUTHORIZATION_BY_FILE_TYPE,
        ParentPermissionEnum.MANAGEMENT_READERS,
        PermissionEnum.FORM_VIEW,
        PermissionEnum.MANAGEMENT_PERMISSION_VIEW,
        PermissionEnum.MANAGEMENT_GROUP_USER_VIEW,
        PermissionEnum.MANAGEMENT_USER_VIEW,
        PermissionEnum.MANAGEMENT_COMPANY_VIEW,
        PermissionEnum.AUTHORIZATION_BY_FILE_TYPE_VIEW,
        PermissionEnum.MANAGEMENT_READERS_VIEW,
      ]),
      children: [
        {
          key: PATH_NAME.MANAGEMENT_AGENT.FORM,
          title: 'Quản lý Template',
          icon: <Dot />,
          label: <Link to={{ pathname: PATH_NAME.MANAGEMENT_AGENT.FORM }}>Quản lý Template</Link>,
          disabled: !checkPermission([ParentPermissionEnum.FORM, PermissionEnum.FORM_VIEW]),
        },
        {
          key: PATH_NAME.MANAGEMENT_AGENT.MANAGEMENT_PERMISSION,
          title: 'Quản lý nhóm quyền',
          icon: <Dot />,
          label: <Link to={{ pathname: PATH_NAME.MANAGEMENT_AGENT.MANAGEMENT_PERMISSION }}>Quản lý nhóm quyền</Link>,
          disabled: !checkPermission([
            ParentPermissionEnum.MANAGEMENT_PERMISSION,
            PermissionEnum.MANAGEMENT_PERMISSION_VIEW,
          ]),
        },
        {
          key: PATH_NAME.MANAGEMENT_AGENT.MANAGEMENT_GROUP_USER,
          title: 'Quản lý nhóm người dùng',
          icon: <Dot />,
          label: (
            <Link to={{ pathname: PATH_NAME.MANAGEMENT_AGENT.MANAGEMENT_GROUP_USER }}>Quản lý nhóm người dùng</Link>
          ),
          disabled: !checkPermission([
            ParentPermissionEnum.MANAGEMENT_GROUP_USER,
            PermissionEnum.MANAGEMENT_GROUP_USER_VIEW,
          ]),
        },
        {
          key: PATH_NAME.MANAGEMENT_AGENT.MANAGEMENT_USER,
          title: 'Quản lý người dùng',
          icon: <Dot />,
          label: <Link to={{ pathname: PATH_NAME.MANAGEMENT_AGENT.MANAGEMENT_USER }}>Quản lý người dùng</Link>,
          disabled: !checkPermission([ParentPermissionEnum.MANAGEMENT_USER, PermissionEnum.MANAGEMENT_USER_VIEW]),
        },
        {
          key: PATH_NAME.MANAGEMENT_AGENT.AUTHORIZATION_BY_FILE_TYPE,
          title: 'Phân quyền theo phân loại hồ sơ',
          icon: <Dot />,
          label: (
            <Link to={{ pathname: PATH_NAME.MANAGEMENT_AGENT.AUTHORIZATION_BY_FILE_TYPE }}>
              Phân quyền theo phân loại hồ sơ
            </Link>
          ),
          disabled: !checkPermission([
            ParentPermissionEnum.AUTHORIZATION_BY_FILE_TYPE,
            PermissionEnum.AUTHORIZATION_BY_FILE_TYPE_VIEW,
          ]),
        },
        {
          key: PATH_NAME.MANAGEMENT_AGENT.MANAGEMENT_COMPANY,
          title: 'Quản lý cơ quan',
          icon: <Dot />,
          label: <Link to={{ pathname: PATH_NAME.MANAGEMENT_AGENT.MANAGEMENT_COMPANY }}>Quản lý cơ quan</Link>,
          disabled: !checkPermission([ParentPermissionEnum.MANAGEMENT_COMPANY, PermissionEnum.MANAGEMENT_COMPANY_VIEW]),
        },
        {
          key: PATH_NAME.MANAGEMENT_AGENT.MANAGEMENT_READERS,
          title: 'Quản lý độc giả',
          icon: <Dot />,
          label: <Link to={{ pathname: PATH_NAME.MANAGEMENT_AGENT.MANAGEMENT_READERS }}>Quản lý độc giả</Link>,
          disabled: !checkPermission([ParentPermissionEnum.MANAGEMENT_READERS, PermissionEnum.POSITION_VIEW]),
        },
      ].filter((item) => !item.disabled),
    }),
    [],
  );

  const SYSTEM_MANAGEMENT = useMemo(
    () => ({
      key: PATH_NAME.SYSTEM_MANAGEMENT.BASE,
      label: (
        <Typography.Title level={5} style={{ margin: 0, fontWeight: 500, fontSize: '14px' }}>
          Quản trị hệ thống
        </Typography.Title>
      ),
      icon: <MultiUsers />,
      disabled: !checkPermission([
        ScreenPermissionEnum.SYSTEM_MANAGEMENT,
        ParentPermissionEnum.SYSTEM_LOGS,
        PermissionEnum.SYSTEM_LOGS_VIEW,
      ]),
      children: [
        {
          key: PATH_NAME.SYSTEM_MANAGEMENT.SYSTEM_LOGS,
          title: 'Nhật ký hệ thống',
          icon: <Dot />,
          label: <Link to={{ pathname: PATH_NAME.SYSTEM_MANAGEMENT.SYSTEM_LOGS }}>Nhật ký hệ thống</Link>,
        },
      ],
    }),
    [],
  );
  const SUBMIT_HISTORY = useMemo(
    () => ({
      key: PATH_NAME.SUBMIT_HISTORY.BASE,
      icon: <NopLuuLichSu />,
      label: (
        <Typography.Title level={5} style={{ margin: 0, fontWeight: 500, fontSize: '14px' }}>
          Nộp lưu lịch sử
        </Typography.Title>
      ),
      disabled: !checkPermission([
        ScreenPermissionEnum.SUBMIT_HISTORY,
        ParentPermissionEnum.SUBMIT_HISTORY_MANAGEMENT,
        ParentPermissionEnum.REVIEW_SUBMIT_HISTORY,
        ParentPermissionEnum.SUBMITTED_HISTORY,
        PermissionEnum.SUBMIT_HISTORY_MANAGEMENT_VIEW,
        PermissionEnum.REVIEW_SUBMIT_HISTORY_VIEW,
        PermissionEnum.SUBMITTED_HISTORY_VIEW,
      ]),
      children: [
        {
          key: PATH_NAME.SUBMIT_HISTORY.SUBMIT_HISTORY_MANAGEMENT.BASE,
          icon: <Dot />,
          label: (
            <Link to={{ pathname: PATH_NAME.SUBMIT_HISTORY.SUBMIT_HISTORY_MANAGEMENT.BASE }}>
              Quản lý hồ sơ nộp lưu lịch sử
            </Link>
          ),
          disabled: !checkPermission([
            ParentPermissionEnum.SUBMIT_HISTORY_MANAGEMENT,
            PermissionEnum.SUBMIT_HISTORY_MANAGEMENT_VIEW,
          ]),
        },
        {
          key: PATH_NAME.SUBMIT_HISTORY.REVIEW_SUBMIT_HISTORY.BASE,
          icon: <Dot />,
          label: (
            <Link to={{ pathname: PATH_NAME.SUBMIT_HISTORY.REVIEW_SUBMIT_HISTORY.BASE }}>
              Duyệt hồ sơ nộp lưu lịch sử
            </Link>
          ),
          disabled: !checkPermission([
            ParentPermissionEnum.REVIEW_SUBMIT_HISTORY,
            PermissionEnum.REVIEW_SUBMIT_HISTORY_VIEW,
          ]),
        },
        {
          key: PATH_NAME.SUBMIT_HISTORY.SUBMITTED_HISTORY.BASE,
          icon: <Dot />,
          label: (
            <Link to={{ pathname: PATH_NAME.SUBMIT_HISTORY.SUBMITTED_HISTORY.BASE }}>
              Danh sách hồ sơ đã nộp lưu lịch sử
            </Link>
          ),
          disabled: !checkPermission([ParentPermissionEnum.SUBMITTED_HISTORY, PermissionEnum.SUBMITTED_HISTORY_VIEW]),
        },
      ].filter((item) => !item.disabled),
    }),
    [],
  );

  const MENU_ITEMS: MenuProps['items'] = useMemo(() => {
    return [
      {
        key: PATH_NAME.DASHBOARD,
        icon: <TongQuan />,
        label: (
          <Link to={{ pathname: PATH_NAME.DASHBOARD }}>
            <Typography.Title level={5} style={{ margin: 0, fontWeight: 500, fontSize: '14px' }}>
              Tổng quan
            </Typography.Title>
          </Link>
        ),
        disabled: !checkPermission([ScreenPermissionEnum.DASHBOARD]),
      },
      COLLECTION_AND_SUBMISSION,
      VARIABLE_BINNING,
      DOCUMENT_STORAGE,
      REVIEW_DESTROY_DOCUMENT,
      DOCUMENT_MINING_MANAGEMENT,
      REPORT_STATISTICS,
      PRIVATE_DIRECTORY,
      GENERAL_DIRECTORY,
      MANAGEMENT_AGENT,
      SYSTEM_MANAGEMENT,
      SUBMIT_HISTORY,
    ].filter((item: any) => !item.disabled);
  }, [
    COLLECTION_AND_SUBMISSION,
    VARIABLE_BINNING,
    GENERAL_DIRECTORY,
    DOCUMENT_STORAGE,
    REVIEW_DESTROY_DOCUMENT,
    PRIVATE_DIRECTORY,
    DOCUMENT_MINING_MANAGEMENT,
    SUBMIT_HISTORY,
    MANAGEMENT_AGENT,
    REPORT_STATISTICS,
    SYSTEM_MANAGEMENT,
  ]);

  if (!childPath) {
    currentPath = `/${parentPath}`;
  } else {
    currentPath = `/${parentPath}/${childPath}`;
  }

  return (
    <Menu
      className='sidebar-menu'
      theme='light'
      mode='inline'
      defaultSelectedKeys={[PATH_NAME.DASHBOARD]}
      selectedKeys={[currentPath]}
      defaultOpenKeys={[`/${pathname.split('/')[1]}`]}
      items={MENU_ITEMS}
    />
  );
};

export default SideBar;

import { Button, Space } from 'antd';
import { CheckPermission } from 'components';
import { PermissionEnum } from 'core/enums/PermissionEnum.enum';
import { useNavigate } from 'react-router-dom';

enum ModalKey {
  REFUSE = 'REFUSE',
  APPROVE = 'SEND',
}

const useApprovePage = (
  isDisplay: boolean,
  openModal: (modalId?: string | number | undefined, data?: any) => void,
  permissionRefuse: PermissionEnum,
  permissionApprove: PermissionEnum,
) => {
  const navigate = useNavigate();

  const action = (
    <div className='flex--row-end'>
      {isDisplay ? (
        <Space>
          <CheckPermission allowedPermissions={[permissionRefuse]}>
            <Button
              type='primary'
              style={{ background: '#D60000', border: 'none' }}
              onClick={() => openModal(ModalKey.REFUSE)}
            >
              Từ chối
            </Button>
          </CheckPermission>
          <CheckPermission allowedPermissions={[permissionApprove]}>
            <Button
              type='primary'
              style={{ background: '#161DB8', border: 'none' }}
              onClick={() => openModal(ModalKey.APPROVE)}
            >
              Phê duyệt
            </Button>
          </CheckPermission>
        </Space>
      ) : (
        <Button type='primary' ghost onClick={() => navigate(-1)}>
          Quay lại
        </Button>
      )}
    </div>
  );

  return { action };
};

export default useApprovePage;

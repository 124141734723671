import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { DocumentPayload } from 'services/document/document.model';

interface DestroyDocument {
  listId: number[] | null;
  removeDestroyDocument: DocumentPayload[];
  isTransfer: boolean;
}
const initialState: DestroyDocument = {
  listId: null,
  isTransfer: true,
  removeDestroyDocument: [],
};

const destroyDocumentSlice = createSlice({
  name: 'destroyDocument',
  initialState,
  reducers: {
    setIdListDestroyDocument: (state: DestroyDocument, action: PayloadAction<number[]>) => {
      state.listId = action.payload;
    },
    setRemoveListDocumentOfDestroy: (state: DestroyDocument, action: PayloadAction<DocumentPayload[]>) => {
      state.removeDestroyDocument = action.payload;
    },
    clearIdList: (state: DestroyDocument) => {
      return initialState;
    },
  },
});

export const { setIdListDestroyDocument, clearIdList, setRemoveListDocumentOfDestroy } = destroyDocumentSlice.actions;

export default destroyDocumentSlice.reducer;

export const getIdListDestroy = (state: RootState) => state.destroy.listId;
export const isTransfer = (state: RootState) => state.destroy.isTransfer;
export const removeDestroyDocument = (state: RootState) => state.destroy.removeDestroyDocument;

import { useQuery } from '@tanstack/react-query';
import { OrganizationService } from 'services';
import { OrganizationPayload } from 'services/organization/organization.model';

const transformTreeDataTable = (dataSource: any): any => {
  const { children, ...data } = dataSource;

  if (!children || children?.length === 0) {
    return { value: data.value, title: data.title, children: null, key: data.value };
  }

  const dataChildren = children
    .map((item: any) => ({ value: item.Id, title: item.Name, key: item.Id, children: item.children }))
    .map((item: any) => transformTreeDataTable(item));

  return { ...data, children: dataChildren };
};

const useFetchOrganizationTree = (enabled = true, payload?: OrganizationPayload) => {
  // thực hiện 1 request http tới api, lấy danh sách đơn vị theo mô hình cây
  const { data: response } = useQuery({
    queryKey: ['ORGANIZATION_DASHBOARD_KEY'],
    queryFn: () => OrganizationService.getComboBoxOrganizationTree(payload ? payload : {}),
    enabled,
  });
  const dataSource = response
    ? response.data
        .map((item) => ({ value: item.Id, title: item.Name, key: item.Id, children: item.children }))
        .map((item) => transformTreeDataTable(item))
    : [];
  return { dataSource };
};

export default useFetchOrganizationTree;

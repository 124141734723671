import { ComboBoxItem } from 'core/interface/combo-box-item';
import { Paging } from 'core/interface/paging';
import { ResponseWithPaging } from 'core/interface/response-has-paging';
import httpClient from 'services/http-client';
import { BoxPayload } from 'services/own-categories/box/box.model';

export const PREFIX_BOX = '/api/Box';

const getBox = (payload: BoxPayload, paging: Paging) => {
  return httpClient.post<ResponseWithPaging<BoxPayload[]>>(`${PREFIX_BOX}/search`, payload, {
    headers: {
      PageOption: JSON.stringify(paging),
    },
  });
};
const getComboboxBox = (payload: BoxPayload) => {
  return httpClient.post<ComboBoxItem[]>(`${PREFIX_BOX}/getComboboxItem`, payload);
};
const getBoxDetail = (id: number) => {
  return httpClient.get<BoxPayload>(`${PREFIX_BOX}/id=${id}`);
};
const createBox = (payload: BoxPayload) => {
  return httpClient.post(`${PREFIX_BOX}`, payload);
};
const exportListBox = (payload: BoxPayload) => {
  return httpClient.post(`${PREFIX_BOX}/ExportListBox`, payload, {
    responseType: 'blob',
  });
};
const updateBox = (payload: BoxPayload) => {
  return httpClient.put(`${PREFIX_BOX}`, payload);
};
const deleteBox = (id: number) => {
  return httpClient.delete(`${PREFIX_BOX}`, { params: { id: id } });
};

const BoxService = {
  getBox,
  getBoxDetail,
  createBox,
  updateBox,
  deleteBox,
  getComboboxBox,
  exportListBox,
};

export default BoxService;

import { useQuery } from '@tanstack/react-query';
import { TemplateService } from 'services';

const useFetchComboBoxTemplate = (enabled = true) => {
  const { data: response } = useQuery({
    queryKey: ['Template_combobox'],
    queryFn: () => TemplateService.getComboBoxTemplate({}),
    enabled: enabled,
  });

  const dataSource = response ? response.data.map((item) => ({ label: item.Name, value: item.Id })) : [];
  return { dataSource };
};

export default useFetchComboBoxTemplate;

import { WithPermission } from 'components';
import { PATH_NAME } from 'core/constants/path-name';
import { PermissionEnum } from 'core/enums/PermissionEnum.enum';
import { lazy, Suspense } from 'react';
const DocumentStorage = lazy(() => import('pages/DocumentStorage/DocumentStorage'));

// In mã vạch
const BarcodeList = lazy(() => import('pages/DocumentStorage/Children/BarcodeList'));
const BarcodeListPage = WithPermission(BarcodeList);
// Tra cứu hồ sơ
const DocumentSearchDetail = lazy(() => import('pages/DocumentStorage/Children/DocumentSearch/DocumentSearchDetail'));
const DocumentSearchDetailPage = WithPermission(DocumentSearchDetail);
const DocumentSearchLayout = lazy(() => import('pages/DocumentStorage/Children/DocumentSearch/DocumentSearchLayout'));
const DocumentSearchList = lazy(() => import('pages/DocumentStorage/Children/DocumentSearch/DocumentSearchList'));
const DocumentSearchListPage = WithPermission(DocumentSearchList);

// Tra cứu tài liệu
const DocumentComponentSearchLayout = lazy(
  () => import('pages/DocumentStorage/Children/DocumentComponentSearch/DocumentComponentSearchLayout'),
);
const DocumentComponentSearchList = lazy(
  () => import('pages/DocumentStorage/Children/DocumentComponentSearch/DocumentComponentSearchList'),
);
const DocumentComponentSearchListPage = WithPermission(DocumentComponentSearchList);
const DocumentComponentDetail = lazy(() => import('pages/Share/components/DocumentComponentDetail'));
const DocumentComponentDetailPage = WithPermission(DocumentComponentDetail);

// Di chuyển hồ sơ
const MoveDocument = lazy(() => import('pages/DocumentStorage/Children/MoveDocument/MoveDocument'));
const MoveDocumentPage = WithPermission(MoveDocument);
const MoveDocumentDetail = lazy(() => import('pages/DocumentStorage/Children/MoveDocument/MoveDocumentDetail'));
const MoveDocumentDetailPage = WithPermission(MoveDocumentDetail);
const MoveDocumentLayout = lazy(() => import('pages/DocumentStorage/Children/MoveDocument/MoveDocumentLayout'));

// Xếp hộp cặp
const SortBox = lazy(() => import('pages/DocumentStorage/Children/SortBox/SortBox'));
const SortBoxPage = WithPermission(SortBox);

// Đóng băng hồ sơ
const FreezingDocument = lazy(() => import('pages/DocumentStorage/Children/FreezingDocument/FreezingDocument'));
const FreezingDocumentPage = WithPermission(FreezingDocument);
const FreezingDocumentDetail = lazy(
  () => import('pages/DocumentStorage/Children/FreezingDocument/FreezingDocumentDetail'),
);
const FreezingDocumentDetailPage = WithPermission(FreezingDocumentDetail);
const FreezingDocumentLayout = lazy(
  () => import('pages/DocumentStorage/Children/FreezingDocument/FreezingDocumentLayout'),
);

// Phiếu di chuyển hồ sơ
const TransferDocumentComponent = lazy(
  () => import('pages/DocumentStorage/Children/TransferDocumentComponent/TransferDocumentComponent'),
);
const TransferDocumentComponentPage = WithPermission(TransferDocumentComponent);

const DOCUMENT_STORAGE = {
  path: `${PATH_NAME.DOCUMENT_STORAGE.BASE}`,
  element: (
    <Suspense fallback={'Đang tải...'}>
      <DocumentStorage />
    </Suspense>
  ),
  children: [
    {
      index: true,
      element: (
        <Suspense fallback={'Đang tải...'}>
          <BarcodeListPage requiredPermissions={[PermissionEnum.BARCODE_VIEW]} />
        </Suspense>
      ),
    },
    {
      path: 'in-ma-vach',
      element: (
        <Suspense fallback={'Đang tải...'}>
          <BarcodeListPage requiredPermissions={[PermissionEnum.BARCODE_VIEW]} />
        </Suspense>
      ),
    },
    {
      path: 'tra-cuu-ho-so',
      element: (
        <Suspense fallback={'Đang tải...'}>
          <DocumentSearchLayout />
        </Suspense>
      ),
      children: [
        {
          index: true,
          element: (
            <Suspense fallback={'Đang tải...'}>
              <DocumentSearchListPage requiredPermissions={[PermissionEnum.DOCUMENT_SEARCH_VIEW]} />
            </Suspense>
          ),
        },
        {
          path: '',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <DocumentSearchListPage requiredPermissions={[PermissionEnum.DOCUMENT_SEARCH_VIEW]} />
            </Suspense>
          ),
        },
        {
          path: 'chi-tiet-ho-so/:id',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <DocumentSearchDetailPage requiredPermissions={[PermissionEnum.DOCUMENT_SEARCH_DETAIL]} />
            </Suspense>
          ),
        },
        {
          path: 'chi-tiet-tai-lieu/:id',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <DocumentComponentDetailPage requiredPermissions={[PermissionEnum.DOCUMENT_SEARCH_DETAIL_DOC_COMP]} />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: 'tra-cuu-tai-lieu',
      element: (
        <Suspense fallback={'Đang tải...'}>
          <DocumentComponentSearchLayout />
        </Suspense>
      ),
      children: [
        {
          index: true,
          element: (
            <Suspense fallback={'Đang tải...'}>
              <DocumentComponentSearchListPage requiredPermissions={[PermissionEnum.DOCUMENT_COMPONENT_SEARCH_VIEW]} />
            </Suspense>
          ),
        },
        {
          path: '',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <DocumentComponentSearchListPage requiredPermissions={[PermissionEnum.DOCUMENT_COMPONENT_SEARCH_VIEW]} />
            </Suspense>
          ),
        },
        {
          path: 'chi-tiet-tai-lieu/:id',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <DocumentComponentDetailPage requiredPermissions={[PermissionEnum.DOCUMENT_COMPONENT_SEARCH_DETAIL]} />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: 'xep-hop-cap',
      element: (
        <Suspense fallback={'Đang tải...'}>
          <SortBoxPage requiredPermissions={[PermissionEnum.SORT_BOX_VIEW]} />
        </Suspense>
      ),
    },
    {
      path: 'di-chuyen-ho-so',
      element: (
        <Suspense fallback={'Đang tải...'}>
          <MoveDocumentLayout />
        </Suspense>
      ),
      children: [
        {
          index: true,
          element: (
            <Suspense fallback={'Đang tải...'}>
              <MoveDocumentPage requiredPermissions={[PermissionEnum.MOVE_DOCUMENT_VIEW]} />
            </Suspense>
          ),
        },
        {
          path: 'chi-tiet-ho-so/:id',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <MoveDocumentDetailPage requiredPermissions={[PermissionEnum.MOVE_DOCUMENT_DETAIL]} />
            </Suspense>
          ),
        },
        {
          path: 'chi-tiet-tai-lieu/:id',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <DocumentComponentDetailPage requiredPermissions={[PermissionEnum.MOVE_DOCUMENT_DETAIL_DOC_COMP]} />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: 'dong-bang-ho-so',
      element: (
        <Suspense fallback={'Đang tải...'}>
          <FreezingDocumentLayout />
        </Suspense>
      ),
      children: [
        {
          index: true,
          element: (
            <Suspense fallback={'Đang tải...'}>
              <FreezingDocumentPage requiredPermissions={[PermissionEnum.FREEZING_DOCUMENT_VIEW]} />
            </Suspense>
          ),
        },
        {
          path: 'chi-tiet-ho-so/:id',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <FreezingDocumentDetailPage requiredPermissions={[PermissionEnum.FREEZING_DOCUMENT_DETAIL]} />
            </Suspense>
          ),
        },
        {
          path: 'chi-tiet-tai-lieu/:id',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <DocumentComponentDetailPage requiredPermissions={[PermissionEnum.FREEZING_DOCUMENT_DETAIL_DOC_COMP]} />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: 'phieu-di-chuyen-tai-lieu',
      element: (
        <Suspense fallback={'Đang tải...'}>
          <TransferDocumentComponentPage requiredPermissions={[PermissionEnum.TRANSFER_DOCUMENT_VIEW]} />
        </Suspense>
      ),
    },
  ],
};

export default DOCUMENT_STORAGE;

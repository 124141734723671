import { ComboBoxItem } from 'core/interface/combo-box-item';
import { Paging } from 'core/interface/paging';
import { ResponseWithPaging } from 'core/interface/response-has-paging';
import httpClient from 'services/http-client';
import { OrganizationPayload } from 'services/organization/organization.model';
import { DocumentClassification } from './document-classification.model';

export const PREFIX_DOCUMENT_CLASSIFICATION = '/api/DocumentClassification';

const getComboBoxDocumentClassification = (payload: OrganizationPayload) => {
  return httpClient.post<ComboBoxItem[]>(`${PREFIX_DOCUMENT_CLASSIFICATION}/getComboboxItem`, payload);
};
const createDocumentClassification = (payload: DocumentClassification) => {
  return httpClient.post<DocumentClassification[]>(`${PREFIX_DOCUMENT_CLASSIFICATION}`, payload);
};
const updateDocumentClassification = (payload: DocumentClassification) => {
  return httpClient.put<DocumentClassification[]>(`${PREFIX_DOCUMENT_CLASSIFICATION}`, payload);
};

const getDocumentClassification = (id: number) => {
  return httpClient.get<DocumentClassification>(`${PREFIX_DOCUMENT_CLASSIFICATION}/id=${id}`);
};
const searchDocumentClassification = (payload: DocumentClassification, paging: Paging) => {
  return httpClient.post<ResponseWithPaging<DocumentClassification[]>>(
    `${PREFIX_DOCUMENT_CLASSIFICATION}/search`,
    payload,
    {
      headers: {
        PageOption: JSON.stringify(paging),
      },
    },
  );
};
const deleteDocumentClassification = (id: number) => {
  return httpClient.delete(`${PREFIX_DOCUMENT_CLASSIFICATION}`, { params: { id: id } });
};

const DocumentClassificationService = {
  getComboBoxDocumentClassification,
  createDocumentClassification,
  searchDocumentClassification,
  updateDocumentClassification,
  getDocumentClassification,
  deleteDocumentClassification,
};

export default DocumentClassificationService;

import { useQuery } from '@tanstack/react-query';
import { DocumentPlanService } from 'services';
import { DocumentPlanPayload } from 'services/document-plan/document-plan.model';

const useFetchComboboxItemHaveDocumentFinish = (enabled?: number | boolean, payload?: DocumentPlanPayload) => {
  const { data: response } = useQuery({
    queryKey: ['COMBO_BOX_ITEM_DOCUMENT_PLAN_KEY', payload],
    queryFn: () => DocumentPlanService.getComboboxItemHaveDocumentFinish(payload ? payload : {}),
    enabled: !!enabled,
    keepPreviousData: true,
  });

  const dataSource = response ? response.data.map((item) => ({ label: item.Name, value: item.Id })) : [];
  return { dataSource };
};

export default useFetchComboboxItemHaveDocumentFinish;

import { PagingOption } from 'core/constants/paging-option';
import { Paging } from 'core/interface/paging';
import { useState } from 'react';

const usePaging = (sortBy?: string, currentPage = PagingOption.CURRENT_PAGE, pageSize = PagingOption.PAGE_SIZE) => {
  const [paging, setPaging] = useState<Paging>({
    CurrentPage: currentPage,
    PageSize: pageSize,
    Paging: true,
    sortBy: sortBy ? sortBy : null,
  });

  return { paging, setPaging };
};

export default usePaging;

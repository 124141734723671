import { getBorrowFormEnumValue } from 'core/enums/borrow-form.enum';
import React, { useMemo } from 'react';

const useGetBorrowForm = () => {
  const BorrowFormEnumValue = getBorrowFormEnumValue();
  const dataSource = useMemo(
    () =>
      BorrowFormEnumValue.map((item) => ({
        label: item?.label as string,
        value: item?.value as unknown as string,
      })),
    [],
  );

  return { dataSource };
};

export default useGetBorrowForm;

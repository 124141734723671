import { Paging } from 'core/interface/paging';
import { ResponseWithPaging } from 'core/interface/response-has-paging';
import { DocumentComponent, DocumentComponentPayload } from 'services/document-component/document-component.model';
import httpClient from 'services/http-client';

export const PREFIX_DOCUMENT_COMPONENT = '/api/DocumentComponent';

const getDocumentComponent = (payload: DocumentComponentPayload, paging: Paging) => {
  return httpClient.post<ResponseWithPaging<DocumentComponent[]>>(`${PREFIX_DOCUMENT_COMPONENT}/search`, payload, {
    headers: {
      PageOption: JSON.stringify(paging),
    },
  });
};
const getDocumentMining = (payload: DocumentComponentPayload, paging: Paging) => {
  return httpClient.post<ResponseWithPaging<DocumentComponent[]>>(
    `${PREFIX_DOCUMENT_COMPONENT}/searchDocumentMining`,
    payload,
    {
      headers: {
        PageOption: JSON.stringify(paging),
      },
    },
  );
};
const getDocumentComponentNotPaging = (payload: DocumentComponentPayload) => {
  return httpClient.post<DocumentComponent[]>(`${PREFIX_DOCUMENT_COMPONENT}/search`, payload);
};
const createDocumentComponent = (payload: DocumentComponent) => {
  return httpClient.post(`${PREFIX_DOCUMENT_COMPONENT}`, payload);
};
const exportListDocumentComponent = (payload: DocumentComponent) => {
  return httpClient.post(`${PREFIX_DOCUMENT_COMPONENT}/ExportListDocumentComponent`, payload, {
    responseType: 'blob',
  });
};
const exportListDocumentComponentInCatalog = (payload: DocumentComponent) => {
  return httpClient.post(`${PREFIX_DOCUMENT_COMPONENT}/ExportListDocumentComponentInCatalog`, payload, {
    responseType: 'blob',
  });
};
const exportListHistoryStorageDocumentComponent = (payload: DocumentComponent) => {
  return httpClient.post(`${PREFIX_DOCUMENT_COMPONENT}/ExportListHistoryStorageDocumentComponent`, payload, {
    responseType: 'blob',
  });
};
const updateDocumentComponent = (payload: DocumentComponent) => {
  return httpClient.put(`${PREFIX_DOCUMENT_COMPONENT}`, payload);
};
const getDocumentComponentDetail = (id: number) => {
  return httpClient.get<DocumentComponent>(`${PREFIX_DOCUMENT_COMPONENT}/id=${id}`);
};
const getDocumentComponentSearchExploit = (payload: DocumentComponentPayload) => {
  return httpClient.post<DocumentComponent[]>(`${PREFIX_DOCUMENT_COMPONENT}/searchExploit`, payload);
};
const deleteDocumentComponent = (id: number) => {
  return httpClient.delete<DocumentComponent[]>(`${PREFIX_DOCUMENT_COMPONENT}`, { params: { id } });
};
const deleteListDocumentComponent = (idList: number[]) => {
  return httpClient.delete<DocumentComponent[]>(`${PREFIX_DOCUMENT_COMPONENT}/DeleteList`, { data: idList });
};

const DocumentComponentService = {
  getDocumentComponent,
  createDocumentComponent,
  getDocumentComponentDetail,
  updateDocumentComponent,
  getDocumentComponentNotPaging,
  getDocumentComponentSearchExploit,
  getDocumentMining,
  exportListDocumentComponent,
  exportListHistoryStorageDocumentComponent,
  exportListDocumentComponentInCatalog,
  deleteDocumentComponent,
  deleteListDocumentComponent,
};

export default DocumentComponentService;

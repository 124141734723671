import { ExclamationCircleFilled } from '@ant-design/icons';
import { QueryClient, UseMutationResult } from '@tanstack/react-query';
import { Modal } from 'antd';
import { AxiosResponse } from 'axios';
import { t } from 'i18next';
import { getErrorMessage } from 'utils';
import showNotification from 'utils/show-notification';

const { confirm } = Modal;
const showConfirmDeleteModal = (
  name = '',
  id: number,
  deleteService: UseMutationResult<AxiosResponse<any, any>, unknown, number, unknown>,
  queryClient: QueryClient,
  key: string,
) => {
  confirm({
    title: 'Xác nhận xóa?',
    icon: <ExclamationCircleFilled />,
    content: 'Bạn có chắc chắn muốn xóa ' + name,
    okText: 'Xóa',
    okType: 'danger',
    cancelText: 'Đóng',
    onOk() {
      deleteService
        .mutateAsync(id)
        .then((_) => {
          showNotification(
            'success',
            t('notification.success'),
            `${name.charAt(0).toUpperCase() + name.slice(1)} đã được xoá`,
          );
          queryClient.invalidateQueries({ queryKey: [key] });
        })
        .catch((error) => {
          const message = error?.response?.data;
          showNotification(
            'error',
            t('notification.error'),
            typeof message === 'string' ? message : 'Lỗi không xác định, vui lòng thử lại',
          );
        });
    },
  });
};
export default showConfirmDeleteModal;

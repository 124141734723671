import { ComboBoxItem } from 'core/interface/combo-box-item';
import { Paging } from 'core/interface/paging';
import { ResponseWithPaging } from 'core/interface/response-has-paging';
import httpClient from 'services/http-client';
import {
  CaseBoxInOrganization,
  ComboBoxTree,
  Organization,
  OrganizationPayload,
} from 'services/organization/organization.model';

export const PREFIX_AUTH = '/api/Organization';

const getComboBoxOrganization = (payload: OrganizationPayload) => {
  return httpClient.post<ComboBoxItem[]>(`${PREFIX_AUTH}/getComboboxItem`, payload);
};
const getComboBoxOrganizationTree = (payload: OrganizationPayload) => {
  return httpClient.post<ComboBoxTree[]>(`${PREFIX_AUTH}/getComboboxItem`, { ...payload, IsCase: true });
};
const createOrganization = (payload: Organization) => {
  return httpClient.post<Organization[]>(`${PREFIX_AUTH}`, payload);
};
const updateOrganization = (payload: Organization) => {
  return httpClient.put<Organization[]>(`${PREFIX_AUTH}`, payload);
};

const getOrganization = (id: number) => {
  return httpClient.get<Organization>(`${PREFIX_AUTH}/id=${id}`);
};
const getOrganizationToTable = (payload: Organization, paging: Paging) => {
  return httpClient.post<ResponseWithPaging<Organization[]>>(`${PREFIX_AUTH}/getDataToTableCase`, payload, {
    headers: {
      PageOption: JSON.stringify(paging),
    },
  });
};
const caseBoxInOrganization = (id: number) => {
  return httpClient.get<CaseBoxInOrganization>(`${PREFIX_AUTH}/CaseBoxInOrganization`, { params: { id } });
};

const searchOrganization = (payload: Organization, paging: Paging) => {
  return httpClient.post<ResponseWithPaging<Organization[]>>(`${PREFIX_AUTH}/search`, payload, {
    headers: {
      PageOption: JSON.stringify(paging),
    },
  });
};
const searchOrganizationCombobox = (payload: Organization) => {
  return httpClient.post<Organization[]>(`${PREFIX_AUTH}/search`, payload);
};
const getComboboxItemByDocumentPlan = (documentPlanId: number) => {
  return httpClient.get<ComboBoxItem[]>(`${PREFIX_AUTH}/getComboboxItemByDocumentPlan`, {
    params: {
      documentPlanId,
    },
  });
};
const deleteOrganization = (id: number) => {
  return httpClient.delete(`${PREFIX_AUTH}`, { params: { id: id } });
};

const exportFileExcel = (payload: Organization) => {
  return httpClient.post(`${PREFIX_AUTH}/ExportListManagerOrganzation`, payload, {
    responseType: 'blob',
  });
};

const deleteOrganizationList = (idList: number[]) => {
  return httpClient.delete(`${PREFIX_AUTH}/DeleteList`, {
    data: idList,
  });
};

const OrganizationService = {
  getComboBoxOrganization,
  createOrganization,
  searchOrganization,
  updateOrganization,
  getOrganization,
  deleteOrganization,
  getOrganizationToTable,
  searchOrganizationCombobox,
  exportFileExcel,
  deleteOrganizationList,
  getComboBoxOrganizationTree,
  getComboboxItemByDocumentPlan,
  caseBoxInOrganization,
};

export default OrganizationService;

import { setCredentials } from 'app/auth/auth.slice';
import { useAppDispatch } from 'app/hooks';
import useLocalStorage from 'hooks/useLocalStorage';
import { AuthService } from 'services/user';

const useRefreshToken = () => {
  const dispatch = useAppDispatch();
  const [refreshToken, setRefreshToken] = useLocalStorage('refreshToken', '');

  const refresh = async () => {
    if (!refreshToken) return;
    const response = await AuthService.refreshToken({ RefreshToken: refreshToken });
    const {
      AccessToken: newAccessToken,
      RefreshToken: newRefreshToken,
      AvatarLink,
      Email,
      FullName,
      Location,
      Permission,
      Phonenumber,
      UserId,
      UserName,
      CustomerType,
      ExpirationTime,
      OrganizationId,
      OrganizationName,
      OrganizationCode,
      UnitOrganizationName,
      LocationDetail,
      Passport,
    } = response.data;

    dispatch(
      setCredentials({
        accessToken: newAccessToken,
        avatarLink: AvatarLink,
        email: Email,
        fullName: FullName,
        location: Location,
        permission: Permission,
        phoneNumber: Phonenumber,
        userId: UserId,
        userName: UserName,
        customerType: CustomerType,
        expirationTime: ExpirationTime,
        organizationId: OrganizationId,
        organizationName: OrganizationName,
        organizationCode: OrganizationCode,
        unitOrganizationName: UnitOrganizationName,
        locationDetail: LocationDetail,
        passport: Passport,
      }),
    );
    setRefreshToken(newRefreshToken);

    return newAccessToken;
  };

  return refresh;
};

export default useRefreshToken;

import { Paging } from 'core/interface/paging';
import { ResponseWithPaging } from 'core/interface/response-has-paging';
import { BorrowTicket, BorrowTicketPayload } from 'services/borrow-ticket/borow-ticket.model';
import httpClient from 'services/http-client';

export const PREFIX_BORROW_TICKET = '/api/BorrowTicket';

const getBorrowTicket = (payload: BorrowTicketPayload) => {
  return httpClient.post<BorrowTicket[]>(`${PREFIX_BORROW_TICKET}/search`, payload);
};

const getBorrowTicketList = (payload: BorrowTicketPayload, paging: Paging) => {
  return httpClient.post<ResponseWithPaging<BorrowTicket[]>>(`${PREFIX_BORROW_TICKET}/search`, payload, {
    headers: {
      PageOption: JSON.stringify(paging),
    },
  });
};
const getBorrowTicketDetail = (id: number) => {
  return httpClient.get<BorrowTicket>(`${PREFIX_BORROW_TICKET}/id=${id}`);
};

const createBorrowTicket = (payload: BorrowTicket) => {
  return httpClient.post(`${PREFIX_BORROW_TICKET}`, payload);
};

const updateBorrowTicket = (payload: BorrowTicket) => {
  return httpClient.put(`${PREFIX_BORROW_TICKET}`, payload);
};

const deleteBorrowTicket = (id: number) => {
  return httpClient.delete(`${PREFIX_BORROW_TICKET}`, { params: { id: id } });
};

const BorrowTicketService = {
  getBorrowTicket,
  deleteBorrowTicket,
  getBorrowTicketList,
  createBorrowTicket,
  updateBorrowTicket,
  getBorrowTicketDetail,
};

export default BorrowTicketService;

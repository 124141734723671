import { store } from 'app/store';
import dayjs from 'dayjs';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const elasticsearch = require('elasticsearch-browser');

const LOG_INDEX = 'frontend-development';
const APP_FIELD = 'Application';
const ENV_FIELD = 'Environment';
const VERSION_FIELD = 'Version';
const USER_NAME_FIELD = 'UserName';
const ELAPSED_MS_FIELD = 'ElapsedMilliseconds';
const REQUEST_PATH_FIELD = 'RequestPath';
const URL_FIELD = 'Url';
const APP_STATE_FIELD = 'AppState';
const APP_ACTION_EVENT = 'ActionEvent';

const client = elasticsearch.Client({
  host: process.env.REACT_APP_ELK_HOST,
});

const getMessageTemplate = () => {
  const fields: string[] = [
    APP_FIELD,
    ENV_FIELD,
    VERSION_FIELD,
    USER_NAME_FIELD,
    ELAPSED_MS_FIELD,
    REQUEST_PATH_FIELD,
    URL_FIELD,
    APP_STATE_FIELD,
    APP_ACTION_EVENT,
  ];
  const template = fields.map((field) => `{${field}}`).join(' - ');

  return template;
};

const logErrorToElasticsearch = async (message: any) => {
  const url = window.location.href;
  const userName = store.getState().auth.userName;

  const body = {
    '@timestamp': `${dayjs().toISOString()}`,
    level: 'Error',
    messageTemplate: getMessageTemplate(),
    message: JSON.stringify(message),
    fields: {
      environment: process.env.NODE_ENV,
      userId: userName,
      url,
    },
  };

  try {
    await client.create({
      index: LOG_INDEX,
      type: '_doc',
      id: `${LOG_INDEX}_${dayjs().toISOString()}`,
      body: JSON.stringify(body),
    });
  } catch (error) {
    console.error(error);
  }
};

export default logErrorToElasticsearch;

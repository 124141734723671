import React from 'react';

interface Props {
  className?: string;
}

const Close = (props: Props) => {
  return (
    <svg height='24' width='24' fill='none' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M18.9296 3.37329C19.3592 2.94372 20.0557 2.94372 20.4853 3.37329C20.9149 3.80287 20.9149 4.49935 20.4853 4.92893C20.4614 4.95276 20.4368 4.97528 20.4114 4.99647L20.4146 4.99964L13.4849 11.9293L20.556 19.0004L20.5528 19.0035C20.5782 19.0247 20.6029 19.0472 20.6267 19.0711C21.0563 19.5006 21.0563 20.1971 20.6267 20.6267C20.1971 21.0563 19.5006 21.0563 19.0711 20.6267C19.0472 20.6029 19.0247 20.5782 19.0035 20.5528L19.0004 20.556L11.9293 13.4849L4.92969 20.4845C4.92944 20.4848 4.92919 20.485 4.92893 20.4853C4.92868 20.4855 4.92843 20.4858 4.92818 20.486L4.85822 20.556L4.85505 20.5528C4.42289 20.9135 3.77904 20.891 3.3733 20.4853C2.96756 20.0795 2.94505 19.4357 3.30576 19.0035L3.30259 19.0004L3.37284 18.9301C3.37299 18.93 3.37315 18.9298 3.3733 18.9296C3.37345 18.9295 3.37361 18.9293 3.37376 18.9292L10.3737 11.9293L3.51487 5.07051L3.51472 5.07035L3.51457 5.0702L3.44401 4.99964L3.44718 4.99647C3.08647 4.56431 3.10898 3.92046 3.51472 3.51472C3.92046 3.10898 4.56431 3.08647 4.99647 3.44718L4.99964 3.44401L5.0702 3.51457L5.07035 3.51472L5.07051 3.51487L11.9293 10.3737L18.8589 3.444L18.8621 3.44717C18.8833 3.42179 18.9058 3.39713 18.9296 3.37329Z'
        fill='#4D4D4D'
        fillRule='evenodd'
      />
    </svg>
  );
};

export default Close;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

interface AuthState {
  accessToken: string;
  expirationTime: number | null;
  userName: string;
  userId: number | null;
  permission: number[];
  phoneNumber: string;
  email: string;
  fullName: string;
  avatarLink: any;
  customerType: any;
  location: any;
  organizationName: string;
  organizationId: number | null;
  organizationCode: string | '';
  unitOrganizationName: string;
  locationDetail: string;
  passport: string;
  tokenFirebase?: string;
}
const initialState: AuthState = {
  accessToken: '',
  userName: '',
  userId: null,
  permission: [],
  phoneNumber: '',
  email: '',
  fullName: '',
  expirationTime: null,
  location: [],
  customerType: '',
  avatarLink: undefined,
  organizationName: '',
  organizationId: null,
  organizationCode: '',
  unitOrganizationName: '',
  locationDetail: '',
  passport: '',
  tokenFirebase: '',
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state: AuthState, action: PayloadAction<AuthState>) => {
      const {
        accessToken,
        userName,
        userId,
        permission,
        phoneNumber,
        email,
        fullName,
        expirationTime,
        location,
        customerType,
        avatarLink,
        organizationId,
        organizationName,
        organizationCode,
        unitOrganizationName,
        locationDetail,
        passport,
      } = action.payload;
      state.accessToken = accessToken;
      state.userName = userName;
      state.userId = userId;
      state.permission = permission;
      state.phoneNumber = phoneNumber;
      state.email = email;
      state.fullName = fullName;
      state.expirationTime = expirationTime;
      state.location = location;
      state.customerType = customerType;
      state.avatarLink = avatarLink;
      state.organizationId = organizationId;
      state.organizationName = organizationName;
      state.organizationCode = organizationCode;
      state.unitOrganizationName = unitOrganizationName;
      state.locationDetail = locationDetail;
      state.passport = passport;
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    logOut: (state: AuthState) => {},
    getTokenFirebase: (state: AuthState, action: PayloadAction<string>) => {
      state.tokenFirebase = action.payload;
    },
  },
});

export const { setCredentials, logOut, getTokenFirebase } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentToken = (state: RootState) => state.auth.accessToken;
export const selectUser = (state: RootState) => state.auth;
export const selectTokenFirebase = (state: RootState) => state.auth.tokenFirebase;

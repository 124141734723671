export enum UsingLevelEnum {
  KHONG_KHAI_THAC,
  CAN_KHAI_BAO,
  CAN_PHE_DUYET,
}
export const UsingLevelEnumValue = new Map<number, { label: string; value: UsingLevelEnum; color: string }>([
  [UsingLevelEnum.KHONG_KHAI_THAC, { label: 'Không khai thác', value: UsingLevelEnum.KHONG_KHAI_THAC, color: 'red' }],
  [UsingLevelEnum.CAN_KHAI_BAO, { label: 'Cần khai báo', value: UsingLevelEnum.CAN_KHAI_BAO, color: 'blue' }],
  [UsingLevelEnum.CAN_PHE_DUYET, { label: 'Cần phê duyệt', value: UsingLevelEnum.CAN_PHE_DUYET, color: 'gold' }],
]);
export const getUsingLevelEnumValue = () => {
  return Object.values(UsingLevelEnum)
    .filter((item) => typeof item === 'number')
    .map((item: any) => UsingLevelEnumValue.get(item));
};

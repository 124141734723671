export enum StatusDocumentPlan {
  BACK_UP,
  TAO_MOI,
  CHO_DUYET,
  TU_CHOI,
  DA_DUYET,
  DA_DONG,
}

export const StatusDocumentPlanValue = new Map<number, { label: string; value: StatusDocumentPlan; color: string }>([
  [StatusDocumentPlan.CHO_DUYET, { label: 'Chờ duyệt', value: StatusDocumentPlan.CHO_DUYET, color: 'gold' }],
  [StatusDocumentPlan.DA_DUYET, { label: 'Đã duyệt', value: StatusDocumentPlan.DA_DUYET, color: 'green' }],
  [StatusDocumentPlan.TU_CHOI, { label: 'Từ chối', value: StatusDocumentPlan.TU_CHOI, color: 'red' }],
  [StatusDocumentPlan.TAO_MOI, { label: 'Tạo mới', value: StatusDocumentPlan.TAO_MOI, color: 'blue' }],
  [StatusDocumentPlan.DA_DONG, { label: 'Đã đóng', value: StatusDocumentPlan.DA_DONG, color: 'gray' }],
  [StatusDocumentPlan.BACK_UP, { label: 'Back_up', value: StatusDocumentPlan.BACK_UP, color: '' }],
]);
export const getStatusDocumentPlanValue = () => {
  return Object.values(StatusDocumentPlan)
    .filter((item) => typeof item === 'number')
    .map((item: any) => StatusDocumentPlanValue.get(item));
};

export enum BorrowFormEnum {
  KHAI_THAC_TRUC_TUYEN,
  SAO_Y_BAN_CHINH,
  BAN_CUNG,
}
export const BorrowFormEnumValue = new Map<number, { label: string; value: BorrowFormEnum }>([
  [BorrowFormEnum.BAN_CUNG, { label: 'Bản cứng', value: BorrowFormEnum.BAN_CUNG }],
  [BorrowFormEnum.KHAI_THAC_TRUC_TUYEN, { label: 'Khai thác trực tuyến', value: BorrowFormEnum.KHAI_THAC_TRUC_TUYEN }],
  [BorrowFormEnum.SAO_Y_BAN_CHINH, { label: 'Sao y bản chính', value: BorrowFormEnum.SAO_Y_BAN_CHINH }],
]);

export const getBorrowFormEnumValue = () => {
  return Object.values(BorrowFormEnum)
    .filter((item) => typeof item === 'number')
    .map((item: any) => BorrowFormEnumValue.get(item));
};

import { selectCurrentToken } from 'app/auth/auth.slice';
import { PATH_NAME } from 'core/constants/path-name';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

const ProtectedRoute = () => {
  const token = useSelector(selectCurrentToken);
  return token ? <Outlet /> : <Navigate to={PATH_NAME.LOGIN} replace />;
};

export default ProtectedRoute;

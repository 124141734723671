import { Typography } from 'antd';
import React, { ReactNode } from 'react';

interface DetailPageLayoutProps {
  title?: ReactNode;
  children: ReactNode;
  action: ReactNode;
}

const ApproveLayout = ({ title, children, action }: DetailPageLayoutProps) => {
  return (
    <div className='DetailPageLayout'>
      <div className='container'>
        {/* title */}
        <Typography.Title level={3}>{title}</Typography.Title>

        {/* content */}
        {children}

        {/* action */}
        <div className='page-action'>{action}</div>
      </div>
    </div>
  );
};

export default React.memo(ApproveLayout);

import LocationService from 'data';
import { useMemo } from 'react';

const useFetchDistrictList = (provinceId: string, districtId: string) => {
  const WardByDistrictId = useMemo(() => LocationService.getWardsByDistrict(provinceId, districtId), [districtId]);

  const dataSource = useMemo(() => {
    return WardByDistrictId.length === 0
      ? []
      : WardByDistrictId.map((ward) => ({
          key: ward.Id,
          label: ward.Name,
          value: ward.Id,
        }));
  }, [WardByDistrictId]);

  return { dataSource };
};

export default useFetchDistrictList;

import { Paging } from 'core/interface/paging';
import { ResponseWithPaging } from 'core/interface/response-has-paging';
import { DocumentTransfer, DocumentTransferPayload } from 'services/document-transfer/document-transfer.model';
import httpClient from 'services/http-client';

export const PREFIX_DOCUMENT_TRANSFER = '/api/DocumentTransfer';

const getDocumentTransfer = (payload: DocumentTransferPayload, paging: Paging) => {
  return httpClient.post<ResponseWithPaging<DocumentTransfer[]>>(`${PREFIX_DOCUMENT_TRANSFER}/search`, payload, {
    headers: {
      PageOption: JSON.stringify(paging),
    },
  });
};

const createDocumentTransfer = (payload: DocumentTransferPayload) => {
  return httpClient.post(`${PREFIX_DOCUMENT_TRANSFER}`, payload);
};
const getDocumentTransferDetail = (id: number) => {
  return httpClient.get<DocumentTransfer>(`${PREFIX_DOCUMENT_TRANSFER}/id=${id}`);
};

const DocumentTransferService = {
  getDocumentTransfer,
  createDocumentTransfer,
  getDocumentTransferDetail,
};

export default DocumentTransferService;

import { ColumnDocumentComponent, getColumnDocumentComponentValue } from 'core/enums/column-document-component';
import { useMemo } from 'react';

const useGetColumnDocumentComponent = () => {
  const columnDocumentComponent = getColumnDocumentComponentValue();
  const dataSource = useMemo(
    () =>
      columnDocumentComponent.map((item) => ({
        label: item?.label as string,
        value: item?.value as unknown as ColumnDocumentComponent,
      })),
    [],
  );
  return { dataSource };
};

export default useGetColumnDocumentComponent;

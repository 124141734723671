import { ComboBoxItem } from 'core/interface/combo-box-item';
import { Paging } from 'core/interface/paging';
import { ResponseWithPaging } from 'core/interface/response-has-paging';
import { DocumentFilterPayload, DocumentPayload, SearchHistory } from 'services/document/document.model';
import httpClient from 'services/http-client';

export const PREFIX_DOCUMENT = '/api/Document';

const getDocument = (payload: DocumentFilterPayload, paging: Paging) => {
  return httpClient.post<ResponseWithPaging<DocumentPayload[]>>(`${PREFIX_DOCUMENT}/search`, payload, {
    headers: {
      PageOption: JSON.stringify(paging),
    },
  });
};
const getSearchHistory = (payload: SearchHistory, paging: Paging) => {
  return httpClient.post<ResponseWithPaging<SearchHistory[]>>(`${PREFIX_DOCUMENT}/searchHistory`, payload, {
    headers: {
      PageOption: JSON.stringify(paging),
    },
  });
};
const getSearchHistoryDetail = (id: number) => {
  return httpClient.post<SearchHistory[]>(`${PREFIX_DOCUMENT}/searchHistory`, { Id: id });
};
const searchForPageSearchDocument = (payload: DocumentFilterPayload, paging: Paging) => {
  return httpClient.post<ResponseWithPaging<DocumentPayload[]>>(
    `${PREFIX_DOCUMENT}/searchForPageSearchDocument`,
    payload,
    {
      headers: {
        PageOption: JSON.stringify(paging),
      },
    },
  );
};
const getDocumentNotPaging = (payload: DocumentFilterPayload) => {
  return httpClient.post<DocumentPayload[]>(`${PREFIX_DOCUMENT}/search`, payload);
};

const getDocumentComboBoxItem = (payload: DocumentPayload) => {
  return httpClient.post<{ Id: number; Title: string }[]>(`${PREFIX_DOCUMENT}/getComboboxItem`, payload);
};
const getDocumentDetail = (id: number) => {
  return httpClient.get<DocumentPayload>(`${PREFIX_DOCUMENT}/id=${id}`);
};
const exportFileZipDocument = (documentId: number) => {
  return httpClient.get<Blob>(`${PREFIX_DOCUMENT}/ExportFileZipDocument`, {
    params: { documentId },
    responseType: 'blob',
  });
};

const createDocument = (payload: DocumentPayload) => {
  return httpClient.post<DocumentPayload>(`${PREFIX_DOCUMENT}`, payload);
};

const updateDocument = (payload: DocumentPayload) => {
  return httpClient.put(`${PREFIX_DOCUMENT}`, payload);
};
const updateDocumentList = (payload: DocumentPayload[]) => {
  return httpClient.put(`${PREFIX_DOCUMENT}/UpdateList`, payload);
};
const updateStatusDocumentList = (payload: DocumentPayload[]) => {
  return httpClient.put(`${PREFIX_DOCUMENT}/UpdateStatusList`, payload);
};

const deleteDocument = (id: number) => {
  return httpClient.delete(`${PREFIX_DOCUMENT}`, { params: { id: id } });
};
const deleteDocumentList = (idList: number[]) => {
  return httpClient.delete(`${PREFIX_DOCUMENT}/DeleteList`, { data: idList });
};

const exportListDocument = (payload: DocumentPayload) => {
  return httpClient.post<DocumentPayload>(`${PREFIX_DOCUMENT}/ExportListDocument`, payload, {
    responseType: 'blob',
  });
};
const exportListFreezeDocument = (payload: DocumentPayload) => {
  return httpClient.post<DocumentPayload>(`${PREFIX_DOCUMENT}/ExportListFreezeDocument`, payload, {
    responseType: 'blob',
  });
};
const exportListDocumentTransfer = (payload: DocumentPayload) => {
  return httpClient.post<DocumentPayload>(`${PREFIX_DOCUMENT}/ExportListDocumentTranfer`, payload, {
    responseType: 'blob',
  });
};
const exportListHistoryStorageDocument = (payload: DocumentPayload) => {
  return httpClient.post<DocumentPayload>(`${PREFIX_DOCUMENT}/ExportListHistoryStorageDocument`, payload, {
    responseType: 'blob',
  });
};
const exportListApproveStorageDocument = (payload: DocumentPayload) => {
  return httpClient.post<DocumentPayload>(`${PREFIX_DOCUMENT}/ExportListApproveStorageDocument`, payload, {
    responseType: 'blob',
  });
};
const exportCollectDocument = (payload: DocumentPayload) => {
  return httpClient.post<DocumentPayload>(`${PREFIX_DOCUMENT}/ExportCollectDocument`, payload, {
    responseType: 'blob',
  });
};
const exportAcceptingDocument = (payload: DocumentPayload) => {
  return httpClient.post<DocumentPayload>(`${PREFIX_DOCUMENT}/ExportAcceptingDocument`, payload, {
    responseType: 'blob',
  });
};
const exportCategoryDocument = (payload: DocumentPayload) => {
  return httpClient.post<DocumentPayload>(`${PREFIX_DOCUMENT}/ExportCategoryDocument`, payload, {
    responseType: 'blob',
  });
};
const exportSearchDocument = (payload: DocumentPayload) => {
  return httpClient.post<DocumentPayload>(`${PREFIX_DOCUMENT}/ExportSearchDocument`, payload, {
    responseType: 'blob',
  });
};
const getComboboxItemOrganization = (payload: DocumentPayload) => {
  return httpClient.post<ComboBoxItem[]>(`${PREFIX_DOCUMENT}/getComboboxItemOrganization`, payload);
};

const getComboboxItemStorageTime = (payload: DocumentPayload) => {
  return httpClient.post<ComboBoxItem[]>(`${PREFIX_DOCUMENT}/getComboboxItemStorageTime`, payload);
};

const DocumentService = {
  getDocumentNotPaging,
  getDocument,
  getDocumentDetail,
  createDocument,
  updateDocument,
  deleteDocument,
  updateDocumentList,
  updateStatusDocumentList,
  getDocumentComboBoxItem,
  deleteDocumentList,
  exportListDocument,
  exportListFreezeDocument,
  exportListDocumentTransfer,
  exportListHistoryStorageDocument,
  exportCollectDocument,
  exportAcceptingDocument,
  exportCategoryDocument,
  exportListApproveStorageDocument,
  exportSearchDocument,
  searchForPageSearchDocument,
  getComboboxItemOrganization,
  getComboboxItemStorageTime,
  exportFileZipDocument,
  getSearchHistory,
  getSearchHistoryDetail,
};

export default DocumentService;

import { WithPermission } from 'components';
import { PATH_NAME } from 'core/constants/path-name';
import { PermissionEnum } from 'core/enums/PermissionEnum.enum';

import { lazy, Suspense } from 'react';

const ReportStatistic = lazy(() => import('pages/ReportStatistics/ReportStatistics'));
const DigitalStorageInventoryStatus = lazy(
  () => import('pages/ReportStatistics/Children/DigitalStorageInventoryStatus'),
);
const BackpackStatistics = lazy(() => import('pages/ReportStatistics/Children/BackpackStatistics'));
const MiningStatistic = lazy(() => import('pages/ReportStatistics/Children/MiningStatistic'));

const DigitalStorageInventoryStatusPage = WithPermission(DigitalStorageInventoryStatus);
const BackpackStatisticsPage = WithPermission(BackpackStatistics);
const MiningStatisticPage = WithPermission(MiningStatistic);
const REPORT_STATISTICS_ROUTE = {
  path: `${PATH_NAME.REPORT_STATISTICS.BASE}`,
  element: (
    <Suspense fallback={'Đang tải...'}>
      <ReportStatistic />
    </Suspense>
  ),
  children: [
    {
      index: true,
      element: (
        <Suspense fallback={'Đang tải...'}>
          <DigitalStorageInventoryStatusPage
            requiredPermissions={[PermissionEnum.DIGITAL_STORAGE_INVENTORY_STATUS_VIEW]}
          />
        </Suspense>
      ),
    },
    {
      path: 'hien-trang-so-hoa-kho-luu-tru',
      element: (
        <Suspense fallback={'Đang tải...'}>
          <DigitalStorageInventoryStatusPage
            requiredPermissions={[PermissionEnum.DIGITAL_STORAGE_INVENTORY_STATUS_VIEW]}
          />
        </Suspense>
      ),
    },
    {
      path: 'thong-ke-hop-cap',
      element: (
        <Suspense fallback={'Đang tải...'}>
          <BackpackStatisticsPage requiredPermissions={[PermissionEnum.BACKPACK_STATISTICS_VIEW]} />
        </Suspense>
      ),
    },
    {
      path: 'thong-ke-khai-thac',
      element: (
        <Suspense fallback={'Đang tải...'}>
          <MiningStatisticPage requiredPermissions={[PermissionEnum.MINING_STATISTICS_VIEW]} />
        </Suspense>
      ),
    },
  ],
};
export default REPORT_STATISTICS_ROUTE;

import { StatusDocument } from 'core/enums/status-document';
import { getStatusDocumentComponentValue } from 'core/enums/status-document-component.enum';
import { useMemo } from 'react';

const useGetStatusDocumentComponent = () => {
  const statusDocumentComponentValue = getStatusDocumentComponentValue();
  const dataSource = useMemo(
    () =>
      statusDocumentComponentValue.map((item) => ({
        label: item?.label as string,
        value: item?.value as unknown as StatusDocument,
      })),
    [],
  );

  return { dataSource };
};

export default useGetStatusDocumentComponent;

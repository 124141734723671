import { getBorrowObjectEnumValue } from 'core/enums/borrow-object.enum';
import { useMemo } from 'react';

const useGetBorrowObject = () => {
  const BorrowObjectEnumValue = getBorrowObjectEnumValue();
  const dataSource = useMemo(
    () =>
      BorrowObjectEnumValue.map((item) => ({
        label: item?.label as string,
        value: item?.value as unknown as string,
      })),
    [],
  );

  return { dataSource };
};

export default useGetBorrowObject;

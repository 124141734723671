import { getUsageModeEnumValue } from 'core/enums/usage-mode.enum';
import { getUsingLevelEnumValue } from 'core/enums/using-level.enumn';
import React, { useMemo } from 'react';

const useGetUsingLevel = () => {
  const usingLevelEnumValue = getUsingLevelEnumValue();
  const dataSource = useMemo(
    () =>
      usingLevelEnumValue.map((item) => ({
        label: item?.label as string,
        value: item?.value as unknown as number,
      })),
    [],
  );

  return { dataSource };
};

export default useGetUsingLevel;

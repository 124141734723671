export enum StatusDocumentComponent {
  DANG_BIEN_MUC,
  HOAN_THANH,
}

export const StatusDocumentComponentValue = new Map<
  number,
  { label: string; value: StatusDocumentComponent; color: string }
>([
  [
    StatusDocumentComponent.DANG_BIEN_MUC,
    { label: 'Đang biên mục', value: StatusDocumentComponent.DANG_BIEN_MUC, color: 'gold' },
  ],
  [
    StatusDocumentComponent.HOAN_THANH,
    { label: 'Hoàn thành', value: StatusDocumentComponent.HOAN_THANH, color: 'green' },
  ],
]);
export const getStatusDocumentComponentValue = () => {
  return Object.values(StatusDocumentComponent)
    .filter((item) => typeof item === 'number')
    .map((item: any) => StatusDocumentComponentValue.get(item));
};

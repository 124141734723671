import { useQuery } from '@tanstack/react-query';
import { DocumentService } from 'services';
import { DocumentPayload } from 'services/document/document.model';

const useFetchOrganizationOfDocument = (payload?: DocumentPayload, enabled = true) => {
  const { data: response } = useQuery({
    queryKey: ['ORGANIZATION_OF_DOCUMENT_COMBO_BOX_KEY'],
    queryFn: () => DocumentService.getComboboxItemOrganization(payload ? payload : {}),
    enabled,
  });

  const dataSource = response ? response.data.map((item) => ({ label: item.Name, id: item.Id, value: item.Code })) : [];
  return { dataSource };
};

export default useFetchOrganizationOfDocument;

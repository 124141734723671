import { ComboBoxItem } from 'core/interface/combo-box-item';
import { Paging } from 'core/interface/paging';
import { ResponseWithPaging } from 'core/interface/response-has-paging';
import { Category, CategoryPayload } from 'services/category/category.model';
import httpClient from 'services/http-client';

export const PREFIX_AUTH = '/api/Category';

const getComboBoxCategory = (payload: CategoryPayload) => {
  return httpClient.post<ComboBoxItem[]>(`${PREFIX_AUTH}/getComboboxCategory`, payload);
};

const getCategoryList = (payload: CategoryPayload, paging: Paging) => {
  return httpClient.post<ResponseWithPaging<Category[]>>(`${PREFIX_AUTH}/search`, payload, {
    headers: {
      PageOption: JSON.stringify(paging),
    },
  });
};
const getCategoryDetail = (id: number) => {
  return httpClient.get<Category>(`${PREFIX_AUTH}/id=${id}`);
};

const createCategory = (payload: Category) => {
  return httpClient.post(`${PREFIX_AUTH}`, payload);
};

const updateCategory = (payload: Category) => {
  return httpClient.put(`${PREFIX_AUTH}`, payload);
};

const deleteCategory = (id: number) => {
  return httpClient.delete(`${PREFIX_AUTH}`, { params: { id: id } });
};

const deleteCategoryList = (idList: number[]) => {
  return httpClient.delete(`${PREFIX_AUTH}/DeleteList`, {
    data: idList,
  });
};

const exportFileExcel = (payload: Category) => {
  return httpClient.post(`${PREFIX_AUTH}/ExportListCategory`, payload, {
    responseType: 'blob',
  });
};

const CategoryService = {
  getComboBoxCategory,
  deleteCategory,
  getCategoryList,
  createCategory,
  updateCategory,
  getCategoryDetail,
  deleteCategoryList,
  exportFileExcel,
};

export default CategoryService;

import { TypeColumn } from 'core/enums/type-column';

export enum ColumnDocumentComponent {
  StorageOrganizationId = 'StorageOrganizationId',
  InfoSymbol = 'InfoSymbol',
  Note = 'Note',
  ActionTime = 'ActionTime',
  PhysicalStatus = 'PhysicalStatus',
  UsageMode = 'UsageMode',
  NameDocument = 'NameDocument',
  NumberDocument = 'NumberDocument',
  Content = 'Content',
  PaperCount = 'PaperCount',
  KeyWord = 'KeyWord',
  TrustLevel = 'TrustLevel',
  PageCount = 'PageCount',
  DocumentSymbol = 'DocumentSymbol',
  DocumentComponentCode = 'DocumentComponentCode',
  HistoryStorageOrganizationName = 'HistoryStorageOrganizationName',
  Code = 'Code',
  Autographs = 'Autographs',
  OrderNumber = 'OrderNumber',
  NumberSymbolOfDocument = 'NumberSymbolOfDocument',
  Author = 'Author',
}

export enum ColumnVideo {
  UsageMode = 'UsageMode',
  ActionLocation = 'ActionLocation',
  Quality = 'Quality',
  Note = 'Note',
  InfoSymbol = 'InfoSymbol',
  HistoryStorageOrganizationName = 'HistoryStorageOrganizationName',
  StorageNumber = 'StorageNumber',
  Author = 'Author',
  AddDocument = 'AddDocument',
  EventName = 'EventName',
  ActionTime = 'ActionTime',
  VideoDuration = 'VideoDuration',
  TitleFile = 'TitleFile',
  PhysicalStatus = 'PhysicalStatus',
}

export enum ColumnImage {
  UsageMode = 'UsageMode',
  CaptureSize = 'CaptureSize',
  ActionLocation = 'ActionLocation',
  Note = 'Note',
  InfoSymbol = 'InfoSymbol',
  HistoryStorageOrganizationName = 'HistoryStorageOrganizationName',
  StorageNumber = 'StorageNumber',
  Color = 'Color',
  Author = 'Author',
  AddDocument = 'AddDocument',
  EventName = 'EventName',
  ActionTime = 'ActionTime',
  TitleFile = 'TitleFile',
  PhysicalStatus = 'PhysicalStatus',
}

export const ColumnDocumentComponentValue = new Map<
  string,
  { label: string; value: ColumnDocumentComponent; typeColumn: TypeColumn }
>([
  [
    ColumnDocumentComponent.StorageOrganizationId,
    { label: 'Tác giả', value: ColumnDocumentComponent.StorageOrganizationId, typeColumn: TypeColumn.STRING },
  ],
  [
    ColumnDocumentComponent.InfoSymbol,
    { label: 'Ký hiệu thông tin', value: ColumnDocumentComponent.InfoSymbol, typeColumn: TypeColumn.STRING },
  ],
  [
    ColumnDocumentComponent.Note,
    { label: 'Ghi chú', value: ColumnDocumentComponent.Note, typeColumn: TypeColumn.STRING },
  ],
  [
    ColumnDocumentComponent.ActionTime,
    { label: 'Ngày tháng năm văn bản', value: ColumnDocumentComponent.ActionTime, typeColumn: TypeColumn.TIME },
  ],
  [
    ColumnDocumentComponent.PhysicalStatus,
    { label: 'Trình trạng vật lý', value: ColumnDocumentComponent.PhysicalStatus, typeColumn: TypeColumn.STRING },
  ],
  [
    ColumnDocumentComponent.UsageMode,
    { label: 'Chế độ sử dụng', value: ColumnDocumentComponent.UsageMode, typeColumn: TypeColumn.SELECT },
  ],
  [
    ColumnDocumentComponent.KeyWord,
    { label: 'Từ khóa', value: ColumnDocumentComponent.KeyWord, typeColumn: TypeColumn.STRING },
  ],
  [
    ColumnDocumentComponent.Content,
    { label: 'Trích yếu nội dung', value: ColumnDocumentComponent.Content, typeColumn: TypeColumn.STRING },
  ],
  [
    ColumnDocumentComponent.NameDocument,
    { label: 'Tên loại văn bản', value: ColumnDocumentComponent.NameDocument, typeColumn: TypeColumn.STRING },
  ],
  [
    ColumnDocumentComponent.PageCount,
    { label: 'Số trang', value: ColumnDocumentComponent.PageCount, typeColumn: TypeColumn.STRING },
  ],
  [
    ColumnDocumentComponent.PaperCount,
    { label: 'Tờ số/ trang số', value: ColumnDocumentComponent.PaperCount, typeColumn: TypeColumn.NUMBER },
  ],
  [
    ColumnDocumentComponent.TrustLevel,
    { label: 'Mức độ tin cậy', value: ColumnDocumentComponent.TrustLevel, typeColumn: TypeColumn.STRING },
  ],
  [
    ColumnDocumentComponent.NumberDocument,
    { label: 'Số của văn bản', value: ColumnDocumentComponent.NumberDocument, typeColumn: TypeColumn.STRING },
  ],
  [
    ColumnDocumentComponent.DocumentSymbol,
    { label: 'Số, ký hiệu của văn bản', value: ColumnDocumentComponent.DocumentSymbol, typeColumn: TypeColumn.STRING },
  ],
  [
    ColumnDocumentComponent.DocumentComponentCode,
    {
      label: 'Mã định danh văn bản',
      value: ColumnDocumentComponent.DocumentComponentCode,
      typeColumn: TypeColumn.STRING,
    },
  ],
  [
    ColumnDocumentComponent.HistoryStorageOrganizationName,
    {
      label: 'Mã định danh văn bản',
      value: ColumnDocumentComponent.HistoryStorageOrganizationName,
      typeColumn: TypeColumn.STRING,
    },
  ],
  [
    ColumnDocumentComponent.Code,
    {
      label: 'Mã hồ sơ',
      value: ColumnDocumentComponent.Code,
      typeColumn: TypeColumn.STRING,
    },
  ],
  [
    ColumnDocumentComponent.Autographs,
    {
      label: 'Bút tích',
      value: ColumnDocumentComponent.Autographs,
      typeColumn: TypeColumn.STRING,
    },
  ],
  [
    ColumnDocumentComponent.OrderNumber,
    {
      label: 'Số thứ tự văn bản',
      value: ColumnDocumentComponent.OrderNumber,
      typeColumn: TypeColumn.STRING,
    },
  ],
  [
    ColumnDocumentComponent.NumberSymbolOfDocument,
    {
      label: 'Số - ký hiệu của văn bản',
      value: ColumnDocumentComponent.NumberSymbolOfDocument,
      typeColumn: TypeColumn.STRING,
    },
  ],
  [
    ColumnDocumentComponent.Author,
    {
      label: 'Người ban hành',
      value: ColumnDocumentComponent.Author,
      typeColumn: TypeColumn.STRING,
    },
  ],
]);

export const ColumnImageValue = new Map<string, { label: string; value: ColumnImage; typeColumn: TypeColumn }>([
  [ColumnImage.UsageMode, { label: 'Chế độ sử dụng', value: ColumnImage.UsageMode, typeColumn: TypeColumn.SELECT }],
  [
    ColumnImage.ActionLocation,
    { label: 'Địa điểm chụp', value: ColumnImage.ActionLocation, typeColumn: TypeColumn.STRING },
  ],
  [ColumnImage.Color, { label: 'Màu sắc', value: ColumnImage.Color, typeColumn: TypeColumn.STRING }],
  [ColumnImage.Note, { label: 'Ghi chú', value: ColumnImage.Note, typeColumn: TypeColumn.STRING }],
  [
    ColumnImage.InfoSymbol,
    { label: 'Ký hiệu thông tin', value: ColumnImage.InfoSymbol, typeColumn: TypeColumn.STRING },
  ],
  [
    ColumnImage.HistoryStorageOrganizationName,
    { label: 'Mã cơ quan lưu trữ', value: ColumnImage.HistoryStorageOrganizationName, typeColumn: TypeColumn.STRING },
  ],
  [ColumnImage.StorageNumber, { label: 'Số lưu trữ', value: ColumnImage.StorageNumber, typeColumn: TypeColumn.STRING }],
  [ColumnImage.Author, { label: 'Tác giả', value: ColumnImage.Author, typeColumn: TypeColumn.STRING }],
  [ColumnImage.AddDocument, { label: 'Tập đính kèm', value: ColumnImage.AddDocument, typeColumn: TypeColumn.STRING }],
  [ColumnImage.EventName, { label: 'Tên sự kiện', value: ColumnImage.EventName, typeColumn: TypeColumn.STRING }],
  [ColumnImage.ActionTime, { label: 'Thời gian', value: ColumnImage.ActionTime, typeColumn: TypeColumn.TIME }],
  [ColumnImage.CaptureSize, { label: 'Cỡ ảnh', value: ColumnImage.CaptureSize, typeColumn: TypeColumn.STRING }],
  [ColumnImage.TitleFile, { label: 'Tiêu đề ảnh', value: ColumnImage.TitleFile, typeColumn: TypeColumn.STRING }],
  [
    ColumnImage.PhysicalStatus,
    { label: 'Tình trạng vật lý', value: ColumnImage.PhysicalStatus, typeColumn: TypeColumn.STRING },
  ],
]);

export const ColumnVideoValue = new Map<string, { label: string; value: ColumnVideo; typeColumn: TypeColumn }>([
  [ColumnVideo.UsageMode, { label: 'Chế độ sử dụng', value: ColumnVideo.UsageMode, typeColumn: TypeColumn.SELECT }],
  [ColumnVideo.ActionLocation, { label: 'Địa điểm', value: ColumnVideo.ActionLocation, typeColumn: TypeColumn.STRING }],
  [ColumnVideo.Quality, { label: 'Chất lượng', value: ColumnVideo.Quality, typeColumn: TypeColumn.STRING }],
  [ColumnVideo.Note, { label: 'Ghi chú', value: ColumnVideo.Note, typeColumn: TypeColumn.STRING }],
  [
    ColumnVideo.InfoSymbol,
    { label: 'Ký hiệu thông tin', value: ColumnVideo.InfoSymbol, typeColumn: TypeColumn.STRING },
  ],
  [
    ColumnVideo.HistoryStorageOrganizationName,
    { label: 'Mã cơ quan lưu trữ', value: ColumnVideo.HistoryStorageOrganizationName, typeColumn: TypeColumn.STRING },
  ],
  [ColumnVideo.StorageNumber, { label: 'Số lưu trữ', value: ColumnVideo.StorageNumber, typeColumn: TypeColumn.STRING }],
  [ColumnVideo.Author, { label: 'Tác giả', value: ColumnVideo.Author, typeColumn: TypeColumn.STRING }],
  [ColumnVideo.AddDocument, { label: 'Tập đính kèm', value: ColumnVideo.AddDocument, typeColumn: TypeColumn.STRING }],
  [ColumnVideo.EventName, { label: 'Tên sự kiện', value: ColumnVideo.EventName, typeColumn: TypeColumn.STRING }],
  [ColumnVideo.ActionTime, { label: 'Thời gian', value: ColumnVideo.ActionTime, typeColumn: TypeColumn.TIME }],
  [ColumnVideo.VideoDuration, { label: 'Thời lượng', value: ColumnVideo.VideoDuration, typeColumn: TypeColumn.STRING }],
  [
    ColumnVideo.TitleFile,
    { label: 'Tiêu đề phim/ âm thanh', value: ColumnVideo.TitleFile, typeColumn: TypeColumn.STRING },
  ],
  [
    ColumnVideo.PhysicalStatus,
    { label: 'Tình trạng vật lý', value: ColumnVideo.PhysicalStatus, typeColumn: TypeColumn.STRING },
  ],
]);

export const getColumnDocumentComponentValue = () => {
  return Object.values(ColumnDocumentComponent)
    .filter((item) => typeof item === 'string')
    .map((item: any) => ColumnDocumentComponentValue.get(item));
};
export const getColumnVideoValue = () => {
  return Object.values(ColumnVideo)
    .filter((item) => typeof item === 'string')
    .map((item: any) => ColumnVideoValue.get(item));
};
export const getColumnImageValue = () => {
  return Object.values(ColumnImage)
    .filter((item) => typeof item === 'string')
    .map((item: any) => ColumnImageValue.get(item));
};

import { useQuery } from '@tanstack/react-query';
import { OrganizationService } from 'services';

const useFetchComboboxItemOrganization = (enabled = true) => {
  const { data: response } = useQuery({
    queryKey: ['ORGANIZATION_COMBO_BOX_KEY'],
    queryFn: () => OrganizationService.getComboBoxOrganization({}),
    enabled,
  });

  const dataSource = response ? response.data.map((item) => ({ label: item.Name, value: item.Code, id: item.Id })) : [];
  return { dataSource };
};

export default useFetchComboboxItemOrganization;

import { Paging } from 'core/interface/paging';
import { ResponseWithPaging } from 'core/interface/response-has-paging';
import httpClient from 'services/http-client';
import { Catalogue, CataloguePayload, ComboBoxCatalogue } from 'services/own-categories/catalogue/catalogue.model';

export const PREFIX_CATALOGUE = '/api/Catalogue';

const getCatalogue = (payload: CataloguePayload, paging: Paging) => {
  return httpClient.post<ResponseWithPaging<Catalogue[]>>(`${PREFIX_CATALOGUE}/search`, payload, {
    headers: {
      PageOption: JSON.stringify(paging),
    },
  });
};
const getComboBoxCatalogue = (payload: CataloguePayload) => {
  return httpClient.post<ComboBoxCatalogue[]>(`${PREFIX_CATALOGUE}/getComboboxItem`, payload);
};
const getCatalogueDetail = (id: number) => {
  return httpClient.get<Catalogue>(`${PREFIX_CATALOGUE}/id=${id}`);
};
const createCatalogue = (payload: CataloguePayload) => {
  return httpClient.post(`${PREFIX_CATALOGUE}`, payload);
};
const exportListCatalogue = (payload: CataloguePayload) => {
  return httpClient.post(`${PREFIX_CATALOGUE}/ExportListCatalogue`, payload, {
    responseType: 'blob',
  });
};
const createCatalogueList = (payload: CataloguePayload[]) => {
  return httpClient.post(`${PREFIX_CATALOGUE}/createList`, payload);
};
const updateCatalogue = (payload: CataloguePayload) => {
  return httpClient.put(`${PREFIX_CATALOGUE}`, payload);
};
const deleteCatalogue = (id: number) => {
  return httpClient.delete(`${PREFIX_CATALOGUE}`, { params: { id: id } });
};

const CatalogueService = {
  getCatalogue,
  getCatalogueDetail,
  createCatalogue,
  updateCatalogue,
  deleteCatalogue,
  getComboBoxCatalogue,
  createCatalogueList,
  exportListCatalogue,
};

export default CatalogueService;

import { InboxOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import { File } from 'core/constants/file';
import { t } from 'i18next';
import { Dispatch } from 'react';
import { showNotification } from 'utils';

interface FilePdfComponentProps {
  setFileList: Dispatch<any>;
  preview: any;
  size?: 'large' | 'small' | 'tiny';
  disable?: boolean;
  content?: React.ReactNode;
  allowed: RegExp;
}

const { Dragger } = Upload;

const FileComponent = (props: FilePdfComponentProps) => {
  const { setFileList, preview, size = 'large', disable = false, allowed } = props;
  const uploadFileProps = {
    multiple: false,
    maxCount: 1,
    beforeUpload(file: any) {
      if (file.size > File.LIMIT_SIZE) {
        showNotification('error', t('notification.error'), 'Kích thước tập tin không quá 100MB');
        return Upload.LIST_IGNORE;
      }
      if (!allowed.exec(file.name)) {
        showNotification('error', t('notification.error'), 'Tập tin không đúng định dạng');
        return Upload.LIST_IGNORE;
      }
      return true;
    },
    onChange(info: any) {
      const { file } = info;
      setFileList(file.originFileObj);
    },
  };

  const customRequest = (options: any) => {
    const { onSuccess } = options;
    onSuccess('done');
  };
  // size large là size lớn - Tiny là rất nhỏ khi size === tiny thì có tên FilePdfComponentTiny còn lại tương tự với size khác
  const className =
    size === 'large' ? 'FileComponentLarge' : size === 'tiny' ? 'FileComponentTiny' : 'FileComponentSmall';
  return (
    <div>
      {!preview && (
        <Dragger
          name='files'
          {...uploadFileProps}
          customRequest={customRequest}
          className={`FileComponent ${className}`}
          disabled={disable}
        >
          <p className='ant-upload-text'>
            <InboxOutlined />{' '}
            <span>
              Kéo thả file vào đây để tải lên hoặc <span className='text-hight-light'>Chọn file từ thiết bị</span>
            </span>
          </p>
          <div className='ant-upload-hint'>
            {props.content ? (
              props.content
            ) : (
              <>
                <p>Kích thước tập tin không quá 100MB, định dạng file:</p>
                <p>- Với tài liệu văn bản: PDF hoặc PNG</p>
                <p>- Với tài liệu hình ảnh: JPEG</p>
                <p>- Với tài liệu video: MPEG-4, AVI, WMV</p>
              </>
            )}
          </div>
        </Dragger>
      )}
    </div>
  );
};

export default FileComponent;

import { DeleteOutlined } from '@ant-design/icons';
import { Button, Image } from 'antd';
import React from 'react';
interface ImageViewerProps {
  preview: string;
  onRemovePdfFile?: () => void;
}
const ImageViewer = (props: ImageViewerProps) => {
  const handleRemoveFile = () => {
    if (props.onRemovePdfFile) props.onRemovePdfFile();
  };
  return (
    <div className='ImageVideoViewer'>
      <div className='image-video-action' style={{ zIndex: '100' }}>
        <Button type='primary' size='small' ghost icon={<DeleteOutlined />} onClick={handleRemoveFile}>
          Xóa file
        </Button>
      </div>
      <div className='image-video'>
        <Image src={props.preview} alt='image-file' />
      </div>
    </div>
  );
};

export default ImageViewer;

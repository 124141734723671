import { AnyAction, combineReducers, configureStore, Reducer } from '@reduxjs/toolkit';
import authReducer from './auth/auth.slice';
import collapsedReducer from './menu-collapsed.slice';
import destroyReducer from './destroy-document.slice';
import filterReducer from './filter-raise.slice';
import filterDocument from './filter-document-search.slice';
import sortBox from './sort-box.slice';

const combinedReducer = combineReducers({
  auth: authReducer,
  collapsed: collapsedReducer,
  destroy: destroyReducer,
  filter: filterReducer,
  filterDocument: filterDocument,
  sortBox: sortBox,
});

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === 'auth/logOut') {
    state = {} as RootState;
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV === 'development',
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof combinedReducer>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

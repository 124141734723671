import { StatusDocumentPlan, getStatusDocumentPlanValue } from 'core/enums/status-document-plan.enum';
import React, { useMemo } from 'react';

const useGetStatusDocumentPlan = () => {
  const statusDocumentPlanValue = getStatusDocumentPlanValue();
  const dataSource = useMemo(
    () =>
      statusDocumentPlanValue.map((item) => ({
        label: item?.label as string,
        value: item?.value as StatusDocumentPlan,
      })),
    [],
  );

  return { dataSource };
};

export default useGetStatusDocumentPlan;

import { useQuery } from '@tanstack/react-query';
import { PhongLuuTruService } from 'services/own-categories';
import { PhongLuuTruPayload } from 'services/own-categories/phong-luu-tru/phong-luu-tru.model';

const useFetchPhongLuuTru = (payload?: PhongLuuTruPayload, enabled = true) => {
  const { data: response } = useQuery({
    queryKey: ['PHONG_LUU_TRU_KEY'],
    queryFn: () => PhongLuuTruService.getComboBoxPhongLuuTru(payload ? payload : {}),
    enabled: enabled,
  });

  const dataSource = response ? response.data.map((item) => ({ label: item.PhongName, value: item.Id })) : [];
  return { dataSource };
};

export default useFetchPhongLuuTru;

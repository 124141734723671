import React from 'react';

interface Props {
  className?: string;
}

const IconDocument = (props: Props) => {
  return (
    <svg height='48' width='48' fill='none' viewBox='0 0 48 48' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M18 20H20C20.5304 20 21.0391 19.7893 21.4142 19.4142C21.7893 19.0391 22 18.5304 22 18C22 17.4696 21.7893 16.9609 21.4142 16.5858C21.0391 16.2107 20.5304 16 20 16H18C17.4696 16 16.9609 16.2107 16.5858 16.5858C16.2107 16.9609 16 17.4696 16 18C16 18.5304 16.2107 19.0391 16.5858 19.4142C16.9609 19.7893 17.4696 20 18 20ZM18 24C17.4696 24 16.9609 24.2107 16.5858 24.5858C16.2107 24.9609 16 25.4696 16 26C16 26.5304 16.2107 27.0391 16.5858 27.4142C16.9609 27.7893 17.4696 28 18 28H30C30.5304 28 31.0391 27.7893 31.4142 27.4142C31.7893 27.0391 32 26.5304 32 26C32 25.4696 31.7893 24.9609 31.4142 24.5858C31.0391 24.2107 30.5304 24 30 24H18ZM40 17.88C39.9792 17.6963 39.9389 17.5153 39.88 17.34V17.16C39.7838 16.9544 39.6556 16.7653 39.5 16.6L27.5 4.6C27.3347 4.44443 27.1456 4.31616 26.94 4.22C26.8803 4.21152 26.8197 4.21152 26.76 4.22C26.5568 4.10348 26.3324 4.02869 26.1 4H14C12.4087 4 10.8826 4.63214 9.75736 5.75736C8.63214 6.88258 8 8.4087 8 10V38C8 39.5913 8.63214 41.1174 9.75736 42.2426C10.8826 43.3679 12.4087 44 14 44H34C35.5913 44 37.1174 43.3679 38.2426 42.2426C39.3679 41.1174 40 39.5913 40 38V18C40 18 40 18 40 17.88ZM28 10.82L33.18 16H30C29.4696 16 28.9609 15.7893 28.5858 15.4142C28.2107 15.0391 28 14.5304 28 14V10.82ZM36 38C36 38.5304 35.7893 39.0391 35.4142 39.4142C35.0391 39.7893 34.5304 40 34 40H14C13.4696 40 12.9609 39.7893 12.5858 39.4142C12.2107 39.0391 12 38.5304 12 38V10C12 9.46957 12.2107 8.96086 12.5858 8.58579C12.9609 8.21071 13.4696 8 14 8H24V14C24 15.5913 24.6321 17.1174 25.7574 18.2426C26.8826 19.3679 28.4087 20 30 20H36V38ZM30 32H18C17.4696 32 16.9609 32.2107 16.5858 32.5858C16.2107 32.9609 16 33.4696 16 34C16 34.5304 16.2107 35.0391 16.5858 35.4142C16.9609 35.7893 17.4696 36 18 36H30C30.5304 36 31.0391 35.7893 31.4142 35.4142C31.7893 35.0391 32 34.5304 32 34C32 33.4696 31.7893 32.9609 31.4142 32.5858C31.0391 32.2107 30.5304 32 30 32Z'
        fill='#262626'
      />
    </svg>
  );
};

export default IconDocument;

import { ComboBoxItem } from 'core/interface/combo-box-item';
import { Paging } from 'core/interface/paging';
import { ResponseWithPaging } from 'core/interface/response-has-paging';
import { DocumentPlan, DocumentPlanExport, DocumentPlanPayload } from 'services/document-plan/document-plan.model';
import httpClient from 'services/http-client';

export const PREFIX_DOCUMENT_PLAN = '/api/DocumentPlan';

const getDocumentPlan = (payload: DocumentPlanPayload, paging: Paging) => {
  return httpClient.post<ResponseWithPaging<DocumentPlan[]>>(`${PREFIX_DOCUMENT_PLAN}/search`, payload, {
    headers: {
      PageOption: JSON.stringify(paging),
    },
  });
};
const getDocumentPlanDetail = (id: number) => {
  return httpClient.get<DocumentPlan>(`${PREFIX_DOCUMENT_PLAN}/id=${id}`);
};
const getComboBoxItemDocumentPlan = (payload: DocumentPlanPayload) => {
  return httpClient.post<ComboBoxItem[]>(`${PREFIX_DOCUMENT_PLAN}/getComboboxItem`, payload);
};
const getComboboxItemHaveDocumentFinish = (payload: DocumentPlanPayload) => {
  return httpClient.post<ComboBoxItem[]>(`${PREFIX_DOCUMENT_PLAN}/getComboboxItemHaveDocumentFinish`, payload);
};

const createDocumentPlan = (payload: DocumentPlan) => {
  return httpClient.post(`${PREFIX_DOCUMENT_PLAN}`, payload);
};

const updateDocumentPlan = (payload: DocumentPlan) => {
  return httpClient.put(`${PREFIX_DOCUMENT_PLAN}`, payload);
};

const deleteDocumentPlan = (id: number) => {
  return httpClient.delete(`${PREFIX_DOCUMENT_PLAN}`, { params: { id: id } });
};
const deleteDocumentPlanList = (idList: number[]) => {
  return httpClient.delete(`${PREFIX_DOCUMENT_PLAN}/DeleteList`, {
    data: idList,
  });
};
const exportListDocumentPlan = (payload: DocumentPlanExport) => {
  return httpClient.post(`${PREFIX_DOCUMENT_PLAN}/ExportListDocumentPlan`, payload, {
    responseType: 'blob',
  });
};
const exportListApprovedDocumentPlan = (payload: DocumentPlan) => {
  return httpClient.post(`${PREFIX_DOCUMENT_PLAN}/ExportListApprovedDocumentPlan`, payload, {
    responseType: 'blob',
  });
};

const DocumentPlanService = {
  getDocumentPlan,
  getDocumentPlanDetail,
  createDocumentPlan,
  updateDocumentPlan,
  deleteDocumentPlan,
  getComboBoxItemDocumentPlan,
  deleteDocumentPlanList,
  exportListDocumentPlan,
  exportListApprovedDocumentPlan,
  getComboboxItemHaveDocumentFinish,
};

export default DocumentPlanService;

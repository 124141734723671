import React, { useState } from 'react';
import { PDFDocumentProxy } from 'pdfjs-dist/types/src/display/api';
import { pdfjs } from 'react-pdf';

const useGetNumberPagePdf = (url: any) => {
  const [numberPages, setNumberPages] = useState<number | null>(null);

  function onDocumentLoadSuccess(pdf: PDFDocumentProxy) {
    setNumberPages(pdf.numPages);
  }
  if (url) {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

    const loadingTask = pdfjs.getDocument(url);

    loadingTask.promise.then((pdf) => {
      onDocumentLoadSuccess(pdf);
    });
  }
  return { numberPages };
};

export default useGetNumberPagePdf;

import { getStatusDocumentValue, StatusDocument } from 'core/enums/status-document';
import React, { useMemo } from 'react';

const useGetStatusDocument = () => {
  const statusDocumentValue = getStatusDocumentValue();
  const dataSource = useMemo(
    () =>
      statusDocumentValue.map((item) => ({
        label: item?.label as string,
        value: item?.value as unknown as StatusDocument,
      })),
    [],
  );

  return { dataSource };
};

export default useGetStatusDocument;

import { useQuery } from '@tanstack/react-query';
import { DocumentService } from 'services';
import { DocumentPayload } from 'services/document/document.model';

const useFetchStorageTimeOfDocument = (payload?: DocumentPayload, enabled = true) => {
  const { data: response } = useQuery({
    queryKey: ['STORAGE_TIME_OF_DOCUMENT_COMBO_BOX_KEY'],
    queryFn: () => DocumentService.getComboboxItemStorageTime(payload ? payload : {}),
    enabled,
  });

  const dataSource = response ? response.data.map((item) => ({ label: item.Name, value: item.Id })) : [];
  return { dataSource };
};

export default useFetchStorageTimeOfDocument;

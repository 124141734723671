import { Paging } from 'core/interface/paging';
import { ResponseWithPaging } from 'core/interface/response-has-paging';
import { Decision, DecisionPayload } from 'services/decision/decision.model';
import httpClient from 'services/http-client';

export const PREFIX_DECISION = '/api/Decision';

const getDecision = (payload: DecisionPayload, paging: Paging) => {
  return httpClient.post<ResponseWithPaging<Decision[]>>(`${PREFIX_DECISION}/search`, payload, {
    headers: {
      PageOption: JSON.stringify(paging),
    },
  });
};
const getDecisionDetail = (id: number) => {
  return httpClient.get<Decision>(`${PREFIX_DECISION}/id=${id}`);
};

const createDecision = (payload: DecisionPayload) => {
  return httpClient.post(`${PREFIX_DECISION}`, payload);
};
const exportListDecision = (payload: DecisionPayload) => {
  return httpClient.post(`${PREFIX_DECISION}/ExportListDecision`, payload, {
    responseType: 'blob',
  });
};

const updateDecision = (payload: DecisionPayload) => {
  return httpClient.put(`${PREFIX_DECISION}`, payload);
};

const deleteDecision = (id: number) => {
  return httpClient.delete(`${PREFIX_DECISION}`, { params: { id: id } });
};

const DecisionService = {
  getDecision,
  getDecisionDetail,
  createDecision,
  updateDecision,
  deleteDecision,
  exportListDecision,
};

export default DecisionService;

import { ComboBoxItem } from 'core/interface/combo-box-item';
import { Paging } from 'core/interface/paging';
import { ResponseWithPaging } from 'core/interface/response-has-paging';
import httpClient from 'services/http-client';
import { ProfileType, ProfileTypePayload } from 'services/own-categories/profile-type/profile-type.model';

export const PREFIX_PROFILE_TYPE = '/api/ProfileType';

const getProfileType = (payload: ProfileTypePayload, paging: Paging) => {
  return httpClient.post<ResponseWithPaging<ProfileType[]>>(`${PREFIX_PROFILE_TYPE}/search`, payload, {
    headers: {
      PageOption: JSON.stringify(paging),
    },
  });
};
const getComboBoxProfileType = (payload: ProfileTypePayload) => {
  return httpClient.post<ComboBoxItem[]>(`${PREFIX_PROFILE_TYPE}/getComboboxItem`, payload);
};
const getProfileTypeDetail = (id: number) => {
  return httpClient.get<ProfileType>(`${PREFIX_PROFILE_TYPE}/id=${id}`);
};
const createProfileType = (payload: ProfileTypePayload) => {
  return httpClient.post(`${PREFIX_PROFILE_TYPE}`, payload);
};
const exportListProfileType = (payload: ProfileTypePayload) => {
  return httpClient.post(`${PREFIX_PROFILE_TYPE}/ExportListProfileType`, payload, {
    responseType: 'blob',
  });
};
const updateProfileType = (payload: ProfileTypePayload) => {
  return httpClient.put(`${PREFIX_PROFILE_TYPE}`, payload);
};
const deleteProfileType = (id: number) => {
  return httpClient.delete(`${PREFIX_PROFILE_TYPE}`, { params: { id: id } });
};

const ProfileTypeService = {
  getProfileType,
  getProfileTypeDetail,
  createProfileType,
  updateProfileType,
  deleteProfileType,
  getComboBoxProfileType,
  exportListProfileType,
};

export default ProfileTypeService;

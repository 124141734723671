import { Button, Checkbox, Space } from 'antd';
import { useState } from 'react';

const useFooter = (
  id: number | undefined,
  hide: () => void,
  handleOk?: () => void,
  mode?: 'view' | 'add' | 'edit',
  confirmLoading = false,
  isShowContinue = false,
) => {
  const [isContinue, setIsContinue] = useState(false);

  const footer = (
    <div className={!id && isShowContinue ? 'flex--row-between' : 'flex--row-end'} key='action'>
      {!id && isShowContinue ? (
        <Checkbox onChange={(e) => setIsContinue(e.target.checked)}>Thêm và tiếp tục</Checkbox>
      ) : null}
      <Space>
        <Button type='default' onClick={() => hide()}>
          Đóng
        </Button>
        {mode !== 'view' && (
          <Button type='primary' onClick={handleOk} loading={confirmLoading}>
            {id ? 'Cập nhật' : 'Thêm mới'}
          </Button>
        )}
      </Space>
    </div>
  );

  return { footer, isContinue };
};

export default useFooter;

import { LogoutOutlined, MenuFoldOutlined, MenuUnfoldOutlined, UnlockOutlined, UserOutlined } from '@ant-design/icons';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  Avatar,
  Badge,
  Button,
  Col,
  Descriptions,
  Dropdown,
  Form,
  Image,
  Input,
  Layout,
  List,
  MenuProps,
  Modal,
  Popover,
  Row,
  Skeleton,
  Typography,
} from 'antd';
import { selectTokenFirebase, selectUser } from 'app/auth/auth.slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { setCollapsedState } from 'app/menu-collapsed.slice';
import { IconDocumentSmall, Notification } from 'assets/icons';
import { CheckPermission, ModalComponent } from 'components';
import { PagingOption } from 'core/constants/paging-option';
import { PATH_NAME } from 'core/constants/path-name';
import { SYSTEM_PROPERTIES } from 'core/constants/system-properties';
import { ParentPermissionEnum, ScreenPermissionEnum } from 'core/enums/PermissionEnum.enum';
import { ScreenItemEnumValue, TypeScreen } from 'core/enums/screen-item.enum';
import { BaseModal } from 'core/interface/base-modal';
import { Paging } from 'core/interface/paging';
import dayjs from 'dayjs';
import { useModal, usePaging, useShowNotification } from 'hooks';
import { t } from 'i18next';
import React, { useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router';
import { NotificationService } from 'services';
import { NotificationResult } from 'services/notification/notification.model';
import { UserService } from 'services/user';
import { UserChangePass } from 'services/user/user/user.model';
import { formatDate, getErrorMessage, showNotification } from 'utils';
import { onMessageListener } from './../firebase';
import checkPermission from 'utils/check-permission';

const { Header: HeaderComponent } = Layout;
const PASSWORD_PATTERN = SYSTEM_PROPERTIES.PASSWORD;
const PASSWORD_MESSAGE = 'Mật khẩu không phù hợp. Vui Lòng nhập lại.';

enum ModalId {
  PASSWORD_MODAL_ID = 1,
  PROFILE_MODAL_ID = 2,
}
interface HeaderProps {
  collapsed: boolean;
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
}

type ChangeProfileProps = BaseModal;
type ChangePasswordModalProps = BaseModal;
interface ContentProps {
  notificationResponse: NotificationResult[];
  setPaging: React.Dispatch<React.SetStateAction<Paging>>;
  paging: Paging;
  totalItem: number;
  setDataNotification: React.Dispatch<React.SetStateAction<NotificationResult[]>>;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  numberNoticeReadied: boolean;
}
const confirmLogout = (tokenFirebase: string) => {
  Modal.warning({
    title: 'Đăng xuất',
    content: 'Bạn chắc chắn có muốn đăng xuất khỏi tài khoản không?',
    okText: 'Đồng ý',
    cancelText: 'Huỷ bỏ',
    okCancel: true,
    onOk: async () => {
      await UserService.firebaseUnregisterFCMToken(tokenFirebase).catch(() =>
        showNotification('error', t('notification.error'), 'Có lỗi xảy ra!'),
      );
      localStorage.clear();
      window.location.href = PATH_NAME.LOGIN;
    },
  });
};

const Title = () => {
  return (
    <Typography.Title level={5} style={{ margin: '1rem 0' }}>
      Thông báo
    </Typography.Title>
  );
};

const Content = ({
  notificationResponse,
  setPaging,
  paging,
  totalItem,
  setDataNotification,
  setIsOpen,
  numberNoticeReadied,
}: ContentProps) => {
  const { handleError } = useShowNotification();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { userName } = useAppSelector(selectUser);

  const loadMoreData = () => {
    setPaging({ CurrentPage: paging.CurrentPage + 1, PageSize: paging.PageSize, Paging: true, sortBy: null });
  };

  const handleReadOne = (data: NotificationResult) => {
    if (!checkPermission(ScreenItemEnumValue.get(data.ScreenId as number)?.permission)) {
      setIsOpen(false);
      handleError('Bạn không có quyền truy cập trang này!');

      // nếu user chưa được add vào userReader thì phải call api để add và userReader
      if (!data.UserReaded?.includes(userName)) {
        NotificationService.updateNotification({
          Id: data.Id,
          UserReaded: [...(data.UserReaded as string[]), userName],
        })
          .then(() => {
            queryClient.invalidateQueries({ queryKey: ['NUMBER_NOTICE_KEY'] });
          })
          .catch((error) => handleError(getErrorMessage(error)));
      }
      return;
    }
    if (data.UserReaded?.includes(userName)) {
      setIsOpen(false);
      if (ScreenItemEnumValue.get(data.ScreenId as number)?.type === TypeScreen.PAGE) {
        navigate(`${ScreenItemEnumValue.get(data.ScreenId as number)?.path}/${data.ObjectId}`, {
          state: { mode: 'view', isNotice: true },
          replace: true,
        });
      } else {
        navigate(`${ScreenItemEnumValue.get(data.ScreenId as number)?.path}`, {
          state: { isModal: true, objectId: data.ObjectId },
          replace: true,
        });
      }
      return;
    }
    NotificationService.updateNotification({
      Id: data.Id,
      UserReaded: [...(data.UserReaded as string[]), userName],
    })
      .then(() => {
        setIsOpen(false);
        setDataNotification([]);
        queryClient.invalidateQueries({ queryKey: ['NUMBER_NOTICE_KEY'] });
        if (ScreenItemEnumValue.get(data.ScreenId as number)?.type === TypeScreen.PAGE) {
          navigate(`${ScreenItemEnumValue.get(data.ScreenId as number)?.path}/${data.ObjectId}`, {
            state: { mode: 'view', isNotice: true },
            replace: true,
          });
        } else {
          navigate(`${ScreenItemEnumValue.get(data.ScreenId as number)?.path}`, {
            state: { isModal: true, objectId: data.ObjectId },
            replace: true,
          });
        }
      })
      .catch((error) => handleError(getErrorMessage(error)));
  };

  const handleReadAll = () => {
    const dataUpdate = notificationResponse.map((item) => {
      if (item.UserReaded?.includes(userName)) {
        return { ...item };
      } else {
        return { ...item, UserReaded: [...(item.UserReaded as string[]), userName] };
      }
    });
    NotificationService.updateReadAllNotice()
      .then(() => {
        setIsOpen(true);
        setDataNotification(dataUpdate as NotificationResult[]);
        queryClient.invalidateQueries({ queryKey: ['NUMBER_NOTICE_KEY'] });
      })
      .catch((error) => handleError(getErrorMessage(error)));
  };

  return (
    <div className='Notification'>
      <div id='scrollableDiv'>
        <InfiniteScroll
          dataLength={notificationResponse.length}
          next={loadMoreData}
          hasMore={notificationResponse.length < totalItem}
          loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
          scrollableTarget='scrollableDiv'
        >
          <List
            itemLayout='horizontal'
            dataSource={notificationResponse ?? []}
            renderItem={(item) => {
              return (
                <List.Item onClick={() => handleReadOne(item)}>
                  <List.Item.Meta
                    className={item.UserReaded?.includes(userName) ? 'readied' : 'unread'}
                    avatar={<IconDocumentSmall />}
                    title={
                      <div className='flex--row-between'>
                        <div className='flex--row-start' style={{ width: '55%' }}>
                          <Typography.Text title={item.Title} ellipsis style={{ width: '100%' }}>
                            {item.Title}
                          </Typography.Text>
                        </div>
                        <Typography.Text style={{ width: '35%', textAlign: 'right', fontSize: '11px' }}>
                          {dayjs(item.SendDate).fromNow()}
                        </Typography.Text>
                      </div>
                    }
                    description={
                      <>
                        <Typography.Paragraph style={{ fontSize: '1.4rem', fontWeight: 600, marginBottom: '0.4rem' }}>
                          {item.Content}
                        </Typography.Paragraph>
                        <Typography.Text style={{ fontSize: '1.1rem' }} italic>
                          {formatDate(item.SendDate)}
                        </Typography.Text>
                      </>
                    }
                  />
                </List.Item>
              );
            }}
          />
        </InfiniteScroll>
      </div>
      <div className='flex--row-start footer-notice'>
        <Button
          type='primary'
          ghost
          onClick={() => {
            setIsOpen(false);
            setDataNotification([]);
            navigate(PATH_NAME.NOTICE);
          }}
        >
          Xem tất cả
        </Button>
        <Button type='default' onClick={() => handleReadAll()} disabled={numberNoticeReadied}>
          Đánh dấu tất cả là đã đọc
        </Button>
      </div>
    </div>
  );
};

const NotificationHeader = () => {
  const [dataNotification, setDataNotification] = useState<NotificationResult[]>([]);
  const { paging, setPaging } = usePaging();
  const [isOpen, setIsOpen] = useState(false);
  const queryClient = useQueryClient();

  // thực hiện 1 request http tới api, lấy danh sách hồ sơ của kế hoạch
  const { data: notificationResponse } = useQuery({
    queryKey: ['NOTIFICATION_KEY', paging],
    queryFn: () => NotificationService.getNotification({}, paging),
    onSuccess({ data }) {
      setDataNotification([...dataNotification, ...data.Data]);
    },
    enabled: isOpen,
  });

  // thực hiện 1 request http tới api, lấy danh sách hồ sơ của kế hoạch
  const { data: numberNotification } = useQuery({
    queryKey: ['NUMBER_NOTICE_KEY'],
    queryFn: () => NotificationService.getNumberNotice(),
  });

  useEffect(() => {
    onMessageListener().then((payload) => {
      if (payload) {
        queryClient.invalidateQueries({ queryKey: ['NUMBER_NOTICE_KEY'] });
      }
    });
  });

  return (
    <Popover
      open={isOpen}
      placement='bottomRight'
      title={<Title />}
      content={
        <Content
          notificationResponse={dataNotification}
          paging={paging}
          setPaging={setPaging}
          totalItem={notificationResponse?.data.TotalItems ?? 0}
          setDataNotification={setDataNotification}
          setIsOpen={setIsOpen}
          numberNoticeReadied={numberNotification?.data === 0}
        />
      }
      trigger='click'
      onOpenChange={(open) => {
        setIsOpen(open);
        open
          ? setPaging({ CurrentPage: PagingOption.CURRENT_PAGE, PageSize: paging.PageSize, Paging: true, sortBy: null })
          : setDataNotification([]);
      }}
    >
      <Badge count={numberNotification?.data}>
        <Button title='Thông báo' type='link' icon={<Notification />} />
      </Badge>
    </Popover>
  );
};
const ChangePasswordModal = ({ hide, isModalOpen }: ChangePasswordModalProps) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { handleSuccess, handleError } = useShowNotification();

  const changePasswordMutation = useMutation({
    mutationFn: (payload: UserChangePass) => UserService.userChangePass(payload),
  });

  const handleOk = () => {
    const { OldPassword, NewPassword, ReNewPassword } = form.getFieldsValue();
    const payload: UserChangePass = {
      OldPassword,
      NewPassword,
      ReNewPassword,
    };
    form.validateFields().then(() => {
      changePasswordMutation
        .mutateAsync(payload)
        .then(() => {
          handleSuccess('Đổi mật khẩu thành công, Vui lòng đăng nhập lại');
          localStorage.clear();
          navigate(PATH_NAME.LOGIN);
        })
        .catch((error) => {
          handleError(getErrorMessage(error));
        });
    });
  };
  return (
    <ModalComponent title='Đổi mật khẩu' okText='Cập nhật' handleOk={handleOk} isModalOpen={isModalOpen} hide={hide}>
      <Form layout='vertical' form={form}>
        <Form.Item
          name='OldPassword'
          label='Mật khẩu hiện tại'
          rules={[
            {
              required: true,
              whitespace: true,
              message: 'Vui lòng nhập mật khẩu hiện tại.',
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name='NewPassword'
          label='Mật khẩu mới'
          rules={[
            {
              required: true,
              whitespace: true,
              message: 'Vui lòng nhập mật khẩu mới.',
            },
            {
              pattern: PASSWORD_PATTERN,
              message: PASSWORD_MESSAGE,
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name='ReNewPassword'
          label='Xác nhận mật khẩu mới'
          dependencies={['NewPassword']}
          rules={[
            ({ getFieldValue }) => ({
              validator: (_, value) => {
                if (!value || getFieldValue('NewPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Xác nhận mật khẩu chưa trùng khớp, vui lòng nhập lại.'));
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Typography.Paragraph>
          <Typography.Text strong italic>
            Lưu ý:
          </Typography.Text>
          <Typography.Text italic>
            Mật khẩu cần ít nhất 8 ký tự bao gồm: chữ hoa, chữ thường, số và ký tự đặc biệt.
          </Typography.Text>
        </Typography.Paragraph>
      </Form>
    </ModalComponent>
  );
};
const Profile = ({ hide, isModalOpen }: ChangeProfileProps) => {
  const [form] = Form.useForm();

  const {
    userName,
    email,
    fullName,
    avatarLink,
    locationDetail,
    unitOrganizationName,
    passport,
    phoneNumber,
    organizationName,
  } = useAppSelector(selectUser);

  useEffect(() => {
    form.setFieldsValue({
      UserName: userName,
      FullName: fullName,
      Email: email,
      Location: locationDetail,
      Passport: passport,
      PhoneNumber: phoneNumber,
    });
  }, [userName, fullName, email, userName, email, fullName, avatarLink, location, passport, phoneNumber, phoneNumber]);

  return (
    <ModalComponent
      title='Thông tin người dùng'
      handleOk={() => ''}
      isModalOpen={isModalOpen}
      hide={hide}
      width={800}
      footer={[]}
    >
      <Form layout='vertical' form={form} disabled>
        <Row gutter={24}>
          <Col span={10} style={{ textAlign: 'center' }}>
            <Image src={avatarLink ? avatarLink : '/images/default-avatar.jpg'} />
            <Typography.Title level={5} style={{ marginTop: '10px' }}>
              {fullName}
            </Typography.Title>
            <Descriptions column={1} labelStyle={{ fontWeight: 600 }}>
              <Descriptions.Item label='Đơn vị'>{unitOrganizationName}</Descriptions.Item>
              <Descriptions.Item label='Cơ quan'>{organizationName}</Descriptions.Item>
            </Descriptions>
          </Col>
          <Col span={14}>
            <Form.Item label='Tên đăng nhập' name='UserName'>
              <Input placeholder='Nhập tên đăng nhập' />
            </Form.Item>

            <Form.Item label='Họ và tên' name='FullName'>
              <Input placeholder='Nhập họ và tên' />
            </Form.Item>

            <Form.Item label='Email' name='Email'>
              <Input placeholder='Nhập email' />
            </Form.Item>

            <Form.Item label='Số CMND/Hộ chiếu' name='Passport'>
              <Input placeholder='Nhập CMND/Hộ chiếu' />
            </Form.Item>

            <Form.Item label='Số điện thoại' name='PhoneNumber'>
              <Input placeholder='Nhập số điện thoại' />
            </Form.Item>

            <Form.Item label='Địa chỉ' name='Location'>
              <Input placeholder='Nhập địa chỉ' />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </ModalComponent>
  );
};
const Setting = () => {
  const { userName, avatarLink } = useAppSelector(selectUser);
  const { modal, openModal, hideModal } = useModal();
  const tokenFirebase = useAppSelector(selectTokenFirebase);

  const items: MenuProps['items'] = useMemo(() => {
    return [
      {
        key: '1',
        label: <span onClick={() => openModal(ModalId.PROFILE_MODAL_ID)}>Thông tin cá nhân</span>,
        icon: <UserOutlined />,
      },
      {
        key: '2',
        label: <span onClick={() => openModal(ModalId.PASSWORD_MODAL_ID)}>Đổi mật khẩu</span>,
        icon: <UnlockOutlined />,
      },
      { type: 'divider' },
      {
        key: '3',
        label: <span onClick={() => confirmLogout(tokenFirebase as string)}>Đăng xuất</span>,
        icon: <LogoutOutlined />,
      },
    ];
  }, []);

  return (
    <>
      <div className='flex--row' style={{ alignItems: 'center', paddingRight: '1.6rem' }}>
        <Dropdown menu={{ items }} placement='bottom'>
          <Avatar
            className='mr8'
            icon={<UserOutlined />}
            src={avatarLink}
            alt='avatar'
            style={{ backgroundColor: '#87d068', cursor: 'pointer' }}
          />
        </Dropdown>

        <Typography.Text style={{ fontSize: '1.2rem' }}>Xin chào,&nbsp;</Typography.Text>
        <br />
        <Typography.Text strong style={{ fontSize: '1.4rem' }}>
          {userName}
        </Typography.Text>
      </div>
      {modal.show && ModalId.PASSWORD_MODAL_ID === modal.modalId && (
        <ChangePasswordModal isModalOpen={modal.show} hide={hideModal} />
      )}
      {modal.show && ModalId.PROFILE_MODAL_ID === modal.modalId && (
        <Profile isModalOpen={modal.show} hide={hideModal} />
      )}
    </>
  );
};

const Header = ({ collapsed, setCollapsed }: HeaderProps) => {
  const dispatch = useAppDispatch();

  return (
    <HeaderComponent className='site-layout-background' style={{ padding: 0, borderBottom: '1px solid #d9d9d9' }}>
      {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
        className: 'trigger',
        onClick: () => {
          setCollapsed(!collapsed);
          dispatch(setCollapsedState(!collapsed));
        },
      })}
      <div className='title-content-page'>HỆ THỐNG QUẢN LÝ & LƯU TRỮ ĐIỆN TỬ</div>
      <div className='flex--row-between' style={{ gap: '1.6rem' }}>
        <CheckPermission allowedPermissions={[ScreenPermissionEnum.NOTICE]}>
          <NotificationHeader />
        </CheckPermission>
        <Setting />
      </div>
    </HeaderComponent>
  );
};

export default Header;

import { Paging } from 'core/interface/paging';
import { ResponseWithPaging } from 'core/interface/response-has-paging';
import httpClient from 'services/http-client';
import { NotificationPayload, NotificationResult } from 'services/notification/notification.model';

export const PREFIX_NOTIFICATION = '/api/Notification';

const getNotification = (payload: NotificationPayload, paging: Paging) => {
  return httpClient.post<ResponseWithPaging<NotificationResult[]>>(`${PREFIX_NOTIFICATION}/search`, payload, {
    headers: {
      PageOption: JSON.stringify(paging),
    },
  });
};
const getNotificationDetail = (id: number) => {
  return httpClient.get<NotificationResult>(`${PREFIX_NOTIFICATION}/id=${id}`);
};
const getNumberNotice = () => {
  return httpClient.get<number>(`${PREFIX_NOTIFICATION}/GetNumberNotice`);
};

const createNotification = (payload: NotificationPayload) => {
  return httpClient.post(`${PREFIX_NOTIFICATION}`, payload);
};

const updateNotification = (payload: NotificationPayload) => {
  return httpClient.put(`${PREFIX_NOTIFICATION}`, payload);
};
const updateReadAllNotice = () => {
  return httpClient.put(`${PREFIX_NOTIFICATION}/updateReadAllNotice`);
};

const deleteNotification = (id: number) => {
  return httpClient.delete(`${PREFIX_NOTIFICATION}`, { params: { id: id } });
};

const NotificationService = {
  getNotification,
  getNotificationDetail,
  createNotification,
  updateNotification,
  deleteNotification,
  getNumberNotice,
  updateReadAllNotice,
};

export default NotificationService;

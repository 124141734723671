import { CloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';

interface ClearAllFilterButtonProps {
  className?: string;
  clearFilters: () => void;
}
const ClearAllFilterButton = ({ className, clearFilters }: ClearAllFilterButtonProps) => {
  return (
    <Button className={className} type='default' icon={<CloseOutlined />} onClick={() => clearFilters()}>
      Xoá tất cả bộ lọc
    </Button>
  );
};

export default ClearAllFilterButton;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

interface CollapsedState {
  isCollapsed: boolean;
}
const initialState: CollapsedState = {
  isCollapsed: false,
};

const menuCollapsedSlice = createSlice({
  name: 'collapsed',
  initialState,
  reducers: {
    setCollapsedState: (state: CollapsedState, action: PayloadAction<boolean>) => {
      state.isCollapsed = action.payload;
    },
  },
});

export const { setCollapsedState } = menuCollapsedSlice.actions;

export default menuCollapsedSlice.reducer;

export const selectCollapsedState = (state: RootState) => state.collapsed.isCollapsed;

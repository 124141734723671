import { useMutation } from '@tanstack/react-query';
import type { RcFile, UploadFile } from 'antd/es/upload/interface';
import { t } from 'i18next';
import { useState } from 'react';
import { FileStorageService } from 'services';
import showNotification from 'utils/show-notification';

const useUploadFile = () => {
  const [fileList, setFileList] = useState<any>(null);

  const uploadMutation = useMutation({
    mutationFn: (payload: FormData) => FileStorageService.uploadFile(payload),
  });
  const uploadVideoAndImagesMutation = useMutation({
    mutationFn: (payload: FormData) => FileStorageService.uploadVideoAndImages(payload),
  });

  const upload = async (fileList: UploadFile[], isImagesAndVideos: boolean) => {
    const [file] = fileList;
    const formData = new FormData();
    const fileExtension = file.name.split('.').pop();
    formData.append('UploadedFile', file as RcFile);
    formData.append('FileExtension', fileExtension as string);

    // upload file to server
    try {
      if (isImagesAndVideos) {
        const {
          data: { FileUrl, FileName },
        } = await uploadVideoAndImagesMutation.mutateAsync(formData);
        return { FileUrl, FileName };
      } else {
        const {
          data: { FileUrl, FileName },
        } = await uploadMutation.mutateAsync(formData);
        return { FileUrl, FileName };
      }
    } catch (error) {
      showNotification('error', t('notification.error'), 'Tải lên thất bại.');
    }
  };

  return { upload, fileList, setFileList };
};

export default useUploadFile;

export enum StatusDestroyDecision {
  TAO_MOI,
  CHO_DUYET_TIEU_HUY,
  DA_TIEU_HUY,
  TU_CHOI_TIEU_HUY,
  DA_KHOI_PHUC,
}

export const StatusDestroyDecisionValue = new Map<
  number,
  { label: string; value: StatusDestroyDecision; color: string }
>([
  [StatusDestroyDecision.TAO_MOI, { label: 'Tạo mới', value: StatusDestroyDecision.TAO_MOI, color: 'blue' }],
  [
    StatusDestroyDecision.CHO_DUYET_TIEU_HUY,
    { label: 'Chờ duyệt tiêu hủy', value: StatusDestroyDecision.CHO_DUYET_TIEU_HUY, color: 'gold' },
  ],
  [
    StatusDestroyDecision.DA_TIEU_HUY,
    { label: 'Đã tiêu hủy', value: StatusDestroyDecision.DA_TIEU_HUY, color: 'purple' },
  ],
  [
    StatusDestroyDecision.TU_CHOI_TIEU_HUY,
    { label: 'Từ chối tiêu hủy', value: StatusDestroyDecision.TU_CHOI_TIEU_HUY, color: 'red' },
  ],
  [
    StatusDestroyDecision.DA_KHOI_PHUC,
    { label: 'Đã khôi phục', value: StatusDestroyDecision.DA_KHOI_PHUC, color: 'green' },
  ],
]);
export const getStatusDestroyDecisionValue = () => {
  return Object.values(StatusDestroyDecision)
    .filter((item) => typeof item === 'number')
    .map((item: any) => StatusDestroyDecisionValue.get(item));
};

import { ComboBoxItem } from 'core/interface/combo-box-item';
import { Paging } from 'core/interface/paging';
import { ResponseWithPaging } from 'core/interface/response-has-paging';
import httpClient from 'services/http-client';
import { ShelfPayload } from 'services/own-categories/shelf/shelf.model';

export const PREFIX_SHELF = '/api/Shelf';

const getShelf = (payload: ShelfPayload, paging: Paging) => {
  return httpClient.post<ResponseWithPaging<ShelfPayload[]>>(`${PREFIX_SHELF}/search`, payload, {
    headers: {
      PageOption: JSON.stringify(paging),
    },
  });
};
const getComboBoxShelf = (payload: ShelfPayload) => {
  return httpClient.post<ComboBoxItem[]>(`${PREFIX_SHELF}/getComboboxItem`, payload);
};

const getShelfDetail = (id: number) => {
  return httpClient.get<ShelfPayload>(`${PREFIX_SHELF}/id=${id}`);
};
const createShelf = (payload: ShelfPayload) => {
  return httpClient.post(`${PREFIX_SHELF}`, payload);
};
const exportListShelf = (payload: ShelfPayload) => {
  return httpClient.post(`${PREFIX_SHELF}/ExportListShelf`, payload, {
    responseType: 'blob',
  });
};
const updateShelf = (payload: ShelfPayload) => {
  return httpClient.put(`${PREFIX_SHELF}`, payload);
};
const deleteShelf = (id: number) => {
  return httpClient.delete(`${PREFIX_SHELF}`, { params: { id: id } });
};

const ShelfService = {
  getShelf,
  getShelfDetail,
  createShelf,
  updateShelf,
  deleteShelf,
  getComboBoxShelf,
  exportListShelf,
};

export default ShelfService;

import { Typography } from 'antd';
import React, { useState } from 'react';

const ExpandText = ({ text }: { text: React.ReactNode }) => {
  const [expand, setExpand] = useState(false);
  const [counter, setCounter] = useState(0);

  const handleExpand = () => {
    setExpand(true);
    setCounter(!expand ? counter + 0 : counter + 1);
  };
  const handleCloseExpand = () => {
    setExpand(false);
    setCounter(!expand ? counter + 0 : counter + 1);
  };
  return (
    <div key={counter}>
      <Typography.Paragraph
        ellipsis={{
          rows: 3,
          expandable: true,
          symbol: 'Xem thêm',
          onExpand: handleExpand,
        }}
      >
        {text}
        {expand && <a onClick={handleCloseExpand}>Đóng</a>}
      </Typography.Paragraph>
    </div>
  );
};

export default ExpandText;

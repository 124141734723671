import { WithPermission } from 'components';
import { PATH_NAME } from 'core/constants/path-name';
import { PermissionEnum } from 'core/enums/PermissionEnum.enum';
import { lazy, Suspense } from 'react';

// Quản lý kế hoạch thu thập
const CollectionAndSubmission = lazy(() => import('pages/CollectionAndSubmission/CollectionAndSubmission'));
const DocumentPlanManagementLayout = lazy(
  () => import('pages/CollectionAndSubmission/Children/DocumentPlanManagement/DocumentPlanManagementLayout'),
);

const DocumentPlanManagementList = lazy(
  () => import('pages/CollectionAndSubmission/Children/DocumentPlanManagement/DocumentPlanManagementList'),
);
const DocumentPlanManagementListPage = WithPermission(DocumentPlanManagementList);

const DocumentPlanManagementDetail = lazy(
  () => import('pages/CollectionAndSubmission/Children/DocumentPlanManagement/DocumentPlanManagementDetail'),
);
const DocumentPlanManagementDetailPage = WithPermission(DocumentPlanManagementDetail);

// Duyệt kế hoạch thu thập
const ReviewDocumentPlanList = lazy(
  () => import('pages/CollectionAndSubmission/Children/ReviewDocumentPlan/ReviewDocumentPlanList'),
);
const ReviewDocumentPlanListPage = WithPermission(ReviewDocumentPlanList);

const ReviewDocumentPlanLayout = lazy(
  () => import('pages/CollectionAndSubmission/Children/ReviewDocumentPlan/ReviewDocumentPlanLayout'),
);

const ReviewDocumentPlanDetail = lazy(
  () => import('pages/CollectionAndSubmission/Children/ReviewDocumentPlan/ReviewDocumentPlanDetail'),
);
const ReviewDocumentPlanDetailPage = WithPermission(ReviewDocumentPlanDetail);

// Quản lý thu thập
const DocumentManagementList = lazy(
  () => import('pages/CollectionAndSubmission/Children/DocumentManagement/DocumentManagementList'),
);
const DocumentManagementListPage = WithPermission(DocumentManagementList);
const DocumentManagementDetail = lazy(
  () => import('pages/CollectionAndSubmission/Children/DocumentManagement/DocumentManagementDetail'),
);
const DocumentManagementDetailPage = WithPermission(DocumentManagementDetail);
const DocumentManagementLayout = lazy(
  () => import('pages/CollectionAndSubmission/Children/DocumentManagement/DocumentManagementLayout'),
);

const DocumentComponentDetail = lazy(() => import('pages/Share/components/DocumentComponentDetail'));
const DocumentComponentDetailPage = WithPermission(DocumentComponentDetail);

// Mục lục hồ sơ đã nộp lưu
const DocumentIndex = lazy(() => import('pages/CollectionAndSubmission/Children/DocumentIndex/DocumentIndex'));
const DocumentIndexPage = WithPermission(DocumentIndex);
const DocumentIndexDetail = lazy(
  () => import('pages/CollectionAndSubmission/Children/DocumentIndex/DocumentIndexDetail'),
);
const DocumentIndexDetailPage = WithPermission(DocumentIndexDetail);
const DocumentIndexLayout = lazy(
  () => import('pages/CollectionAndSubmission/Children/DocumentIndex/DocumentIndexLayout'),
);

// Quản lý biên bản bàn giao
const HandoverDocumentManagement = lazy(
  () => import('pages/CollectionAndSubmission/Children/HandoverDocumentManagement/HandoverDocumentManagement'),
);
const HandoverDocumentManagementPage = WithPermission(HandoverDocumentManagement);

const HandoverDocManagementLayout = lazy(
  () => import('pages/CollectionAndSubmission/Children/HandoverDocumentManagement/HandoverDocManagementLayout'),
);
const HandoverDocDetail = lazy(
  () => import('pages/CollectionAndSubmission/Children/HandoverDocumentManagement/HandoverDocDetail'),
);

// Tiếp nhận hồ sơ nộp lưu
const ReceiveDocument = lazy(() => import('pages/CollectionAndSubmission/Children/ReceiveDocument/ReceiveDocument'));
const ReceiveDocumentPage = WithPermission(ReceiveDocument);
const ReceiveDocumentDetail = lazy(
  () => import('pages/CollectionAndSubmission/Children/ReceiveDocument/ReceiveDocumentDetail'),
);
const ReceiveDocumentDetailPage = WithPermission(ReceiveDocumentDetail);
const ReceiveDocumentLayout = lazy(
  () => import('pages/CollectionAndSubmission/Children/ReceiveDocument/ReceiveDocumentLayout'),
);

// Duyệt và gửi hồ sơ nộp lưu
const ReviewAndSubmitDocument = lazy(
  () => import('pages/CollectionAndSubmission/Children/ReviewAndSubmitDocument/ReviewAndSubmitDocument'),
);
const ReviewAndSubmitDocumentPage = WithPermission(ReviewAndSubmitDocument);
const ReviewAndSubmitDocumentDetail = lazy(
  () => import('pages/CollectionAndSubmission/Children/ReviewAndSubmitDocument/ReviewAndSubmitDocumentDetail'),
);
const ReviewAndSubmitDocumentDetailPage = WithPermission(ReviewAndSubmitDocumentDetail);
const ReviewAndSubmitDocumentLayout = lazy(
  () => import('pages/CollectionAndSubmission/Children/ReviewAndSubmitDocument/ReviewAndSubmitDocumentLayout'),
);

const COLLECTION_AND_SUBMISSION = {
  path: `${PATH_NAME.COLLECTION_AND_SUBMISSION.BASE}`,
  element: (
    <Suspense fallback={'Đang tải...'}>
      <CollectionAndSubmission />
    </Suspense>
  ),
  children: [
    {
      index: true,
      element: (
        <Suspense fallback={'Đang tải...'}>
          <DocumentPlanManagementListPage requiredPermissions={[PermissionEnum.DOCUMENT_PLAN_VIEW]} />
        </Suspense>
      ),
    },
    {
      path: 'quan-ly-ke-hoach-thu-thap',
      element: (
        <Suspense fallback={'Đang tải...'}>
          <DocumentPlanManagementLayout />
        </Suspense>
      ),
      children: [
        {
          index: true,
          element: (
            <Suspense fallback={'Đang tải...'}>
              <DocumentPlanManagementListPage requiredPermissions={[PermissionEnum.DOCUMENT_PLAN_VIEW]} />
            </Suspense>
          ),
        },
        {
          path: '',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <DocumentPlanManagementListPage requiredPermissions={[PermissionEnum.DOCUMENT_PLAN_VIEW]} />
            </Suspense>
          ),
        },
        {
          path: 'chi-tiet-ke-hoach-thu-thap/:id',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <DocumentPlanManagementDetailPage requiredPermissions={[PermissionEnum.DOCUMENT_PLAN_DETAIL]} />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: 'duyet-ke-hoach-thu-thap',
      element: (
        <Suspense fallback={'Đang tải...'}>
          <ReviewDocumentPlanLayout />
        </Suspense>
      ),
      children: [
        {
          index: true,
          element: (
            <Suspense fallback='Đang tải...'>
              <ReviewDocumentPlanListPage requiredPermissions={[PermissionEnum.REVIEW_DOCUMENT_PLAN_VIEW]} />
            </Suspense>
          ),
        },
        {
          path: '',
          element: (
            <Suspense fallback='Đang tải...'>
              <ReviewDocumentPlanListPage requiredPermissions={[PermissionEnum.REVIEW_DOCUMENT_PLAN_VIEW]} />
            </Suspense>
          ),
        },
        {
          path: 'chi-tiet-ke-hoach-thu-thap/:id',
          element: (
            <Suspense fallback='Đang tải...'>
              <ReviewDocumentPlanDetailPage requiredPermissions={[PermissionEnum.REVIEW_DOCUMENT_PLAN_DETAIL]} />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: 'quan-ly-thu-thap',
      element: (
        <Suspense fallback={'Đang tải...'}>
          <DocumentManagementLayout />
        </Suspense>
      ),
      children: [
        {
          index: true,
          element: (
            <Suspense fallback={'Đang tải...'}>
              <DocumentManagementListPage requiredPermissions={[PermissionEnum.DOCUMENT_MANAGEMENT_VIEW]} />
            </Suspense>
          ),
        },
        {
          path: '',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <DocumentManagementListPage requiredPermissions={[PermissionEnum.DOCUMENT_MANAGEMENT_VIEW]} />
            </Suspense>
          ),
        },
        {
          path: 'chi-tiet-ho-so/:id',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <DocumentManagementDetailPage requiredPermissions={[PermissionEnum.DOCUMENT_MANAGEMENT_DETAIL]} />
            </Suspense>
          ),
        },
        {
          path: 'bien-muc-tai-lieu/:id',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <DocumentComponentDetailPage requiredPermissions={[PermissionEnum.DOCUMENT_MANAGEMENT_DETAIL]} />
            </Suspense>
          ),
        },
        {
          path: 'bien-muc-tai-lieu',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <DocumentComponentDetailPage requiredPermissions={[PermissionEnum.DOCUMENT_MANAGEMENT_DETAIL]} />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: 'duyet-va-gui-ho-so-nop-luu',
      element: (
        <Suspense fallback={'Đang tải...'}>
          <ReviewAndSubmitDocumentLayout />
        </Suspense>
      ),
      children: [
        {
          index: true,
          element: (
            <Suspense fallback={'Đang tải...'}>
              <ReviewAndSubmitDocumentPage requiredPermissions={[PermissionEnum.REVIEW_AND_SUBMIT_DOC_VIEW]} />
            </Suspense>
          ),
        },
        {
          path: '',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <ReviewAndSubmitDocumentPage requiredPermissions={[PermissionEnum.REVIEW_AND_SUBMIT_DOC_VIEW]} />
            </Suspense>
          ),
        },
        {
          path: 'chi-tiet-ho-so/:id',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <ReviewAndSubmitDocumentDetailPage requiredPermissions={[PermissionEnum.REVIEW_AND_SUBMIT_DOC_DETAIL]} />
            </Suspense>
          ),
        },
        {
          path: 'bien-muc-tai-lieu/:id',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <DocumentComponentDetailPage requiredPermissions={[PermissionEnum.REVIEW_AND_SUBMIT_DOC_DETAIL]} />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: 'tiep-nhan-ho-so-nop-luu',
      element: (
        <Suspense fallback={'Đang tải...'}>
          <ReceiveDocumentLayout />
        </Suspense>
      ),
      children: [
        {
          index: true,
          element: (
            <Suspense fallback={'Đang tải...'}>
              <ReceiveDocumentPage requiredPermissions={[PermissionEnum.RECEIVE_DOC_VIEW]} />
            </Suspense>
          ),
        },
        {
          path: '',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <ReceiveDocumentPage requiredPermissions={[PermissionEnum.RECEIVE_DOC_VIEW]} />
            </Suspense>
          ),
        },
        {
          path: 'chi-tiet-ho-so/:id',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <ReceiveDocumentDetailPage requiredPermissions={[PermissionEnum.RECEIVE_DOC_DETAIL]} />
            </Suspense>
          ),
        },
        {
          path: 'bien-muc-tai-lieu/:id',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <DocumentComponentDetailPage requiredPermissions={[PermissionEnum.RECEIVE_DOC_DETAIL]} />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: 'muc-luc-ho-so-nop-luu',
      element: (
        <Suspense fallback={'Đang tải...'}>
          <DocumentIndexLayout />
        </Suspense>
      ),
      children: [
        {
          index: true,
          element: (
            <Suspense fallback={'Đang tải...'}>
              <DocumentIndexPage requiredPermissions={[PermissionEnum.DOC_INDEX_VIEW]} />
            </Suspense>
          ),
        },
        {
          path: '',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <DocumentIndexPage requiredPermissions={[PermissionEnum.DOC_INDEX_VIEW]} />
            </Suspense>
          ),
        },
        {
          path: 'chi-tiet-ho-so/:id',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <DocumentIndexDetailPage requiredPermissions={[PermissionEnum.DOC_INDEX_DETAIL]} />
            </Suspense>
          ),
        },
        {
          path: 'bien-muc-tai-lieu/:id',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <DocumentComponentDetailPage requiredPermissions={[PermissionEnum.DOC_INDEX_DETAIL]} />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: 'quan-ly-bien-ban-ban-giao',
      element: (
        <Suspense fallback={'Đang tải...'}>
          <HandoverDocManagementLayout />
        </Suspense>
      ),
      children: [
        {
          index: true,
          element: (
            <Suspense fallback={'Đang tải...'}>
              <HandoverDocumentManagementPage requiredPermissions={[PermissionEnum.HANDOVER_DOCUMENT_VIEW]} />
            </Suspense>
          ),
        },
        {
          path: '',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <HandoverDocumentManagementPage requiredPermissions={[PermissionEnum.HANDOVER_DOCUMENT_VIEW]} />
            </Suspense>
          ),
        },
        {
          path: 'chi-tiet-bien-ban/:id',
          element: (
            <Suspense fallback={'Đang tải...'}>
              <HandoverDocDetail />
            </Suspense>
          ),
        },
      ],
    },
  ],
};
export default COLLECTION_AND_SUBMISSION;

import { store } from 'app/store';
import { ScreenPermissionEnum } from 'core/enums/PermissionEnum.enum';

const checkPermission = (allowedPermissions: any[] = []) => {
  const { permission: permissionList } = store.getState().auth;
  return permissionList?.some((permission: any) =>
    [...allowedPermissions, ScreenPermissionEnum.ADMIN].includes(permission),
  );
};

export default checkPermission;

import React from 'react';

interface Props {
  className?: string;
}

const ShelvesMove = (props: Props) => {
  return (
    <svg height='24' width='24' fill='none' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11 7.03611V7L9 7V9H11V7.03611ZM15.2921 4.57628L12.904 5.57238C12.8549 5.46872 12.7884 5.37415 12.7071 5.29289C12.6142 5.20003 12.504 5.12638 12.3827 5.07612C12.2614 5.02587 12.1313 5 12 5H9H7H4C3.86868 5 3.73864 5.02587 3.61732 5.07612C3.49599 5.12638 3.38575 5.20003 3.29289 5.29289C3.20003 5.38575 3.12638 5.49599 3.07612 5.61732C3.02587 5.73864 3 5.86868 3 6V9V11V15V17V20C3 20.1313 3.02587 20.2614 3.07612 20.3827C3.12638 20.504 3.20003 20.6142 3.29289 20.7071C3.38575 20.8 3.49599 20.8736 3.61732 20.9239C3.73864 20.9741 3.86868 21 4 21H7H9H12C12.1313 21 12.2614 20.9741 12.3827 20.9239C12.504 20.8736 12.6142 20.8 12.7071 20.7071C12.8 20.6142 12.8736 20.504 12.9239 20.3827C12.9741 20.2614 13 20.1313 13 20V17V15V12.0975L16.4076 20.3814C16.4576 20.503 16.5309 20.6134 16.6233 20.7065C16.7157 20.7995 16.8254 20.8734 16.9462 20.9237C17.0669 20.9741 17.1963 21 17.327 21C17.4577 21 17.5871 20.9741 17.7079 20.9237L21.3856 19.3897C21.5064 19.3394 21.6161 19.2656 21.7085 19.1725C21.8009 19.0794 21.8742 18.969 21.9242 18.8474C21.9743 18.7258 22 18.5955 22 18.4639C22 18.3323 21.9743 18.202 21.9242 18.0804L16.5924 5.11863C16.5424 4.99704 16.4691 4.88657 16.3767 4.79351C16.2843 4.70046 16.1746 4.62664 16.0538 4.57628C15.9331 4.52592 15.8037 4.5 15.673 4.5C15.5423 4.5 15.4129 4.52592 15.2921 4.57628ZM14.0572 9.43013L13.2955 7.57845L15.1344 6.81146L15.8961 8.66314L14.0572 9.43013ZM17.8656 18.6885L17.1039 16.8369L18.9428 16.0699L19.7045 17.9215L17.8656 18.6885ZM16.3422 14.9852L18.1811 14.2182L16.6578 10.5148L14.8189 11.2818L16.3422 14.9852ZM9 15V11H11V15H9ZM7 15V11H5V15H7ZM5 7V9H7V7H5ZM5 17H7V19H5V17ZM9 17H11V19H9V17Z'
        fill='#4D4D4D'
        fillRule='evenodd'
      />
    </svg>
  );
};

export default ShelvesMove;

import { FC } from 'react';
import { Navigate } from 'react-router';
import {} from 'utils';
import checkPermission from 'utils/check-permission';

interface WithPermissionProps {
  requiredPermissions: any[];
}

const WithPermission = <P extends object>(Component: React.ComponentType<P>) => {
  const WithPermission: FC<P & WithPermissionProps> = (props: WithPermissionProps) => {
    const { requiredPermissions, ...rest } = props;

    // Xác định xem người dùng có đủ phép cho phép cần thiết hay không
    const hasPermission = checkPermission(requiredPermissions);

    if (!hasPermission) {
      return <Navigate to='/403' />;
    }

    return <Component {...(rest as P)} />;
  };

  return WithPermission;
};

export default WithPermission;
